import bigDecimal from 'js-big-decimal';
import moment from 'moment';
import React from 'react'
import { useAppContext } from '../../AppContext';
import func from '../../services/functions';
import zIndex from '@material-ui/core/styles/zIndex';
var QRCode = require('qrcode.react');

const ThermalPrinterInvoiceContent = ({ invoice }) => {
    const {
        user: [user, setUser],
        selectedCompany: [selectedCompany],
      } = useAppContext();
      if (!invoice) {
          return <div></div>
        }
    const  companyNuis= user.companiesRights.find((companyItem) => companyItem.id === parseInt(selectedCompany))?.nuis;
    const { parties = {}, qrurl, number, items, discount, invoiceTotalWithoutVat, invoiceTotalWithVat, issueDate, opCode, businessUnitCode, iic, fic, currency, rate } = invoice;
    const urlParams = new URLSearchParams(qrurl);
    const softwareCode = urlParams.get('sw');
    const tin = urlParams.get('tin');
    var vat = [];
    vat = items.map((item) => { return { vatCategory: item.vatCategory, vatPrice: bigDecimal.subtract(item.totalWithVat, item.totalWithoutVat) } });

    const groupedVats = Array.from(vat.reduce(
        (m, { vatCategory, vatPrice }) => m.set(vatCategory, bigDecimal.add((m.get(vatCategory) || 0), vatPrice)), new Map
    ), ([vatCategory, vatPrice]) => ({ vatCategory, vatPrice }));

    const caluculateVatChar = (vatCategory) => {
        return String.fromCharCode(65 + groupedVats.findIndex((vatItem) => vatItem.vatCategory === vatCategory))
    }
    let sellerName=companyNuis=="L82413034P"?"Todo Shop":parties?.seller?.name
    let addressName=companyNuis=="L82413034P"?"Rruga Fortuzi 8,Tirane":parties?.seller?.address
    const style = { fontSize: 12, fontFamily: "'Roboto', sans-serif", zIndex: 0 }
    return <div id="thermalPrinterInvoice">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
            <div style={{ ...style, fontSize: 17 }}>{sellerName}</div>
            <div style={style}>NIPT: {tin}</div>
            <div style={style}>Adresa:{addressName}</div>
            <div style={style}>{parties?.seller?.town},</div>
            <div style={style}>{parties?.seller?.country} </div>
            <div style={{...style,marginBottom:"5px"}}>Kodi i software: {softwareCode}</div>
            {parties?.buyer?.name && <div style={{...style,fontSize:16}}>Bleresi: {parties.buyer.name}</div>}
            {parties?.buyer?.tin && <div style={{ ...style, fontSize: 16 }}> {parties?.buyer?.buyerIDType ?? ""}: {parties.buyer.tin}</div>}
        </div>
        <hr style={{ borderTop: "dotted 1px" }} />
        <div style={{ ...style, fontSize: 17, textAlign: "center" }}>Fature tatimore shitje: {number}</div>
        <br />
        {items.map((item) => {
            return <>
                <div style={{ display: "flex", justifyContent: "start", }}>
                    <div style={style}>{item.quantity} x {item?.priceWithVat!==null ? parseFloat(item?.priceWithVat).toFixed(2): ""} </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={style}>{item.name} </div>
                    <div style={style}>{parseFloat(item.totalWithVat).toFixed(2)} {String.fromCharCode(65 + groupedVats.findIndex((vatItem) => vatItem.vatCategory === item.vatCategory))}</div>
                </div>
            </>
        })}
        <br />
        {groupedVats.map((vatItem) => {
            return (<div key={vatItem.id} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={style}> TVSH {vatItem.vatCategory}% {caluculateVatChar(vatItem.vatCategory)}</div>
                <div style={style}>{parseFloat(vatItem.vatPrice).toFixed(2)}  {caluculateVatChar(vatItem.vatCategory)}</div>
            </div>)
        })}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={style}>Vlera pa TVSH</div>
            <div style={style}>{func.getFormattedMoneyValue(
                  invoice.invoiceDiscountedTotalWithoutVat
                )}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={style}>Total TVSH</div>
            <div style={style}>{func.getFormattedMoneyValue(invoice.invoiceTotalVat)}</div>
        </div>
        <hr style={{ borderTop: "dotted 1px" }} />
        {currency !== "ALL" ?

            (
                <>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={style}>Total ({currency})</div>
                        <div style={style}>{parseFloat(invoiceTotalWithVat).toFixed(2)}</div>
                    </div>
                    <br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={style}>Total (ALL)</div>
                        <div style={style}>{parseFloat(bigDecimal.multiply(invoiceTotalWithVat, rate)).toFixed(2)}</div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={style}>Kursi Kembimit</div>
                        <div style={style}>{rate}</div>
                    </div>
                </>
            ) :
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={style}>Total ({currency})</div>
                <div style={style}>{parseFloat(invoiceTotalWithVat).toFixed(2)}</div>
            </div>
        }
        {/* {currency !== "ALL" &&
           
        } */}
        <br />
        <div style={{ textAlign: "center", }}>
            <div style={style}>Data dhe ora: {moment(issueDate).format("DD-MM-YYYY HH:mm")}</div>
            <div style={style}>Operatori: {opCode}</div>
            <div style={style}>Kodi i biznesit: {businessUnitCode}</div>
            <br />
            <div style={{ ...style, fontSize: 8.5 }}>NSLF: {iic}</div>
            <div style={{ ...style, fontSize: 8.5 }}>NIVF: {fic}</div>
            <hr style={{ borderTop: "dotted 1px" }} />
            <div style={{ display: "flex", justifyContent: "center" }}>
            </div>
            {qrurl && <QRCode renderAs="svg" value={qrurl} size={150} imageSettings={{ src: ("https://profisc.al/wp-content/uploads/2021/10/profisc-logo-print.png"), height: 20, width: 22, excavate: true }} />}
        </div>
        <hr style={{ borderTop: "dotted 1px" }} />
        <div style={{ ...style, textAlign: "center" }}>Gjeneruar nga ProFisc (www.profisc.al)</div>
        {process.env.REACT_APP_SERVER === 'DEMO' && (
            <div style={{
                display: 'flex',
                justifyContent: "center",
                transform: "translateY(-18%)",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1000
            }}>

                <div
                    style={{
                        fontSize: "65px",
                        fontWeight: "bold",
                        transform: "rotate(-45deg)",
                        color: "#ccc",
                        zIndex: -1000
                    }}
                >
                    TEST COUPON
                </div>
            </div>
        )}


    </div >
}


export default ThermalPrinterInvoiceContent
