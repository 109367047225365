import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import func, { loadingAction, swalAlert } from "../../services/functions";
import mainAxios from "../../services/mainAxios";
import generateQueueColumns from "./columns/generateQueueColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import { useAppContext, usePersistedState } from "../../AppContext";
import Swal from "sweetalert2";
// import ExportQueue from "./ExportQueue"
import { useTranslation } from "react-i18next";
import Select from "react-select";
import selectStyle from "../../design/selectStyle";
import DateRange from "../global/DateRange";
import AlertSubscription from "../global/AlertSubscription";
import ExportCsvPrime from "../global/ExportCsvPrime";
import PrimeTable, { onColReorder } from "../PrimeTable/PrimeTable";
import { Column } from "primereact/column";
import AlertCertificate from "../global/AlertCertificate";
import AdditionalSaleStatistics from "./AdditionalSaleStatistics";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { globalFilterStatisticCard } from "../global/StatisticsCards";
import CisMessage from "../global/CisMessage";





const moment = require("moment");

const SaleInvoices = () => {
  const { t,i18n } = useTranslation("translations");

  const [uIType, setUIType] = useState({
    value: "eic",
    label: "EIC",
    uidValue: "",
  });

  const [queues, setQueues] = useState(null);
  const [pdfToDisplay, setPdfToDisplay] = useState(null);
  const [additonalStatsVisible,setAdditonalStatsVisible]=useState(false)

  const defaultExpand = {
    invoiceItems: [],
    expandedRows: null,
    loadingInvoiceItems: false,
    expandedInvoiceRows: [],
    expandedInvoiceData: null,
    loadingInvoiceData: false
  }
  const [expanded, setExpanded] = useState(defaultExpand)


  const {
    user: [user],
    selectedCompany: [selectedCompany],
    queueColumns: [queueColumns, setQueueColumns],
    company: [fullCompany],
    dates: [dates, setDates],
  } = useAppContext();

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const [loading, setLoading] = useState(false);
  const [loadingRefreshStatus, setLoadingRefreshStatus] = useState({
    loading: false,
    index: null,
  });
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingPdf, setLoadingPdf] = React.useState(false);

  const refreshAllStatuses = useRef(null);
  const [persistedData, setPersistedData] = usePersistedState(
    null,
    "sessionData"
  );
  const [statistics, setStatistics] = useState(null);

  const businessUnitCodes = React.useMemo(() => {
    return [
      {
        label: t("invoice.businessUnitCode"),
        value: null,
      },
    ].concat(
      company.branches.map((branch) => {
        return {
          label: branch.businUnitCode + " | " + branch.sellerName,
          value: branch.businUnitCode,
        };
      })
    );
  }, [selectedCompany]);

  const filterParams = {
    businessUnitCode: businessUnitCodes.some(
      (branch) => branch?.value === persistedData?.businessUnitCode
    )
      ? persistedData?.businessUnitCode
      : null,
    operatorCode: null,
    tcrCode: null,
    isEinvoice: null,
    ublId: null,
    correctiveInvoiceType: null,
    ublInvoiceTypeCode: null,
    isForeign: null,
    ublCurrency: [],
    invoiceStatus: ["100,-100"],
    fiscInvoiceType: null,
    ublProfileId: [],
    isOldInvoice: null,
    isDifferentPeriod: null,
    period: null,
    fromCis:null,
    external:"false",


    // checkCisStatus:"false"
  };

  const defaultFilters = {
    fromDate: dates.fromDate,
    toDate: dates.toDate,
    params: {
      ...filterParams,
    },
  };
  const [filters, setFilters] = useState(defaultFilters);

  const tcrList = React.useMemo(() => {
    let buCode = filters?.params?.businessUnitCode;
    let filteredBranch = company.branches.find(
      (branch) => branch.businUnitCode == buCode
    );
    let tcrTypes = filteredBranch?.tcrTypes?.filter(
      (tcrType) => tcrType?.allowInvoice
    );
    let tcrList =
      tcrTypes?.map((tcr) => {
        let sellerName = filteredBranch.sellerName;
        return {
          label: tcr.tcrCode + "-" + sellerName,
          value: tcr.tcrCode,
        };
      }) ?? [];

    return tcrList.length > 0
      ? [{ label: "Select", value: null }].concat(tcrList)
      : tcrList;
  }, [selectedCompany, filters?.params?.businessUnitCode]);



  const userCompanyOperator = user?.userCompanyOperator?.map(
    (userOpCode) => userOpCode.operator
  ) ?? [];

  const cb = company.branches
    .reduce((acc, next) => acc.concat(next.tcrTypes), [])
    .map((tcrType) => tcrType.operatorCode);

  const operatorCodes = [
    {
      label: t("invoice.selectOptions"),
      value: null,
    },
  ].concat(
    [...new Set(userCompanyOperator.concat(cb))].map((operatorCode) => ({
      label: operatorCode,
      value: operatorCode,
    }))
  );


  React.useEffect(() => {
    getFilteredResults(
      {
        data: { ...defaultFilters, params: { ...defaultFilters.params } },
        pageSize: 10,
        page: 1
      });
  }, [selectedCompany]);

  const [queuesForCsv, setQueuesForCsv] = useState([]);

  const defaultSortCriteria={
    field: "issueDatetime",
    direction: "DESC"
  } 

  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
    sortCriteria:defaultSortCriteria
  });
  const pdfContainer = useRef();



  const getFilteredResults = (
    {
      data,
      pageSize = 10,
      page = 1,
      clearFilters = false,
      getQueuesForCsv = false,
      refreshSatus,
      setQueueValues = true,
      includePeriod=true,
      sortCriteria = [ defaultSortCriteria ]
    }={}
  ) => {

    const queueData = data
      ? { ...data, params: { ...data.params } }
      : { ...filters, params: { ...filters.params } };
    if (queuesForCsv.length > 0) setQueuesForCsv([]);
    if (refreshSatus) {
      setLoadingRefreshStatus({ loading: true, eic: refreshSatus?.eic });
    }
    else {
      if (setQueueValues) setLoading(true);
    }

    let correctiveInvoiceType = null;
    let ublInvoiceTypeCode = null;

    if (queueData?.params?.correctiveInvoiceType) {
      if (queueData?.params?.correctiveInvoiceType == 380) {
        correctiveInvoiceType = null;
        ublInvoiceTypeCode = queueData.params.correctiveInvoiceType;
      } else {
        correctiveInvoiceType = queueData.params.correctiveInvoiceType;
        ublInvoiceTypeCode = null;
      }
    }
    queueData.params.correctiveInvoiceType = correctiveInvoiceType;
    queueData.params.ublInvoiceTypeCode = ublInvoiceTypeCode;
    if (uIType?.uidValue && !clearFilters) {

      if (uIType?.like == true && ((uIType?.uidValue ?? "").split(",").length == 1)) {
        let val = "<L>" + uIType.uidValue + "<L>";
        queueData.params[uIType.value] = val;
      } else {
        if (uIType.type === "date") {
          queueData.params[uIType.value] = uIType?.uidValue ? moment(uIType.uidValue).format("M/yyyy") : ""
        } else {
          queueData.params[uIType.value] = uIType.uidValue
        }
      }
    }



    let invoiceStatus = queueData.params.invoiceStatus;


    let invStatCorrectedAndSuccessful = ["100,-100", "-100"].every((status) => {
      return invoiceStatus.includes(status);
    });

    if (invStatCorrectedAndSuccessful) {
      invoiceStatus = "-100";
    } else {
      invoiceStatus = invoiceStatus.join(",") || null;
    }

    const params=refreshSatus?.iic ?
    {

      iic: refreshSatus.iic,
    } :
    {
      ...queueData.params,
      ublCurrency: queueData.params.ublCurrency?.join(",") || null,
      ublProfileId: queueData.params.ublProfileId?.join(",") || null,
      invoiceStatus,
      ...(refreshSatus?.eic ? { eic: refreshSatus.eic } : {}),
    }

    // if(!params.toBeVerified){
    //   params.external="false"
    // }
    let requestBody = {
      object: refreshSatus?.action ? "RefreshEinvoiceStatus" : "GetQueueV",
      value: refreshSatus?.value ? refreshSatus?.value : refreshSatus?.action ? refreshSatus?.action : null,
      ...(includePeriod) && { ...queueData },
      params,
      pagination: getQueuesForCsv
        ? null
        : {
          pageSize,
          pageNumber: page,
        },
        sortCriteria
    };

    return mainAxios
      .post(`apiEndpoint/${refreshSatus ? "call" : "search"}`, requestBody)
      .then((res) => {
        refreshSatus
          ? setLoadingRefreshStatus({ loading: false })
          : setLoading(false);
        if (res?.data?.status === true) {
          if (!setQueueValues) return res.data.content;
          if (getQueuesForCsv) {
            setQueuesForCsv(res.data.content);
          } else {
            let content = res.data.content;
            if ((refreshSatus?.eic || refreshSatus?.iic) && content?.length > 0) {
              getFilteredResults({page:tableRows.currentPage,pageSize:tableRows.rowsPerPage})
            } else {
              setQueues(content);
              setTableRows((tableRows)=>({
                ...tableRows,
                totalRows: res?.data?.totalSize,
              }));
            }
          }
          return true;
        } else {
          if (setQueueValues) setQueues([]);
        }
      })
      .catch((err) => {
        if (setQueueColumns) setLoading(false);
        return null;
      });
  };

  useEffect(() => {
    if (!(filters?.fromDate && filters?.toDate)) {
      return;
    }
    let endpoint = persistedData?.businessUnitCode
      ? "/endpoint/v2/reports/queue/branch/aggregate"
      : "/endpoint/v2/reports/queue/company/aggregate";
    mainAxios
      .post(endpoint, {
        object: "GetQueueCashDepositStatisticsAnalytics",
        fromDate: moment(filters?.fromDate).format("YYYY-MM-DD"),
        toDate: moment(filters?.toDate).format("YYYY-MM-DD"),
        ...(filters?.params?.businessUnitCode
          ? { params: { businessUnitCode: filters?.params?.businessUnitCode } }
          : {}),
      })
      .then((res) => {
        if (res?.data?.status) {
          let getStatistics = calculateStatistics(res.data.content);
          setStatistics(getStatistics);
        }
      });
  }, [
    filters?.fromDate,
    filters?.toDate,
    filters?.params?.businessUnitCode,
    selectedCompany,
  ]);



  const calculateStatistics = (data) => {
    if (data?.length > 0) {
      const { id, tcrCode, businessUnitCode, actionFrom, company, ...rest } =
        data[0];
      let stats = {};
      Object.keys(rest).forEach((key) => {
        stats[key] = data.reduce((sum, tcrStat) => sum + tcrStat[key], 0);
      });
      return stats;
    }
    return [];
  };

  const resendQueue = (e, queue) => {
    e.preventDefault();

    Swal.fire({
      title: t(["notification.resendInvoice"]),
      confirmButtonText: t(["company.yes"]),
      showCancelButton: true,
      cancelButtonText: t(["company.no"]),
    }).then((res) => {
      if (res.isConfirmed) {
        setLoadingRequest(true);
        mainAxios
          .post("apiEndpoint/call", {
            object: "ResendInvoice",
            value: null,
            params: {
              // ublId: queue.ublId + "",
              iic: queue?.iic,
            },
          })
          .then((res) => {
            if (res?.data?.status === true) {
              let foundQueue = -1;
              queues.forEach((stateQueue, index) => {
                if (stateQueue === queue) foundQueue = index;
              });
              

              let newQueues = [...queues];
              newQueues[foundQueue].fiscStatus = 100;
              newQueues[foundQueue].einvoiceStatus = 100;
              setQueues(newQueues);
              toast.success(t(["notification.success"]), {
                containerId: "all_toast",
              });
            }
            setLoadingRequest(false);
          });
      }
    });
  };

  const cancelSuccessQueue = (e, queue) => {
    e.preventDefault();

    let params = {
      iic: queue.iic + "",
      fiscStatus: queue.fiscStatus + "",
    };

    if (queue.einvoiceStatus)
      params = { ...params, einvoiceStatus: queue.einvoiceStatus + "" };

    Swal.fire({
      title: t(["notification.cancel"]),
      confirmButtonText: t(["company.yes"]),
      showCancelButton: true,
      cancelButtonText: t(["company.no"]),
    }).then((res) => {
      if (res.isConfirmed) {
        loadingAction({func:()=>{
          setLoadingRequest(true);
          return mainAxios
          .post("apiEndpoint/call", {
            object: "SendCorrectiveEinvoice",
            params,
          })
          .then((res) => {
            if (res?.data?.status === true) {
              getFilteredResults();
              toast.success(t(["notification.cancelled"]), {
                containerId: "all_toast",
              });
            }
            setLoadingRequest(false);
          })
          .catch(err=>{
            setLoadingRequest(false);
          })
        },message:t("common.invoiceCancelling")})
        setLoadingRequest(true);

      }
    });
  };

  const cancelQueue = (e, queue) => {
    e.preventDefault();
    Swal.fire({
      title: t(["notification.cancel"]),
      confirmButtonText: t(["company.yes"]),
      showCancelButton: true,
      cancelButtonText: t(["company.no"]),
    }).then((res) => {
      if (res.isConfirmed) {
        loadingAction({func:()=>{
          setLoadingRequest(true);
          return mainAxios
          .post("apiEndpoint/call", {
            object: "CancelInvoiceRequest",
            value: null,
            params: {
              iic: queue.iic + "",
              fiscStatus: queue.fiscStatus + "",
            },
          })
          .then((res) => {
            if (res?.data?.status === true) {
              getFilteredResults();
              toast.success(t(["notification.cancelled"]), {
                containerId: "all_toast",
              });
            }
            setLoadingRequest(false);
          })
          .catch(err=>{
            setLoadingRequest(false);
          })
        },message:"Please wait,invoice is being cancelled..."})
        setLoadingRequest(true);

      }
    });
  };

  const showInvoicePdf = (key, invoice, specificTemplate) => {
    setLoadingPdf(true);
    if (key === "iic") {
      let params = {
        iic: invoice.iic,
      }
      if (specificTemplate) params.specificTemplate = specificTemplate
      mainAxios
        .post("apiEndpoint/search", {
          object: "GetFiscPDF",
          params
        })
        .then((res) => {
          if (res.data.status === true) {
            const blob = func.blobToFile(
              func.b64toBlob(res.data.content[0], "application/pdf"),
              "Custom Invoice"
            );
            setPdfToDisplay(URL.createObjectURL(blob));
            setTimeout(() => {
              pdfContainer.current.classList.toggle("open");
            }, 0);
          }
          setLoadingPdf(false);
        });
    } else if (key === "eic") {
      mainAxios
        .post("apiEndpoint/search", {
          object: "GetEinvoicesRequest",
          params: {
            iic: invoice.iic,
            tcrCode: invoice.tcrCode,
            search: "einvoice",
            dataFrom: "CIS",
          },
        })
        .then((res) => {
          if (res?.data?.content[0]?.pdf) {
            const blob = func.blobToFile(
              func.b64toBlob(res.data.content[0].pdf, "application/pdf"),
              "Custom Invoice"
            );
            setPdfToDisplay(URL.createObjectURL(blob));
            setTimeout(() => {
              pdfContainer.current.classList.toggle("open");
              setLoadingPdf(false);
            }, 0);
          }
          setLoadingPdf(false);
        });
    }
  };

  const handleParamsChange = (e, label, val) => {
    const key = e ? e.target.name : label;
    const value = e ? e.target.value : val;
    setFilters((filters) => ({
      ...filters,
      params: {
        ...filters.params,
        [key]: value,
      },
    }));
  };

  const templateRefMenu=useRef()
  const [templateMenuModel,setTemplateMenuModel]=useState(null)

  
  const getTemplateMenuOptions = useCallback((currInvoice,companyAttributesValues) =>{
    let _templates = companyAttributesValues?.map(attr=>{
          return {
              label:attr.value,
              command: () => { showInvoicePdf("iic", currInvoice,attr.value) }
          }
      })
    return _templates
  })


  const showIicPdf=({key,invoice,event})=>{
    event.preventDefault();
    let companyAttributesValues = fullCompany?.companyAttributes?.find(attr => { return attr?.attribute?.param === "pdfTemplate" })?.companyAttributesValues ?? []
    if(companyAttributesValues.length>1){
        const templates=getTemplateMenuOptions(invoice,companyAttributesValues)
        setTemplateMenuModel(()=>{
          return templates
        })
        templateRefMenu.current.templateData=templates
        templateRefMenu.current.toggle(event)
    }else{
    let companyAttributesValues = fullCompany?.companyAttributes?.find(attr => { return attr?.attribute?.param === "pdfTemplate" })?.companyAttributesValues
      showInvoicePdf("iic", invoice,companyAttributesValues?.[0]?.value)
    }
  }


  let columns = generateQueueColumns(
    {
      resendQueue,
      cancelQueue,
      cancelSuccessQueue,
      queueColumns,
      loadingRequest,
      t,
      loadingPdf,
      showInvoicePdf,
      getFilteredResults,
      tableRows,
      loadingRefreshStatus,
      operatorCodes,
      handleParamsChange,
      filters,
      fullCompany,
      user,
      showIicPdf,
      i18n
    }
  );


  const [selectedColumns, setSelectedColumns] = usePersistedState(
    columns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})})),
    "queueColumnsPrime6"
  );

  const invoiceItemsColumns = useMemo(() => [
    {
      field: "",
      header: "",
      style: { maxWidth: 40 },
    },
    {
      field: "itemCode",
      header: t("books.itemCode"),
      style: { minWidth: 180, maxWidth: 200 },
      sortable: true,
    },
    {
      field: "itemName",
      header: t("books.itemName"),
      style: { minWidth: 180, maxWidth: 200 },
      sortable: true,
    },
    {
      field: "unitOfMeasure",
      header: t("books.unitOfMeasure"),
      style: { minWidth: 150, maxWidth: 150 },
    },
    {
      field: "itemQuantity",
      header: t("books.itemQuantity"),
      style: { minWidth: 150, maxWidth: 180 },
    },
    {
      field: "unitPriceBefore",
      header: t("books.unitPriceBefore"),
      style: { minWidth: 150, maxWidth: 150 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.unitPriceBefore ?? 0),
      align: "right"
    },
    {
      field: "unitPriceAfter",
      header: t("books.unitPriceAfter"),
      style: { minWidth: 180, maxWidth: 180 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.unitPriceAfter ?? 0),
      align: "right"
    },
    {
      field: "vatRate",
      header: t("books.vatRate"),
      style: { minWidth: 100, maxWidth: 100 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.vatRate ?? 0),
      align: "right"
    },
    {
      field: "linePriceBefore",
      header: t("books.linePriceBefore"),
      style: { minWidth: 200, maxWidth: 200 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.linePriceBefore ?? 0),
      align: "right"
    },
    {
      field: "vatAmount",
      header: t("books.vatAmount"),
      style: { minWidth: 150, maxWidth: 150 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.vatAmount ?? 0),
      align: "right"
    },
    {
      field: "",
      header: "",
      style: { width: 2500 },
    },

  ], [t]);




  const typeOptions = [
    {
      key: "isEinvoice",
      label: t("invoice.eInvoicesOption"),
      value: "true",
      count: statistics?.einvoicesNo,
    },
    {
      key: "isEinvoice",
      label: t("parameters.onlyFiscalization"),
      value: "false",
      count: statistics?.fiscalizatedInvoicesNo,
    },
  ];

  const sourceOptions = [

    {
      key: "isApiSource",
      label: "API",
      value: "true",
      count: statistics?.apiSourceNo,
    },

    {
      key: "isApiSource",
      label: t("common.manual"),
      value: "false",
      count: statistics?.manualInvoiceSourceNo,
    },
    {
      key: "toBeVerified",
      label: t("queue.toBeVerified"),
      value: "true",
      count: statistics?.toBeVerified,
      roundPillStyle: statistics?.toBeVerified>0?"to-verify-roundpill":null
    },
    // {
    //   key: "fromCis",
    //   label: t("queue.fromSelfCare"),
    //   value: "false",
    //   count: statistics?.manualInvoiceSourceNo,
    // },


  ]

  const statusOptions = [
    // {
    //   key:"invoiceStatus",
    //   label: "All Statuses",
    //   value: "-1,100,-100",
    //   count: statistics?.errorInvoicesNo + statistics?.successInvoicesNo
    // },

    {
      key: "invoiceStatus",
      label: t("status.successful"),
      multiSelect: true,
      value: "100,-100",
      count: statistics?.successInvoicesNo,
    },
    {
      key: "invoiceStatus",
      label: "Error",
      multiSelect: true,
      value: "-2",
      count: statistics?.errorInvoicesNo,
    },
    {
      key: "invoiceStatus",
      label: "Pending",
      multiSelect: true,
      value: "-1",
      count: statistics?.pendingInvoicesNo,
    },
  ];

  const otherOptions = [
    {
      key: "ublProfileId",
      multiSelect: true,
      count: statistics?.selfInvoicesNo,
      label: t("invoice.selfInvoice"),
      value: "P12",
    },
    {
      key: "ublProfileId",
      multiSelect: true,
      count:
        (statistics?.noncashCorrectiveNo ?? 0) +
        (statistics?.cashCorrectiveNo ?? 0),
      label: t("invoice.corrective"),
      value: "P10",
    },
    {
      key: "fiscInvoiceType",
      count: statistics?.cashInvoicesNo,
      label: "Cash",
      value: "cash",
    },
    {
      key: "isForeign",
      count: statistics?.foreignInvoicesNo,
      label: t("common.foreign"),
      value: "true",
    },
    {
      key: "isSubseq",
      count: statistics?.subseqInvoicesNo,
      label: "Subseq",
      value: "true",
    },
    {
      key: "invoiceStatus",
      multiSelect: true,
      count: statistics?.correctedInvoicesNo,
      label: t("status.corrected"),
      value: "-100",
    },
    {
      key: "isOldInvoice",
      count: statistics?.oldInvoicesNo,
      label: t("queue.oldInvoice"),
      value: "true",
    },

  ];

  const currencyOptions = [
    {
      key: "ublCurrency",
      count: statistics?.allCurrencyInvoicesNo,
      multiSelect: true,
      label: "Lek",
      value: "ALL",
    },
    {
      key: "ublCurrency",
      count: statistics?.usdCurrencyInvoicesNo,
      multiSelect: true,
      label: "USD",
      value: "USD",
    },
    {
      key: "ublCurrency",
      count: statistics?.eurCurrencyInvoicesNo,
      multiSelect: true,
      label: "Euro",
      value: "EUR",
    },
  ];

  const filterStatisticCard=(typeOptions,props)=>globalFilterStatisticCard(typeOptions,{...props,handleParamsChange,getFilteredResults,user,filters})



  const headerButtonsLeft = (
    <div className="d-flex justify-content-center align-items-center">
      <div style={{ width: 200, marginRight: 20 }}>
        <Select
          // style={{width:"100%"}}
          placeholder={"TCR Code"}
          styles={selectStyle}
          options={tcrList}
          isSearchable={true}
          value={
            tcrList.find(
              (option) => option.value === filters.params?.tcrCode
            ) ?? ""
          }
          onChange={(tcrCode) => {
            getFilteredResults({
              data: {
                ...filters,
                params: { ...filters.params, "tcrCode": tcrCode.value },
              }
            });
            handleParamsChange(null, "tcrCode", tcrCode.value)
          }}
          name="tcrCode"
        />
      </div>
      <div
        style={{ width: 180 }}
        className={`btn btn-sm btn-outline-primary rounded-pill ${loadingRefreshStatus?.loading ? "disabled" : ""
          }`}
        disabled={loadingRefreshStatus?.loading}
        onClick={(e) => {
          if(user?.role?.id==1){ //sysAdmin
            refreshAllStatuses.current.toggle(e)
          }else {
            refreshCisStatus()

          }
        }}
      >
        <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
        Refresh All CIS Status
      </div>
      {/* <div
        style={{ width: 180 }}
        className={`btn btn-sm btn-outline-info rounded-pill ml-2`}
        onClick={(e) =>setAdditonalStatsVisible(true)}
      >
      <FontAwesomeIcon icon={faChartGantt} className="mr-2"/>        
          Additional Statistics
      </div> */}

    </div>
  )

  const onRowCollapse = () => {
    setExpanded(defaultExpand);
  };
  const rowExpansionTemplate = () => {
    const expandedInvoiceData = expanded?.expandedInvoiceData?.data
    if (expanded?.loadingInvoiceData || expanded?.loadingInvoiceItems) return <div style={{ width: "100vw" }}><Loader /> </div>
    return (
      <div className="d-flex flex-column ml-3 mt-3">
        {expandedInvoiceData && <div className="mb-4">
          <h4>{expanded?.expandedInvoiceData?.type == "Corrective" ? t("queue.correctedInvoice") : t("queue.correctiveInvoice")}</h4>
          <PrimeTable
            loading={expanded?.loadingInvoiceData}
            value={expandedInvoiceData ?? []}
            columns={columns}
            showFilters={false}
            paginator={false}
            filterDisplay={false}
            // responsiveLayout="scroll"
            selectedColumns={[selectedColumns, setSelectedColumns]}
            showHeader={false}

          >
            <Column style={{width:40}}/>
          </PrimeTable>
        </div>}
        <div className="mb-5" style={{ width: "100vw" }}>
          <h4>Invoice Items</h4>
          <PrimeTable
            loading={expanded?.loadingInvoiceItems}
            value={expanded.invoiceItems}
            columns={invoiceItemsColumns}
            showFilters={false}
            paginator={false}
            filterDisplay={false}
            responsiveLayout="scroll"
          />
        </div>

      </div>
    );
  };

  const invoiceItemsOnExpand = (event) => {
    setExpanded((state) => ({ ...state, loadingInvoiceItems: true, expandedRows: [event.data] }))
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetSaleInvoiceItems",
        params: {
          saleInvoiceId: event?.data?.id?.toString(),
        },
      })
      .then((res) => {
        setExpanded(state => ({ ...state, loadingInvoiceItems: false }))
        if (res) {
          setExpanded((state) => ({ ...state, invoiceItems: res?.data?.content }))
        }
      })
      .catch(err => setExpanded(state => ({ ...state, loadingInvoiceItems: false })))
  }

  const correctiveOnExpand = async (event) => {
    const data = event.data
    const params = {}
    let type = null
    if (data.invoiceType === "Korrigjuese" || data.correctiveInvoiceType === "CORRECTIVE") {
      params.iic = data.iicRef
      type = "Corrective"
    }else if ((data.fiscStatus == -100 && !data?.isEinvoice) || (data.isEinvoice == true && data.eInvoiceStatus == -100)) {
      params.iicRef = data.iic
      type = "Corrected"
    }
    if (!params.iic && !params.iicRef) return
    setExpanded((state) => ({ ...state, loadingInvoiceData: true }))

    const invoices = await getFilteredResults({ data: { params: { ...defaultFilters.params, ...params } }, page: 1, setQueueValues: false })
    if (invoices?.length > 0) {
      setExpanded((state) => ({ ...state, loadingInvoiceData: false, expandedInvoiceData: { data: invoices, type } }))
    } else {
      setExpanded((state) => ({ ...state, loadingInvoiceData: false, expandedInvoiceData: null })); return
    }
  }

  const onRowExpand = (event) => {
    correctiveOnExpand(event)
    invoiceItemsOnExpand(event)
  };


  const refreshAllCisStatuses=()=>{
    mainAxios.post("/apiEndpoint/async/call",{
      object: "RefreshEinvoiceStatus" ,
      refreshSatus: { action: "UpdateAll" },
      value: "UpdateAll",
      params:{},
      pagination: {
        pageSize:10,
        pageNumber: 1,
      }
    })
    .then(res=>{
      if(res.data.status){
        toast.success(res.data.message, {
          containerId: "all_toast",
        })
      }
    })
  }

  const refreshAllStatusesModel=()=>{
    return [
      {
      label: t("queue.refreshAllCisStatuses"),
      command:async () => {
        const res = await swalAlert({ title: t("queue.refreshAllCisStatuses"),text: t("queue.refreshAllStatuses"),t})
        if (res) refreshAllCisStatuses()
      },
      disabled: loadingRefreshStatus?.loading
    },
    {
      label: t("queue.refreshAllCisStatusesPeriod"),
      command: () => {
        refreshCisStatus()
       },
      disabled: loadingRefreshStatus?.loading
    }

  ]
  }

  const refreshCisStatus=({includePeriod=true}={})=>{
    getFilteredResults({
      page: tableRows.currentPage,
      refreshSatus: { action: "UpdateAll"},
      includePeriod
    });
  }

  const model = refreshAllStatusesModel()
  return queues ? (
    <>
      <Menu model={templateRefMenu?.current?.templateData ??[]} popup ref={templateRefMenu} />
      {user?.role?.id == 1 && <Menu model={model} popup ref={refreshAllStatuses} />}
      <div className="d-flex flex-wrap justify-content-between align-items-center my-1">
        <div className="left-header d-flex align-items-center">
          <h3 className="mb-0 p-0">{t("navbar.fiscalizedInv")}</h3>
          <small className="ml-3">

            <ExportCsvPrime
              data={queuesForCsv}
              columns={columns}
              onClick={() => getFilteredResults({ getQueuesForCsv: true })}
              excludedColumns={["status", "actions", "print"]}
              callback={(invoice, formattedObject) => {
                formattedObject["Fisc Status"] = invoice.fiscStatus;
                formattedObject["Einvoice Status"] =
                  invoice.einvoiceStatus || "";
                formattedObject["Is Einvoice"] = invoice.einvoice
                  ? "TRUE"
                  : "FALSE";
              }}
            />
          </small>

          <AlertCertificate />
          <AlertSubscription />
        </div>
        <CisMessage objectType={"GET_S_INVOICES_FROM_CIS"} />
        <Dialog
          header="Additional Statistics"
          visible={additonalStatsVisible}
          onHide={() => setAdditonalStatsVisible(false)}
          breakpoints={{ "960px": "85vw", "641px": "100vw" }}
          className="container mx-auto"
        >
          <AdditionalSaleStatistics />
        </Dialog>
        <div className="ml-md-auto mb-md-0">
          <DateRange
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            onChange={(dates) => {
              let fromDate = dates[0];
              let toDate = dates[1];
              if (fromDate && toDate) {
                getFilteredResults({
                  data: {
                    ...filters,
                    fromDate,
                    toDate,
                    params: { ...filters.params },
                  }
                });
              }
              setFilters((filters) => ({
                ...defaultFilters,
                fromDate,
                toDate,
              }));
              setDates({ fromDate, toDate })
              // setPersistedData({ ...persistedData, fromDate, toDate });
            }}
          />
        </div>
        <div className="col-6 col-md-2 d-flex pr-0 align-items-center">
          <label className="align-items-center mr-2 mb-0">BU</label>
          <Select
            styles={selectStyle}
            className="w-100"
            options={businessUnitCodes}
            value={
              filters.params?.businessUnitCode
                ? businessUnitCodes.find(
                  (option) =>
                    option.value === filters.params?.businessUnitCode
                )
                : { label: t("invoice.businessUnitCode"), value: "" }
            }
            isSearchable={true}
            onChange={(businessUnitCode) => {
              handleParamsChange(
                null,
                "businessUnitCode",
                businessUnitCode.value
              );
              setPersistedData({
                ...persistedData,
                businessUnitCode: businessUnitCode.value,
              });
              getFilteredResults({
                data: {
                  ...filters,
                  params: {
                    ...filters.params,
                    businessUnitCode: businessUnitCode.value,
                  },
                }
              });
            }}
            name="businessUnitCode"
          />
        </div>
      </div>
      <div className="row px-1">
        <ul className="col-12 col-sm-6 col-md-2 p-2 mb-1">
          {filterStatisticCard(typeOptions, {
            title: t("home.invoiceType"),
            cardClasses: "grid-2",
          })}
        </ul>
        <ul className="col-12 col-sm-6 col-md-2 p-2 mb-1">
          {filterStatisticCard(statusOptions, {
            title: t("home.status"),
            cardClasses: "grid-2",
          })}
        </ul>
        <ul className="col-12 col-sm-6 col-md-2 p-2 mb-1">
          {filterStatisticCard(sourceOptions, {
            title: t("common.source"),
            cardClasses: "grid-2",
          })}
        </ul>
        <ul className="col-12 col-sm-6 col-md-2 p-2 mb-1">
          {filterStatisticCard(currencyOptions, {
            title: t("invoice.currency"),
            cardClasses: "grid-2",
          })}
        </ul>
        <ul className={`col-12 col-sm-6 col-md-4 p-2 mb-1`}>
          {filterStatisticCard(otherOptions, {
            title: t("invoice.other"),
            cardClasses: "grid-6",
            type: "checkbox",
          })}
        </ul>
      </div>
      <div className="card border-0 shadow">
        <div className="card-body p-0">
          <div>
            <PrimeTable
              showFilters={true}
              filters={[filters, setFilters]}
              onFilter={(data) => {
                setFilters(data.filters);
              }}
              defaultFilters={defaultFilters}
              tableRows={[tableRows, setTableRows]}
              value={queues}
              selectedColumns={[selectedColumns, setSelectedColumns]}
              searchableKeys={["buyerName", "ublCustomerCityName", "buyerNuis"]}
              columns={columns}
              expandedRows={expanded.expandedRows}
              onRowExpand={onRowExpand}
              onRowCollapse={onRowCollapse}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="ublId"
              onRemoteDataChange={(remoteData) => {
                const updatedFilters = remoteData.filters ?? filters
                const updatedTableData = remoteData.tableRows ?? tableRows
                let { currentPage, rowsPerPage, sortCriteria } = updatedTableData
                sortCriteria=sortCriteria?[sortCriteria]:[]
                getFilteredResults({ data: updatedFilters, pageSize: rowsPerPage, page: currentPage, sortCriteria })
              }}
              headerButtonsLeft={headerButtonsLeft}
              onColReorder={(reorder) => onColReorder({ reorder, setSelectedColumns, childrenCount: 1 })}
              columnDeps={[expanded, fullCompany,loadingRequest,tableRows]}
              loading={loading}
              defaultSortCriteria={defaultSortCriteria}

            >
              <Column
                expander={() => true}
                style={{ minWidth: "75px",maxWidth:"75px" }}
                header=""
                field="expansion"
                frozen={true}
              />
            </PrimeTable>
          </div>
        </div>
        <div className="pdf-container" ref={pdfContainer}>
          {pdfToDisplay && (
            <div className="pdf-viewer">
              <div
                className="pdf-overlay"
                onClick={() => {
                  setTimeout(() => {
                    setPdfToDisplay(null);
                  }, 150);
                  pdfContainer.current.classList.toggle("open");
                }}
              />
              <div className="pdf-content">
                <embed src={pdfToDisplay} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default SaleInvoices;
