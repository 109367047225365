import React, { useRef,useState } from "react";
import Select from "react-select";
import { useAppContext } from "../../../AppContext";
import func from "../../../services/functions";
// import unitsOfMeasure from "../../../services/unitsOfMeasure.json";
// import unitsOfMeasureAlb from "../../../services/unitOfMeasureAlb.json";

import { useTranslation, withTranslation } from "react-i18next";
import PopOver from "../../global/PopOver";
import AsyncSelect from "react-select/async";
import mainAxios from "../../../services/mainAxios";
import { Preloader, ThreeDots } from 'react-preloader-icon';

const bigDecimal = require("js-big-decimal");
const { formatNumber,countDecimals } = func;

const InvoiceItem = ({
  item,
  invoiceState,
  setInvoiceState,
  index,
  disabled,
  autoCompleted,
  setAutoCompleted,
  addCustomItem,
  unitsOfMeasure
}) => {
  const inputRef = useRef(null);
  const { t, i18n, i18next } = useTranslation("translations");
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const [codeLoading, setCodeLoading] = useState(false);
  const typeOfVatCategoryOptions = [
    { label: "0%", value: "0" },
    { label: "6%", value: "6" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];

  const vatTypeOptions = [
    { label: t("vat.none"), value: null },
    { label: t("vat.type_1"), value: "TYPE_1" },
    { label: t("vat.type_2"), value: "TYPE_2" },
    { label: t("vat.tax_free"), value: "TAX_FREE" },
    { label: t("vat.margin_scheme"), value: "MARGIN_SCHEME" },
    { label: t("vat.export_of_goods"), value: "EXPORT_OF_GOODS" },
  ];

  let unitsOfMeasureDefault= unitsOfMeasure?.find(unitsOfMeasure => unitsOfMeasure?.code === "C62") ?? null

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#000" : styles.color,
      backgroundColor: state.isSelected ? "#0075670d" : styles.color,
      borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
      "&:hover": {
        color: "#000000",
        backgroundColor: "#0075670d",
      },
    }),
    control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 0.16rem rgba(68, 68, 68, 0.135)" : 0,
      borderWidth: state.isFocused ? "2px" : "1px",
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      "&:hover": {
        borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      },
    }),
  };
  
  const handleItemChange = (e, selectKey, selectValue, length) => {
    const key = e ? e?.target?.name : selectKey;
    let value = e ? e?.target?.value : selectValue;
    value = length != undefined ? value.slice(0, length) : value;

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: [
        ...invoiceState.items.map((oldItem, oldIndex) =>
          oldIndex !== index
            ? oldItem
            : {
              ...item,
              [key]: value,
            }
        ),
      ],
    }));
  };



  const firstUpdate = useRef(true);
// 
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (item?.code?.length < 3 || autoCompleted) {
      return;
    } 

    const indicator = setTimeout(() => {
      setCodeLoading(true);
      mainAxios
        .post("apiEndpoint/search", {
          object: "GetCompanyItems",
          value: null,
          params: {
            companyItemLiveSearch: item.code,
            company:company.id.toString()
          },
          company: company.id,
        })
        .then((res) => {
          setCodeLoading(false);
          setAutoCompleted(false);
          if (res.data.status == true) {
            if (res.data.content.length > 0) {
              addCustomItem(res.data.content[0]);
            }
          }

        });
    }, 400);
    return () => {
      clearTimeout(indicator);
    };
  }, [ item.code ])



  React.useEffect(()=>{
    if(invoiceState?.items[index]?.unitOfMeasureId==null){
      handleItemChange(
        null,
        "unitOfMeasureId",
        unitsOfMeasureDefault
      )
    }
    if(invoiceState?.items[index]?.unitOfMeasureId?.code === "H87"){
      let xppObj= unitsOfMeasure.find(unitOfMeasure => unitOfMeasure.value === "XPP")
      handleItemChange(
        null,
        "unitOfMeasureId",
        xppObj
      )
    }

  },[unitsOfMeasure])

  const handleDiscountChange = (key, value) => {
    const {
      unitPrice,
      discountType: dt,
      discountValue: dv,
      vatCategory: vat,
    } = invoiceState.items[index];
    const discountType = key === "discountType" ? value : dt;
    const discountValue = key === "discountValue" ? value : dv;
    let discount;
    let count=countDecimals(discountValue);
    if (discountType === "Percentage") {
      if(count>6){
        return;
      }
      discount = bigDecimal.multiply(
        unitPrice,
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      if(count>2){
        return;
      }
      discount = discountValue;
    }

    const priceWithoutVat = bigDecimal.subtract(unitPrice, discount);

    const priceWithVat =
      vat == 0
        ? unitPrice
        : bigDecimal.multiply(
          unitPrice,
          bigDecimal.add(1, bigDecimal.divide(vat, 100))
        );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: [
        ...invoiceState.items.slice(0, index),
        {
          ...invoiceState.items[index],
          [key]: value,
          priceWithoutVat: parseFloat(priceWithoutVat).toFixed(2),
          priceWithVat: parseFloat(priceWithVat).toFixed(2),
        },
        ...invoiceState.items.slice(index + 1),
      ],
    }));
  };

  const handleQuantityChange = (e) => {
    let quantity = e.target.value;

    if (quantity.split(".")[1]) {
      quantity = quantity.split(".");
      quantity = quantity[0] + "." + quantity[1].slice(0, 10);
    }

    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
          ...item,
          quantity,
        }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
  };



  const handleUnitPriceChange = (e) => {
    let unitPrice = e.target.value;
    if (unitPrice.split(".")[1]) {
      unitPrice = unitPrice.split(".");
      unitPrice = unitPrice[0] + "." + unitPrice[1].slice(0, 10);
    }
    if (unitPrice.includes("-")) {
      unitPrice = unitPrice.split("-")[1] || "";
    }

    const {
      vatCategory: vat,
      discountValue,
      discountType,
    } = invoiceState.items[index];
    let discount;

    if (discountType === "Percentage") {
      discount = bigDecimal.multiply(
        unitPrice,
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      discount = discountValue;
    }


    const priceWithoutVat = bigDecimal.subtract(unitPrice, discount);
    const priceWithVat =
      vat == 0
        ? priceWithoutVat
        : bigDecimal.multiply(
          priceWithoutVat,
          bigDecimal.add(1, bigDecimal.divide(vat, 100))
        );
    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
          ...item,
          unitPrice,
          priceWithVat ,
          priceWithoutVat,
        }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
  };

  const handlePriceWithVatChange = (e) => {
    let value = e.target.value;
    if (value.split(".")[1]) {
      value = value.split(".");
      value = value[0] + "." + value[1].slice(0, 10);
    }
    if (value.includes("-")) {
      value = value.split("-")[1] || "";
    }

    const {
      vatCategory: vat,
      discountValue,
      discountType,
    } = invoiceState.items[index];
    const priceWithVat = new bigDecimal(value).getValue();
    const priceWithoutVat =
      vat == 0
        ? priceWithVat
        : bigDecimal.divide(
          priceWithVat,
          bigDecimal.add(1, bigDecimal.divide(vat, 100))
        );
    let discount;

    if (discountType === "Percentage") {
      discount = bigDecimal.multiply(
        bigDecimal.divide(
          priceWithoutVat,
          bigDecimal.subtract(1, bigDecimal.divide(discountValue, 100))
        ),
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      discount = discountValue;
    }

    const unitPrice = bigDecimal.add(priceWithoutVat, discount);

    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
          ...item,
          unitPrice,
          priceWithVat,
          priceWithoutVat,
        }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
  };

  const handlePriceWithoutVatChange = (e) => {
    let value = e.target.value;
    if (value.includes(".")) {
      value = value.split(".");
      value = value[0] + "." + value[1].slice(0, 2);
    }

    const {
      vatCategory: vat,
      discountValue,
      discountType,
    } = invoiceState.items[index];
    const priceWithoutVat = new bigDecimal(value).getValue();

    const priceWithVat =
      vat == 0
        ? priceWithoutVat
        : bigDecimal.multiply(
          priceWithoutVat,
          bigDecimal.add(1, bigDecimal.divide(vat, 100))
        );
    let discount;
    if (discountType === "Percentage") {
      discount = bigDecimal.multiply(
        bigDecimal.divide(
          priceWithoutVat,
          bigDecimal.subtract(1, bigDecimal.divide(discountValue, 100))
        ),
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      discount = discountValue;
    }

    const unitPrice = bigDecimal.add(priceWithoutVat, discount);

    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
          ...item,
          unitPrice: parseFloat(unitPrice).toFixed(2),
          priceWithVat: parseFloat(priceWithVat).toFixed(2),
          priceWithoutVat,
        }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
  };

  /* const handleVatChange = (e) => {
    const newVat = e.target.value;
    const vat = newVat;
    const priceWithoutVat = new bigDecimal(
      invoiceState.items[index].priceWithoutVat
    ).getValue();
    const priceWithVat =
      vat == 0
        ? priceWithoutVat
        : bigDecimal.multiply(
            priceWithoutVat,
            bigDecimal.add(1, bigDecimal.divide(vat, 100))
          );
    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
            ...item,
            priceWithVat: parseFloat(priceWithVat).toFixed(2),
            priceWithoutVat,
            vatCategory: newVat,
          }
    );
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
  }; */

  const handleVatChange = (e) => {
    const newVat = e.value;
    const vat = newVat;
    const priceWithoutVat = new bigDecimal(
      invoiceState.items[index].priceWithoutVat
    ).getValue();
    const priceWithVat =
      vat == 0
        ? priceWithoutVat
        : bigDecimal.multiply(
          priceWithoutVat,
          bigDecimal.add(1, bigDecimal.divide(vat, 100))
        );

    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
          ...item,
          priceWithVat: parseFloat(priceWithVat).toFixed(2),
          priceWithoutVat,
          vatCategory: newVat,
        }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
  };

  const handleVatLabelChange = (e) => {
    const vatLabel = e.value || null;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: invoiceState.items.map((oldItem, oldIndex) =>
        oldIndex !== index
          ? oldItem
          : {
            ...item,
            vatLabel,
          }
      ),
    }));
  };

  const loadOptions = async (name, callback) => {
    let items;
    if (name.length > 2) {
      return await mainAxios
        .post("apiEndpoint/search", {
          object: "GetCompanyItems",//GetCompanyItems new object
          value: null,
          params: {
            companyItemLiveSearch:name,
            company:company.id.toString()

          },
          company: company.id,
        })
        .then((res) => {
          setAutoCompleted(true);
          items = res.data.content.map((el) => {
            return {
              ...el,
              label: el.name + " - " + el.code,
              value: el.name,
            };
          });

          return items;
        });
    }
  };
  const addItem = (item) => {
    const isEuro = invoiceState?.currency === "EUR"
    const newItem = {
      name: item?.name,
      code: item?.code,
      description: item?.description,
      discountType: "Percentage",
      discountValue: "",
      priceWithVat: isEuro ? (item?.priceWvatEur?.toString() ?? "0") : (item?.priceWvatAll?.toString() ?? "0"),
      priceWithoutVat: isEuro ? (item?.priceWovatEur?.toString() ?? "0") : (item?.priceWovatAll?.toString() ?? "0"),
      quantity: "1",
      totalWithVat: "",
      totalWithoutVat: "",
      unitOfMeasureId: item?.unitOfMeasureId ?? unitsOfMeasureDefault,
      unitPrice: isEuro ? (item?.priceWovatEur?.toString() ?? "0") : (item?.priceWovatAll?.toString() ?? "0"),
      vatCategory: item?.vatCategory ?? "",
      vatLabel: item?.vatReason == ""?  null: item?.vatReason,
    };
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: [
        ...invoiceState.items?.slice(0, index),
        {...newItem }
      ],
    }));
    inputRef.current.blur();
    inputRef.current.focus();
  };

  const handleInputChanged = (input, reason, length ) => {
    if (
      reason?.action === "input-blur" ||
      reason?.action === "menu-close" ||
      reason?.action === "set-value"
    ) {
      return;
    } else {
      handleItemChange(null,"name",input,50)
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="code">
                  {t("item.code")}
                  <span className="text-danger"> *</span>
                  <PopOver title={t("hints.itemCode")} />
                </label>
                <div className={`${codeLoading?"input-loading":"input-length-validation"} w-100`}>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    // ref={codeRef}
                    className="form-control"
                    placeholder="E.g. 1002"
                    disabled={disabled}
                    value={item.code}
                    onChange={(e)=>handleItemChange(e,null,null,50)}
                  />
                    {codeLoading ? <div className="custom-loading">
                      <Preloader
                        use={ThreeDots}
                        size={25}
                        strokeWidth={6}
                        strokeColor="#777"
                        duration={1000}
                      />
                    </div>:
                      <div className={`length-text ${item?.code?.length == 50 ? "text-danger" : ""}`}>
                        {item?.code?.length}/50
                      </div>
                    }
                    

                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">
                  {t("item.name")}
                  <span className="text-danger"> *</span>
                  <PopOver title={t("hints.itemName")} />
                </label>
                <AsyncSelect
                  styles={customStyles}
                  placeholder={t("taxpayer.name")}
                  name="name"
                  ref={inputRef}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  className="basic-multi-select"
                  onChange={(value) => addItem(value)}
                  cacheOptions
                  value={item.label}
                  inputValue={item.name}
                  loadOptions={loadOptions}
                  defaultOptions
                  onInputChange={(input,reason)=>handleInputChanged(input,reason,50)}
                  isClearable={true}
                  isDisabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="quantity">
                  {t("item.quantity")}
                  <span className="text-danger"> *</span>
                  <PopOver title={t("hints.itemQuantity")} />
                </label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  className="form-control"
                  placeholder="E.g. 1"
                  disabled={disabled}
                  value={item.quantity}
                  onChange={handleQuantityChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group text-right">
                <label htmlFor="unitPrice">
                  {t("item.unitPrice")}
                  <span className="text-danger"> *</span>
                  <PopOver title={t("hints.itemUnitPrice")} />
                </label>
                <input
                  type={disabled ? "text" : "number"}
                  step="0.01"
                  min="0.01"
                  id="unitPrice"
                  name="unitPrice"
                  className="form-control text-right"
                  placeholder="E.g. 100"
                  disabled={disabled}
                  value={
                    disabled ? func.formattedPrice(item.unitPrice,10) 
                    : item.unitPrice
                  }
                  onChange={handleUnitPriceChange}
                />
                <label>
                  <small> {t("item.unitPriceSmall")} </small>
                </label>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="priceWithoutVat">
                  {t("item.unitPriceNoVat")}
                  <span className="text-danger"> *</span>
                  <PopOver title={t("hints.itemunitPriceNoVat")} />
                </label>
                <input
                  type="number"
                  step="0.01"
                  min="0.01"
                  id="priceWithoutVat"
                  name="priceWithoutVat"
                  className="form-control"
                  placeholder="E.g. 100"
                  disabled={disabled}
                  value={item.priceWithoutVat}
                  onChange={handlePriceWithoutVatChange}
                />
                <label>
                  <small> {t("item.unitPriceNoVatSmall")}</small>
                </label>
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="form-group text-right">
                <label htmlFor="priceWithVat">
                  {t("item.unitPriceVat")}
                  <span className="text-danger"> *</span>
                  <PopOver title={t("hints.itemunitPriceVat")} />
                </label>

                <input
                  type={disabled ? "text" : "number"}
                  step="0.01"
                  min="0.01"
                  id="priceWithVat"
                  name="priceWithVat"
                  className="form-control text-right"
                  placeholder="E.g. 100"
                  disabled={disabled}
                  value={
                    disabled
                      ? func.formattedPrice(item.priceWithVat,10)
                      : item.priceWithVat
                  }
                  onChange={handlePriceWithVatChange}
                />
                <label>
                  <small>{t("item.unitPriceVatSmall")}</small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="description">
            {t("item.description")}
            {/* <span className="text-danger"> *</span> */}

            <PopOver title={t("hints.itemDescription")} />
          </label>
          <textarea
            type="text"
            rows="5"
            id="description"
            name="description"
            className="form-control"
            placeholder="E.g. 1TB SSD, 16GB RAM"
            disabled={disabled}
            value={item.description}
            onChange={handleItemChange}
          />
          <div className="custom-control custom-checkbox mr-3 mt-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id={"investment_" + index}
              name="investment"
              checked={item.investment}
              onChange={(e) =>
                handleItemChange(null, "investment", e.target.checked)
              }
              disabled={disabled}
            />
            <label
              style={{ paddingTop: "3px", paddingLeft: "2px" }}
              htmlFor={"investment_" + index}
              className="custom-control-label"
            >
              {t("item.investment")}
              <PopOver title={t("item.investmentHint")} />
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">
                  {t("item.measure")}
                  <PopOver title={t("hints.itemMeasure")} />
                </label>
                {disabled ? (
                  <input
                    style={{ zIndex: 10000000 }}
                    className="form-control"
                    value={i18n.language == "alb" ? item?.unitOfMeasureId?.albanianName :item?.unitOfMeasureId?.englishName}
                    disabled
                  />
                ) : (
                  
                  <Select
                    styles={customStyles}
                    options={
                      unitsOfMeasure?.map(unitOfMeasure=>{
                        return {
                          ...unitOfMeasure,
                          label : i18n.language == "alb" ? unitOfMeasure?.albanianName: unitOfMeasure?.englishName,
                          value : unitOfMeasure?.code
                        }
                      }) ?? []
                    }
                    value={
                      {
                        label: i18n.language == "alb" ? item?.unitOfMeasureId?.albanianName :item?.unitOfMeasureId?.englishName,
                        value: item?.unitOfMeasureId?.code
                      }
                    }
                    isSearchable={true}
                    onChange={({albanianName,code,englishName,id,showInFront}) =>
                      handleItemChange(
                        null,
                        "unitOfMeasureId",
                        {albanianName,code,englishName,id,showInFront}
                      )
                    }
                    name="unitOfMeasureId"
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="discount">
                  {t("common.discount")}
                  <PopOver title={t("hints.itemDiscount")} />
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    step="0.01"
                    min="0.01"
                    id="discount"
                    name="discount"
                    className="form-control"
                    value={item.discountValue}
                    onChange={(e) =>
                      handleDiscountChange("discountValue", e.target.value)
                    }
                    disabled={disabled}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-primary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      disabled={disabled}
                    >
                      {item.discountType}
                    </button>
                    <div className="dropdown-menu">
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDiscountChange("discountType", "Percentage");
                        }}
                      >
                        {t("invoice.percentage")}
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDiscountChange("discountType", "Value");
                        }}
                      >
                        {t("invoice.value")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="vatCategory">
                  {t("item.vatCategory")}
                  <PopOver title={t("hints.itemVatCategory")} />
                </label>
                <Select
                  styles={customStyles}
                  type="text"
                  id="vatCategory"
                  name="vatCategory"
                  options={typeOfVatCategoryOptions}
                  value={
                    typeOfVatCategoryOptions.find(
                      (VatC) => (VatC.value === item.vatCategory || (VatC.value+".00") === item.vatCategory)
                    ) || null
                  }
                  onChange={(e) => handleVatChange(e)}
                  isDisabled={disabled}
                />
                {/* <select
                  type="text"
                  id="vatCategory"
                  name="vatCategory"
                  className="form-control"
                  disabled={!company.issuerInVat || disabled}
                  value={item.vatCategory}
                  onChange={handleVatChange}
                >
                  <option value="0">0%</option>
                  <option value="6">6%</option>
                  <option value="10">10%</option>
                  <option value="20">20%</option>
                </select> */}
              </div>
            </div>
            <div className="col-md-6">
              {item.vatCategory === "0" && (
                <div className="form-group select-h-m">
                  <label htmlFor="vatLabel">
                    {t("item.vatLabel")}
                    <span className="text-danger"> *</span>
                    <PopOver title={t("hints.itemVatLabel")} />
                  </label>
                  <Select
                    styles={customStyles}
                    type="text"
                    id="vatLabel"
                    name="vatLabel"
                    options={vatTypeOptions}
                    value={
                      vatTypeOptions.find(
                        (VatC) => VatC.value === item.vatLabel
                      ) || ""
                    }
                    onChange={(e) => handleVatLabelChange(e)}
                    isDisabled={disabled}
                  />
                  {/* <select
                    type="text"
                    id="vatLabel"
                    name="vatLabel"
                    className="form-control"
                    disabled={!company.issuerInVat || disabled}
                    value={item.vatLabel || ""}
                    onChange={handleVatLabelChange}
                  >
                    <option value="">{t("vat.none")}</option>
                    <option value="TYPE_1">{t("vat.type_1")}</option>
                    <option value="TYPE_2">{t("vat.type_2")}</option>
                    <option value="TAX_FREE">{t("vat.tax_free")}</option>
                  </select> */}
                </div>
              )}
            </div>
          </div>
          <div className="row"></div>
        </div>

        <div className="col-md-6 mt-5">
          <div className="form-group">
            <label htmlFor="totalWithoutVat">
              {t("item.totalWithoutVat")}
              <PopOver title={t("hints.itemtotalWithoutVat")} />
            </label>
            <input
              type="text"
              id="totalWithoutVat"
              name="totalWithoutVat"
              className="form-control"
              disabled
              value={func.formattedPrice(item.totalWithoutVat)}
            />
          </div>
        </div>

        <div className="col-md-6 mt-5">
          <div className="form-group">
            <div className="form-group">
              <label htmlFor="totalWithVat">
                {t("item.totalWithVat")}
                <PopOver title={t("hints.itemtotalWithVat")} />
              </label>
              <input
                type="text"
                id="totalWithVat"
                name="totalWithVat"
                className="form-control"
                disabled
                value={func.formattedPrice(item.totalWithVat)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceItem;
