import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import jwt_decode from "jwt-decode";
import Loader from '../Layout/Loader'
import { useTranslation } from "react-i18next";
import mainAxios from '../../services/mainAxios';

const ResetEmail = (props) => {
    const { t, i18n } = useTranslation('translations');
    const [state, setState] = React.useState({
        error: null,
    })
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        // console.log(jwt_decode(new URLSearchParams(props.location.search).get("token")))
        setLoading(true)
                mainAxios.post('general/resetEmail', {
                    object: "ResetEmail",
                    content: {
                        // newPassword: state.newPassword,
                        token: new URLSearchParams(props.location.search).get("t"),

                    },
                    // username: jwt_decode(new URLSearchParams(props.location.search).get("token")).sub,
                }).then(res => {
                    if (res?.data?.status === true) {
                        toast.success(t(['toast.updateEmail']), {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            progress: undefined,
                            containerId: "all_toast"
                        })
                        props.history.push('/')

                    }
                    else {
                        setState(state => ({
                            ...state,
                            error: null
                        }))
                    }
                    setLoading(false)
                })
    }, [])

  return (
    <Loader />
  )
}

export default ResetEmail