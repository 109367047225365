import React from "react";
import func from "../../../services/functions";

import { useTranslation, withTranslation } from "react-i18next";
const bigDecimal = require("js-big-decimal");
const { round } = func;

const InvoiceTotals = ({ invoiceState }) => {
  const { t, i18n } = useTranslation("translations");
  const isAlbanian = invoiceState.currency === "ALL";
  const currencyToDisplay = isAlbanian ? "Lek" : invoiceState.currency;
  const rate = invoiceState.rate;

  

  const convertedValue = invoiceState.items.reduce(
    (sum, item) => {
      return bigDecimal.add(
        bigDecimal.add(
          round(bigDecimal.multiply(
            item.totalWithoutVat,
            rate), 2
          ),
          round(bigDecimal.multiply(bigDecimal.multiply(item.totalWithoutVat, rate),
            bigDecimal.divide(item.vatCategory, 100)), 2)
        ),
        sum
        )
    },
    0
  )

  return (
    <div className="card border-0 shadow mb-4">
      <div className="card-header">
        <h4 className="card-title mb-0">{t("invoice.total")}</h4>
      </div>
      <div className="card-body">
        <div className="total-values">
          <div className="row">
            <div className="col-md-7">
              <p>{t("invoice.totalNoVat")}</p>
            </div>
            <div className="col-md-5 text-right">
              <p>
                {func.getFormattedMoneyValue(
                  invoiceState.invoiceTotalWithoutVat
                )}{" "}
                {currencyToDisplay}
              </p>
            </div>
            <div className="col-md-7">
              <p>{t("invoice.totalDiscount")}</p>
            </div>
            <div className="col-md-5 text-right">
              <p>
                {func.getFormattedMoneyValue(invoiceState.invoiceTotalDiscount)}{" "}
                {currencyToDisplay}
              </p>
            </div>
            <div className="col-md-7">
              <p>{t("item.totalWithoutVat")}</p>
            </div>
            <div className="col-md-5 text-right">
              <p>
                {func.getFormattedMoneyValue(
                  invoiceState.invoiceDiscountedTotalWithoutVat
                )}{" "}
                {currencyToDisplay}
              </p>
            </div>
            <div className="col-md-7">
              <p>{t("invoice.totalVat")}</p>
            </div>
            <div className="col-md-5 text-right">
              <p>
                {func.getFormattedMoneyValue(invoiceState.invoiceTotalVat)}{" "}
                {currencyToDisplay}
              </p>
            </div>
            <div className="col-md-7">
              <p>{t("item.totalWithVat")}</p>
            </div>
            <div className="col-md-5 text-right">
              <p>
                {func.getFormattedMoneyValue(invoiceState.invoiceTotalWithVat)}{" "}
                {currencyToDisplay}
              </p>
            </div>
            <div className="col-md-7">
              <h3>
                <strong>
                  {t("invoice.totalPay")} ({currencyToDisplay})
                </strong>
              </h3>
            </div>
            <div className="col-md-5 text-right">
              <h3>
                <strong>
                  {func.getFormattedMoneyValue(
                    invoiceState.invoiceTotalWithVat
                  )}{" "}
                  {currencyToDisplay}
                </strong>
              </h3>
            </div>
            {!isAlbanian && (
              <>
                <div className="col-md-7">
                  <h3>
                    <strong>{t("invoice.totalPay")} (Lek)</strong>
                  </h3>
                </div>
                <div className="col-md-5 text-right">
                  <h3>
                    <strong>
                      {func.getFormattedMoneyValue(convertedValue)} Lek
                    </strong>
                  </h3>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTotals;
