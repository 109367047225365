import React from 'react'
import { withRouter } from 'react-router'

const Page404 = (props) => {
    return (
        <div className="not-found-wrapper">
            <div className="not-found">
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="main">
                    <h1 className="text-primary">404</h1>
                    <p>It looks like you're lost...<br/>That's a trouble?</p>
                    <button type="button" onClick={() => props.history.goBack()}>Go back</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Page404)