import { faEdit, faPlus, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../Layout/Loader'
import mainAxios from '../../../services/mainAxios'
import Table from '../../Table'
import { useTranslation, withTranslation } from "react-i18next";
import AlertCertificate from '../../global/AlertCertificate';
import PrimeTable from '../../PrimeTable/PrimeTable';
import ExportCsvPrime from '../../global/ExportCsvPrime';
import { Dialog } from "primereact/dialog";
import EditCategories from './EditCategories';

const moment = require("moment")

const ItemsCategory = () => {

    const [categories, setCategories] = React.useState(null)
    const { t, i18n } = useTranslation('translations');
    const company = localStorage.getItem('selectedCompany')
    const [visibleCategory, setVisibleCategory] = React.useState({ open: false, id: null });
    const [reloadCategory, setReloadCategory] = React.useState(false)
    const [loadingCategories, setLoadingCategories] = React.useState(false)
    const [tableRows, setTableRows] = React.useState({
        totalRows: 0,
        rowsPerPage: 10,
        currentPage: 1,
    });
    const [filters, setFilters] = React.useState({
        params:{}
      });

      const defaultFilters = {
        active: null
      }

    const getFilteredResults = ({ updatedFilters, rowsPerPage = 10, currentPage = 1, exportCsv = false, sortCriteria, searchQuery } = {}) => {
        if (!exportCsv) setLoadingCategories(true)
        mainAxios.post('apiEndpoint/search', {
            object: "GetCategories",
            params: {
                id: company.id,
            },
            pagination: exportCsv ? null : {
                pageSize: rowsPerPage,
                pageNumber: currentPage,
            },
            params:updatedFilters?.params,
            sortCriteria,
            value: null,
            type: "basic"
        }).then(res => {
            if (!exportCsv) setLoadingCategories(false)
            if (res) {
                setCategories(res.data.content)
                setTableRows((tableRows) => ({
                    ...tableRows,
                    totalRows: res?.data?.totalSize,
                }));
                return true
            }
            return false
        })
            .catch(err => {
                if (!exportCsv) setLoadingCategories(false)
                return false
            })
    }

    React.useEffect(() => {
        getFilteredResults()
    }, [company, reloadCategory])

    const onUpdateCategory = () => {
        setReloadCategory(rlCategory => !rlCategory)
        setVisibleCategory({ open: false, id: null })
    }

    const getItemCategory = () => {
        return mainAxios.post('apiEndpoint/search', {
            object: "GetCategories",
            value: null,
            type: 'basic',
            params: {
                id: company.id,
            },

        }).then(res => {
            if (res) {
                setCategories(res.data.content)
                return true
            }
            return false
        })
    }

    const columns = [
        {
            header: `${t('item.name')}`,
            field: 'name',
            sortable: true,
            filter:true,
            style: { minWidth: '30px', maxWidth: "30px", height: "45px" },
            formatedExportValue: true,

        },
        {
            header: `${t('item.code')}`,
            field: 'code',
            sortable: true,
            filter:true,
            style:{ minWidth: '30px', maxWidth:"30px" },
            formatedExportValue: true,

        },
        {
            header: `${t("itemsCategory.categoryType")}`,
            field: 'type',
            sortable: true,
            formatedExportValue: true,
            style:{ minWidth: '30px', maxWidth:"30px" },
            format: (value) => {
                if (value.type == 1) return "Normal"
                return value.type
            },
            body: (value) => {
                if (value.type == 1) return "Normal"
                return value.type
            }
        },
        {
            header: `${t('itemsCategory.active')}`,
            field: 'isActive',
            sortable: true,
            filter:true,
            dataType: "boolean",
            filterType:"TRISTATECHECKBOX",
            formatedExportValue: true,
            format: (value) => {
               return value.active ? 'Yes' : 'No'
            },
            align:"center",
            style:{ minWidth: '30px', maxWidth:"30px" },
            body: (value) => value.active ? 'Yes' : 'No'
        },
        {
            header: t(['home.actions']),
            field: "actions",
            body: categories => (
                <>
                    <Link
                        onClick={() => setVisibleCategory({ open: true, id: categories.id })}
                        className="nav-link p-0 text-primary">
                        <FontAwesomeIcon icon={faEdit} className="mr-1" />
                        {t('company.edit')}
                    </Link>
                </>
            )
        }
    ];


    return (
        categories ?

            <>
                <div className="d-flex justify-content-between align-items-center my-2">
                    <div className="d-flex align-items-center">
                        <h3 className="m-0 p-0 d-flex align-items-center">
                            <span className='mr-3'>{t("itemsCategory.categories")}</span>
                            <ExportCsvPrime
                                data={categories}
                                columns={columns}
                                onClick={() => getItemCategory({ exportCsv: true })}
                                excludedColumns={["expansionCol", "actions"]}
                            />
                        </h3>
                        <AlertCertificate />
                    </div>
                    <div className="page-actions">
                        <button
                            onClick={() => { setVisibleCategory({ open: true, id: null }) }}
                            className="btn btn-sm btn-primary">
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            {t("itemsCategory.addCategory")}
                        </button>
                    </div>
                </div>
                <div>
                    <Dialog
                        header={visibleCategory?.id ? t("itemsCategory.editCategory") : t("itemsCategory.addCategory")}
                        visible={visibleCategory?.open}
                        onHide={() => setVisibleCategory({ open: false, id: null })}
                        style={{ width: "30vw" }}
                        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                    >
                        {visibleCategory?.open && <EditCategories onUpdateCategory={onUpdateCategory} id={visibleCategory?.id} />}
                    </Dialog>
                </div>
                <div className="card border-0 shadow">
                    <div className="card-body">
                        <PrimeTable
                            columns={columns}
                            loading={loadingCategories}
                            value={[...categories]}
                            filters={[filters, setFilters]}
                            tableRows={[tableRows, setTableRows]}
                            onFilter={(data) => {
                                setFilters(data.filters);
                              }}
                            reorderableColumns={true}
                            showFilters={true}
                            defaultFilters={defaultFilters}
                            headerButtonsRight
                            responsiveLayout="scroll"
                            className="white-header"
                            filterDisplay={'row'}
                            showSelectColumns={false}
                            onRemoteDataChange={(remoteData) => {
                                const updatedFilters = remoteData.filters
                                const updatedTableData = remoteData.tableRows ?? tableRows
                                let { currentPage, rowsPerPage, sortCriteria } = updatedTableData
                                sortCriteria = sortCriteria ? [sortCriteria] : null
                                getFilteredResults({ updatedFilters, rowsPerPage, currentPage, sortCriteria, searchQuery: remoteData?.searchQuery })
                            }}
                        />
                    </div>
                </div>
            </>
            :
            <Loader />
    )
}

export default ItemsCategory