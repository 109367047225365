import React from "react";
import InvoiceStatus from "./InvoiceStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faEye,
  faQuestionCircle,
  faRedoAlt,
  faSync,
  faTimes,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import func from "../../../services/functions";
import ReactTooltip from "react-tooltip";

const bigDecimal = require("js-big-decimal");

const moment = require("moment");
const genereateCisFormatColumns = (
  showInvoicePdf,
  cisColumns,
  t,
  loadingPdf,
  partyTypeForTable,
  dataFrom
) => {
  let columns = cisColumns != null ? cisColumns.split(",") : null;

  //console.log({partyTypeForTable, columns})
  
  return [
    {
      name: t(["invoice.docNumber"]),
      selector:  dataFrom ==="DB"? "ublId":"docNumber" ,
      width: "150px",
      sortable: true,
      defaultChecked: columns ? columns.includes( "ublId") || columns.includes("docNumber"): true,
    },
    {
      name: t(["invoice.docType"]),
      width: "150px",
      selector: "docType",
      sortable: true,
      defaultChecked: columns ? columns.includes("docType") : true,
    },
    {
      name: "EIC",
      selector: "eic",
      width: "350px",
      sortable: true,
      cell: columns=>(
        columns.eic?(
        <input type="text" 
            onFocus={(event) => event.target.select()} 
            value={columns.eic} 
            style={{ border: 0,userSelect: "text",fontSize:"13px",width:"235px"}}
            />
        ):""
    ), 
      defaultChecked: columns ? columns.includes("eic") : true,
    },
    {
      name: "ERP ID",
      selector: "erpId",
      width: "300px",
      sortable: true,
      cell: columns=>(
        columns.erpId?(
        <input type="text" 
            onFocus={(event) => event.target.select()} 
            value={columns.erpId} 
            style={{ border: 0,userSelect: "text",fontSize:"13px",width:"235px"}}
            />
        ):""
    ), 
      defaultChecked: columns ? columns.includes("erpId") : true,
    },
    {
      name: t(["home.date"]),
      selector: "recordDateTime",
      width: "200px",
      sortable: true,
      format: (invoice) => {
        
        return moment(invoice.recordDate).format("DD/MM/YYYY HH:mm");
      },
      defaultChecked: columns ? columns.includes("recordDateTime") : true,
    },
    {
      name: t(["invoice.dueDateTime"]),
      selector: "dueDateTime",
      width: "200px",
      sortable: true,
      format: (invoice) =>{
        // console.log({invoice});
        if(invoice.dueDateTime){
          return moment(invoice.dueDateTime).format("DD/MM/YYYY HH:mm")
        }
        },
      defaultChecked: columns ? columns.includes("dueDateTime") : true,
    },
    {
      name: t("invoice.buyer"),
      selector: "buyerTin",
      width: "150px",
      sortable: true,
      defaultChecked: columns ? columns.includes("buyerTin") : true,
    },
    {
      name: t("invoice.seller"),
      selector: "sellerTin",
      width: "150px",
      sortable: true,
      defaultChecked: columns ? columns.includes("sellerTin") : true,
    },
    {
      name: t(["home.amount"]),
      selector: "amount",
      sortable: true,
      format: (invoice) => invoice.amount ? func.getFormattedMoneyValue(invoice.amount) : 0,
      defaultChecked: columns ? columns.includes("amount") : true,
    },

    {
      name: t(["invoice.partyType"]),
      width: "150px",
      selector: "partyType",
      sortable: true,
      format: (invoice) =>
        (invoice.partyType == "SELLER" && t(["invoice.seller"])) ||
        (invoice.partyType == "BUYER" && t(["invoice.buyer"])) ||
        "",
      defaultChecked: columns ? columns.includes("partyType") : true,
    },
    {
      name: "Status",
      selector: "status",
      format: (invoice) =>
        (invoice.status == "ACCEPTED" && t(["status.ACCEPTED"])) ||
        (invoice.status == "REFUSED" && t(["status.REFUSED"])) ||
        (invoice.status == "PAID" && t(["status.PAID"])) ||
        (invoice.status == "DELIVERED" && t(["status.DELIVERED"])) ||
        (invoice.status == "PARTIALLY_PAID" && t(["status.PARTIALLY_PAID"])) ||
        t(["status.AWAITING"]),
      defaultChecked: columns ? columns.includes("status") : true,
    },
    {
      name: t(["invoice.print"]),
      selector: "pdf",
      format: (invoice) => (
        <a
          href="#"
          className={
            "nav-link p-0 d-flex align-items-center stretched-link" +
            (loadingPdf ? " disabled" : "")
          }
          onClick={(e) => {
            e.preventDefault();
            showInvoicePdf(invoice);
          }}
        >
          <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
          {t("invoice.view")}
        </a>
      ),
      defaultChecked: columns ? columns.includes("pdf") : true,
    },
  ];
};

export default genereateCisFormatColumns;
