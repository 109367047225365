import React, { useState } from "react";

import { faCheck, faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mainAxios from "../../services/mainAxios";
import Select from "react-select";
import selectStyle, { errorSelectStyle } from "../../design/selectStyle";
import { toast } from "react-toastify";
import Loader from "../../components/Layout/Loader";
import Page404 from "../../components/Page404";
import { useTranslation, withTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext";
import countries from "../../services/countries.json";
import { buyerIDTypes } from "../CustomInvoices/WizardSteps/data";
import func, { partyToCustomer } from "../../services/functions";
import PopOver from "../global/PopOver";

const EditCustomer = ({errors:errorsAbove,invoiceState,fromInvoice,setInvoiceState,setVisible,...props}) => {
  const { t, i18n } = useTranslation("translations");
  const [loading, setLoading] = React.useState(false);
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany],
  } = useAppContext();

  const [customer, setCustomer] = useState(invoiceState?.customer?.tin ?{ ...invoiceState?.customer,...partyToCustomer({party:invoiceState?.parties?.buyer})} : partyToCustomer({party:invoiceState?.parties?.buyer}))
  
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    tin: null,
    address: null,
    town: null,
  });

const tinTypes = [
  { label: "NUIS", value: "1" },
  { label: "ID", value: "2" },
  { label: "PASS", value: "3" },
  { label: "VAT", value: "4" },
  { label: "TAX", value: "5" },
  { label: "SOC", value: "6" },
];

  React.useEffect(() => {
    if(invoiceState) return 
    const companyId = props?.id ?? props?.match?.params?.id;
    
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCustomer",
        params: {
          id: companyId,
        },
      })
      .then((res) => {
        if (res) {
          setCustomer(res?.data?.content[0])
        }
        
      });
  }, []);

    const createOnInvoice = () => {
      let { name, tin,  address, town, } = customer;
      tin =tin!=null? tin.trim():tin
          // if (customer.parties.seller.name) customer.parties.seller.name = customer.parties.seller.name.trim();

      let checkNiptFormat=new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(tin);
      let checkError=!name || !tin ||  !address || !town || (!checkNiptFormat && customer.type==tinTypes[0].value);
      if (!name) {
        setErrors((errors) => {
          return { ...errors, name: "toast.customerNameError" };
        });
      }
  
      if (customer.type==tinTypes[0].value && !checkNiptFormat ) {
        setErrors((errors) => {
          return { ...errors, tin: "toast.incorrectNiptForCustomer" };
        });
      }
      if (!tin) {
        setErrors((errors) => {
          return { ...errors, tin: "toast.customerTinError" };
        });
      }
  
      if(!address){
        setErrors((errors) => {
          return { ...errors, address: "toast.customerAddressError" };
        });
      }
  
      if(!town){
        setErrors((errors) => {
          return { ...errors, town: "toast.customerTownError" };
        });
      }
  
      if(checkError){
        setLoading(false);
  
        return;
      }
      let type = customer?.type
      ? buyerIDTypes.find((type) => type.value == customer.type).label
      : "";
      setInvoiceState((invoiceState)=>({
        ...invoiceState,
        parties: {
          ...invoiceState.parties,
          buyer:{
            ...invoiceState.parties.buyer,
            tin: tin ?? "",
                name: customer?.name ?? "",
                address: customer?.address ?? "",
                country: func.getFullCountry(customer?.country, false),
                town: customer?.town ?? "",
                email: customer?.email ?? null,
                buyerIDType: invoiceState.einvoice
                  ? buyerIDTypes[0].label
                  : !invoiceState?.einvoice && customer?.type
                    ? type
                    : buyerIDTypes[1].label,
          }
        }
      }))
    setVisible(false)
  }



  const editCompany = (e) => {
    e.preventDefault();

    setLoading(true);
    let { name, tin, address, email,town, } = customer;
    tin =tin!=null? tin.trim():tin
    let checkNiptFormat=new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(tin);
    let checkError=!name || !tin || !address || !town || (customer.type==tinTypes[0].value && !checkNiptFormat);
    if (!name) {
      setErrors((errors) => {
        return { ...errors, name: "toast.customerNameError" };
      });
    }
    if (customer.type==tinTypes[0].value && !checkNiptFormat) {
      setErrors((errors) => {
        return { ...errors, tin: "toast.incorrectNiptForCustomer" };
      });
    }
    if (!tin) {
      setErrors((errors) => {
        return { ...errors, tin: "toast.customerTinError" };
      });
    }
  
    if(!address){
      setErrors((errors) => {
        return { ...errors, address: "toast.customerAddressError" };
      });
    }
    
    if(!town){
      setErrors((errors) => {
        return { ...errors, town: "toast.customerTownError" };
      });
    }
    
    if(checkError){
      setLoading(false);

      return;
    }
    if(props.onCustomerUpdate){
      props.onCustomerUpdate(customer)
    }

    if(customer.tin) customer.tin = customer.tin.trim()
      console.log({customer});
    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "Customers",
        content: {
          ...customer,
        },
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(t(["toast.successCustomers"]), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            containerId: "all_toast",
          });
          if(props?.onCustomerUpdate) props.onCustomerUpdate(res.data.content[0])
          // props.history.replace('/companies/' + customer.company.id)
          if(!props?.id && !fromInvoice) {
            return props.history.replace("/customers");
          } 
        }
        setLoading(false);
      });
  };


  const handleCompanyInputs = (e, selectKey, selectValue) => {
    const key = e ? e.target.name : selectKey;
    const value = e ? e.target.value : selectValue;
    if(errors[key]){
      setErrors(prevState=>{return {...prevState,[key]:null}})
    }
    setCustomer({
      ...customer,
      [key]: value
    })
  };



  if(!customer) return <Loader/>
  console.log({invoiceState})
  const typeDisabled = fromInvoice?(invoiceState?.einvoice):false
  const content=<>
  <div className="row">
    <div className="col-md-12">
      <div className="form-group">
        <label htmlFor="customers_name">{t("customers.name")}</label>
        <span className="text-danger"> *</span>
        <input
          type="text"
          className={`form-control ${errors.name ? "is-invalid" : ""}`}
          name="name"
          value={customer?.name}
          onChange={handleCompanyInputs}
        />
        {errors.name && (
          <div className="invalid-feedback">{t(errors.name)}</div>
        )}
      </div>
    </div>

    <div className="col-md-12">
      <div className="form-group">
        <label>
          {t("customers.identificationType")}
        {typeDisabled && <PopOver title={t("invoice.identificationType")} />}
        
        </label>
        <Select
          styles={selectStyle}
          name="type"
          options={tinTypes}
          value={tinTypes.find((type) => type.value == customer.type)}
          onChange={(type) =>
            setCustomer((customer) => ({ ...customer, type: type.value }))
          }
          isDisabled={typeDisabled}
        />
      </div>
    </div>
    <div className="col-md-12">
      <div className="form-group">
      <label htmlFor="customer_tin">{tinTypes.find((type) => type.value === customer.type)?.label ?? "NUIS"}</label>
        <span className="text-danger"> *</span>
        <input
          type="text"
          className={`form-control ${errors.tin ? "is-invalid" : ""}`}
          name="tin"
          value={customer?.tin}
          onChange={handleCompanyInputs}
        />
        {errors.tin && (
          <div className="invalid-feedback">{t(errors.tin)}</div>
        )}
      </div>
    </div>
    <div className="col-md-12">
      <div className="form-group">
        <label htmlFor="customer_address">{t("customers.address")}</label>
        <span className="text-danger"> *</span>
        <input
          type="text"
          className={`form-control ${
            errors.address || errorsAbove?.["parties.buyer.address"]
              ? "is-invalid"
              : ""
          }`}
          name="address"
          value={customer?.address}
          onChange={handleCompanyInputs}
        />
        {errors.address && (
          <div className="invalid-feedback">{t(errors.address)}</div>
        )}
      </div>
    </div>
    <div className="col-md-12">
      <div className="form-group">
        <label htmlFor="customers_name">{t("email.email")}</label>
        <input
          type="text"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
          name="email"
          value={customer?.email}
          onChange={handleCompanyInputs}
        />
      </div>
    </div>
    <div className="col-md-12">
      <div className="form-group">
        <label htmlFor="customer_town">{t("customers.town")}</label>
        <span className="text-danger"> *</span>
        <input
          type="text"
          className={`form-control ${
            errors.town || errorsAbove?.["parties.buyer.town"]
              ? "is-invalid"
              : ""
          }`}
          name="town"
          value={customer?.town}
          onChange={handleCompanyInputs}
        />
        {errors.town && (
          <div className="invalid-feedback">{t(errors.town)}</div>
        )}
      </div>
    </div>
    <div className="col-md-12">
      <div className="form-group">
        <label htmlFor="customer_country">{t("customers.country")}</label>
        <span className="text-danger"> *</span>
        <Select
          styles={errors.country || errorsAbove?.["parties.buyer.country"] ? errorSelectStyle : selectStyle}
          name="country"
          isSearchable={true}
          options={countries}
          menuPosition="fixed"
          value={
            countries.find(
              (country) =>
                country.shortCode === customer?.country ||
                country.value == customer?.country
            ) || ""
          }
          onChange={(e) =>
            handleCompanyInputs(null, "country", e.shortCode)
          }
        />
      </div>
    </div>
    {!fromInvoice && (
        <div className="col-md-4" style={{ marginTop: "35px" }}>
            <div className="form-group custom-control custom-checkbox mr-sm-2" style={{zIndex:0}}>
              <input
                type="checkbox"
                className="custom-control-input"
                id="defaultCustomer"
                name="defaultCustomer"
                checked={customer?.defaultCustomer}
                onChange={(e) =>
                  handleCompanyInputs(null, "defaultCustomer", e.target.checked)
                }
              />
              <label
                style={{ paddingTop: "3px", paddingLeft: "2px" }}
                htmlFor="defaultCustomer"
                className="custom-control-label"
              >
              {t("customers.default")}
              </label>
            </div>
          </div>)
      }
  </div>
 { fromInvoice &&<button className="btn btn-outline-primary mr-2 mt-3" onClick={createOnInvoice}>
    {loading ? (
      <Loader size="xs" color="white" />
    ) : (
      <>
      <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
        {t("invoice.saveInIvoice")}
      </>
    )}
  </button>}
  <button
    className="btn btn-primary mt-3"
    onClick={editCompany}
    disabled={loading}
    style={{ minWidth: "100px" }}
    type="submit"
  >
    {loading ? (
      <Loader size="xs" color="white" />
    ) : (
      <>
        <FontAwesomeIcon icon={faCheck} className="mr-2" />
        {t("invoice.saveInSystem")}
      </>
    )}
  </button>


  </>
  
  if(loading) return  <Loader className='d-flex align-itmes-center justify-content-center' />

return content

};

export default EditCustomer;
