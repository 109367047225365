import React from 'react'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import jwt_decode from "jwt-decode";
import Loader from '../Layout/Loader'
import { useTranslation } from "react-i18next";
import mainAxios from '../../services/mainAxios';

const EditPassword = (props) => {

    const { t, i18n } = useTranslation('translations');
    const [state, setState] = React.useState({
        newPassword: "",
        confirmPassword: "",
        error: null,
        error_password: null
    })
    const [loading, setLoading] = React.useState(false)


    const editPassword = (e) => {
        e.preventDefault()
        const userId = props.match.params.id
        if (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(state.newPassword)) {
            if (state.newPassword === state.confirmPassword) {
                setLoading(true)
                mainAxios.post('general/resetPassword', {
                    object: "ResetPassword",
                    content: {
                        newPassword: state.newPassword,
                        token: new URLSearchParams(props.location.search).get("token"),

                    },
                    username: jwt_decode(new URLSearchParams(props.location.search).get("token")).sub,
                }).then(res => {
                    if (res?.data?.status === true) {
                        toast.success(t(['toast.updatePassword']), {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            progress: undefined,
                            containerId: "all_toast"
                        })
                        props.history.push('/')

                    }
                    else {
                        setState(state => ({
                            ...state,
                            error: null
                        }))
                    }
                    setLoading(false)
                })
            }
            else {
                setState(state => ({
                    ...state,
                    error: t(['toast.identical'])
                }))
            }
        }
        else {
            setState(state => ({
                ...state,
                error_password: t(['toast.passwordError'])
            }))
        }

    }

    const handlePasswordInputs = (e) => {
        const key = e.target.name
        const value = e.target.value

        setState(state => ({
            ...state,
            [key]: value
        }))
    }

    return (
        <div className="row mt-0 mt-sm-0 mt-md-5 mt-lg-5">
            <form className="col-xl-5 col-lg-8 col-md-8 col-sm-10 col-12 mx-auto mt-5" onSubmit={editPassword}>
                <div className="card border-0 shadow mt-5">
                    <div className="card-header border-0">
                        <h3 className="mb-0">{t('login.resetPasswordHeader')}</h3>
                    </div>
                    <div className="card-body mt-4">
                        <div className="form-group pb-3">
                            <label htmlFor="new_password">
                                {state.error_password && <span className="d-block text-danger">{state.error_password}</span>}
                                {t('users.newPassword')}
                            </label>
                            <input
                                type="password"
                                className={"form-control" + (state.error_password ? " border-danger" : "")}
                                id="new_password"
                                name="newPassword"
                                placeholder={t('users.newPassword')}
                                value={state.newPassword}
                                onChange={handlePasswordInputs}
                                required />
                        </div>
                        <div className="form-group pb-3">
                            <label htmlFor="confirm_password">
                                {t('users.confirmPassword')}
                                {state.error && <span className="ml-2 text-danger">{state.error}</span>}
                            </label>
                            <input
                                type="password"
                                className={"form-control" + (state.error ? " border-danger" : "")}
                                id="confirm_password"
                                name="confirmPassword"
                                placeholder={t('users.confirmPassword')}
                                value={state.confirmPassword}
                                onChange={handlePasswordInputs}
                                required />
                        </div>
                        <div className="d-flex align-items-center  pb-3">
                            <button
                                className="btn btn-primary mr-3"
                                disabled={loading}
                                style={{ minWidth: "100px" }}
                                type="submit">
                                {loading ? <Loader size="xs" color="white" /> :
                                    (<>
                                        <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
                                    </>)}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default EditPassword