import React,{useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

const InternalPoCombo = ({ invoice, handleChange, disabled }) => {
  const [hasInternalPoCombo,setHasInternalPoCombo]=useState(false);
  const { t } = useTranslation("translations");

  let internalPo=invoice?.purchaseInvoiceExtracted?.internalPo ?? null;

  useEffect(()=>{
      if(internalPo?.no || internalPo?.name || internalPo?.date){
        setHasInternalPoCombo(true);
      }else{
        setHasInternalPoCombo(false);
      }
  },[internalPo?.no,internalPo?.name,internalPo?.date])

  return (
    <div className="card border-0 mt-4 w-100">
      <div className="card-header border-0">
        <div 
          className="custom-control custom-checkbox mr-sm-2"
          onClick={(e) =>
            setHasInternalPoCombo((state)=>!state)
          }
          >
          <input
            type="checkbox"
            className="custom-control-input"
            name="hasInternalPoCombo"
            checked={hasInternalPoCombo}
            disabled={disabled}
            readOnly
          />
          <label
            style={{ paddingTop: "3px", paddingLeft: "5px" }}
            className="custom-control-label"
          >
            {t("purchases.internalPO")}
          </label>
        </div>
      </div>
      <div className="card-body">
        {hasInternalPoCombo ? (
          <div className="row">
            <div className="col-md-6">
              <div className="form-group input-length-validation">
                <label className="form-label">
                  No
                </label>
                <input
                  name="internalPONo"
                  type="number"
                  value={internalPo?.no ? internalPo?.no:""}
                  className="form-control required"
                  disabled={disabled}
                  onChange={(e)=> handleChange("","purchaseInvoiceExtracted.internalPo.no",e.target.value,20)}
                />
                <div
                 className={`length-text ${internalPo?.no?.length == 20 ? "text-danger" : 0}`}>
                   {internalPo?.no?.length ?? 0}/20
                   </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">{t("home.dateOfYes")}</label>
                <DatePicker
                  autoComplete='off'
                  selected={internalPo?.date ?? ""}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  showDisabledMonthNavigation
                  onChange={(date) =>  handleChange(null, "purchaseInvoiceExtracted.internalPo.date", date)}
                  customInput={<input className="form-control" />}
                />
              </div>

            </div>

            <div className="col-xl-12 col-12 ">
              <div className="form-group input-length-validation">
                <label className="form-label">
                  {t("invoice.name")}
                </label>
                <textarea
                  name="name"
                  type="text"
                  rows="1"
                  className="form-control"
                  disabled={disabled}
                  value={internalPo?.name ?? ""}
                  onChange={(e)=> handleChange("","purchaseInvoiceExtracted.internalPo.name",e.target.value,50)}  />
                 <div
                 className={`length-text ${internalPo?.name  == 50 ? "text-danger" : ""}`}>
                   {internalPo?.name?.length ?? 0 }/50
                   </div>
                   
              </div>
            </div>

        
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default InternalPoCombo;
