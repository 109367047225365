import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{ useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

const DateRange = ({fromDate,toDate,onChange,showDateMenu=true,disabled=false,dateInputStyle, showYearDropdown= true,...props}) => {
  const moment = require("moment")
  const [isOpen, setIsOpen] = useState(false);

const DateInput = React.forwardRef((props, ref) => {
  return (
      <button ref={ref} disabled={disabled} className={`btn btn-light bg-white shadow-sm dropdown-toggle btn-sm btn-custom`} style={{...dateInputStyle}} onClick={props.onClick}>
          <FontAwesomeIcon icon={faCalendarAlt} className="text-primary mr-2"/>
          {props.children}
      </button>
  )
  })
const fixedDates= (dateRange) => {
  
  let startDate;
  let endDate=moment()._d;
  switch(dateRange){
    case "today":
      startDate=moment().startOf('day')._d;
      startDate=new Date(startDate.setHours(2, 0, 0, 0))
      endDate=moment().endOf('day')._d
      break;
    case "yesterday":
      startDate=moment().subtract(1, 'days')._d
      startDate=new Date(startDate.setHours(2, 0, 0, 0))
      endDate = moment(startDate).endOf('day')._d
      break;
    case "thisWeek":
      startDate=moment().startOf('isoWeek')._d; 
      endDate=moment().endOf('day')._d
      break;
    case "lastWeek":
      startDate=moment().subtract(1, 'weeks').startOf('isoWeek')._d; 
      endDate=moment().subtract(1, 'weeks').endOf("isoWeek")._d;
      break;
    case "thisMonth":
      startDate=moment().startOf('month')._d; 
      endDate=moment().endOf('day')._d
      break;
    case "lastMonth":
      startDate=moment().subtract(1, 'months').startOf('month')._d; 
      endDate=moment().subtract(1, 'months').endOf("month")._d;
      break;
  }
  onChange([startDate,endDate])
  setIsOpen(false)
} 
const onClickOutside=()=>{
  setIsOpen(false)
  if(fromDate && !toDate){
    onChange([fromDate,fromDate])
  }
}

  return (
  <>
    <div className='react-date-picker'>
    <ReactDatePicker
      selectsRange={true}
      onInputClick={() => setIsOpen(true)}
      onClickOutside={onClickOutside}
      open={isOpen}
      showYearDropdown={showYearDropdown}
      startDate={fromDate}
      endDate={toDate}
      onChange={(dates)=>{
        if(dates[0] && dates[1]) {setIsOpen(false);}
        let fromDate=dates[0]? (typeof dates[0]=="number" ?dates[0]:new Date(dates[0].setHours(2, 0, 0, 0))):null;
        let toDate=dates[1] ? (typeof dates[1]=="number"?dates[1]:new Date(dates[1].setHours(2, 0, 0, 0))):null
        onChange([fromDate,toDate])
      }}
      customInput={<DateInput>{fromDate ? moment(fromDate).format("DD/MM/YYYY"):"Date"} - {toDate ?moment(toDate).format("DD/MM/YYYY"):"Date"}</DateInput>}
      isClearable={true}
      {...props}>
      {isOpen && showDateMenu && (
      <div className="d-none d-md-flex list-group date-range-actions">
        <button onClick={()=>fixedDates("today")} className="list-item">Today</button>
        <button onClick={()=>fixedDates("yesterday")} className="list-item">Yesterday</button>
        <button onClick={()=>fixedDates("thisWeek")} className="list-item">This Week</button>
        <button onClick={()=>fixedDates("lastWeek")} className="list-item">Last Week</button>
        <button onClick={()=>fixedDates("thisMonth")} className="list-item">This Month</button>
        <button onClick={()=>fixedDates("lastMonth")} className="list-item">Last Month</button>
      </div>)}
      </ReactDatePicker>

    </div>
    </>
  )
}

export default DateRange