import React, { useCallback, useState } from 'react'
import Select from "react-select"
import func from '../../services/functions'
import { customStyles } from '../../design/selectStyle'

const ReactSelectPagination = ({ getFilteredResults, pagination, setPagination, ...props }) => {
  const [searchQuery, setSearchQuery] = useState("");


  const inputDebounce = useCallback(func.debounce(({ pageSize, pageNumber, searchQuery, loadMore }) => {
    getFilteredResults({ pageSize, pageNumber, setPagination, searchQuery, loadMore })
  }, 400), [])

  const handleInputCompanyChange = (value, reason) => {
    if (reason?.action === "menu-close") {
      if (searchQuery?.length > 0) {
        setSearchQuery("")
        getFilteredResults()
      }
      return
    }
    if (reason?.action === "input-blur") return
    if (reason?.action === "set-value") return
    setSearchQuery(value);
    inputDebounce({ searchQuery: value })
  }

  const loadMoreOptions = () => {
    if (pagination.pageSize * pagination.pageNumber < pagination.totalRows) {
      let { pageSize, pageNumber } = pagination
      const newPageNumber = pageNumber + 1
      inputDebounce({ pageSize, pageNumber: newPageNumber, searchQuery: searchQuery, loadMore: true })
    }
  };

  return (
    <Select
      onMenuScrollToBottom={loadMoreOptions}
      inputValue={searchQuery ?? ""}
      onInputChange={handleInputCompanyChange}
      styles={customStyles}
      className="w-100"
      isSearchable={true}
      isClearable={true}
      {...props}
    />
  )
}

export default ReactSelectPagination