import { faBan, faBuilding, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import selectStyle from "../../design/selectStyle";

const ChangeCompany = ({ selectedCompany, swal, companyList, setSelectedCompany }) => {
  const [localCompany, setLocalCompany] = useState(selectedCompany);
  const { t } = useTranslation("translations");

  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedCompany(localCompany)
    swal.close()
  };

  const companyOptions = companyList?.map(company => {
    return {
      label: company.name,
      value: company.id
    }
  }) ?? []

  return (
    <div className="row m-0">
      <form className="text-left mx-auto" onSubmit={handleSubmit}>
        <div className="row">
          <div className="justify-content-center mt-4 mx-auto">
            <FontAwesomeIcon icon={faBuilding} size="4x" color={"#007567"} />
          </div>
          <div className="form-group col-xl-12 col-12 mt-5 d-flex justify-content-center">
            <ReactSelect
              styles={selectStyle}
              className="w-100"
              options={companyOptions}
              isSearchable
              value={companyOptions
                .find((cl) => cl.value == localCompany)}
              onChange={(e) => setLocalCompany(e.value.toString())}
            />
          </div>
          <div className="form-group col-xl-12 col-12 mt-5 d-flex justify-content-center align-items-end">
            <button
              type="submit"
              style={{ minWidth: "100px" }}
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faCheck} className="mr-2" />
              {t("common.save")}
            </button>
            <button
              type="button"
              style={{ minWidth: "100px" }}
              className="btn btn-danger ml-4"
              onClick={(e) => {
                e.preventDefault();
                swal.close();
              }}
            >
              <FontAwesomeIcon icon={faBan} className="mr-2" />
              {t("common.cancel")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangeCompany;