import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

const InternalAgreementCombo = ({ invoice, handleChange, disabled }) => {

  const [hasInternalAgreementCombo, setHasInternalAgreementPoCombo] = useState(false);
  const { t } = useTranslation("translations");


  let internalAgreement = invoice?.purchaseInvoiceExtracted?.agreementInfo;

  useEffect(() => {
    if (internalAgreement?.no || internalAgreement?.name || internalAgreement?.date) {
      setHasInternalAgreementPoCombo(true);
    } else {
      setHasInternalAgreementPoCombo(false);
    }
  }, [internalAgreement?.no, internalAgreement?.name, internalAgreement?.date])

  return (
    <div className="card border-0  mt-4 w-100">
      <div className=" card-header border-0">
        <div className="custom-control d-flex align-items-center custom-checkbox mr-sm-2"
          onClick={(e) =>
            setHasInternalAgreementPoCombo((state) => !state)
          }
        >
          <input
            type="checkbox"
            className="custom-control-input"
            checked={hasInternalAgreementCombo}
            disabled={disabled}
            readOnly
          />
          <label
            style={{ paddingTop: "3px", paddingLeft: "5px" }}
            className="custom-control-label"
          >
            {t("purchases.internalAgreement")}
          </label>
        </div>
      </div>
      <div className="card-body">
        {hasInternalAgreementCombo ? (
          <div className="row">
            <div className="col-md-6">
              <div className="form-group input-length-validation">
                <label className="form-label">
                  No
                </label>
                <input
                  name="agreementNO"
                  type="number"
                  className="form-control required"
                  disabled={disabled}
                  value={internalAgreement?.no ?? ""}
                  onChange={(e) => handleChange("", "purchaseInvoiceExtracted.agreementInfo.no", e.target.value, 20)}
                />
                <div
                  className={`length-text ${internalAgreement?.no?.length == 20 ? "text-danger" : ""}`}>
                  {internalAgreement?.no?.length ?? 0}/20
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">{t("home.dateOfAgreemnet")}</label>
                <DatePicker
                  autoComplete='off'
                  selected={internalAgreement?.date}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  name="date"
                  showDisabledMonthNavigation
                  onChange={(date) =>   handleChange("", "purchaseInvoiceExtracted.agreementInfo.date", date)}
                  customInput={<input className="form-control" />}
                />
              </div>
            </div>

            <div className="col-xl-12 col-12">
              <div className="form-group input-length-validation">
                <label className="form-label">
                  {t("invoice.name")}

                </label>
                <textarea
                  name="agreementName"
                  type="text"
                  className="form-control"
                  rows="1"
                  disabled={disabled}
                  value={internalAgreement?.name ?? ""}
                  onChange={(e) =>  handleChange("", "purchaseInvoiceExtracted.agreementInfo.name", e.target.value, 50)}
                />
                <div
                  className={`length-text ${internalAgreement?.name == 50 ? "text-danger" : ""}`}>
                  {internalAgreement?.name?.length ?? 0}/50
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default InternalAgreementCombo;
