import React, { useState, useRef, useEffect } from "react";
import Table from "../Table";
import { DatePicker, RangeDatePicker } from "@y0c/react-datepicker";
// import calendar style
// You can customize style by copying asset folder.
import "../../design/assets-rangeDate/styles/calendar.scss";
import func from "../../services/functions";
import mainAxios from "../../services/mainAxios";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faInfoCircle,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import { useAppContext } from "../../AppContext";
import StatisticBox from "../Statistics/StatisticBox";
import Swal from "sweetalert2";
// import ExportInvoices from "./ExportInvoices"
import { useTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import selectStyle from "../../design/selectStyle";
import CiscFormatFilters from "./CiscFormatFilters";
import AddOrRemoveColumns from "./columns/AddOrRemoveColumns";
import currencies from '../../services/currencies.json'
import genereateCisFormatColumns from "./columns/generateCisFormatColumns";
import ExportCsv from "../global/ExportCsv";

const moment = require("moment");
window.moment = moment;
const Invoices = (props) => {
  const { t, i18n } = useTranslation("translations");

  let _isMounted = true;

  const {
    user: [user],
    selectedCompany: [selectedCompany],
    cisColumns: [cisColumns],
    company: [fullCompany]
  } = useAppContext();

  const [state, setState] = useState({
    filters: {
      params: {
        dataFrom: "DB",
        partyType: [1, 2, 3, 4, 5, 7].includes(user.role.id)
          ? "SELLER"
          : "BUYER",
        buyerTin: "",
        sellerTin: "",
        search: "einvoice",
        status: "",
        // amount: "",
        // currency: "",
      },
    },
  });

  const [uIType, setUIType] = useState({
    value: "eic",
    label: "EIC",
    uidValue: "",
  });

  const partyTypeOptions = [
    { value: "SELLER", label: t("invoice.seller") },
    { value: "BUYER", label: t("invoice.buyer") },
  ];

  const [invoices, setInvoices] = useState(null);
  const [pdfCount, setPdfCount] = useState(0);
  const [pdfToDisplay, setPdfToDisplay] = useState(null);
  const [dataFromFordDocCol,setDataFromForDocCol]=useState("DOC");


  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const [partyTypeForTable, setPartyTypeForTable] = useState("SELLER");
  const pdfContainer = useRef();

  const [ciscFilters, setCisCFilters] = useState({
    status: "",
    buyer: "",
    erpId: "",
  });

  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [invoicesForCSV, setInvoicesForCSV] = useState([]);


  const getInvoicesForCVS =async () =>  {
    let filterData = state.filters;
    setPartyTypeForTable(state.filters.params.partyType);
    const { dataFrom, partyType } = state.filters.params;
    filterData = {
      ...filterData,
      params: {
        ...filterData.params,
        dataFrom,
        partyType: partyType?.length > 0 ? partyType : null,
        search: "einvoice",
      },
    };

    if (uIType?.uidValue) {
      let val;

      if (dataFrom === "CIS") {
        val = uIType.uidValue;
        if (
          uIType.value === "eic" &&
          !new RegExp(
            /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/
          ).test(val)
        ) {
          toast.warning(
            "Incorrect Pattern for type " + uIType.value.toUpperCase(),
            {
              containerId: "all_toast",
            }
          );
        } else {
          filterData.params = {
            ...filterData.params,
            [uIType.value]: val,
          };
        }
      } else {
        //concat with <L> in case of DB
        val = "<L>" + uIType.uidValue + "<L>";
        filterData.params = {
          ...filterData.params,
          [uIType.value]: val,
        };
      }
    }

    const value = null;

    return  mainAxios
      .post("apiEndpoint/search", {
        object: "GetEinvoicesRequestV2",
        value,
        ...filterData,
      })
      .then((res) => {

        if (res) {
          setInvoicesForCSV(res.data.content);
          return true;
        }
        return false;
      })
      .catch((err)=>{return false;}) 
  };
  
  const getFilteredResults = (clearedFilters, pageSize = 10, page = 1) => {
    var filterData = clearedFilters ? clearedFilters : state.filters;
    if(invoicesForCSV.length>0) setInvoicesForCSV([])

    if (!clearedFilters) {
      //set party type filter
      setPartyTypeForTable(state.filters.params.partyType);

      _isMounted && setLoading(true);

      const { dataFrom, partyType } = state.filters.params;

      //set other filter params (dataFrom DB/CIS)
      filterData = {
        ...filterData,
        params: {
          ...filterData.params,
          dataFrom,
          partyType: partyType.length > 0 ? partyType : null,
          search: "einvoice",
        },
      };


      //set filter params eic / erp / iic
      if (uIType?.uidValue) {
        let val;
        if (dataFrom === "CIS") {
          val = uIType.uidValue;
          if (
            uIType.value === "eic" &&
            !new RegExp(
              /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/
            ).test(val)
          ) {
            toast.warning(
              "Incorrect Pattern for type " + uIType.value.toUpperCase(),
              {
                containerId: "all_toast",
              }
            );
          } else {
            filterData.params = {
              ...filterData.params,
              [uIType.value]: val,
            };
          }
        } else {
          //concat with <L> in case of DB
          val = "<L>" + uIType.uidValue + "<L>";
          filterData.params = {
            ...filterData.params,
            [uIType.value]: val,
          };
        }
      }
    }


    const value = null; // state.filters.params.eic.length > 0 ? state.filters.params.eic : null
    const requestBody = {
      object: "GetEinvoicesRequestV2",
      pagination: {
        pageSize: pageSize,
        pageNumber: page,
      },
      value,
      ...filterData,
    };

    mainAxios
      .post("apiEndpoint/search", requestBody)
      .then((res) => {
        if (res) {
          _isMounted && setInvoices(res.data.content);
          setTableRows({
            rowsPerPage: pageSize,
            currentPage: page,
            totalRows: res?.data?.totalSize,
          });
        } else {
          toast.warning(t(["toast.warningInvoice"]), {
            containerId: "all_toast",
          });
          _isMounted && setInvoices([]);
        }
        _isMounted && setLoading(false);
        _isMounted &&
          setState((state) => ({
            ...state,
            filters: {
              ...state.filters,
              params: { ...state.filters.params, dataFrom: "DB" },
            },
          }));
      })
      .catch((err) => {
        _isMounted && setLoading(false);
      });
  };

  const handleRowsPerPage = (pageSize, page) => {
    getFilteredResults("", pageSize, page);
  };

  React.useEffect(() => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCount",
        params: {
          count: "pdfPpath",
        },
      })
      .then((res) => {
        if (res?.data?.status === true) {
          setPdfCount(res.data.content[0]);
          getFilteredResults("", 10, 1);
          setDataFromForDocCol(state.filters.params.dataFrom);
        } else {
          setPdfCount(0);
          setInvoices([]);
        }
      });

    return () => {
      _isMounted = false;
    };
  }, [selectedCompany]);

  const uIOptions = [
    { value: "eic", label: "EIC" },
    { value: "erpId", label: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? "ERP ID" },
    { value: "iic", label: t("invoice.IIC") },
    { value: "ublId",label:t("invoice.DocNumber")}
  ];

  const currencyOptions = [
    {
      label: "Select...",
      value: "",
    },
  ].concat(currencies);

  const statusOptions = [
    {
      label: "Select...",
      value: "",
    },
    {
      label: "Delivered",
      value: "DELIVERED",
    },
    {
      label: "Accepted",
      value: "ACCEPTED",
    },
    {
      label: "Refused",
      value: "REFUSED",
    },
    {
      label: "Paid",
      value: "PAID",
    },
    {
      label: "Partially Paid",
      value: "PARTIALLY_PAID"
    }
  ];


  const showInvoicePdf = (invoice) => {
    setLoadingPdf(true);

    mainAxios
      .post("apiEndpoint/search", {
        object: "GetEinvoicesRequestV2",
        value: invoice.eic,
        params: {
          eic: invoice.eic,
          company: "1",
          dataFrom: "CIS",
        },
      })
      .then((res) => {
        setLoadingPdf(false);
        if (res.data.status == true) {
          const blob = func.blobToFile(
            func.b64toBlob(res.data.content[0].pdf, "application/pdf"),
            "Invoice"
          );
          setPdfToDisplay(URL.createObjectURL(blob));
          setTimeout(() => {
            pdfContainer.current.classList.toggle("open");
          }, 0);

          mainAxios
            .post("apiEndpoint/search", {
              object: "GetCount",
              params: {
                count: "pdfPpath",
              },
            })
            .then((res) => {
              if (res) {
                setPdfCount(res.data.content[0]);
              } else {
                setPdfCount(0);
              }
            });
        }
      });
  };

  const columns = genereateCisFormatColumns(
    showInvoicePdf,
    cisColumns,
    t,
    loadingPdf,
    partyTypeForTable,
    dataFromFordDocCol
  );

  /*
    STATUSES
        1- ACCEPTED
        2- REFUSED
        3- PAID
        4- PARTIALLY_PAID
    */
  const changeInvoicesStatus = (invoicesToUpdate, status) => {
    const value =
      invoicesToUpdate.length > 1
        ? invoicesToUpdate.reduce(
          (all, next) => all + "," + next.eic,
          invoicesToUpdate[0].eic
        )
        : invoicesToUpdate[0].eic;
    mainAxios
      .post("apiEndpoint/call", {
        object: "EinvoiceChangeStatusRequest",
        value,
        status,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          let newInvoices = invoices
            .slice()
            .map((invoice) =>
              value.includes(invoice.eic) ? { ...invoice, status } : invoice
            );
          setInvoices(newInvoices);
          toast.success(t(["toast.statusUpdate"]), {
            containerId: "all_toast",
          });
        }
      });
  };

  const addRemoveColumn = () => {
    Swal.fire({
      title: "<strong>Add Or Remove Column</strong>",
      icon: "info",
      html: invoices.reduce((acc, curr) => acc + curr),
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: "Thumbs down",
    });
  };

  const handleFilterChange = (key, value) => {
    setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        [key]: value,
      },
    }));
  };

  const handleParamsChange = (e, label, val) => {
    const key = e ? e.target.name : label;
    const value = e ? e.target.value : val;

    if (uIOptions?.map((op) => op.value).includes(key)) {
      setUIType({
        value: key,
        label: uIOptions?.find((op) => op.value == key)?.label,
        uidValue: value,
      });
    } else {
      setState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          params: {
            ...state.filters.params,
            [key]: value,
          },
        },
      }));
    }
  };

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        params: {
          ...state.filters.params,
          sellerTin: state?.filters.params.sellerTin === "SELLER" ? state?.filters.params.buyerTin : "",
          buyerTin: state?.filters.params.sellerTin === "BUYER" ? state?.filters.params.sellerTin : "",
        },
      },
    }));
    if (state?.filters.params.partyType === "BUYER") {
      setUIType({
        value: "eic",
        label: "EIC",
        uidValue: "",
      })
    }
  }, [state.filters.params.partyType])

  const onDateRangeChange =
    (title) =>
      (...args) => {

        let from = args[0];
        let to = args[1];
        if (from && to) {
          const newStartDate = new Date(from);
          const newEndDate = new Date(to);
          const one_day = 1000 * 60 * 60 * 24;
          let diffDuration = Math.ceil(
            (newEndDate.getTime() - newStartDate.getTime()) / one_day
          );

          if (diffDuration > 40) {
            toast.warning(t(["toast.diffDate"]), {
              containerId: "all_toast",
            });
          } else {
            setState((state) => ({
              ...state,
              filters: {
                ...state.filters,
                fromDate: new Date(args[0]?.setHours(2, 0, 0, 0)),
                toDate: new Date(args[1]?.setHours(2, 0, 0, 0)),
              },
            }));
          }
        }
      };

  const clearFilters = () => {
    if (uIType?.uidValue) {
      setUIType({
        value: "eic",
        label: "EIC",
        uidValue: "",
      });
    }

    let cleared = {
      ...state,
      params: {
        partyType: [1, 2, 3, 4, 5, 7].includes(user.role.id)
          ? "SELLER"
          : "BUYER",
        dataFrom: "DB",
        buyerTin: "",
        sellerTin: "",
        search: "einvoice",
        status: "",
        // amount: "",
        // currency: "",
      },
      fromDate: func.getDateFromToday(-2),
      toDate: func.getDateFromToday(1),
    };
    setDataFromForDocCol("DB");
    setState({
      ...state,
      filters: {
        params: {
          dataFrom: "DB",
          partyType: [1, 2, 3, 4, 5, 7].includes(user.role.id)
            ? "SELLER"
            : "BUYER",
          search: "einvoice",
          buyerTin: "",
          sellerTin: "",
          status: "",
          // amount: "",
          // currency: "",
        },

        fromDate: func.getDateFromToday(-2),
        toDate: func.getDateFromToday(1),
      },
    });

    getFilteredResults(cleared, 10, 1);
  };

  const sentInvoices =
    invoices &&
    invoices.filter((invoice) => invoice.partyType === "SELLER");
  const receivedInvoices =
    invoices &&
    invoices.filter((invoice) => invoice.partyType === "BUYER");

  return invoices ? (
    <>
      <div className="d-flex align-items-center mt-4 mb-4">
        <h3 className="mb-0 d-flex align-items-center">{t("navbar.invoices")}
        <small className="ml-3">
        <ExportCsv
        data={invoicesForCSV}
        columns={columns}
        onClick={getInvoicesForCVS}
        excludedColumns={["Actions","Print"]}
      />
      </small>
      </h3>
        <div className="alert alert-warning mb-0 px-3 py-2 ml-3" role="alert">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          {t("invoice.currencymessage")}
        </div>
        <div className="ml-auto">{/* <ExportInvoices data={invoices}/> */}</div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-xl d-flex mb-30">
          <StatisticBox
            header={"# " + sentInvoices.length}
            description={
              t("invoice.sentInvoices") + " " + "(Total Number, Amount)"
            }
            icon={<FontAwesomeIcon icon={faReceipt} />}
          // total={
          //   func.getFormattedMoneyValue(
          //     sentInvoices.length > 0 &&
          //       sentInvoices.reduce((acc, next) => acc + next.amount, 0)
          //   ) + " Lek"
          // }
          />
        </div>
        {/* . */}

        {/* <div className="col-12 col-sm-6 col-xl d-flex mb-30">
                    <StatisticBox
                        header={func.getFormattedMoneyValue(sentInvoices.length !== 0 ? sentInvoices.length === 1 ? sentInvoices[0].amount : sentInvoices.reduce((acc, next) => acc + next.amount, 0) : 0) + " L"}
                        description="Total Amount"
                        icon={<FontAwesomeIcon icon={faDollarSign}/>}/>
                </div> */}
        <div className="col-12 col-sm-6 col-xl d-flex mb-30">
          <StatisticBox
            header={"# " + receivedInvoices.length}
            description={
              t("invoice.receivedInvoices") + " " + "(Total Number, Amount)"
            }
            icon={<FontAwesomeIcon icon={faReceipt} />}
          // total={
          //   func.getFormattedMoneyValue(
          //     receivedInvoices.length > 0
          //       ? receivedInvoices.reduce(
          //           (acc, next) => acc + next.amount,
          //           receivedInvoices[0].amount
          //         )
          //       : 0
          //   ) + " Lek"
          // }
          />
        </div>
        {/* <div className="col-12 col-sm-6 col-xl d-flex mb-30">
                    <StatisticBox
                        header={func.getFormattedMoneyValue(receivedInvoices.length !== 0 ? receivedInvoices.length === 1 ? receivedInvoices[0].amount : receivedInvoices.reduce((acc, next) => acc + next.amount, receivedInvoices[0].amount) : 0) + " L"}
                        description="Total Amount"
                        icon={<FontAwesomeIcon icon={faDollarSign}/>}/>
                </div> */}
        {/* <div className="col-12 col-sm-6 col-xl d-flex">
                    <StatisticBox
                        header={"# " + invoices.length}
                        description="Successful invoices"
                        icon={<FontAwesomeIcon icon={faReceipt}/>}/>
                </div> */}
        <div className="col-12 col-sm-6 col-xl d-flex mb-30">
          <StatisticBox
            header={"# " + pdfCount}
            description={t("home.downloadedPDF")}
            icon={<FontAwesomeIcon icon={faFilePdf} />}
          />
        </div>
      </div>
      <div className="card border-0 shadow">
        <div className="card-body">
          <div className="row">
            <div className="col-md-2 col-sm-4 col-6 mb-3 mr-2">
            <label className="w-100">{t("invoice.fromToDate")}:</label>
              <RangeDatePicker
                startText="From"
                endText="To"
                initialStartDate={func.getDateFromToday(-2)}
                initialEndDate={func.getDateFromToday(1)}
                onChange={onDateRangeChange("Range DatePicker")}
              />
            </div>
            <div className="col-md-2 col-sm-4 col-6 mb-3">
              <label
                //style={{ "paddingBottom": "0.3em" }}
                className="w-100"
                htmlFor="partyType"
              >
                {t("invoice.partyType")}:
              </label>
              <Select
                id="partyTypeId"
                name="partyType"
                value={partyTypeOptions.find(option => option.value === state.filters.params.partyType)}
                options={
                  [1, 2, 3, 4, 5, 7].includes(user.role.id)
                    ? partyTypeOptions
                    : [partyTypeOptions[1]]
                }
                defaultValue={[1, 2, 3, 4, 5, 7].includes(user.role.id) ? partyTypeOptions[0] : partyTypeOptions[1]}
                onChange={(e) => handleParamsChange(null, "partyType", e.value)}
                styles={selectStyle}
              />
            </div>
            {state.filters.params.partyType == "SELLER" && <div className="col-md-2 col-sm-4 col-6 mb-3">
              <label
                className="w-100"
                htmlFor="buyerNuis"
              //style={{ "paddingBottom": "0.3em" }}
              >
                {t("invoice.buyerNuis")}:
              </label>
              <input
                className="form-control"
                id="buyerNuis"
                name={"buyerTin"}
                value={state?.filters.params.buyerTin}
                onChange={handleParamsChange}
              />
            </div>}
            {state.filters.params.partyType == "BUYER" && <div className="col-md-2 col-sm-4 col-6 mb-3">
              <label
                className="w-100"
                htmlFor="sellerNuis"
              //style={{ "paddingBottom": "0.3em" }}
              >
                {t("invoice.sellerNuis")}:
              </label>
              <input
                className="form-control"
                id="sellerNuis"
                name={"sellerTin"}
                value={state?.filters.params.sellerTin}
                onChange={handleParamsChange}
              />
            </div>}
            <div className="col-md-2 col-sm-4 col-6 mb-3">
              <label className="w-100" htmlFor="uiType">
                EIC - {t("invoice.IIC")} - ERP ID - {t("invoice.NUIS")}:
              </label>
              <Select
                options={state.filters.params.partyType == "BUYER" ? uIOptions.filter(option => option.value === "eic" || option.value==="ublId") : uIOptions}
                value={uIType}
                onChange={(option) => {
                  setUIType({
                    value: option.value,
                    label: option.label,
                    uidValue: "",
                  });
                }}
                styles={selectStyle}
                name="uiOption"
              />
            </div>
            {
              <div className="col-md-2 col-sm-4 col-6 mb-3">
                <label
                  className="w-100"
                  htmlFor={uIType.value+"unique"}
                //style={{ "paddingBottom": "0.3em" }}
                >
                  {uIType.label}:
                </label>
                <input
                  className="form-control"
                  id={uIType.value+"unique"}
                  name={uIType.value}
                  value={uIType.uidValue}
                  onChange={handleParamsChange}
                />
              </div>
            }
          </div>
          <div className="row">
            {/* <div className="col-md-2 col-sm-4 col-6 mb-3">
              <label
                className="w-100"
                htmlFor="amount"
              //style={{ "paddingBottom": "0.3em" }}
              >
                {t("home.amount")}
              </label>
              <input
                className="form-control"
                id="amount"
                name="amount"
                value={state?.filters.params.amount}
                onChange={handleParamsChange}
              />
            </div> */}
            {/* <div className="col-md-2 col-sm-4 col-6 mb-3">
              <label
                className="w-100"
                htmlFor="invoiceStatus"
              //style={{ "paddingBottom": "0.3em" }}
              >
                Status:
              </label>
              <Select
                styles={SelectStyle}
                options={statusOptions}
                value={statusOptions.find(
                  (statusOption) => statusOption.value === state?.filters.params.status
                )}
                isSearchable={true}
                onChange={(status) =>{
                  handleParamsChange(null, "status", status.value)
                }
                }
                name="status"
              />
            </div> */}
             {/* <div className="col-md-2 col-sm-4 col-6 mb-3">
              <label
                className="w-100"
                htmlFor="currency"
              //style={{ "paddingBottom": "0.3em" }}
              >
                {t("invoice.currency")}:
              </label>
              <Select
                styles={SelectStyle}
                options={currencyOptions}
                value={currencyOptions.find(
                  (currencyOption) => currencyOption.value === state?.filters.params.currency
                )}
                isSearchable={true}
                onChange={(status) =>{
                  handleParamsChange(null, "currency", status.value)
                }
                }
                name="currency"
              />
            </div> */}
          </div>
          {/* <CiscFormatFilters
            filters={state?.filters}
            handleParamsChange={handleParamsChange}
            company={company}
            user={user}
            
          /> */}
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12 d-flex align-items-end mb-3">
              <div className="d-flex align-items-center w-100">
                <div className="custom-control custom-checkbox mr-sm-2 ">
                  <input
                    type="checkbox"
                    name="dataFrom"
                    id="dataFrom"
                    className="custom-control-input"
                    checked={state.filters.params.dataFrom === "CIS"}
                    onChange={(e) => {
                      const value = e.target.checked ? "CIS" : "DB";

                      setState((state) => ({
                        ...state,
                        filters: {
                          ...state.filters,
                          params: { ...state.filters.params, dataFrom: value },
                        },
                      }));
                    }}
                  />
                  <label
                    htmlFor="dataFrom"
                    className="custom-control-label ml-2"
                    style={{ paddingLeft: "0.5em", paddingTop: "0.2em" }}
                  >
                    {t("invoice.getData")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-2 col-sm-6 col-12 d-flex align-items-end mb-3">
              <div className="d-flex align-items-center w-100">
                <button
                  className="btn btn-primary mr-3"
                  disabled={loading}
                  style={{ minWidth: "80px" }}
                  onClick={(e) => {
                    const { currentPage, rowsPerPage } = tableRows;
                    e.preventDefault();
                    getFilteredResults("", rowsPerPage, currentPage);
                  }}
                >
                  {loading ? (
                    <Loader size="xs" color="white" />
                  ) : (
                    t(["navbar.search"])
                  )}
                </button>
                <a
                  href="#"
                  disabled={state.loading}
                  className={
                    "nav-link p-0" + (state.loading ? " disabled" : "")
                  }
                  onClick={(e) => {
                    // e.preventDefault();
                    clearFilters();
                  }}
                >
                  {t("queue.clearFilters")}
                </a>
              </div>
            </div>
          </div>
          <AddOrRemoveColumns columns={columns} />
          <div className="reports mt-2">
            {columns.filter((column) => column.defaultChecked).length > 0 ? (
              <Table
                uploadBtn={false}
                columns={columns.filter((column) => column.defaultChecked)}
                data={[...invoices]}
                enablePagination={{
                  paginationServer: true,
                  totalRows: tableRows?.totalRows,
                  onChangeRowsPerPage: (e) => {
                    setTableRows({
                      ...tableRows,
                      rowsPerPage: e,
                    });
                    handleRowsPerPage(e, tableRows?.currentPage);
                  },
                  onChangePage: (number, page) => {
                    setTableRows({
                      ...tableRows,
                      currentPage: number,
                    });
                    handleRowsPerPage(tableRows?.rowsPerPage, number);
                  },
                }}
                selectable
                rowSelectCritera={(invoice) =>
                  invoice.partyType === "SELLER" ||
                  invoice.status === "ACCEPTED" ||
                  invoice.status === "REJECTED"
                }
                selectionActions={
                  [1, 2, 3, 6].includes(user.role.id)
                    ? [
                      {
                        name: t(["queue.accepted"]),
                        callback: (selectedInvoices) =>
                          changeInvoicesStatus(selectedInvoices, "ACCEPTED"),
                      },
                      {
                        name: t(["queue.rejected"]),
                        callback: (selectedInvoices) =>
                          changeInvoicesStatus(selectedInvoices, "REFUSED"),
                      },
                      // {name: "Paid", callback: (selectedInvoices) => changeInvoicesStatus(selectedInvoices, "PAID")},
                      // {name: "Partially Paid", callback: (selectedInvoices) => changeInvoicesStatus(selectedInvoices, "PARTIALLY_PAID")}
                    ]
                    : []
                }
                searchableKeys={[
                  "ublId",
                  "docType",
                  "eic",
                  "recDateTime",
                  "dueDateTime",
                  "partyType",
                  "status",
                  "amount",
                ]}
              />
            ) : (
              <div className="no-column-selected">NO DATA</div>
            )}
          </div>
        </div>
        <div className="pdf-container" ref={pdfContainer}>
          {pdfToDisplay && (
            <div className="pdf-viewer">
              <div
                className="pdf-overlay"
                onClick={() => {
                  setTimeout(() => {
                    setPdfToDisplay(null);
                  }, 150);
                  pdfContainer.current.classList.toggle("open");
                }}
              />
              <div className="pdf-content">
                <embed src={pdfToDisplay} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default Invoices;
