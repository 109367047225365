import React, { useState, useRef } from 'react'
import Accordion from "./Accordion"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import InvoiceItem from './InvoiceItem'
import { toast } from 'react-toastify'
import func from '../../../services/functions'
import { useAppContext } from '../../../AppContext'
import { Preloader, ThreeDots } from 'react-preloader-icon'
import { useTranslation, withTranslation } from "react-i18next";
import mainAxios from '../../../services/mainAxios'
import PopOver from "../../global/PopOver";
import { Link } from 'react-router-dom'

const bigDecimal = require("js-big-decimal");

// let lastItemsNum = 1

const InvoiceItems = ({ invoiceState, setInvoiceState, fullItems,setFullItems, disabled }) => {

    const { t, i18n } = useTranslation('translations');
    const { user: [user,], selectedCompany: [selectedCompany,], unitsOfMeasure, } = useAppContext()
    const company = user.companiesRights.find(company => company.id === parseInt(selectedCompany))
    const [openAccordion, setAccordionOpen] = useState(true);

    const isAlbanian = invoiceState.currency === "ALL"
    const currencyToDisplay = isAlbanian ? "Lek" : invoiceState.currency

    // search by barcode
    const [codeLoading, setCodeLoading] = useState(false);
    const [customItemAdded, setCustomItemAdded] = useState(false);
    const codeRef = useRef(null);
    const firstUpdate = useRef(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [code, setCode] = useState("");
    const [autoCompleted, setAutoCompleted] = useState(false);
    const [quantityChanged,setQuantityChanged]=useState(false);
    const [sameItemIndex,setSameItemIndex]=useState(0);



    let unitsOfMeasureDefault= unitsOfMeasure?.find(unitsOfMeasure => unitsOfMeasure?.code === "C62") ?? null


    React.useEffect(() => {
        const {
          items,
          invoiceTotalWithoutVat,
          invoiceTotalDiscount,
          invoiceDiscountedTotalWithoutVat,
          invoiceTotalVat,
          invoiceTotalWithVat,
        } = func.calculateInvoiceData(invoiceState);
        setInvoiceState((state) => ({
          ...state,
          items,
          invoiceTotalWithoutVat,
          invoiceTotalDiscount,
          invoiceDiscountedTotalWithoutVat,
          invoiceTotalVat,
          invoiceTotalWithVat,
        }));
      }, [
        ...invoiceState.items.map((item) => {
          const {
            quantity,
            priceWithoutVat,
            priceWithVat,
            discountValue,
            discountType,
            vatCategory,
          } = item;
          return `${quantity},${priceWithoutVat},${priceWithVat},${discountValue},${discountType},${vatCategory}`;
        }),
        invoiceState.discount,
      ]);

    React.useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (code?.length < 4) {
            return;
        }

        const indicator = setTimeout(() => {
            let index = invoiceState.items.findIndex(oldItem => oldItem.code === code);
            if (index >= 0) {
                setInvoiceState((invoiceState) => ({
                    ...invoiceState,
                    items: [
                        ...invoiceState.items?.slice(0, index),
                        {
                            ...invoiceState.items[index],
                            quantity: bigDecimal.add(invoiceState.items[index].quantity, 1),
                        },
                        ...invoiceState.items?.slice(index + 1, invoiceState.items.length)
                    ],
                }));
                setQuantityChanged(true);
                setSameItemIndex(index)
                setCode("");
                return;
            }
            setCodeLoading(true);
            mainAxios
                .post("apiEndpoint/search", {
                    object: "GetCompanyItems",
                    value: null,
                    params: {
                      companyItemLiveSearch: code,
                      company:company.id.toString()
                    },
                    company: company.id,
                })
                .then((res) => {
                    setCodeLoading(false);
                    if (res.data.status == true) {
                        if (res.data.content.length > 0) {
                            setAutoCompleted(true) //needed inside invoice item because it affects code field which does a request
                            addCustomItem(res.data.content[0]);
                            setCustomItemAdded(true);
                            setCode("");
                        }
                    }

                });
        }, 400);
        return () => {
            clearTimeout(indicator);
        };
    }, [code])

    React.useEffect(() => {
        if (customItemAdded) {
            setCustomItemAdded(false);
            setAutoCompleted(false);
            addNewItem();
        }
    }, [customItemAdded])
    const addCustomItem = (item) => {
        const isEuro = invoiceState?.currency === "EUR"
        setInvoiceState((invoiceState) => ({
            ...invoiceState,
            items: [
                ...invoiceState.items?.slice(0, currentIndex),
                {
                    name: item?.name,
                    code: item?.code,
                    description: item?.description,
                    discountType: "Percentage",
                    discountValue: "",
                    priceWithVat: isEuro ? (item?.priceWvatEur?.toString() ?? "0") :(item?.priceWvatAll?.toString() ?? "0"),
                    priceWithoutVat: isEuro ? (item?.priceWovatEur?.toString() ?? "0") : (item?.priceWovatAll?.toString() ?? "0") ,
                    quantity: "1",
                    totalWithVat: "",
                    totalWithoutVat: "",
                    unitOfMeasureId: item?.unitOfMeasureId ?? unitsOfMeasureDefault,
                    unitPrice: isEuro ? (item?.priceWovatEur?.toString() ?? "0") : (item?.priceWovatAll?.toString() ?? "0"),
                    vatCategory: item?.vatCategory ?? "",
                    vatLabel: item?.vatReason == "" ? null : item?.vatReason,
                },
            ],
        }));

    };

    // React.useEffect(() => {
    //     if(invoiceState.items.length > lastItemsNum) {
    //         const accordions = document.querySelectorAll(".accordion")
    //         accordions[accordions.length-1].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    //         lastItemsNum = invoiceState.items.length
    //     }
    // }, [invoiceState.items])

    const addNewItem = (e) => {
        if (e) {
            e.preventDefault()
        }
        if (!disabled) {
            if (!invoiceState.badDebtInv) {
                let validatedItems = invoiceState.items.filter(item => item?.code?.length > 0 && item?.name?.length > 0 && item?.priceWithVat?.length > 0)
                if (validatedItems.length === invoiceState.items.length) {
                    setAccordionOpen(false)
                    setCurrentIndex(index => index + 1)
                    setInvoiceState(invoiceState => ({
                        ...invoiceState,
                        items: [
                            ...invoiceState.items,
                            {
                                name: "",
                                code: "",
                                description: "",
                                unitOfMeasureId:  unitsOfMeasureDefault ?? null ,
                                quantity: "1",
                                unitPrice: "",
                                priceWithoutVat: "",
                                priceWithVat: "",
                                discountValue: "",
                                discountType: "Percentage",
                                vatCategory: company.issuerInVat ? "20" : "0",
                                vatLabel: null,
                                investment: false,
                                // discountPriceWithoutVat: "",
                                // discountPriceWithVat: "",
                                totalWithoutVat: "",
                                totalWithVat: ""
                            }
                        ]
                    }))
                }
                else {
                    toast.warning(t(['toast.completeItems']), { containerId: "all_toast" })
                }
            }
            else {
                toast.warning("You can only have ONE item in Bad Debt Invoice", { containerId: "all_toast" })
            }
        }
    }

    const removeItem = (e, item, index) => {
        e.preventDefault()
        let newItems = invoiceState.items.slice(0, index).concat(invoiceState.items.slice(index + 1))
        setCurrentIndex(index => index - 1)
        const st = {
            discount: invoiceState.discount,
            items: newItems
        }

        const {
            items,
            invoiceTotalWithoutVat,
            invoiceTotalDiscount,
            invoiceDiscountedTotalWithoutVat,
            invoiceTotalVat,
            invoiceTotalWithVat
        } = func.calculateInvoiceData(st)
        //} = func.calculateInvoiceData(invoiceState.discount, newItems)

        setInvoiceState(invoiceState => ({
            ...invoiceState,
            items,
            invoiceTotalWithoutVat,
            invoiceTotalDiscount,
            invoiceDiscountedTotalWithoutVat,
            invoiceTotalVat,
            invoiceTotalWithVat
        }))
    }


    return (
        <div className="card border-0 shadow mb-4" id="invoice_items">
            <div className="card-header">
                <div className="d-flex align-items-center">
                    <h3 className="mr-3">{t('invoice.items')}</h3>
                    <div className="input-loading" style={{width:280}}>
                        <input
                            type="text"
                            id="code"
                            name="code"
                            ref={codeRef}
                            className="form-control"
                            placeholder={t('item.scanCode')}
                            disabled={disabled}
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                        {codeLoading && <div className="custom-loading">
                            <Preloader
                                use={ThreeDots}
                                size={25}
                                strokeWidth={6}
                                strokeColor="#777"
                                duration={1000}
                            />
                        </div>}
                    </div>
                    <PopOver  
                        customStyle = { {borderRadius: "40%", width: "20px", height: "20px", transform: "scale(0.80)",backgroundColor:"#343a40",color:"white"} }
                        title= {t('hints.scanItem')} />

                </div>
            </div>
            <div className="card-body">
                <div className="invoice-items" style={{ marginLeft: "-20px", marginRight: "-20px" }}>
                    {invoiceState.items.map((item, index) => {
                        return (
                            <Accordion
                                //openAccordion={invoiceState.items.length - 1 === index && index !== 0 ? !openAccordion : openAccordion}
                                openAccordion={index !== invoiceState.items.length - 1 || index == 0 ? openAccordion : !openAccordion}
                                heading={item.name && item.name.length > 0 ? item.name : t(['item.itm']) + ` ${index + 1}`} key={index}
                                //headingRight={item.quantity && item.quantity + " x " + func.getFormattedMoneyValue(item.priceWithVat)+ " " + currencyToDisplay + " = "  + " " + item.totalWithVat + " " + currencyToDisplay}
                                headingRight={item.quantity && "Total: " + func.getFormattedMoneyValue(item.totalWithVat) + " " + currencyToDisplay}
                                quantityChanged={quantityChanged} setQuantityChanged={setQuantityChanged}
                                currentIndex={index} sameItemIndex={sameItemIndex}
                                marginBottom={"120px"}
                            >
                                <div
                                    style={{ padding: "10px 20px 20px" }}>
                                    <InvoiceItem item={item} invoiceState={invoiceState} setInvoiceState={setInvoiceState} index={index}
                                        autoCompleted={autoCompleted} setAutoCompleted={setAutoCompleted}
                                        addCustomItem={addCustomItem} disabled={disabled} unitsOfMeasure={unitsOfMeasure} />
                                    {invoiceState.items.length > 1 &&
                                        <a
                                            href="#"
                                            className={"btn btn-outline-danger" + (disabled ? " disabled" : "")}
                                            disabled={disabled}
                                            onClick={(e) => removeItem(e, item, index)}>
                                            {t('invoice.remove')}
                                        </a>}
                                </div>
                            </Accordion>
                        )
                    }
                    )}
                    <div className="down-divider" />
                </div>
                <div className={"add-new-item border-bottom" + (disabled ? " disabled" : "")}>
                    <Link
                        to="#"
                        className={"new-item-link text-white stretched-link" + (disabled ? " disabled" : "")}
                        disabled={disabled}
                        onClick={addNewItem}>
                        <FontAwesomeIcon icon={faPlus} className="new-item-icon" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default InvoiceItems