import { faFilePdf, faPaperPlane, faPrint, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import func from '../../services/functions';
import mainAxios from '../../services/mainAxios';
import ThermalPrinterInvoiceContent from '../CustomInvoices/ThermalPrinterInvoiceContent';
import Accordion from '../CustomInvoices/WizardSteps/Accordion';

const InvoicePrintInfo = ({invoice,preview}) => {
  const { t } = useTranslation("translations");
  const [pdfToDisplay, setPdfToDisplay] = React.useState(null);
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const pdfContainer = React.useRef();

  useEffect(()=>{
    if(invoice?.status?.status === "APPROVED" &&  preview){
      handlePrint();
    }
  },[invoice.status?.status])

  const handlePrint = () => {

  
    const content = document.getElementById("thermalPrinterInvoice");
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
  
  
    pri.document.open();
    pri.document.write(`<html><style>@page{margin:0mm auto;margin-bottom:10mm;font-family: 'EB Garamond', serif;}</style>${content.innerHTML}</html>`);
    pri.document.close();
    pri.focus();
    pri.print();
    pri.document.close();

  }
  
  const showInvoicePdf = (key, invoice) => {
    setLoadingPdf(true);
    if (key === "iic") {
      mainAxios
        .post("apiEndpoint/search", {
          object: "GetFiscPDF",
          params: {
            iic: invoice.iic,
          },
        })
        .then((res) => {
          const blob = func.blobToFile(
            func.b64toBlob(res.data.content[0], "application/pdf"),
            "Custom Invoice"
          );
          setPdfToDisplay(URL.createObjectURL(blob));
          setTimeout(() => {
            pdfContainer.current.classList.toggle("open");
            setLoadingPdf(false);
          }, 0);
        });
    } else if (key === "eic") {
      mainAxios
        .post("apiEndpoint/search", {
          object: "GetEinvoicesRequest",
          params: {
            iic: invoice.iic,
            tcrCode: invoice.tcrCode,
            search: "einvoice",
            dataFrom: "CIS",
          },
        })
        .then((res) => {
          if (res?.data?.content[0]?.pdf) {
            const blob = func.blobToFile(
              func.b64toBlob(res.data.content[0].pdf, "application/pdf"),
              "Custom Invoice"
            );
            setPdfToDisplay(URL.createObjectURL(blob));
            setTimeout(() => {
              pdfContainer.current.classList.toggle("open");
              setLoadingPdf(false);
            }, 0);
          }
          setLoadingPdf(false);
        });
    }
  };

  let badgeClass = "secondary";
  let statusLabel = "Draft";

  if(invoice){switch (invoice?.status?.status) {
      case "READY_FOR_APPROVAL":
        badgeClass = "info";
        statusLabel = "Ready For Approval";
      case "APPROVED":
        badgeClass = "success";
        statusLabel = "Approved";
         break;
       default:
    }
  }
  if(invoice.iic || invoice.eic || invoice.fic){
    return (<div className="card-accordion">
        
    <Accordion
      heading={
        <div className='d-flex align-items-center justify-content-around'>
          {invoice.iic && (
            <a
              href="#"
              className={
                "btn btn-sm btn-info d-flex-align-items-center" +
                (loadingPdf ? " disabled" : "")
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handlePrint();
              }}
            >
              <FontAwesomeIcon icon={faPrint} className="mr-1" />
              Thermal Fisc
            </a>
          )}
          {invoice.iic && (
            <a
              href="#"
              className={
                "btn btn-sm btn-info d-flex-align-items-center" +
                (loadingPdf ? " disabled" : "")
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showInvoicePdf("iic", invoice);
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Print Fisc
            </a>
          )}
        {invoice.eic && (
            <a
              href="#"
              className={
                "btn btn-sm btn-info d-flex-align-items-center" +
                (loadingPdf ? " disabled" : "")
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showInvoicePdf("eic", invoice);
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Print Einv
            </a>
          )}
          {invoice.qrurl && (
            <a
              href={invoice.qrurl}
              onClick={(e) => e.stopPropagation()}
              className="btn btn-sm btn-info d-flex-align-items-center"
              target="_blank"
            >
              QR Url
            </a>
          )}
          {invoice.iic && (
            <a
              href="#"
              className="btn btn-sm btn-info d-flex-align-items-center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                func.sendEmailPdf(invoice.iic, invoice.customer, invoice?.parties?.buyer?.tin, t);
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} className="mr-1" />
              Email
            </a>
          )}
        </div>
      }
    >
      <div style={{ padding: "10px 20px 20px" }}>
        <p>
          <strong>FIC: </strong> {invoice.fic}
        </p>
        <p>
          <strong>IIC: </strong> {invoice.iic}
        </p>
        <p>
          <strong>EIC: </strong> {invoice.eic}
        </p>
        <p>
          <strong>Erp Id: </strong> {invoice?.internalId ?? ""}
        </p>
      </div>
    </Accordion>
    <div className="d-none">
        {invoice?.action?.action === "APPROVED" && <ThermalPrinterInvoiceContent invoice={invoice} />}
     </div>
    <iframe id="ifmcontentstoprint" className="d-none"></iframe>
    <div className="pdf-container" ref={pdfContainer}>
        {pdfToDisplay && (
          <div className="pdf-viewer">
            <div
              className="pdf-overlay"
              onClick={() => {
                setTimeout(() => {
                  setPdfToDisplay(null);
                }, 150);
                pdfContainer.current.classList.toggle("open");
              }}
            />
            <div className="pdf-content">
              <embed src={pdfToDisplay} />
            </div>
          </div>
        )}
      </div>
  </div>)
  }
  return <></>
}

export default InvoicePrintInfo