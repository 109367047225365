import React, { forwardRef } from "react";

export const DashboardCard=forwardRef(({title,headerRight,children},ref)=>{
    return (
        <div ref={ref} className="card border card-fill filter-card-dashboard shadow w-100">
        <div className="card-title p-3 ml-2 text-left dashboard-donut">
          {title}
          {headerRight}
        </div>
        <div className="card-body p-3 w-100 h-100 d-flex justify-content-center">
            {children}
        </div>
      </div>
    )
})   