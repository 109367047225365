import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import Auth from './Auth'
import { useAppContext } from '../../AppContext'
import Forbidden403 from '../Forbidden403'
import EditPassword from '../Setup/Users/EditPassword'
import ForgetPassword from './ForgetPassword'
import NewLogin from './NewLogin' 


const AuthorizedRoute = (props) => {
    const { user: [user,], selectedCompany: [selectedCompany,],forbidden:[forbidden,] } = useAppContext()
    const location = props.history.location.pathname

    let resetPass;

    if (user) resetPass = user.resetPass
    else resetPass = false;

    if (resetPass) {
        return <Route {...props} component={EditPassword} />
    }
    else if (user && selectedCompany) {
        if
            (
            props.needSpecificRole
            && !props.needSpecificRole.includes(user.role.id)
            && !location.includes("/users/edit/" + user.id)
            && !location.includes("/users/edit-password/" + user.id)|| 
            (props.needsPurchaseRole && !props.needsPurchaseRole.includes(user?.purchaseRole?.id)) || 
            (props.needSpecificUser && !props.needSpecificUser.includes(user.id))
            || forbidden
        )
            return <Route {...props} component={Forbidden403} />
        else
            return <Route {...props} />
    }
    else if (location.includes("/forget-password")) {

        return <Route {...props} component={ForgetPassword}></Route>

    }
    else {
        return <Route {...props} component={NewLogin} />
    }
}

export default withRouter(AuthorizedRoute)