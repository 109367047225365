import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import func from "../../services/functions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TooltipOverlay from "./TooltipOverlay";

const bigDecimal = require("js-big-decimal")

const ExportCsvPrime = ({ label = "Export", data, columns, onClick, excludedColumns, callback,showAmount=false,tooltip }) => {

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("translations");
  const csvInstance = useRef(true)
  // const bigDecimal = require("js-big-decimal")
  let dataFormatted = data?.map((invoice) => {

    const formattedObject = {};
    let amountALL =  "0.00"
    if (showAmount) {amountALL= invoice.currency !== "ALL" ? (func.getFormattedMoneyValue(bigDecimal.multiply(invoice.rate, invoice.invoiceTotalWithVat))) : "0,00"}
    columns.forEach((customInvoiceColumn) => {
      if (!excludedColumns.find(excCol => excCol === customInvoiceColumn.field)) {
        if(showAmount){
          formattedObject[t(['home.amountALL'])] = amountALL;
        }
        const bodyValue = customInvoiceColumn?.body? customInvoiceColumn.body(invoice):null
        const head=customInvoiceColumn?.exportHeader ?? customInvoiceColumn.header
        if (customInvoiceColumn.format) {
          formattedObject[head] = customInvoiceColumn.format(invoice)
        }else if (customInvoiceColumn?.formattedExportValue) {
          formattedObject[head] = bodyValue
        } else {
          formattedObject[head] = invoice[customInvoiceColumn.field]
        }
      }

    });
    if (callback !== undefined) {
      callback(invoice, formattedObject);
    }

    return formattedObject;
  })



  const onCsvClick = () => {
    if (!loading && csvInstance?.current?.link && onClick !== undefined) {
      setLoading(true)
      const loadingToastId=toast.loading("Exporting...",{
        containerId: "all_toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
      })
      onClick()
        .then((res) => {
          setLoading(false)
          // toast.success("Data exported successfully", {
          //   containerId: "all_toast",
          // });
          toast.dismiss(loadingToastId)

          if (res) {
            csvInstance.current.link.click()
          }
        })
    }
  }

  const button=(
    <div
    onClick={() => onCsvClick()}
    className={`btn btn-light bg-white shadow-sm btn-custom btn-sm text-primary ${loading ? "disabled" : ""}`}
  >
    <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
    <span className="text-dark">{label}</span>
  </div>
  )

  return (
    <div>
      {tooltip?
            <TooltipOverlay show={0} hide={0} title={tooltip}>
                {button}
            </TooltipOverlay>:button
      }
      <CSVLink
        ref={csvInstance}
        data={dataFormatted}
        filename={`${new Date().getTime()}.csv`}
        className="hidden"

      />
    </div>
  );
}

export default ExportCsvPrime