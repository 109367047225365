import React, { useEffect, useMemo, useState } from "react";
import { Chart } from "chart.js";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import moment from "moment";
import mainAxios from "../../services/mainAxios";
import func from "../../services/functions";
import { Preloader, Bars } from "react-preloader-icon";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import useOnScreen from "../global/hooks/useOnScreen";

const CashInsightforPeriod = ({ title, sessionData, businessUnitCodes }) => {
  const { t } = useTranslation("translations");
  const {
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const cashInsightRef =React.useRef()
  const cashInsightForPeriod =React.useRef()

  const [loading,setLoading] = useState(false);
  const [cashInsight, setCashInsight] = useState({
    allStats: null,
    groupedByBranch: null,
    allStatsGroupedByBranch: null,
  });


  const isVisible = useOnScreen(cashInsightForPeriod, [selectedCompany])

  useEffect(() => {
    if (!(sessionData?.fromDate && sessionData?.toDate) || !isVisible) {
      return;
    }
    const today = moment();
    const toDate = moment(today).subtract(1, "days").format("YYYY-MM-DD");
    const fromDate = moment(toDate).subtract(6, "days").format("YYYY-MM-DD");
    setLoading(true);
    let buCodes=businessUnitCodes.map(branch=>branch.value)
    mainAxios
      .post("/endpoint/v2/reports/queue/branch/analytic", {
        object: "GetQueueCashDepositStatisticsAnalytics",
        params:{buCodes:buCodes.join(",")},
        fromDate,
        toDate,
      })
      .then((res) => {
        setLoading(false);

        if (res.status == 200 && res.data?.content?.length > 0) {
          let filteredData = res.data.content.filter((stat) => {
            return  stat.tcrCode !== stat.businessUnitCode && stat.tcrCode!=="TS001NC001" ;
          });
          let orderedByDate = orderByDate(filteredData);

          let groupedByBranch = groupByBranch(orderedByDate);
          groupedByBranch=groupedByBranch.map((branch)=>{
            let stats=groupByDate(branch.stats)
            return {...branch,stats}
          })
          setCashInsight((state) => ({
            ...state,
            allStats: filteredData,
            allStatsGroupedByBranch: groupedByBranch,
            groupedByBranch,
            fromDate,
            toDate
          }));
        }
      });
  }, [selectedCompany,isVisible,sessionData]);


  let orderByDate = (stats) => {
    return stats.sort((a, b) => {
      return a.recordDate - b.recordDate;
    });
  };


  const groupByDate = (data) => {
    var result = [];
    data.reduce((res, value) => {
      if (!res[value.recordDate]) {
        res[value.recordDate] = {
          ...value,
          recordDate: value.recordDate,
          cashEnd: 0,
        };
        result.push(res[value.recordDate]);
      }
      res[value.recordDate].cashEnd += value.cashEnd + value.banknoteCashTot;
      return res;
    }, {});

    return result;
  };

  

  const groupByBranch = (stats) => {
    let groupedByBuObj = stats.reduce((group, stat) => {
      const { businessUnitCode } = stat;
      group[businessUnitCode] = group[businessUnitCode] ?? [];
      group[businessUnitCode].push(stat);
      return group;
    }, {});

    let groupedByBuArr = Object.keys(groupedByBuObj).map((key) => {
      return {
        businessUnitCode: key,
        stats: groupedByBuObj[key],
      };
    });
    return groupedByBuArr;
  };

  useEffect(() => {
    if (cashInsight?.allStatsGroupedByBranch) {
      let filteredStats =
        cashInsight.allStatsGroupedByBranch?.filter(
          (group) => group?.businessUnitCode === sessionData?.businessUnitCode
        ) ?? null;
      setCashInsight((prevState) => ({
        ...prevState,
        groupedByBranch: sessionData?.businessUnitCode
          ? filteredStats
          : [...cashInsight.allStatsGroupedByBranch],
      }));
    }
  }, [sessionData?.businessUnitCode, cashInsight?.allStatsGroupedByBranch]);

  const colorArr = [
    { borderColor: "rgb(54, 201, 198)" },
    { borderColor: "rgb(250, 192, 145)" },
    { borderColor: "rgb(80, 117, 223)" },
    { borderColor: "rgb(244, 241, 187)" },
    { borderColor: "rgb(155, 193, 188)" },
  ];

  const randomColor = () => {
    var x = Math.floor(Math.random() * 256);
    var y = Math.floor(Math.random() * 256);
    var z = Math.floor(Math.random() * 256);
    var color = `rgb(${x},${y},${z},1)`;
    return color;
  };

  //add opacity to rgba color
  const addOpacity = (color, opacity) => {
    let colorArr = color.split(",");
    colorArr[3] = opacity;
    return colorArr.join(",");
  };
  let today = useMemo(()=>moment().subtract(1, "days"),[]);
  let last7Days = useMemo(()=>Array.from({ length: 7 }, (_, i) => today.clone().subtract(6 - i, 'days').format("DD MMM YYYY")),[]);

  let datasets =useMemo(()=>{
  
    return   cashInsight?.groupedByBranch?.map((dataset, index) => {
      let color = randomColor();
      let label= businessUnitCodes.find((branch=>branch.value==dataset.businessUnitCode))?.label?.trim() ?? dataset.businessUnitCode
      
      return {
        label,
        data:last7Days.map(date => {
          let tcrStat = dataset.stats.find(stat =>{
            
            return moment(stat.recordDate).format("DD MMM YYYY") === date
            });
            
          return {
            x:tcrStat?.recordDate? moment(tcrStat.recordDate).format("DD MMM YYYY"):date,
            y: tcrStat ? tcrStat.banknoteCashTot : 0,
          };
        }), 
        backgroundColor: addOpacity(
          colorArr?.[index]?.borderColor ?? color,
          0.1
        ),
        // fill: true,
        borderColor: colorArr?.[index]?.borderColor ?? color,
        pointBackgroundColor: colorArr?.[index]?.borderColor ?? color,
        pointBorderColor: colorArr?.[index]?.borderColor ?? color,
        pointHoverBackgroundColor: colorArr?.[index]?.borderColor ?? color,
        pointHoverBorderColor: colorArr?.[index]?.borderColor ?? color,
        pointStyle: "circle",
        pointHitRadius: 10,
        pointBorderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 10,
        pointHoverRadius: 8,
        pointRadius: 4,
        lineTension: 0.5,
        // order: dataset.stats.length-index,
      };
    }) ?? [];
  },[cashInsight?.groupedByBranch,businessUnitCodes])
  


  const labels = useMemo(() => {
    let dates = Array.from(
      new Set(
        cashInsight?.allStats?.map((stat) => {
          return moment(stat.recordDate).format("DD MMM YYYY");
        })
      )
    );

    return dates;
  }, [cashInsight.allStats]);

  const data = {
    labels:last7Days,
    datasets,
  };


  const legend = {
    display: true,
    position: "top",
    labels: {
      fontColor: "#323130",
      fontSize: 24,
    },
  };

  const updateDataset = async (datasetIndex)  => {
    var chart = cashInsightRef.current;
    var meta = chart?.legend?.chart?._metasets?.[datasetIndex]
    if(!meta) return
    let legendItemHidden=meta?.hidden=== undefined || meta.hidden===null ?true : null;
    meta.hidden = legendItemHidden
    chart.update();
};

  window.updateDatasetCashInsight= updateDataset;

  const customLegendInsight={
    id:"customLegendInsight",
    afterUpdate: function(chart ) {
      // Make sure we're applying the legend to the right chart

      let customLegendInsight=chart.config?.options?.plugins?.customLegendInsight
      const legend=document.querySelector("#custom-legend-insight")
      if(!legend || !customLegendInsight || (customLegendInsight && chart?.legend?.legendItems?.length==0)) return
        let innerHtml="";
        let metas= chart?.legend?.chart?._metasets
        chart.legend.legendItems.forEach((item, i) => {
          let meta =metas?.[i]
          let legendItemHidden=meta?.hidden=== undefined || meta.hidden===null ?false : true;
          innerHtml += `
          <li class="d-flex text-left legend-item  ${legendItemHidden?"legend-item-hidden":""}" onclick="updateDatasetCashInsight(${i})"">
            <div class="bullet" style="background-color: ${ item.strokeStyle }"> </div>
            <div class="legend-item-name">${ item.text }</div>
          </li>
        `
        });
        const legendInnerHtml=`<ul class="custom-scroll-bar text-center w-100">${innerHtml}</ul>`
        legend.innerHTML=legendInnerHtml
        return legend
    },
    afterRender: function(chart ) {
      if (!chart.$rendered) {
        chart.$rendered = true;
        let metas = chart?.legend?.chart?._metasets
        if(metas?.length>15){
          for(let i=10;i<metas?.length;i++){
            metas[i].hidden=true
        }
      }
      chart.update();
   }
    }
  }

  Chart.register({
    customLegendInsight
  });

  const options = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Chart Title",
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        bodyFontColor: "#000",
        titleMarginBottom: 10,
        titleFontColor: "#000",
        titleFontSize: 14,
        borderWidth: 1,
        xPadding: 25,
        yPadding: 25,
        caretPadding: 10,
      },
      customLegendInsight
    },
  };

  return (
    <div ref={cashInsightForPeriod}  className="card border card-fill filter-card-dashboard shadow w-100">
      <div className="card-title p-3 ml-3 text-left d-flex">{title}
      <div className="ml-2 p-1" style={{fontSize:13,backgroundColor:"#e1f7f7"}}>{moment(cashInsight.fromDate).format("DD/MM/yyyy")}-{moment(cashInsight.toDate).format("DD/MM/yyyy") }</div>
      </div>
      <div className="card-body">
        <div className="body h-100">
          {loading ? (
            <div className="d-flex justify-content-center h-100 align-items-center ">
              <Preloader
                use={Bars}
                size={90}
                strokeWidth={6}
                duration={1000}
                strokeColor="rgba(209, 231, 221, 1)"
              />
            </div>
          ) : cashInsight.groupedByBranch?.length > 0 ? (
            <div className="d-flex flex-column justify-content-center h-100 w-100 align-items-center ">
              <div id="custom-legend-insight" className="w-100 m-auto" style={{height:"20%"}}></div>
              <div className="cash-insight-chart w-100"  style={{height:"80%"}}>
                <Line data={data} options={options} legend={legend} ref={cashInsightRef} />
              </div>
            </div>
          ) : (
            <div className="list-group h-100 list-widget">
              <h2 className="d-flex justify-content-center h-100 align-items-center text-muted">
                {t("dashboard.noData")}
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CashInsightforPeriod);
