import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import func from "../../../services/functions";
const bigDecimal = require("js-big-decimal");
const { round } = func;

const DemoInvoiceTotals = ({ invoiceState }) => {
  const { t, i18n } = useTranslation("translations");
  const isAlbanian = invoiceState.currency === "ALL";
  const currencyToDisplay = isAlbanian ? "Lek" : invoiceState.currency;
  const rate = invoiceState.rate;

  const convertedValue = invoiceState.items.reduce((sum, item) => {
    return bigDecimal.add(
      bigDecimal.add(
        round(bigDecimal.multiply(item.totalWithoutVat, rate), 2),
        round(
          bigDecimal.multiply(
            bigDecimal.multiply(item.totalWithoutVat, rate),
            bigDecimal.divide(item.vatCategory, 100)
          ),
          2
        )
      ),
      sum
    );
  }, 0);

  return (
    <div>
      <div className="d-flex flex-row align-items-start justify-content-between">
        <span className="fw-medium">
          <span>{t("invoice.totalNoVat")}</span>
        </span>
        <span>
          {func.getFormattedMoneyValue(invoiceState.invoiceTotalWithoutVat)}{" "}
          {currencyToDisplay}
        </span>
      </div>
      <div className="d-flex flex-row align-items-start justify-content-between mt-1">
        <span className="fw-medium">
          <span>{t("invoice.totalDiscount")}</span>
        </span>
        <span>
          {func.getFormattedMoneyValue(invoiceState.invoiceTotalDiscount)}{" "}
          {currencyToDisplay}
        </span>
      </div>
      <div className="d-flex flex-row align-items-start justify-content-between mt-1">
        <span>{t("item.totalWithoutVat")}</span>
        <span>
          {func.getFormattedMoneyValue(
            invoiceState.invoiceDiscountedTotalWithoutVat
          )}{" "}
          {currencyToDisplay}
        </span>
      </div>
      <div className="d-flex flex-row align-items-start justify-content-between mt-1">
        <span>{t("invoice.totalVat")}</span>
        <span>
          {func.getFormattedMoneyValue(invoiceState.invoiceTotalVat)}{" "}
          {currencyToDisplay}
        </span>
      </div>
      <div className="d-flex flex-row align-items-start justify-content-between mt-1">
        <span>{t("item.totalWithVat")}</span>
        <span>
          {func.getFormattedMoneyValue(invoiceState.invoiceTotalWithVat)}{" "}
          {currencyToDisplay}
        </span>
      </div>
      <div className="d-flex flex-row align-items-start justify-content-between mt-1">
        <span className="fw-bold">
          {" "}
          <strong>{t("invoice.totalPay")} ({currencyToDisplay})</strong>
        </span>
        <span>
            <strong>
            {func.getFormattedMoneyValue(invoiceState.invoiceTotalWithVat)}{" "}
            {currencyToDisplay}
            </strong>
        </span>

      </div>
      <div className="d-flex flex-row align-items-start justify-content-between mt-1">
      {!isAlbanian && (
          <>
            <div>
              <span>
                <strong>{t("invoice.totalPay")} (Lek)</strong>
              </span>
            </div>
            <div className="text-right">
              <span>
                <strong>{func.getFormattedMoneyValue(convertedValue)} Lek</strong>
              </span>
            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default DemoInvoiceTotals;
