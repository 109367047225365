import React,{ useEffect, useState } from "react";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mainAxios } from "../../../services/mainAxios";
import { toast } from "react-toastify";
import currencies from "../../../services/currencies.json";
import Select from "react-select";
import selectStyle from "../../../design/selectStyle";
import Loader from "../../Layout/Loader";
import { useTranslation, withTranslation } from "react-i18next";
import { useAppContext } from "../../../AppContext";


const AddBankAccount = ({onBankAdded,bankAccountId,company}) => {
  const { t, i18n } = useTranslation("translations");

  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();

  const companyId = company?.id ?? selectedCompany
  const [bankList, setBankList ] = useState([])
  
  const [bankAccount, setBankAccount] = React.useState({
    bankName: "",
    iban: "",
    currency: "ALL",
    company: {
      id: companyId,
    },
    bank: "",
    active: true,
    includeInInvoice: false,
  });
  const [pageLoading,setPageLoading]=useState(!!bankAccountId)
  const [errors,setErrors]=React.useState({
    bank:null
  })
  useEffect (()=> {
    mainAxios
    .post("apiEndpoint/search", {
      object: "GetBanks",
      params: {},
    })
    .then( res=>{
      if(res.data.status==true){
        setBankList(res.data.content);
      }
    })
  },[])

  const [loading, setLoading] = React.useState(false);

  const addBankAccount = (e) => {
    e.preventDefault();
    if(bankAccount.bank===""){
      setErrors({
        bank:true
      })
      return;
    }
    setLoading(true);

    let content={...bankAccount}
    if(bankAccountId) content.company={id:company.id}
    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "BankAccount",
        content,
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
            onBankAdded() 
            toast.success(bankAccountId ?t("toast.editBankAccount"):t(["toast.addBankAccount"]), {
              containerId: "all_toast",
            });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if(!bankAccountId) return 
    mainAxios.post('apiEndpoint/search', {
        object: "GetBankAccount",
        params: {
            id: bankAccountId
        },
        company: companyId 
    }).then(res => {
        if(res?.data?.status === true) {
            setBankAccount(res.data.content[0]);
        }
        setPageLoading(false)
    })
}, [])

  
 

  const handleInputChange = (e, selector = null, selectorValue = null) => {
    const key = selector ?? e.target.name;
    let value = selectorValue ?? e.target.value;
    setBankAccount((bankAccount) => ({
      ...bankAccount,
      [key]: value,
    }));
  };


  const required = value => value ? undefined : 'Required'

  if(pageLoading) return <Loader/>

  return bankAccount ? (
    <>
      <form
        onSubmit={addBankAccount}
        className="">
        <div>
          <div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="bank">{t("bankAccount.name")}</label>
                  <span className="text-danger"> *</span>
                 
                  <Select
                    value={ bankList.find(bank=>bank.id==bankAccount?.bank?.id) }
                   required={required}
                   getOptionLabel={(opt)=>opt.name}
                   getOptionValue={(opt)=>opt.id}
                    options={bankList}
                    styles={selectStyle}
                    isSearchable={true}
                    onChange={({id}) =>{
                      handleInputChange(null, "bank", {id})
                    }
                    }
                    name="bank"
                
                  />
                {errors.bank&&<div className="text-danger mt-1">{t("common.selectOption")}</div>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="bankName">{t("bankAccount.accountName")}</label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className="form-control"
                    id="bankName"
                    name="bankName"
                    placeholder="E.g 'Credins'"
                    value={bankAccount.bankName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="iban">IBAN</label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className="form-control"
                    id="iban"
                    name="iban"
                    placeholder="E.g 'AL12202110452178520100014578'"
                    value={bankAccount.iban}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="seller_town">{t("invoice.currency")}</label>
                  <span className="text-danger"> *</span>
                  <Select
                    options={currencies}
                    styles={selectStyle}
                    value={currencies.find(
                      (currency) => currency.value === bankAccount.currency
                    )}
                    isSearchable={true}
                    onChange={(currency) =>
                      handleInputChange(null, "currency", currency.value)
                    }
                    name="currency"
                  />
                </div>
              </div>

          
              <div className="col-12">
                <div className="custom-control custom-switch mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={"bankAccount.active"}
                    checked={bankAccount.active}
                    onChange={(e) =>
                      handleInputChange(null, "active", e.target.checked)
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"bankAccount.active"}
                  >
                    {t(["bankAccount.activate"])}
                  </label>
                </div>
              </div>

              <div className="col-12">
                <div className="custom-control custom-switch mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={"bankAccount.includeInInvoice"}
                    checked={bankAccount.includeInInvoice}
                    onChange={(e) =>
                      handleInputChange(null, "includeInInvoice", e.target.checked)
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"bankAccount.includeInInvoice"}
                  >
                    {t(["bankAccount.includeInInvoice"])}
                  </label>
                </div>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  disabled={loading}
                  style={{ minWidth: "100px" }}
                  className="btn btn-primary"
                >
                  {loading ? (
                    <Loader size="xs" color="white" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faCheck} className="mr-2" />
                      {t("common.save")}
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>

  ) : null;
};

export default AddBankAccount;
