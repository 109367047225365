import bigDecimal from 'js-big-decimal';
import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../AppContext';
import func from '../../services/functions';

const CashRegisterThermal = ({ tcrStat ,isSimple=false}) => {
  const { t } = useTranslation("translations");
  const { user: [user,],company:[fullCompany] } = useAppContext()
  if (!tcrStat) {
      return <div></div>
  }   

  const userCompany = user.companiesRights.find(company => company.id === tcrStat["company"])

    const {id,actionFrom,issueDate,recordDate,company,...rest}=tcrStat

    const tcrStatRow=(label,value,style)=>{
      if(value !=undefined){
      return (
        <div key={label} style={{display:"flex",justifyContent:"space-between",  textAlign: "left", padding:"1px 0px",...(style?style:{})}}>
          <div style={{fontWeight:"500", fontSize:"13px"}}>{label}</div>
          <div style={{display:"flex",justifyContent:"center", fontWeight:"550", fontSize:"13px",textAlign: "center", width:"fit-content"}}>{value}</div>
        </div>
      )
    }
    return <></>
    }

    const data=[
      {
        header:t("stat.totalNoAndValue"),
        children:[
          {
            label:t("stat.totInvoicesNo"),
            value:tcrStat["totInvoicesNo"]
          },
          {
            label:t("stat.successInvoicesNo"),
            value:tcrStat["successInvoicesNo"]
          },
          {
            label:t("stat.errorInvoicesNo"),
            value:tcrStat["errorInvoicesNo"]
          },
          {
            label:t("stat.nonCashInvoicesNo"),
            value:tcrStat["noncashInvoicesNo"]
          },
          {
            label:t("stat.eInvoicesNo"),
            value:tcrStat["einvoicesNo"]
          },
          {
            label:t("stat.fiscalizedInvoicesNo"),
            value:tcrStat["fiscalizatedInvoicesNo"]
          },
          {
            label:t("stat.cashCorrectiveNo"),
            value:tcrStat["cashCorrectiveNo"]
          },
          {
            label:t("stat.nonCashCorrectiveNo"),
            value:tcrStat["noncashCorrectiveNo"],
            style:{
              marginBottom:7
            }
          },
          {
            label:t("stat.totalWithoutVat"),
            value:func.getFormattedMoneyValue(tcrStat["totalWithoutVat"] ?? 0),

          },
          {
            label:t("stat.totalVat"),
            value: func.getFormattedMoneyValue(tcrStat["totalVat"] ?? 0)
          },
          {
            label:t("stat.totalWithVat"),
            value:func.getFormattedMoneyValue(tcrStat["totalWithVat"] ?? 0)
          },
        ]
      },
      {
        header:t("stat.cashTransactions"),
        children:[
          {
            label:t("stat.cashInit"),
            value:func.getFormattedMoneyValue(tcrStat["cashInit"]?? 0)
          },
          {
            label:t("stat.cashDeposit"),
            value:func.getFormattedMoneyValue(tcrStat["cashDeposit"]?? 0)
          },
          {
            label:t("stat.cashWithdraw"),
            value:func.getFormattedMoneyValue(tcrStat["cashWithdraw"]?? 0)
          }
        ]
      },
      {
        header:t("stat.cashAndNonCash"),
        children:[
          {
            label:t("stat.nonCashTaxExclusiveAmount"),
            value: func.getFormattedMoneyValue(tcrStat["noncashTaxExclusiveAmount"]?? 0)
          },
          {
            label:t("stat.nonCashTaxAmount"),
            value:func.getFormattedMoneyValue(tcrStat["noncashTaxAmount"]?? 0)
          },
          {
            label:t("stat.nonCashTaxInclusiveAmount"),
            value: func.getFormattedMoneyValue(tcrStat["noncashTaxInclusiveAmount"]?? 0),
            style:{
              marginBottom:7
            }
          },
          {
            label:t("stat.cashTaxExclusiveAmount"),
            value: func.getFormattedMoneyValue(tcrStat["cashTaxExclusiveAmount"]?? 0)
          },
          {
            label:t("stat.cashTaxAmount"),
            value:func.getFormattedMoneyValue(tcrStat["cashTaxAmount"]?? 0)
          },
          {
            label:t("stat.cashTaxInclusiveAmount"),
            value:func.getFormattedMoneyValue(tcrStat["cashTaxInclusiveAmount"]?? 0)
          },
          {
            label:t("stat.cashCorrectiveTot"),
            value:func.getFormattedMoneyValue(tcrStat["cashCorrectiveTot"]?? 0)
          },
          {
            label:t("stat.einvoiceTaxExclusiveAmount"),
            value: func.getFormattedMoneyValue(tcrStat["einvoiceTaxExclusiveAmount"]?? 0)
          },
          {
            label:t("stat.einvoiceTaxAmount"),
            value:func.getFormattedMoneyValue(tcrStat["einvoiceTaxAmount"]?? 0)
          },
          {
            label:t("stat.einvoiceTaxInclusiveAmount"),
            value:func.getFormattedMoneyValue(tcrStat["einvoiceTaxInclusiveAmount"]?? 0),
            style:{
              marginBottom:7
            }
          },
          {
            label:t("stat.nonCashCorrectiveTot"),
            value: func.getFormattedMoneyValue(tcrStat["nonashCorrectiveTot"]?? 0)
          },
        ]
      },
      {
        header:t("stat.paymentsValue"),
        children:[
          {
            label:t("stat.banknoteTot"),
            value:func.getFormattedMoneyValue(tcrStat["banknoteTot"]?? 0)
          },
          {
            label:t("stat.cardTot"),
            value:func.getFormattedMoneyValue(tcrStat["cardTot"]?? 0)
          },
          {
            label:t("stat.svoucherTot"),
            value: func.getFormattedMoneyValue(tcrStat["svoucherTot"]?? 0)
          },
          {
            label:t("stat.companyTot"),
            value:func.getFormattedMoneyValue(tcrStat["companyTot"]?? 0)
          },
          {
            label:t("stat.orderTot"),
            value: func.getFormattedMoneyValue(tcrStat["orderTot"]?? 0)
          },
          {
            label:t("stat.cashInvoicesNo"),
            value:tcrStat["cashInvoicesNo"]
          },
        ]
      },
      {
        header:t("stat.globalStatistics"),
        children:[
          {
            label:t("stat.foreignInvoicesNo"),
            value:tcrStat["foreignInvoicesNo"]
          },
          {
            label:t("stat.allCurrencyInvoicesNo"),
            value:tcrStat["allCurrencyInvoicesNo"]
          },
          {
            label:t("stat.usdCurrencyInvoicesNo"),
            value:tcrStat["usdCurrencyInvoicesNo"]
          },
          {
            label:t("stat.eurCurrencyInvoicesNo"),
            value:tcrStat["eurCurrencyInvoicesNo"]
          },
          {
            label:t("stat.selfInvoicesNo"),
            value:tcrStat["selfInvoicesNo"]
          },
          {
            label:t("stat.subseqInvoicesNo"),
            value:tcrStat["subseqInvoicesNo"]
          },
          {
            label:t("stat.correctedInvoicesNo"),
            value:tcrStat["correctedInvoicesNo"]
          },
          {
            label:t("stat.cashSubseqActionNo"),
            value:tcrStat["cashSubseqActionNo"]
          },
          {
            label:t("stat.cashInit"),
            value:tcrStat["cashInit"]
          },
          {
            label:t("stat.cashDeposit"),
            value:tcrStat["cashDeposit"]
          },
          {
            label:t("stat.cashWithdraw"),
            value:tcrStat["cashWithdraw"]
          },
        ]
      },
      {
        header:t("stat.cashEnd"),
        children:[
          {
            label:t("stat.subseqInvoicesTotal"),
            value:tcrStat["subseqInvoicesTotal"]
          },
          {
            label:t("stat.total"),
            value: func.getFormattedMoneyValue(tcrStat["cashEnd"]?? 0)
          },
        ]
      }
    ]
    return (
    <div id="thermalPrinterInvoice" style={{ display: "flex", flexDirection: "column", justifyContent:"center",alignItems: "center", textAlign: "center"}}>
        {fullCompany?.logoPath && <div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"10px 0px"}} >
              <img  height={65} src={fullCompany?.logoPath } />
            </div>
          </div>}
        <div style={{ fontWeight: "800", fontSize: 16, textAlign: "center" }}>{t("stat.cashRegisterReporting")}</div>
          <div style={{fontWeight:"800",fontSize:16,textAlign:"center"}}>{userCompany?.name}</div>
          <div style={{fontSize:16,textAlign:"center"}}>{user.firstName} {user.lastName}-{tcrStat.statsDate}</div>
          <div style={{margin:"15px 0"}}>
          {tcrStatRow(t("stat.fromDate"),moment(tcrStat["fromDate"]).format("DD/MM/YYYY"))}
          {tcrStatRow(t("stat.toDate"),moment(tcrStat["toDate"]).format("DD/MM/YYYY"))}
          {tcrStatRow(t("stat.tcrCode"),tcrStat["tcrCode"])}
          {tcrStatRow(t("nslf.businessUnitCode"),tcrStat["businessUnitCode"])}
          </div>
          {data.map(tcrSection=>{
            let showHeader=true
            if (isSimple && tcrSection.children.every(stat => (parseInt(stat.value) == 0 || stat.value == undefined) && stat.label != t("stat.total"))) showHeader = false
            
            return <div style={{marginBottom:15}}>
              {showHeader && 
              <>
              <div style={{fontSize:16,fontWeight:800,textAlign:"left"}}>{tcrSection.header}</div>
              <hr style={{ display:"flex",borderTop: "dotted 1px",width:155,marginLeft:0 }} />
              </>
              }
              {tcrSection.children.map((stat)=>tcrStatRow(stat.label,stat.value,stat?.style))}
            </div>
          })}
        {/* <div key={t("stat.cashEnd")} style={{ display: "flex", justifyContent: "space-between", textAlign: "left", padding: "1px 0px"}}>
          <div style={{ fontWeight: "500", fontSize: "13px",fontWeight: "900",fontSize:16 }}>{t("stat.cashEnd")}</div>
          <div style={{ display: "flex", justifyContent: "center", fontWeight: "900", fontSize: "16px", textAlign: "center", width: "fit-content" }}>{tcrStat["cashEnd"]?.toFixed(2)}</div>
        </div> */}
          <hr style={{ display:"flex",borderTop: "dotted 1px" }} />
          <div style={{ textAlign: "center" }}>Gjeneruar nga ProFisc (www.profisc.al)</div>

    </div>
    )
}


export default CashRegisterThermal
