import React from 'react'
import func from '../services/functions'

const DocumentPreview = ({filePreview}) => {
    let output
    switch(filePreview.extension) {
        case "pdf": 
            output = (
                <embed src={func.b64toURL(filePreview.base64, "application/pdf", filePreview.name)}/>
            )
            break;
        case "png":
            output = (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <img src={func.b64toURL(filePreview.base64, "image/png", filePreview.name)}/>
                </div>
            )
            break;
        // case "docx":
        //     output =(
        //       <div className="d-flex align-items-center justify-content-center h-100">
        //         <img src={func.b64toURL(filePreview.base64, "docx", filePreview.name)}/>
        //       </div>
        //     )
        //     break;
        case "jpg": 
            output = (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <img src={func.b64toURL(filePreview.base64, "image/jpeg", filePreview.name)}/>
                </div>
            )
            break;
        // case "xlsx":
        //     output = (
        //         <div className="d-flex align-items-center justify-content-center h-100 position-relative">
        //             <div className="position-absolute" style={{top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>Cannot preview this type of file</div>
        //             <embed src={func.b64toURL(filePreview.base64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filePreview.name)}/>
        //         </div>
        //     )
        //     break;
        case "txt":
            output = (
                <embed src={func.b64toURL(filePreview.base64, "text/plain", filePreview.name)}/>
            )
            break;
        case "xml":
            output = (
                <embed src={func.b64toURL(filePreview.base64, "text/xml", filePreview.name)}/>
            )
            break;
        default:
            output =  (
                <div className="d-flex align-items-center justify-content-center h-100">
                    Cannot preview this type of file
                </div>
            )
    }
    
    return output
}

export default DocumentPreview