import React, { useEffect } from 'react'

import { faCheck, faInfoCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mainAxios from '../../../services/mainAxios'
import { toast } from 'react-toastify'
import Loader from '../../Layout/Loader'
import { useAppContext } from '../../../AppContext'


import { useTranslation, withTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
const EditPassword = (props) => {

    const { t, i18n } = useTranslation('translations');
    const { user: [user, setUser], selectedCompany: [selectedCompany] } = useAppContext()
    const [state, setState] = React.useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        error: null,
        error_password: null,
        loggedUser: user.id == props.match.params.id ? true : false,
    })
    const [loading, setLoading] = React.useState(false)

    const editPassword = (e) => {
        e.preventDefault()
        let userId = props.match.params.id

        if (!userId) userId = user.id


        if (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(state.newPassword)) {
            if (state.newPassword === state.confirmPassword) {
                setLoading(true)
                let object = state.loggedUser ? "UpdatePassword": "UpdateUserPassword";
                mainAxios.post('apiEndpoint/saveOrUpdate', {
                    object: object,
                    content: {
                        id: userId,
                        oldPassword: state.oldPassword,
                        newPassword: state.newPassword
                    },
                    nuis: user.companiesRights.find(
                        (company) => company.id === parseInt(selectedCompany)
                    )?.nuis
                }).then(res => {
                    if (res?.data?.status === true) {
                        setUser({
                            ...user,
                            resetPass: false
                        })
                        props.history.replace('/users/edit/' + userId)
                        toast.success(t(['toast.updatePassword']), {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            progress: undefined,
                            containerId: "all_toast"
                        })
                    }
                    else {
                        setState(state => ({
                            ...state,
                            error: null
                        }))
                    }
                    setLoading(false)
                })
            }
            else {
                setState(state => ({
                    ...state,
                    error: t(['toast.identical'])
                }))
            }
        }
        else {
            setState(state => ({
                ...state,
                error_password: t(['toast.passwordError'])
            }))
        }
    }

    const handlePasswordInputs = (e) => {
        const key = e.target.name
        const value = e.target.value

        setState(state => ({
            ...state,
            [key]: value
        }))
    }

    return (
        <div className="row mt-4">
            <form className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-12 mx-auto" onSubmit={editPassword}>
                <div className="card border-0 shadow mb-4">
                    <div className="card-header border-0">
                        <h3 className="mb-0">{t('users.editPassword')}</h3>
                    </div>
                    <div className="card-body">
                        {state.loggedUser && <div className="form-group">
                            <label htmlFor="old_password">{t('users.oldPassword')}</label>
                            <input
                                type="password"
                                className="form-control"
                                id="old_password"
                                name="oldPassword"
                                placeholder={t('users.oldPassword')}
                                value={state.oldPassword}
                                onChange={handlePasswordInputs}
                                required />
                        </div>}
                        <div className="form-group">
                            <label htmlFor="new_password">
                                {state.error_password && <span className="d-block text-danger">{state.error_password}</span>}
                                {t('users.newPassword')}
                            </label>
                            <input
                                type="password"
                                className={"form-control" + (state.error_password ? " border-danger" : "")}
                                id="new_password"
                                name="newPassword"
                                placeholder={t('users.newPassword')}
                                value={state.newPassword}
                                onChange={handlePasswordInputs}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirm_password">
                                {t('users.confirmPassword')}
                                {state.error && <span className="ml-2 text-danger">{state.error}</span>}
                            </label>
                            <input
                                type="password"
                                className={"form-control" + (state.error ? " border-danger" : "")}
                                id="confirm_password"
                                name="confirmPassword"
                                placeholder={t('users.confirmPassword')}
                                value={state.confirmPassword}
                                onChange={handlePasswordInputs}
                                required />
                        </div>
                        <div className="d-flex align-items-center">
                            <button
                                className="btn btn-primary mr-3"
                                disabled={loading}
                                style={{ minWidth: "100px" }}
                                type="submit">
                                {loading ? <Loader size="xs" color="white" /> :
                                    (<>
                                        <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
                                    </>)}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default EditPassword