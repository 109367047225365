import { useLocalStorage } from "primereact/hooks";
import React,{useRef, useState} from "react";
import func from "./services/functions";
import mainAxios from "./services/mainAxios";

export const usePersistedState = (defaultValue, key) => {
  const value = JSON.parse(localStorage.getItem(key));
  const [state, setState] = React.useState(
    value === "" ? value : value || defaultValue,
    key
  );
  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  const resetSate = () => setState(defaultValue);
  return [state, setState, resetSate];
};

export const AppContext = React.createContext(null);

export const useAppContext = () => React.useContext(AppContext);

let store;

export const getStore = () => ({ ...store });

export const AppContextProvider = ({ children }) => {
  const forbidden = useState(false);
  const company = useState(null);
  const user = usePersistedState(null, "user");
  const selectedCompany = usePersistedState(null, "selectedCompany");
  const queueColumns = usePersistedState(null, "queueColumns");
  const purchasesColumns = usePersistedState(null, "purchaseColumns")
  const [showVersion, setShowVersion] = useLocalStorage(false, "version");
  const [loadingVersion, setLoadingVersion] = useState(true);
  const [unitsOfMeasure,setUnitsOfMeasure] = React.useState([])
  const [unitOfmeasureLoading,setUnitOfmeasureLoading] = React.useState(false)
  const sessionWindow=useRef(null)
  const [invoice, setInvoice] = useState(null);
  const sideBarExpanded = usePersistedState({hover:false,show:false},'SideBar');
  const reloadData = useState([])
  const [refreshStatuses, setRefreshStatuses] = useState([]);

  const defaultUomPagination={
    totalRows: 0,
    pageSize: 30,
    pageNumber: 1,
}

  const [uomPagination,setUomPagination]=useState(defaultUomPagination)
   const handleShowVersion = () => {
    setLoadingVersion(true);
    setTimeout(() => {
      setLoadingVersion(false);
      setShowVersion(!showVersion);
    }, [1500]);
  };

  const customInvoiceColumns = usePersistedState(
    [
      "issueDate",
      "invoiceTypeIcons",
      "recordDate",
      "businessUnitCode",
      "tcrCode",
      "internalId",
      "iic",
      "buyer",
      "seller",
      "status",
      "type",
      "invoiceTotalWithVat",
      "invoiceTotalWithVatALL",
      "currency",
      "actions",
    ],
    "customInvoiceColumns"
  );
  const cisColumns = usePersistedState(null, "cisColumns");

  const wtnColumns = usePersistedState(null, "wtnColumns1");
  const socketRef = React.useState(null);
  const dates=React.useState({
    fromDate: func.getDateFromToday(-2),
    toDate: func.getDateFromToday(1)
  })

  const getUnitOfmeasure=({pageSize=defaultUomPagination.pageSize, pageNumber = defaultUomPagination.pageNumber,searchQuery,loadMore}={})=>{
    setUnitOfmeasureLoading(true)
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetUnitOfMeasure",
        value: "",
        params:{
          unitOfMeasureLiveSearch:searchQuery
        },
        sortCriteria : [{
          field: "priority",
          direction: "ASC"
        }],
        pagination:{
          pageSize,
          pageNumber,
      },
      })
      .then((res) => {
        setUnitOfmeasureLoading(false)
        if (res?.data?.status) {
          let data = res.data?.content.filter((uom) => uom?.showInFront == null || uom?.showInFront == true) ?? []
          setUomPagination((pagination)=>({...pagination,pageNumber,pageSize,totalRows:res?.data?.totalSize}))
          setUnitsOfMeasure((uoms)=>loadMore?uoms.concat(data):data);
        }
      }).catch((err) => {
        setUnitOfmeasureLoading(false)
      })
  }

  const getStatus = () => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetInvoicesResendStatuses",
        value: null,
      })
      .then((res) => {
        if (res) {
          setRefreshStatuses(res?.data?.content);
        }
      });
  };
  
  const getCompany=(companyId)=>{
    const setFullCompany=company[1]
    mainAxios.post('apiEndpoint/search', {
        object: "GetCompany",
        params: {
            id: companyId ?? selectedCompany[0]
        }
    }).then(res => {
        if(res) {
            setFullCompany(res?.data?.content[0])
        }
    })
}

  const getUser=()=>{
    let [currentUser,setCurrentUser]=user
    mainAxios
    .post("apiEndpoint/search",
      {
        object: "GetUser",
        value: null,
        type: "basic",
        params: { username:currentUser.username },
      },
    )
    .then((res) => {
      if (res && res.data?.content?.[0]) {
        let fullUser = res.data.content[0];
        if (
          !fullUser.companiesRights.find(
            (company) => company.id === parseInt(selectedCompany[0])
          )
        ) {
          setCurrentUser(null);
        } else {
          delete res.data.content[0]["companyBranchesTcr"];
          setCurrentUser(fullUser);
        }
      }
    });
  }

  store = {
    user,
    selectedCompany,
    queueColumns,
    customInvoiceColumns,
    purchasesColumns,
    wtnColumns,
    socketRef,
    cisColumns,
    forbidden,
    company,
    showVersion,
    handleShowVersion,
    loadingVersion,
    dates,
    getCompany,
    reloadData,
    unitsOfMeasure,
    setUnitsOfMeasure,
    unitOfmeasureLoading,
    setUnitOfmeasureLoading,
    getUnitOfmeasure,
    uomPagination,
    setUomPagination,
    sessionWindow,
    invoice,
    setInvoice,
    getUser,
    sideBarExpanded,
    getStatus,
    refreshStatuses,
    setRefreshStatuses
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};
