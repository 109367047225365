import React from 'react'
import CustomInvoicesNew from '../CustomInvoices/CustomInvoices_new'


const Transactions = (props) => {
  return (
      <CustomInvoicesNew isPos={true} {...props}/>
  )
}

export default Transactions