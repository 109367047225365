import React, { useCallback, useEffect, useState } from 'react';
import SockJsClient from 'react-stomp';
import Swal from 'sweetalert2';
import { useAppContext } from '../AppContext';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import func from '../services/functions';
// import { RecieptModal, onGetReciept } from './Bkt/RecieptsUtils';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const WebSocket = () => {
    const {socketRef: [,setSocketRef],user:[user],reloadData:[reloadData,setReloadData]} = useAppContext()
    const token = func.getCookie("token")
    const [recieptModal,setRecieptModal]=useState({visible:false,data:null})
    const { t } = useTranslation('translations');

    const reload=useCallback(func.debounce(()=>{
        setReloadData(reloadData=>!reloadData)
    },100),[reloadData])

    const refreshPage = useCallback(
        func.debounce(() => {
            const refreshToast = toast.warn(
                t("bkt.systemRefresh"),
                {
                  containerId:"all_toast",
                  autoClose: false,
                  type:"warning",
                  closeButton:false,
                  closeOnClick:false,
                }
              );

              setTimeout(()=>{
                  toast.dismiss(refreshToast);
                  window.location.reload(true);
              },30000)
        }, 100),
        []
      );

    useEffect(() => {
        const username = user.username; // Set your username here
        if(!username  || !token) return
        const socket = new SockJS(`${process.env.REACT_APP_JAVA_SERVER_URL}public/notifications/`);
        const stompClient = Stomp.over(socket);
        setSocketRef(stompClient);




        stompClient.connect({ username,token}, () => {
            console.log('Web Socket is connected');
            stompClient.subscribe('/topic/notification', (notification) => {

                const body=JSON.parse(notification?.body ?? "")
                onMessage({title:body.message})
            });
            // stompClient.subscribe('/users/queue/messages', (notification)=>onGetReciept({notification,reload,setRecieptModal}));
            stompClient.subscribe('/users/queue/notification', (notification) => {
                const body=JSON.parse(notification?.body ?? "")
                onMessage({title:body.message})
            });
            stompClient.subscribe('/topic/refresh', (notification)=>{refreshPage() } );

            stompClient.send('/users/queue/notification',{}, JSON.stringify({
                name: user.username,
                message:"Update"
            }));
        });

        return () => {
            if(stompClient && stompClient.connected)
            stompClient.disconnect();
        };
    }, [user.username,token,setSocketRef,]); //


    const onMessage = ({title="Notification",text,icon="info"}) => {
        Swal.fire({
            title,
            text,
            icon
        })
    }
    return null
    // return <RecieptModal recieptModal={recieptModal} setRecieptModal={setRecieptModal}/>
}

export default WebSocket

