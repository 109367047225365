import React from 'react';
import { useAppContext } from '../AppContext';

import {useTranslation, withTranslation} from "react-i18next";
const SendNotification = () => {
    
    const {t,i18n} = useTranslation('translations');
    const {user: [user,], socketRef: [socketRef,]} = useAppContext()
    const [message, setMessage] = React.useState("")

    const sendNotification = () => {
        socketRef.send('/topic/notification',{}, JSON.stringify({
            name: user.username,
            message
        }));
    };

    const refreshUsersPage=()=>{
        if(socketRef){
            socketRef.send('/topic/refresh',{}, JSON.stringify({
                name: user.username,
                message:"Refresh"
            }));
        }
    }
    return (
        <div className="row">
            <div className="col-lg-8 col-md-10 col-12 mx-auto">
                <div className="card border-0 shadow mb-3 mt-3">
                    <div className="card-header border-0">
                        <h3 className="mb-0">{t('notification.send')}</h3>
                    </div>
                    <div className="card-body">
                        <button
                            className="btn btn-primary my-2 border-bottom-1"
                            onClick={refreshUsersPage}>
                            Refresh Users Page
                        </button>
                        <textarea 
                            className="form-control mb-3"
                            onChange={(e) => setMessage(e.target.value)}
                            rows="10">
                        </textarea> 
                        <button 
                            className="btn btn-primary"
                            onClick={sendNotification}>
                            {t('invoice.send')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendNotification