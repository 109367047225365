import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faEye,
  faQrcode,
  faTrash,
  faL,
  faCopy,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getStore } from "../../../AppContext";
import CellInput from "../../global/CellInput";
import Select from "react-select";
import cities from '../../../services/cities.json'

const moment = require("moment");

const generateWtnColumns = ({
  wtnColumns,
  showWtnPdf,
  loadingPdf,
  deleteWtn,
  deleting,
  t,
  handleDublicateInvoice,
  loadingDuplicate
}) => {
  const {
    company: [fullCompany],
  } = getStore();

  if (typeof t !== "function") {
    console.error("Error: t is not a function");
    return [];
  }
  const addressPoints = [
    { value: "WAREHOUSE", label: "Warehouse" },
    { value: "EXHIBITION", label: "Exhibition" },
    { value: "STORE", label: "Store" },
    { value: "SALE", label: "Point of Sale" },
    { value: "ANOTHER", label: "Another Person's warehouse" },
    { value: "CUSTOMS", label: "Customs Warehouse" },
    { value: "OTHER", label: "Other" },
  ];

  const destinationPoints = [
    { value: "WAREHOUSE", label: "Warehouse" },
    { value: "EXHIBITION", label: "Exhibition" },
    { value: "STORE", label: "Store" },
    { value: "SALE", label: "Point of Sale" },
    { value: "OTHER", label: "Other" },
  ];
  const vehicleOwnershipOptions = [
    { value: "OWNER", label: "OWNER" },
    { value: "THIRDPARTY", label: "THIRDPARTY" },

  ];


  const statusBody=(wtnInvoice,exportCsv)=>{
    let badgeClass = "secondary";
    let statusLabel = "Draft";

    switch (wtnInvoice?.status?.status) {
      case "APPROVED":
        badgeClass = "success";
        statusLabel = "Approved";
        break;
      case "ERROR":
        badgeClass = "danger";
        statusLabel = "Error";
        break;
      case "DELETED":
        badgeClass = "danger";
        statusLabel = "Deleted";
    }
    if(exportCsv) return statusLabel

    return (
      <h4 className="mb-0">
        <span className={"badge badge-" + badgeClass}>{statusLabel}</span>
      </h4>
    );
  }


  return [
    {
      header: t("home.status"),
      field: "status",
      format: (wtnInvoice)=>statusBody(wtnInvoice,true),
      body: (wtnInvoice)=>statusBody(wtnInvoice),
      filter: false,
      default:true,
      persisted:true
    },
    {
      header: t(["home.actions"]),
      field: "actions",
      style: { minWidth: "300px", maxWidth: "300px" },
      body: (wtnInvoice) => (
        <div className="d-flex gap-2 mt-1">
          <div className="mr-1">
          <Link
            to={"/edit-wtn/" + wtnInvoice.id}
            className={"nav-link p-0"}
          >
            <FontAwesomeIcon icon={faEye} className="mr-1" />
            {t("common.view")}
          </Link>
          </div>

          {wtnInvoice?.wtnic && wtnInvoice?.status?.status != "ERROR" && (
            <a
              href="#"
              style={{width:58}}
              className={
                "nav-link p-0" +
                (loadingPdf ? " disabled" : "")
              }
              onClick={(e) => {
                e.preventDefault();
                showWtnPdf(wtnInvoice?.wtnic);
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              {t("invoice.print")}
            </a>
          )}
          {wtnInvoice.status.id == 7 && (
            <a
              href="#"
              style={{width:58}}
              className={
                "nav-link p-0" +
                (deleting ? " disabled" : "")
              }
              onClick={(e) => deleteWtn(e, wtnInvoice)}
            >
              <div className="text-danger">

              <FontAwesomeIcon icon={faTrash} className="mr-1" />
              {t("common.delete")}
              </div>
            </a>
          )}
              <div className="">
                {wtnInvoice.status.status != 'ERROR' && <a
                href="#"
                className="nav-link p-0 d-flex align-items-center"
                onClick={()=> handleDublicateInvoice({wtnInvoice})}
                >
                {/* <FontAwesomeIcon className="mr-1" icon={faCopy} /> */}
                  {loadingDuplicate ? <FontAwesomeIcon className='mr-1' size='lg' icon={faSpinner} spin /> :  <FontAwesomeIcon className="mr-1" icon={faCopy} />}
                  {t("invoice.duplicate")}
              </a>}
              </div>

        </div>
      ),
      filter: false,
      default:true,
      persisted:true
    },
    {
      header: t(["table.issueDatetime"]),
      field: "issueDate",
      style: { minWidth: "180px", maxWidth: "300px" },
      filterType: "DATE",
      filterConfig: {
          like: false,
          minMaxDate:false
      },
      formattedExportValue:true,
      body: (wtnInvoice) =>
        wtnInvoice?.issueDate
          ? moment(wtnInvoice?.issueDate).format("DD/MM/YYYY HH:mm")
          : "",
      default:true,
      persisted:true,
      sortable: true,
      filter: true,
    },
    {
      header: t("invoice.startDate"),
      field: "wtnStartDate",
      filterType: "DATE",
      filterConfig: {
          like: false,
          minMaxDate:false
      },
      style: { minWidth: "180px", maxWidth: "300px" },
      formattedExportValue:true,
      body: (wtnInvoice) =>
        wtnInvoice?.wtnStartDate
          ? moment(wtnInvoice?.address[0].date).format("DD/MM/YYYY HH:mm")
          : "",
      filter: true,
      default:true,
      persisted:false,
      sortable: true,
    },
    {
      header: t("invoice.eDate"),
      field: "wtnEndDate",
      style: { minWidth: "180px", maxWidth: "300px" },
      filterType: "DATE",
      filterConfig: {
        like: false
      },
      formattedExportValue:true,
      body: (wtnInvoice) =>
      wtnInvoice?.wtnEndDate
          ? moment(wtnInvoice?.address[1].date).format("DD/MM/YYYY HH:mm")
      : "",
      filter: true,
      sortable: true,
      default:true,
      persisted:false
    },
    {
      header: t('invoice.Spoint'),
      field: "startingPoint",
      style: { minWidth: "180px", maxWidth: "300px" },
      sortable: false,
      body: (wtnInvoice) =>
        wtnInvoice?.startingPoint,
      filterType: "DROPDOWN",
      filterConfig: {
          options: addressPoints,
          optionValue: "value",
          optionLabel: "label",
      },
      filter: true,
      default:false,
      persisted:false
    },
    {
      header: t('invoice.Epoint'),
      field: "pointOfArrival",
      style: { minWidth: "180px", maxWidth: "300px" },
      body: (wtnInvoice) =>
        wtnInvoice?.pointOfArrival,
      filterType: "DROPDOWN",
      filterConfig: {
        options: destinationPoints,
        optionValue: "value",
        optionLabel: "label",
    },
      sortable: false,
      filter: true,
      default:false,
      persisted:false
    },
    {
      header: t("invoice.plate"),
      field: "vehiclePlate",
      style: { minWidth: "180px", maxWidth: "300px" },
      body:(wtnInvoice)=> wtnInvoice.vehiclePlate||"",
      sortable: false,
      filter: true,
      default:true,
      persisted:false
    },
    // {
    //   header: t("invoice.idTransport"),
    //   field: "transporterIdType",
    //   style: { minWidth: "200px", maxWidth: "300px" },
    //   body:(wtnInvoice)=> wtnInvoice.transporterIdType||"",
    //   sortable: false,
    //   filter: false,
    //   default:true,
    //   persisted:false
    // },
    {
      header: t("invoice.vehicleOwner"),
      field: "vehicleOwnership",
      style: { minWidth: "200px", maxWidth: "300px" },
      body:(wtnInvoice)=> wtnInvoice.vehicleOwnership||"",
      sortable: false,
      filter: true,
      filterType: "DROPDOWN",
      filterConfig: {
        options: vehicleOwnershipOptions ,
        optionValue: "value",
        optionLabel: "label",
    },
      default:true,
      persisted:false
    },
    {
      header: t("invoice.type"),
      field: "type",
      style: { minWidth: "180px", maxWidth: "300px" },
      body:(wtnInvoice)=> wtnInvoice.type || "",
      sortable: false,
      filter: false,
      default:true,
      persisted:false
    },
    {
      header: t(["invoice.businessUnit"]),
      field: "businessUnitCode",
      style: { minWidth: "180px", maxWidth: "300px" },
      body: (wtnInvoice) => wtnInvoice.businessUnitCode || "",
      sortable: false,
      filter: true,
      default:true,
      persisted:true
    },
    {
      header:
        fullCompany?.companyAttributes?.find(
          (a) => a?.attribute?.param == "internalId"
        )?.value ?? t(["home.internalId"]),
      field: "internalId",
      style: { minWidth: "220px", maxWidth: "300px" },
      body: (wtnInvoice) => wtnInvoice.internalId || "",
      sortable: false,
      filter: true,
      default:true,
      persisted:false
    },
    {
      header: t(["invoice.wtnOrderNumber"]),
      field: "wtnOrderNum",
      style: { minWidth: "240px", maxWidth: "300px" },
      body: (wtnInvoice) => wtnInvoice.wtnOrderNum || "",
      filter: true,
      default:true,
      sortable: true,
      persisted: true
    },
    {
      header: "WTNIC",
      field: "wtnic",
      style: { minWidth: "180px", maxWidth: "300px" },
      format:(row)=>row.wtnic,
      body: (row) => <CellInput value={row?.wtnic ?? ""} width={220} />,
      sortable: false,
      filter: true,
      default:true,
      persisted:false
    },
    {
      header: "FWTNIC",
      field: "fwtnic",
      style: { minWidth: "220px", maxWidth: "300px" },
      format:(row)=>row.fwtnic,
      body: (row) => <CellInput value={row?.fwtnic ?? ""} width={220} />,
      sortable: false,
      filter: true,
      persisted:true,
      default: false
    },
    {
      header: t(["invoice.startAdr"]),
      field: "startAddress",
      style: { minWidth: "180px", maxWidth: "300px" },
      formattedExportValue: true,
      body: (wtnInvoice) =>
        wtnInvoice?.address?.[0]?.address
          ? wtnInvoice.address?.[0].address
          : "",
      filter: true,
      default:true,
      persisted:false,
      sortable: false,
    },
    {
      header: t(["invoice.destinAddr"]),
      field: "destinationAddress",
      style: { minWidth: "180px", maxWidth: "300px" },
      formattedExportValue: true,
      body: (wtnInvoice) =>
        wtnInvoice?.address?.[0]?.address
          ? wtnInvoice.address?.[0].address
          : "",
      filter: true,
      default:true,
      persisted:false,
      sortable: false,
    },
    {
      header: t(["invoice.valueOfGoods"]),
      field: "valueOfGoods",
      style: { minWidth: "220px", maxWidth: "300px" },
      body: (wtnInvoice) => wtnInvoice.valueOfGoods || "",
      sortable: false,
      filter: false,
      default:true
    },
    {
      header: t(["invoice.idTransport"]),
      field: "transporterId",
      style: { minWidth: "220px", maxWidth: "300px" },
      body: (wtnInvoice) => wtnInvoice.transporterId || "",
      sortable: false,
      filter: false,
      default:true
    },
    {
      header: t(["invoice.Operator"]),
      field: "operatorCode",
      style: { minWidth: "180px", maxWidth: "300px" },
      formattedExportValue: true,
      body:(wtnInvoice)=> wtnInvoice?.operatorCode || "",
      persisted:false,
      filter: true,
      default:true,
      sortable: false,
    },
    // {
    //   header: t("invoice.transaction"),
    //   field: "transaction",
    //   style: { minWidth: "180px", maxWidth: "300px" },
    //   // body: (wtnInvoice) => wtnInvoice.transaction || "",
    //   filterType: "MULTISELECT",
    //   filterConfig: {
    //       options: transaction,
    //       optionValue: "value",
    //       optionLabel: "label",
    //   },
    //   persisted:false,
    //   filter: false,
    //   default:true,
    //   sortable: false,

    // },
  ];
};

export default generateWtnColumns;