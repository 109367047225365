export const purchaseAction = (actionName) => {
  let name = (actionName.charAt(0).toUpperCase() + actionName.slice(1)) ?? "";
  switch (actionName) {
    case "save":
      return{
        color:"primary",
        name
      }
    case "accept":
      return{
        color:"primary",
        name
      }
    case "return":
      return {
        color: "danger",
        name
        //name: t("status.return")
      };
    case "reject":
      return {
        color: "danger",
        name
      }
    case "Approve CIS": return {
      color: "success",
      name
    }
    case "Reject CIS":
      return {
        color: "danger",
        name
      }
    case "submit for approval":
      return {
        color: "success",
        name
      }
    default:
      return {
        color: "secondary",
        name,
      };
  }
};