import React, { useCallback,useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import func, { isEmpty, validateInvoice } from "../../../services/functions";
import { useMemo } from "react";
import { AutoComplete } from "primereact/autocomplete";
import mainAxios from "../../../services/mainAxios";
import { useAppContext } from "../../../AppContext";
import { toast } from "react-toastify";
import { handleValidatedItemsInArray } from "../../../validations/InvoiceItems";
import { useLocalStorage } from "primereact/hooks";
import { Preloader, ThreeDots } from "react-preloader-icon";
import PopOver from "../../global/PopOver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import ImportExcel from "../../ImportExcel";
import { typeOfVatCategoryOptions } from "../WizardSteps/data";
import { uomStyle } from "../../../design/selectStyle";
import ReactSelectPagination from "../../global/ReactSelectPagination";

const bigDecimal = require("js-big-decimal");

const DemoTable = ({
  invoiceState,
  setInvoiceState,
  disabled,
  errors,
  setErrors,
  lastStateRef,
  lastStateHelper

}) => {
  const { unitsOfMeasure,getUnitOfmeasure, } = useAppContext()
  
  const { t, i18n } = useTranslation("translations");
  const [suggestionsName, setSugestionsName] = useState();
  const [suggestionsCode, setSugestionsCode] = useState();
  const { countDecimals } = func;
  const [balanceFrozen, setBalanceFrozen] = useState(true);
  const [code, setCode] = useState("");
  const codeRef = useRef(null);
  const [codeLoading, setCodeLoading] = useState(false);

  useEffect(()=>{{
    func.getFormattedMoneyValue()
  }},[])

  const handleDiscountChange = ({ cellOptions, key, value,customItem }) => {
    let index = cellOptions?.rowIndex;

    const {
      unitPrice,
      discountType: dt,
      discountValue: dv,
      vatCategory: vat,
    } = customItem ?? invoiceState.items[index];

    const discountType = key === "discountType" ? value : dt;
    const discountValue = key === "discountValue" ? value : dv;

    let discount;

    let count = countDecimals(discountValue);
    if (discountType === "Percentage") {
      if (count > 6) {
        return;
      }
      discount = bigDecimal.multiply(
        unitPrice,
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      if (count > 2) {
        return;
      }
      discount = discountValue;
    }

    const priceWithoutVat = bigDecimal.subtract(unitPrice, discount);

    const priceWithVat =
      vat == 0
        ? unitPrice
        : bigDecimal.multiply(
            unitPrice,
            bigDecimal.add(1, bigDecimal.divide(vat, 100))
          );

    if(customItem){
      return {
        ...customItem,
        [key]:value,
        priceWithoutVat: parseFloat(priceWithoutVat).toFixed(2),
        priceWithVat: parseFloat(priceWithVat).toFixed(2),
      }
    }

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: [
        ...invoiceState.items.slice(0, index),
        {
          ...invoiceState.items[index],
          [key]: value,
          priceWithoutVat: parseFloat(priceWithoutVat).toFixed(2),
          priceWithVat: parseFloat(priceWithVat).toFixed(2),
        },
        ...invoiceState.items.slice(index + 1),
      ],
    }));
    return value;
  };


  const caluculateInvoiceData=(newState)=>{
    const state=newState ?? invoiceState
    const {
      items,
      invoiceTotalWithoutVat,
      invoiceTotalDiscount,
      invoiceDiscountedTotalWithoutVat,
      invoiceTotalVat,
      invoiceTotalWithVat,
    } = func.calculateInvoiceData(state);
    setInvoiceState((state) => {
      const content={
        ...state,
        items,
        invoiceTotalWithoutVat,
        invoiceTotalDiscount,
        invoiceDiscountedTotalWithoutVat,
        invoiceTotalVat,
        invoiceTotalWithVat,
      }

      if(lastStateHelper?.current?.saved){
        lastStateHelper.current.saved=false
        lastStateRef.current = content
      }

      return content
    });
  }

  React.useEffect(() => {
    caluculateInvoiceData()
  }, [
    ...invoiceState.items.map((item) => {
      const {
        quantity,
        priceWithoutVat,
        priceWithVat,
        discountValue,
        discountType,
        vatCategory,
      } = item;
      return `${quantity},${priceWithoutVat},${priceWithVat},${discountValue},${discountType},${vatCategory}`;
    }),
    invoiceState.discount,
  ]);

  React.useEffect(()=>{
    if(invoiceState?.items[0]?.unitOfMeasureId==null){
      handleItemChange({
        key:"unitOfMeasureId",
        value:unitsOfMeasureDefault,
        unitDefault:true
      })
    }
    if(invoiceState?.items[0]?.unitOfMeasureId?.code === "H87"){
      let xppObj= unitsOfMeasure.find(unitOfMeasure => unitOfMeasure.value === "XPP")
      handleItemChange({
          key:"unitOfMeasureId",
          value:xppObj,
          unitDefault:true
        })
    }

  },[unitsOfMeasure])

  const handleQuantityChange = ({ value, cellOptions }) => {
    let quantity = value;
    const index = cellOptions?.rowIndex;

    if (quantity.split(".")[1]) {
      quantity = quantity.split(".");
      quantity = quantity[0] + "." + quantity[1].slice(0, 10);
    }

    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
            ...oldItem,
            quantity,
          }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
    return quantity;
  };

  const handleUnitPriceChange = ({ value, cellOptions,customItem }) => {
    const index = cellOptions?.rowIndex;
    let unitPrice = customItem?.unitPrice ?? value;
    if (unitPrice.split(".")[1]) {
      unitPrice = unitPrice.split(".");
      unitPrice = unitPrice[0] + "." + unitPrice[1].slice(0, 10);
    }
    if (unitPrice.includes("-")) {
      unitPrice = unitPrice.split("-")[1] || "";
    }

    const {
      vatCategory: vat,
      discountValue,
      discountType,
    } = customItem ?? invoiceState.items[index];
    let discount;

    if (discountType === "Percentage") {
      discount = bigDecimal.multiply(
        unitPrice,
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      discount = discountValue;
    }

    const priceWithoutVat = bigDecimal.subtract(unitPrice, discount);
    const priceWithVat =
      vat == 0
        ? priceWithoutVat
        : bigDecimal.multiply(
            priceWithoutVat,
            bigDecimal.add(1, bigDecimal.divide(vat, 100))
          );

    if(customItem){
      return {
        ...customItem,
        unitPrice,
        priceWithVat,
        priceWithoutVat,
      }
    }

    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
            ...oldItem,
            unitPrice,
            priceWithVat,
            priceWithoutVat,
          }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
    return unitPrice;
  };


  const handlePriceWithVatChange = ({ cellOptions, value }) => {
    let index = cellOptions?.rowIndex;
    if (value.split(".")[1]) {
      value = value.split(".");
      value = value[0] + "." + value[1].slice(0, 10);
    }
    if (value.includes("-")) {
      value = value.split("-")[1] || "";
    }

    const {
      vatCategory: vat,
      discountValue,
      discountType,
    } = invoiceState.items[index];
    const priceWithVat = new bigDecimal(value).getValue();
    const priceWithoutVat =
      vat == 0
        ? priceWithVat
        : bigDecimal.divide(
            priceWithVat,
            bigDecimal.add(1, bigDecimal.divide(vat, 100))
          );
    let discount;

    if (discountType === "Percentage") {
      discount = bigDecimal.multiply(
        bigDecimal.divide(
          priceWithoutVat,
          bigDecimal.subtract(1, bigDecimal.divide(discountValue, 100))
        ),
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      discount = discountValue;
    }

    const unitPrice = bigDecimal.add(priceWithoutVat, discount);

    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
            ...oldItem,
            unitPrice,
            priceWithVat,
            priceWithoutVat,
          }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
    return priceWithVat;
  };

  const handleVatChange = ({ cellOptions, value }) => {
    let index = cellOptions?.rowIndex;
    const newVat = value;
    const vat = newVat;
    const priceWithoutVat = new bigDecimal(
      invoiceState.items[index].priceWithoutVat
    ).getValue();
    if(newVat == 0 && !storeColumns.find(item => item?.field == "vatLabel")){
      const newCols = []
      columns.forEach(col => {
        if(col.field == "vatLabel" || storeColumns.find(scol => scol.field == col.field )){
          newCols.push({
            field:col.field
          })
        }
      })

      setStoreColumns(newCols)
    }
    const priceWithVat = 
      vat == 0
        ? priceWithoutVat
        : bigDecimal.multiply(
            priceWithoutVat,
            bigDecimal.add(1, bigDecimal.divide(vat, 100))
          );

    const updatedItems = invoiceState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
            ...oldItem,
            priceWithVat: parseFloat(priceWithVat).toFixed(2),
            priceWithoutVat,
            vatCategory: newVat,
          }
    );

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: updatedItems,
    }));
  };

  const handleVatLabelChange = ({ cellOptions, value }) => {

    let index = cellOptions?.rowIndex;
    const vatLabel = value || null;

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: invoiceState.items.map((oldItem, oldIndex) =>
        oldIndex !== index
          ? oldItem
          : {
              ...oldItem,
              vatLabel,
            }
      ),
    }));
  };

  const handleItemChange = ({ cellOptions, key, value, length,unitDefault=false }) => {
    const index = cellOptions?.rowIndex ?? 0;
    if (index < 0) return;
    value = length != undefined ? value.slice(0, length) : value;

    setInvoiceState((invoiceState) => {
      const content = {
        ...invoiceState,
        items: [
          ...invoiceState.items.map((oldItem, oldIndex) =>
            oldIndex !== index
              ? oldItem
              : {
                ...oldItem,
                [key]: value,
              }
          ),
        ],
      }
      if (unitDefault) lastStateRef.current = content
      return content

    });
    return value;
  };

  const unitOfMeasureLabel = (unitOfMeasure) => {
    return i18n.language == "alb"
      ? unitOfMeasure?.albanianName
      : unitOfMeasure?.englishName;
  };


  let unitsOfMeasureDefault =
    unitsOfMeasure?.find((unitsOfMeasure) => unitsOfMeasure?.code === "C62") ??
    null;

  const {
    user: [user],
    selectedCompany: [selectedCompany],
    uomPagination,
    setUomPagination
  } = useAppContext();
  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const loadOptions = async (query,scanCode=false) => {
    if (query.length > 2) {
      return await mainAxios
        .post("apiEndpoint/search", {
          object: "GetCompanyItems", //GetCompanyItems new object
          value: null,
          params: {
            companyItemLiveSearch: query,
            company: company.id.toString(),
          },
          company: company.id,
        })
        .then((res) => {
          if (res.data.status) {
            const items = res.data.content.map((el) => {
              return {
                ...el,
                label: el.name + " - " + el.code,
                value: el.name,
              };
            });
            if(scanCode){return items}
            setSugestionsName(items);
            setSugestionsCode(items);
            return;
          }
          if(!scanCode){setSugestionsName([])}
        });
    }
  };

  const addItem = ({item, index,newItemCheck,incrementQuantity}) => {
    const isEuro = invoiceState?.currency === "EUR"

    const newItem = {
      name: item?.name,
      code: item?.code,
      description: item?.description,
      discountType: "Percentage",
      discountValue: "",
      priceWithVat: isEuro ? (item?.priceWvatEur?.toString() ?? "0") : (item?.priceWvatAll?.toString() ?? "0"),
      priceWithoutVat: isEuro ? (item?.priceWovatEur?.toString() ?? "0") : (item?.priceWovatAll?.toString() ?? "0"),
      quantity: "1",
      totalWithVat: "",
      totalWithoutVat: "",
      unitOfMeasureId: item?.unitOfMeasureId ?? unitsOfMeasureDefault,
      unitPrice: isEuro ? (item?.priceWovatEur?.toString() ?? "0") : (item?.priceWovatAll?.toString() ?? "0"),
      vatCategory: item?.vatCategory ?? "",
      vatLabel: item?.vatReason == "" ? null : item?.vatReason,
    };

    setInvoiceState((invoiceState) => {
      if(newItemCheck){
        return {...invoiceState,items:[...invoiceState.items,newItem]}
      }
      return {
      ...invoiceState,
      items: [...invoiceState.items?.map((item, i) => {
        if(incrementQuantity && index == i) return {...newItem,quantity:bigDecimal.add(item.quantity,1)}
        if (index == i) return { ...newItem }
          return { ...item }
       })],
    }});
  };

  const addNewItem = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!disabled) {
      if (!invoiceState.badDebtInv) {
        const validateInvoiceFields = validateInvoice({ setErrors, toast , state: invoiceState,validateOnlyItems:true })
        if (!validateInvoiceFields) return
          setInvoiceState((invoiceState) => ({
            ...invoiceState,
            items: [
              ...invoiceState.items,
              {
                name: "",
                code: "",
                description: "",
                unitOfMeasureId: unitsOfMeasureDefault ?? null,
                quantity: "1",
                unitPrice: "",
                priceWithoutVat: "",
                priceWithVat: "",
                discountValue: "",
                discountType: "Percentage",
                vatCategory: company.issuerInVat ? "20" : "0",
                vatLabel: null,
                investment: false,
                // discountPriceWithoutVat: "",
                // discountPriceWithVat: "",
                totalWithoutVat: "",
                totalWithVat: "",
              },
            ],
          }));
      } else {
        toast.warning("You can only have ONE item in Bad Debt Invoice", {
          containerId: "all_toast",
        });
      }
    }
  };



  const removeItem = (e, item, index) => {
    e.preventDefault();
    let newItems = invoiceState.items
      .slice(0, index)
      .concat(invoiceState.items.slice(index + 1));
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      items: newItems,
    }));
  };

  const vatTypeOptions = [
    { label: t("vat.none"), value: null },
    { label: t("vat.type_1"), value: "TYPE_1" },
    { label: t("vat.type_2"), value: "TYPE_2" },
    { label: t("vat.tax_free"), value: "TAX_FREE" },
    { label: t("vat.margin_scheme"), value: "MARGIN_SCHEME" },
    { label: t("vat.export_of_goods"), value: "EXPORT_OF_GOODS" },
  ];

  const BodyWrapper=({item,options,children,placeholder,validate=false})=>{
    const index=options.rowIndex

    if(validate && errors?.[index+"_"+options.field] && isEmpty(item[options.field])){
      return <input placeholder={placeholder} className="form-control form-control-sm is-invalid" />
    }else if(item[options.field]==""){
      return <InputText placeholder={placeholder}  disabled={ disabled } style={{ width: '7rem' }} />
    }
    return children ?? " "
  }

  const vatCategory = invoiceState?.items?.map(item => item.vatCategory)


  const discountOptions=[
    {
      value:"Percentage",
      label:"%",
    },
    {
      value:"Value",
      label: invoiceState?.currency,
    },

  ]
  const unitOfMeasureCellConfig={
    type: "REACT-SELECT",
    options: unitsOfMeasure,
    getOptionLabel:(uom)=>uom?.code + '-' + unitOfMeasureLabel(uom),
    optionValue:"id",
    placeholder:t("item.measure"),
    menuPosition:"fixed",
    isClearable:false,
    getFilteredResults:getUnitOfmeasure,
    pagination:uomPagination,
    setPagination:setUomPagination,
    onChange: ({
      key,
      value,
      cellOptions,
    }) =>
      handleItemChange({
        key,
        value,
        cellOptions,
      }),

    }
    
  const itemsLength = invoiceState.items.map(item => item)
  const isMaxLength = (fieldLength, maxLength) => fieldLength === maxLength;

  const columns = [
    {
      headerLabel:t("item.code"),
      field: "code",
      header: () => {
        const fieldLength = itemsLength[0].code.length;
        const maxLength = 50;
        const color = isMaxLength(fieldLength, maxLength) ? 'red' : 'inherit';
        return (
          <>
            {t("item.code")} <span style={{ color }}>{`(${fieldLength}/${maxLength})`}</span>
          </>
        );
      },
      style: { minWidth: '8rem', maxWidth: '8rem' },
      cellConfig: {
        onChange: ({ key, value, cellOptions }) =>
          handleItemChange({ key, value, cellOptions, length: 50 }),
      },
      body: (item, options) => (
        <BodyWrapper validate={true} item={item} options={options}>
          {item?.code ?? ""}
        </BodyWrapper>
      )
    },
    {
      headerLabel:t("item.name"),
      field: "name",
      header: () => {
        const fieldLength = itemsLength[0].name.length;
        const maxLength = 50;
        const color = isMaxLength(fieldLength, maxLength) ? 'red' : 'inherit';
        return (
          <>
            {t("item.name")} <span style={{ color }}>{`(${fieldLength}/${maxLength})`}</span>
          </>
        );
      },
      style: { minWidth: '8rem', maxWidth: '8rem' },
      cellConfig: {
        onChange: ({ key, value, cellOptions }) =>
          handleItemChange({ key, value, cellOptions, length: 50 }),
      },
      body: (item, options) => (
        <BodyWrapper validate={true} item={item} options={options}>
          {item?.name ?? ""}
        </BodyWrapper>
      )
    },
    {
      headerLabel:t("item.description"),
      field: "description",
      header: () => {
        const fieldLength = itemsLength[0].description.length;
        const maxLength = 100;
        const color = isMaxLength(fieldLength, maxLength) ? 'red' : 'inherit';
        return (
          <>
            {t("item.description")} <span style={{ color }}>{`(${fieldLength}/${maxLength})`}</span>
          </>
        );
      },
      style: { minWidth: '12rem', maxWidth: '12rem' },
      cellConfig: {
        onChange: ({ cellOptions, key, value, length }) =>
          handleItemChange({ cellOptions, key, value, length: 100 }),
      },
      body: (item, options) => (
        <BodyWrapper validate={true} item={item} options={options}>
          {item?.description ?? ""}
        </BodyWrapper>
      )
    },
    {
      field: "unitOfMeasureId",
      headerLabel: t("item.measure"),
      header: t("item.measure"),
      style:{ minWidth: '9rem', maxWidth:"9rem" },
      cellConfig: unitOfMeasureCellConfig,
      body:(item,options)=><BodyWrapper validate={true} item={item} options={options}>{unitOfMeasureLabel(item.unitOfMeasureId)}</BodyWrapper>

    },
    {
      field: "quantity",
      headerLabel: t("item.quantity"),
      header: t("item.quantity"),
      style:{ minWidth: '7rem', maxWidth:"7rem" },
      cellConfig: {
        type: "number",
        placeholder: "E.g. 1",
        onChange: ({ value, cellOptions }) =>
          handleQuantityChange({ value, cellOptions }),
      },
      body:(item,options)=><BodyWrapper validate={true} item={item} options={options}>{item.quantity}</BodyWrapper>
    },

    {
      field: "unitPrice",
      header: t("item.unitPrice"),
      headerLabel: t("item.unitPrice"),
      style:{ minWidth: '8rem', maxWidth:"8rem" },
      cellConfig: {
        type: "number",
        step: "0.01",
        min: "0.01",
        placeholder: "E.g. 100",
        onChange: ({ value, cellOptions }) =>
          handleUnitPriceChange({ value, cellOptions }),
      },
      body: (item,options) => <BodyWrapper item={item} validate={true} options={options}>{disabled ? func.formattedPrice(item.unitPrice, 10) : item.unitPrice}</BodyWrapper>
    },

    {
      field: "priceWithVat",
      headerLabel: t("item.unitPriceVat") + " " + t("item.withVat"),
      header: t("item.unitPriceVat") + " " + t("item.withVat"),
      style:{ minWidth: '9rem' },
      align: "right",
      cellConfig: {
        type: "number",
        step: "0.01",
        min: "0.01",
        placeholder: "E.g. 100",
        onChange: ({ cellOptions, value }) =>
          handlePriceWithVatChange({ cellOptions, value }),

      },
      body: (item,options) => <BodyWrapper item={item} options={options}>{func.getFormattedMoneyValue(item.priceWithVat)}</BodyWrapper>
    },
    {
      field: "vatCategory",
      header: t("item.vatCategory"),
      headerLabel: t("item.vatCategory"),
      style:{ minWidth: '7rem',maxWidth:"7rem" },
      cellConfig: {
        type: "DROPDOWN",
        onChange: ({ cellOptions, value }) =>
          handleVatChange({ cellOptions, value }),
        options: typeOfVatCategoryOptions,
      },
      body:(item)=> item.vatCategory + "%"
    },
    {
      field: "discountValue",
      headerLabel: t("common.discount"),
      header: t("common.discount"),
      style:{ minWidth: '8rem' },
      cellConfig: {
        inputField:"discountValue",
        dropdownField:"discountType",
        type: "customDiscount",
        options:discountOptions,
        onChange: ({ key, value, cellOptions }) =>handleItemChange({ key, value, cellOptions }),
      },
      body: (item,options) =>{
        let discountType=null
        if(item?.discountType!=null || item?.discountType!==""){
          discountType=item?.discountType==="Percentage"?"%" :invoiceState?.currency
        }
        return <BodyWrapper item={item} options={options}>{item?.discountValue +(discountType ?(" "+discountType):"")}</BodyWrapper>
      }
    },
    {
      field: "investment",
      header: t("item.investment"),
      headerLabel: t("item.investment"),
      style:{ minWidth: '6rem',maxWidth:"6rem" },
      cellConfig: {
        type: "CHECKBOX",
        onChange: ({ key, value, cellOptions }) =>
          handleItemChange({ key, value, cellOptions }),
      },
      body: (item) => <Checkbox checked={item.investment} />,
    },
    {
      field: "vatLabel",
      header: t("item.vatLabel"),
      headerLabel: t("item.vatLabel"),
      style:{ minWidth: '7rem' },
      body:(item)=> vatTypeOptions.find(opt => opt.value == item.vatLabel)?.label ?? "" ,
      cellConfig: {
        placeholder:t("vat.none"),
        type: "DROPDOWN",
        options: vatTypeOptions,
        onChange: ({ cellOptions, value }) =>
          handleVatLabelChange({ cellOptions, value }),
      },
      show:vatCategory.includes('0'),
    },
    {
      field: "totalWithoutVat",
      header: t("hints.itemtotalWithoutVat"),
      headerLabel: t("hints.itemtotalWithoutVat"),
      style:{ minWidth: '8rem' },
      editor:false,
      alignFrozen:'right',
      align:"right",
      body:(item)=>{
        return <strong>
          {func.getFormattedMoneyValue(item.totalWithoutVat)}
        {/* {currencyToDisplay} */}
        </strong>
      },
    },
    {
      field: "totalWithVat",
      headerLabel: t("hints.itemtotalWithVat"),
      header: t("hints.itemtotalWithVat"),
      style:{ minWidth: '10rem' },
      alignFrozen:'right',
      editor:false,
      frozen: true,
      align:"right",
      body:(item)=>{
        return <strong>
          {func.getFormattedMoneyValue(item.totalWithVat)}
            {/* {currencyToDisplay} */}
           </strong>
      }
    },
  ];


  const isAlbanian = invoiceState.currency === "ALL";
  const currencyToDisplay = isAlbanian ? "Lek" : invoiceState.currency;

  const editor = (props) => {
    const { col, cellOptions } = props;
    const cellConfig = col?.cellConfig ?? {}; // i perket kolones
    const currentValue = cellOptions?.value ?? "";

    if (cellConfig?.type === "DROPDOWN") {
      const {
        optionLabel = "label",
        optionValue = "value",
        onChange,
        ...cellProps
      } = cellConfig;

      let fieldDisabled = null

      if(col.field == 'vatLabel' && cellOptions.rowData.vatCategory != 0){
        fieldDisabled = true
      }
      return (
        <Dropdown
          disabled={ fieldDisabled ?? disabled}
          filter
          filterPlaceholder="Search"
          value={currentValue}
          onChange={(e) => {
            const key = col?.field;
            let value = e?.value;
            if (onChange) onChange({ key, value, cellOptions });
            cellOptions.editorCallback(e.value);
          }}

          optionLabel={optionLabel}
          optionValue={optionValue}
          {...cellProps}
        />
      );
    }



    if (col?.field == "description") {
        const key = col?.field
      return <textarea
          placeholder="Description"
          disabled={disabled}
          onChange={(e)=>{
            let value = handleItemChange({
                cellOptions,
                key,
                value:e.target.value,
                length: 100
            })
            cellOptions.editorCallback(value);
          }}
          rows={currentValue?.length > 22 ? 3 : 1}
          className="form-control form-control-sm"
          value={currentValue}
          />;
    }

    if (col.field == "totalWithVat") {
      return <strong>{func.formattedPrice(invoiceState.totalWithVat)}</strong>;
    }
    if (col.field == "totalWithoutVat") {
      return <strong>
        {func.getFormattedMoneyValue(invoiceState.invoiceTotalWithVat)}{" "}
        {currencyToDisplay}
      </strong>;
    }
    if (cellConfig.type == "customDiscount") {
      const item = invoiceState?.items?.[cellOptions?.rowIndex];
      const {
        optionLabel = "label",
        optionValue = "value",
        dropdownField,
        inputField,
        onChange,
        ...cellProps
      } = cellConfig;

      const inputValue=item[inputField]
      const dropdownValue=item[dropdownField]

      return (
        <>
          <div className="custom-input-prime input-group z-0">
            <InputText
              className="form-control"
              value={inputValue}
              onChange={(e) => {
                let value = handleDiscountChange({
                  cellOptions,
                  key: inputField,
                  value: e.target.value,
                });
                cellOptions.editorCallback(value);
              }}

              disabled={disabled}
            />
            <div className="input-group-append">
              <Dropdown
                style={{width:55, height:33}}
                disabled={disabled}
                value={dropdownValue}

                onChange={(e) => {
                  if (onChange) onChange({ key:dropdownField, value:e.value, cellOptions });
                  cellOptions.editorCallback(e.value);
                }}

                optionLabel={optionLabel}
                optionValue={optionValue}
                {...cellProps}
              />
            </div>
          </div>
        </>
      );
    }

    if (col.field == "name" || col.field == "code") {
      const { onChange } = cellConfig;
      return (
        <div className="createInvoice-tableInputs">
        <AutoComplete
          disabled={disabled}
          className="p-autocomplete-input"
          placeholder={`${
            col.field == "code" ? t("item.code") : t("item.name")
          }`}
          value={currentValue}
          maxLength={50}
          field="label"
          suggestions={col.field == "code" ? suggestionsCode : suggestionsName}
          completeMethod={(e)=>loadOptions(e.query)}
          onChange={(e) => {
            const key = col?.field;
            let value = e?.value;
            if (typeof value != "string") return;
            if (onChange) onChange({ key, value, cellOptions });
            cellOptions.editorCallback(value);
          }}

          onSelect={(e) => addItem({item:e.value, index:cellOptions.rowIndex})}
        />
        </div>
      );
    }

    if(cellConfig?.type=="REACT-SELECT"){
      const {
        optionLabel = "label",
        optionValue = "value",
        onChange,
        ...cellProps
      } = cellConfig;
      return (
        <ReactSelectPagination
        styles={uomStyle}
        isDisabled={ disabled}

        value={currentValue}
        onChange={(value) => {
          const key = col?.field;
          cellOptions.editorCallback(value);
          if (onChange) onChange({ key, value, cellOptions });
        }}
        getOptionLabel={option=>option[optionLabel]}
        getOptionValue={(option)=>option[optionValue]}
        {...cellProps}

        />
      )
    }

    if (cellConfig?.type == "CHECKBOX") {
      const { onChange } = cellConfig;
      return (
        <Checkbox
          checked={currentValue}
          disabled={disabled}
          onChange={(e) => {
            const key = col?.field;
            let value = e?.checked;
            if (onChange) onChange({ key, value, cellOptions });
            cellOptions.editorCallback(value);
          }}

        />
      );
    }
    const { type = "text", onChange, ...cellProps } = cellConfig;
    if (col.field == "quantity") {
    }
    return (
      <InputText
        className="p-autocomplete-input"
        placeholder={t("item.quantity")}
        type={type}
        disabled={disabled}
        onChange={(e) => {
          const key = col?.field;
          let value = e.target.value;
          if (onChange) {
            const newValue = onChange({ key, value, cellOptions });
            cellOptions.editorCallback(newValue);
          }
        }}

        value={currentValue}
        {...cellProps}
      />
    );
  };

  const defaultVisibleColumns = useMemo(
    () => columns.map(col=>col.field),[]
  );
  const visibleColumnsDefs = useMemo(
    () => columns.filter((col) => defaultVisibleColumns.includes(col.field) ),
    []
  );


  const [storeColumns,setStoreColumns]=useLocalStorage(
    visibleColumnsDefs.map(col => ({field:col.field})),
    'itemsColumns1'
  )
  const disabledColumns = ['name','code','totalWithVat','totalWithoutVat']

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
      setStoreColumns(orderedSelectedColumns);
  };

  const onSelectAll =(event)=>{
    let orderedSelectedColumns = columns.map((col) => ({field:col?.field}));
    if(event.checked){
      setStoreColumns(visibleColumnsDefs);
    }else{
      setStoreColumns(orderedSelectedColumns);
    }
  }

  const filteredColumns = () => {
    const _columns = [];
    storeColumns.forEach((visibleCol) => {
      const column = columns.find((col) => col.field === visibleCol.field && (col?.show==undefined || col.show===true));
      if (column) _columns.push(column);
    });
    return _columns;
  };
  const dynamicColumns = filteredColumns().map((col, i) => {
    const colProps = {
      style: { height: "2.6rem" },
      key: col?.field,
      field: col?.field,
      header: col?.header,
      rowEdito: false,
      ...col,
    };
    if (col?.editor!==false)
      colProps.editor = (options) => editor({ col, cellOptions: options });

      return <Column  {...colProps} />;
  });

  const multiSelectValue=storeColumns?.map((col) => ({ field: col?.field }))

  const scanCode = useCallback(
      func.debounce(async (query) => {
        const options=await loadOptions(query, true);
        if(!options || options?.length==0) return;
        const newItem=options[0]

        const foundIndex=invoiceState.items.findIndex(item=>item.code===newItem.code)
        let index=foundIndex
        let newItemCheck=false

        if(foundIndex<0 && invoiceState.items.length===1 && invoiceState?.items?.[0]?.code?.length>0){
          newItemCheck=true
        }
        else if(foundIndex<0 && invoiceState.items.length===1){
          index=0
        }else if(foundIndex<0){
          newItemCheck=true
        }
        setCode("")

        addItem({item:newItem,index,newItemCheck,incrementQuantity:invoiceState?.items?.length==1?false: true})
      }, [400]),
      [invoiceState.items]
    );

  const toString=(num)=>{
    return num!=null && num!=undefined ? num.toString():""
  }

  const onItemsImport=(importState)=>{
    let { importedItems,unitsOfMeasureDefault,vatCategoryDefault } = importState
    if(importedItems && importedItems?.length==0) return

    const parsedItems=importedItems.map(item=>{
      let unitOfMeasureId=unitsOfMeasureDefault;
      let {name,code,quantity,unitPrice,vatCategory,description,investment,discount,vatExemptionReason:vatLabel=""}=item

      if(item?.unitOfMeasureId){
      const found =unitsOfMeasure?.find((uom)=>{
        const albName=(uom?.albanianName ?? "").toLowerCase()
        const engName=(uom?.englishName ?? "").toLowerCase()
        let code=(uom?.code ?? "").toString().toLowerCase()
        const fullAlbLabel=code+"-"+albName
        const fullEngLabel=code+"-"+engName

        return [albName,engName,code,fullAlbLabel,fullEngLabel ].includes(
          (item?.unitOfMeasureId??"").toString()?.toLowerCase())
      }


        )
        if(found) unitOfMeasureId = found
      }

      vatCategory= typeOfVatCategoryOptions.find(vat=>vat.value==toString(vatCategory))?.value ?? vatCategoryDefault

    if(vatCategory==="0"){
      const found=vatTypeOptions.find(type=>{
        return (( type.value ?? "").toLowerCase())===(vatLabel.toLowerCase())
      })
      if(found) vatLabel=found.value
    }else{
      vatLabel=""
    }

    let content ={
      name: toString(name),
      code: toString(code),
      description: toString(description),
      discountType: "Value",
      discountValue: func.isNumeric(discount)?toString(discount):"",
      quantity: func.isNumeric(quantity)?toString(quantity):"",
      unitOfMeasureId,
      unitPrice: func.isNumeric(unitPrice) ?toString(unitPrice):"",
      vatCategory,
      priceWithVat:"",
      priceWithoutVat:"",
      totalWithVat:"",
      totalWithoutVat:"",
      investment:(investment?.length>0 ? investment:"").toLowerCase() === "true" || investment===true?true:false,
      vatLabel
  }
    content = handleUnitPriceChange({customItem:content})

    if(func.isNumeric(discount)){
      content= handleDiscountChange({key:"discountValue",customItem:content,value:toString(discount)})
    }

    return content
    })
    let stateContent={
      ...invoiceState,
      items:parsedItems,
    }
    caluculateInvoiceData(stateContent)
    validateInvoice({ setErrors, toast , state: stateContent,validateOnlyItems:true })

  }

  const imporItemsDialogHeader=(
    <div className="d-flex align-items-center">
    <h3 className="m-0">{t("invoice.importItems")}</h3>
    <a
            href={"/items_template.xlsx"}
            className="btn btn-primary shadow-sm btn-sm text-light ml-2"
            download="/items_template.xlsx"
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              {t("invoice.itemsTemplate")}
            </div>
          </a>
    </div>

  )

  const header = (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <label className="w-100 fw-bold header-items ml-2">{t("invoice.items")}</label>
        <input
          type="text"
          id="code"
          name="code"
          ref={codeRef}
          className="form-control form-control-sm"
          placeholder={t("item.scanCode")}
          disabled={disabled}
          value={code}
          onChange={(e) => {
            if(e.target.value.length>1){
              scanCode(e.target.value)
            }
            setCode(e.target.value);
          }}
        />
        {codeLoading && (
          <div className="custom-loading">
            <Preloader
              use={ThreeDots}
              size={25}
              strokeWidth={6}
              strokeColor="#777"
              duration={1000}
            />
          </div>
        )}
        <PopOver
              customStyle = { {borderRadius: "40%", width: "20px", height: "20px", transform: "scale(0.80)",backgroundColor:"#343a40",color:"white"} }
              title= {t('hints.scanItem')}
              />

        {!disabled && <ImportExcel
          vatTypeOptions={vatTypeOptions}
          onItemsImport={onItemsImport}
          requiredColumns={["code","name","quantity","unitPrice"]}
          header={imporItemsDialogHeader}
        />}
      </div>
      <div>
        <MultiSelect
          style={{width:250}}
          value={multiSelectValue}
          options={columns}
          dataKey="field"
          optionLabel="headerLabel"
          optionDisabled={(opt)=> [...disabledColumns,"vatLabel"].includes(opt.field)}
          onChange={onColumnToggle}
          onSelectAll={onSelectAll}
          selectAll={multiSelectValue.length==columns.length}
          // className="w-2rem sm:w-6rem sm:h-2.5rem"
        />
      </div>
    </div>
  );



  const removeItemColumn = (
    <Column
      body={(...props) => {
        const rowIndex = props?.[1]?.rowIndex;
        return (
          <div className="d-flex justify-content-between ">
           {!disabled && <div>
              {invoiceState.items.length > 1 ? (
                <i
                  onClick={(e) => {
                    return invoiceState.items.map((item) => {
                      return (
                        invoiceState.items.length > 1 &&
                        removeItem(e, item, rowIndex)
                      );
                    });
                  }}
                  className="pi pi-trash trash"
                ></i>
              ) : null}
            </div>
           }
          </div>
        );
      }}
      headerStyle={{ width: "10%", minWidth: "4rem" }}
      bodyStyle={{
        textAlign: invoiceState.items.length > 1 ? "center" : "left",
      }}
      alignFrozen="right"
      frozen={balanceFrozen}
    />
  );

  const addRows = (
    <Column
      body={(...props) => {
        const rowIndex = props?.[1]?.rowIndex;
        return (
          <div className="d-flex justify-content-between border-right">
            {!disabled && <div>
              {rowIndex === invoiceState.items.length - 1 ? (
                <i
                  className="pi pi-plus-circle plus"
                  onClick={() => {
                    addNewItem();
                  }}
                ></i>
              ) : null}
            </div>}
          </div>
        );
      }}
      headerStyle={{ width: "10%", minWidth: "2rem" }}
      bodyStyle={{
        textAlign: invoiceState.items.length > 1 ? "center" : "left",
      }}
      alignFrozen="right"
      frozen={true}
    />
  );


  return (
      <div className="card">
    <div className="table-container">
      <DataTable
        scrollable
        scrollHeight="260px"
        editMode="cell"
        resizableColumns
        columnResizeMode="expand"  
        responsiveLayout="scroll"
        size="small"
        inline
        stripedRows
        selectionMode="single"
        value={invoiceState.items ?? []}
        header={header}
      >
        { dynamicColumns }
        { addRows }
        { removeItemColumn }

      </DataTable>
    </div>
    </div>
  );
};

export default DemoTable;