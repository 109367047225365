import React, { useEffect, useRef, useState } from "react";
import mainAxios from "../../services/mainAxios";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from 'react-toastify'
import {
  faCalendarAlt,
  faFilePdf,
  faReceipt,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import Loader from '../Layout/Loader'
import { useAppContext } from "../../AppContext";
import { useTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import selectStyle from "../../design/selectStyle";
import func, { swalAlert } from "../../services/functions";
import PopOver from "../global/PopOver";


export default function   OpenTcrData({data,onChange,onClose}) {
  const { t, i18n } = useTranslation('translations');
  const [state, setState ]=useState(data)
  const [action, setAction] = useState(state?.actionType)
  const [changeDateTime, setChangeDateTime] = useState(new Date());
  const amountRef=React.useRef();
  // const [cash, setCash] = useState("");s
  // const [erpId, setErpId] = useState("");
  // const [subSeqDelivType, setSubSeqDelivType] = useState(null);

  const [formFields, setFormFields] = useState({})
  const {
    user: [user],
    selectedCompany: [selectedCompany],
    company: [fullCompany]
  } = useAppContext();
  const [loading, setLoading] = React.useState(false)
  let currentCompany = user.companiesRights.find((el) => el.id == selectedCompany);
  const issueDateRef = React.useRef();
  const [minIssueDate, setMinIssueDate] = React.useState("");


  const subseqOptions = [
    { "label": "Select if there is an issue...", "value": null },
    { value: "NOINTERNET", label: "No Internet" },
    { value: "BOUNDBOOK", label: "Boundbook" },
    { value: "SERVICE", label: "Service" },
    { value: "TECHNICALERROR", label: "Technical Error" },
  ];
  const popOver = {
    "NOINTERNET": t("hints.noInternet"),
    "BOUNDBOOK": t("hints.boundBook"),
    "SERVICE": t("hints.service") ,
    "TECHNICALERROR": t("hints.TechnicalError"),
  }

  const actionOptions = [
    { "label": "INITIAL", "value": "INITIAL" },
    { "label": "DEPOSIT", "value": "DEPOSIT" },
    { "label": "WITHDRAW", "value": "WITHDRAW" },

  ]


  const handleFormChange = (e, label, val) => {
    let key = e ? e?.target?.name : label
    let value = e ? e?.target?.value : val
    setFormFields(formFields => ({
      ...formFields,
      [key]: value
    }))
  }

  const validateCashDepositObject = async () => {
    const { initialTotal, depositTotal, cashEnd } = state;
    const { cash } = formFields;
    const total = func.isNumeric(cashEnd) ? cashEnd : (initialTotal + depositTotal);
  
    if (action === 'WITHDRAW') {
      const withdrawalAmount = parseFloat(cash);
  
      if (withdrawalAmount > total) {
        return new Promise((resolve) => {
          swalAlert({ title: t("toast.withdrawAlert"), t }).then((result) => {
            resolve(result);
          });
        });
      } else {
        return true;
      }
    }
  
    if (cash) {
      return true;
    } else {
      toast.error('Please fill in the amount.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        containerId: "all_toast"
      });
      return false;
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    const { subSeqDelivType, erpId, cash } = formFields;
    const { tcr, businUnitCode, cashEnd } = state;

    validateCashDepositObject().then((result) => {
      if (result === true) {
        const object = {
          object: "RegisterCashDeposit",
          content: {
            cash: cash,
            tcr: tcr,
            operation: action,
            businessUnitCode: businUnitCode,
            issuerNUIS: currentCompany.nuis,
            changeDateTime: changeDateTime,
            sent: 0,
            fiscStatus: 0,
            subSeqDelivType: subSeqDelivType ?? null,
            erpId: erpId,
          },
          nuis: currentCompany.nuis,
        };

        setLoading(true);
        mainAxios
          .post("/apiEndpoint/cashDeposit", object)
          .then((res) => {
            setLoading(false);
            let strMessage = '';
            if (res?.data?.status === true) {
              if (res?.data?.error === null) {
                strMessage = `Action ${action} Was Successfully Completed `;
                toast.success(strMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  progress: undefined,
                  containerId: "all_toast"
                });
                let key = action?.toLowerCase() + "Total";
                setState(state => {
                  const newState = {
                    ...state,
                    actionType: action,
                    [key]: state[key] + parseFloat(cash),
                  };
                  if (cashEnd) {
                    const newCashEnd = action == "WITHDRAW" ? cashEnd - parseFloat(cash) : cashEnd + parseFloat(cash);
                    newState.cashEnd = newCashEnd;
                  }
                  return newState;
                });
                setFormFields(formFields => ({
                  ...formFields,
                  cash: 0
                }));
                if (onChange) { onChange(); }
                if (onClose) { onClose(); }
              } else {
                strMessage = res?.data?.error?.errorDescription;
                toast.warning(strMessage, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  progress: undefined,
                  containerId: "all_toast"
                });
              }
              setLoading(false);
            }
          })
          .catch((_) => {
            setLoading(false);
          });
      } else {
        // User clicked No in swalAlert or validation failed
        // Do nothing or handle accordingly
      }
    });
  };


  const handleSubseqChange = (subseq) => {
    const { value} = subseq;
    setFormFields((formFields) => ({
      ...formFields,
      subSeqDelivType: value,
    }));

    var newDate = new Date();
    if (value === "BOUNDBOOK") {
      newDate = func.dateBeforeSomeDays(11, newDate);

    } else if (value === "SERVICE" || value === "TECHNICALERROR") {
      newDate = func.dateBeforeSomeDays(1, newDate);
    } else {
      newDate = func.getMinIssueDate();
    }
    setMinIssueDate(newDate);

    if ((value === "BOUNDBOOK" || value === "SERVICE" || value === "TECHNICALERROR")  && changeDateTime < newDate) {
      setFormFields(formFields => ({
        ...formFields,
        changeDateTime: new Date(),
      }));
      issueDateRef.current.setOpen(true);
    }
  };

  const CustomOption = ({ innerProps, isDisabled, label,data}) =>
  !isDisabled ? (
    <div className="option-custom-style"  {...innerProps}>
       {label} {data.value!=null && <PopOver title={popOver[data.value]} />}
      {innerProps.label}
    </div>

  ) : null;


  const handleChangeDateTime = (date) => {
    setChangeDateTime(date);
    issueDateRef.current.setOpen(false);

    let today = new Date();
    let technicalErrorMinDate=func.dateBeforeSomeDays(1,today);
    let boundBookMinDate= func.dateBeforeSomeDays(10,today);
    date=date.setHours(0, 0, 0, 0);
    today=today.setHours(0, 0, 0, 0);
    technicalErrorMinDate=technicalErrorMinDate.setHours(0, 0, 0, 0);
    boundBookMinDate=boundBookMinDate.setHours(0, 0, 0, 0)
    if(date>=today){
      setFormFields(formFields => ({
        ...formFields,
        subSeqDelivType: "",
      }));

      return;
    }
    if (  date>= technicalErrorMinDate && date<today   ) {
      setFormFields(formFields => ({
        ...formFields,
        subSeqDelivType: "SERVICE"
      }))
    }
    else if (date >= boundBookMinDate && boundBookMinDate<today) {
      setFormFields(formFields => ({
        ...formFields,
        subSeqDelivType: "BOUNDBOOK"
      }))
    }
    else {
      setFormFields(formFields => ({
        ...formFields,
        subSeqDelivType: "NOINTERNET",
      }));
    }
  };



  const ref = React.createRef();


  const DateInput = React.forwardRef((state, ref) => {
    return (
      <button
        ref={ref}
        className="btn btn-light bg-white shadow-sm dropdown-toggle btn-sm ml-2 btn-custom"
        onClick={state.onClick}
      >
        <FontAwesomeIcon icon={faCalendarAlt} className="text-primary mr-2" />
        {state.children}
      </button>
    );
  });
  return (
    <div className="row ">
      <div className="col-md-12 mx-auto">
        <div >
          <div>
            <div className="row justify-content-center">
              <div className="col-md-4 mb-4 mr-0 mr-md-5">
                <div className="form-group">
                  <label>Nuis</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={currentCompany.nuis}
                  />
                </div>
                <div className="form-group">
                  <label>Business Unit Code</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={state?.businUnitCode}
                  />
                </div>
                <div className="form-group">
                  <label>TCR</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={state?.tcr}
                  />
                </div>
                <div className="form-group">
                <label>Change Date Time</label>
                  <DatePicker
                    ref={issueDateRef}
                    minDate={minIssueDate ? minIssueDate : func.getMinIssueDate()}
                    onChange={handleChangeDateTime}
                    customInput={
                      <DateInput ref={ref}>
                        {moment(changeDateTime).format("DD/MM/YYYY")}
                      </DateInput>
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  {/* <label>Change Date Time</label>
                  <DatePicker
                    onChange={(date) =>
                      setChangeDateTime(new Date(date.setHours(2, 0, 0, 0)))
                    }
                    customInput={
                      <DateInput ref={ref}>
                        {moment(changeDateTime).format("DD/MM/YYYY")}
                      </DateInput>
                    }
                  /> */}
                   <label>Action Type</label>
                  <Select
                    styles={selectStyle}
                    options={actionOptions}
                    value={actionOptions.find(ac => ac.value === action)}
                    onChange={(ac) => setAction(ac?.value)}
                    name="actionType"
                  />
                  <div className="form-group mb-2 mt-4">
                    <label>{fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? "ERP ID"}</label>
                    <input
                      name="erpId"
                      placeholder={fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? "ERP ID"}
                      type="text"
                      className="form-control"
                      value={formFields['erpId']}
                      onChange={handleFormChange}
                    //onChange={(e) => setErpId(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Amount</label>
                    <input
                      ref={amountRef}
                      name="cash"
                      placeholder="Amount..."
                      type="number"
                      // autoFocus={true}
                      className="form-control"
                      value={formFields['cash']}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Subseq</label>
                    <Select
                      //placeholder="Select if there is an issue..."
                      menuPlacement='top'
                      components={{ Option: CustomOption}}
                      styles={selectStyle}
                      options={subseqOptions}
                      defaultValue={subseqOptions[0]}
                      value={formFields?.subSeqDelivType ? subseqOptions.find(subseq=>subseq.value==formFields.subSeqDelivType): subseqOptions[0]}
                      onChange={(subseq) => handleSubseqChange(subseq)}
                      name="subseq"
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <button
                      className="btn btn-light text-primary mt-4"
                      onClick={(e) => {
                        if(onClose) onClose()
                      }}
                    >
                      Close
                    </button>
                    <button
                      disabled={loading}
                      className="btn btn-primary mt-4  ml-4"
                      onClick={handleSubmit}
                    >
                      {loading ? <Loader size="xs" color="white" /> : t(['common.save'])}
                    </button>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  );
}
