import React from 'react'

const CashRegistry = () => {

    return (
        <h3>Cash Registry</h3>
    )
}

export default CashRegistry
