import React from "react"

const useStateWithCallback = (...args) => {
    const [state, setState] = React.useState(...args);
    const callbacks = React.useRef({});
    Object.values(callbacks.current).forEach((callback) => {
        callback(state);
    });
    const setStateWithCallback = (state, callback) => {
        if (callback) {
            const code = new Date().getTime();
            callbacks.current[code] = (state) => {
                callback(state);
                delete callbacks.current[code];
            };
        }
        return setState(state);
    };
    return [state, setStateWithCallback];
};

export default useStateWithCallback