import React, { useState } from 'react'
import mainAxios from '../services/mainAxios';
import { toast } from 'react-toastify';
import { useAppContext } from '../AppContext';
import { useTranslation } from 'react-i18next';

export const useSubscriptionRequests = ({company,onSubscriptionChange}) => {
  const [actionLoading,setActionLoading]=useState(false)
  const [companySubscriptionsLoading,setCompanySubscriptionsLoading]=useState(false)
  const { user: [user], selectedCompany: [selectedCompany] } = useAppContext()
  const { t } = useTranslation('translations');


  const getSubscriptionPackages=async (id)=>{
    setCompanySubscriptionsLoading(true)
    return await mainAxios.post('apiEndpoint/search', {
        object: "GetSubscriptions",
        company: company.id,
      }).then(res => {
        setCompanySubscriptionsLoading(false)
        if (res.data.status) {
          return res.data.content
        }
        return null
      })
      .catch(err=>{
        setCompanySubscriptionsLoading(false)
        return null
      })
  }

  // let params={}
  // if(id){
  //   params={
  //     id
  //   }
  // }

  const getCompanySubscriptionAddons=async (companySubscriptionId)=>{
    return await mainAxios.post('apiEndpoint/search', {
      object: "GetCompanySubscriptionAddons",
      company: company.id,
      params:{
        companySubscriptionId
      }
    }).then(res => {
      if (res.data.status) {
        return res.data.content
      }
      return null
    })
    .catch(err=>{
      return null
    })
  }

const getSubscriptionById= async (id)=>{
  return await mainAxios.post('apiEndpoint/search', {
    object: "GetCompanySubscriptionsById",
    company: company.id,
    params:{
      id:id.toString()
    }
  }).then(res => {
    if (res.data.status) {
      return res.data.content[0]
    }
    return null
  })
  .catch(err=>{
    return null
  })
}

  const saveCompanySubscription=({state})=>{
    setActionLoading(true); 

    mainAxios
    .post('/apiEndpoint/saveOrUpdate', {
      object: "CompanySubscription",
      content:state,
      nuis: user.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
      )?.nuis,
    })
    .then((res) => {
        setActionLoading(false)
      if (res?.data?.status === true) {
        toast.success(t("subscription.toastSuccess"), { containerId: "all_toast" })
        console.log("test1")
        console.log({onSubscriptionChange})
        if(onSubscriptionChange)onSubscriptionChange()
        console.log("test2")
      } 
    })
    .catch((error) => {
      console.error('Error:', error);
      toast.error('An error occurred');
      setActionLoading(false)
    });
  }
  return { saveCompanySubscription,
    getSubscriptionPackages,
    companySubscriptionsLoading,
    actionLoading,
    getCompanySubscriptionAddons,
    getSubscriptionById
  
  }
}

