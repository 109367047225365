import React, { useState } from "react";
import func from "../../../services/functions";
import { useAppContext } from "../../../AppContext";
import { useTranslation } from "react-i18next";
import PopOver from "../../global/PopOver";
import { Dropdown, Form } from "react-bootstrap";
import Select from "react-select";
import { Dialog } from "primereact/dialog";
import { faAngleDoubleRight, faFileInvoice, faInfo, faRemove, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../Layout/Loader";
import { style } from "@material-ui/system";
import AddBankAccount from "../../Companies/BankAccounts/AddBankAccount";

const { formatNumber, countDecimals } = func;
const DemoSideMenu = ({ errors, ...props }) => {
  let { invoiceState, setInvoiceState, bankAccounts=[], disabled, history } =
    props;

    bankAccounts=[{label:"None",value:"none",id:null}].concat(bankAccounts)
  const { t } = useTranslation("translations");
  const vatTypeOptions = [
    { label: t("vat.none"), value: "" },
    { label: t("vat.type_1"), value: "TYPE_1" },
    { label: t("vat.type_2"), value: "TYPE_2" },
    { label: t("vat.tax_free"), value: "TAX_FREE" },
    { label: t("vat.margin_scheme"), value: "MARGIN_SCHEME" },
    { label: t("vat.export_of_goods"), value: "EXPORT_OF_GOODS" },
  ];
  const {
    user: [user],
    handleShowVersion,
    loadingVersion: loadingVersion,
    selectedCompany: [selectedCompany],
    company: [fullCompany],
    getCompany
  } = useAppContext();

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  const [minIssueDate, setMinIssueDate] = React.useState("");
  const issueDateRef = React.useRef();
  const [bankAccShow, setbankaccShow] = React.useState(false);

  React.useEffect(() => {
    let today = new Date();
    if (invoiceState.paymentMethodType === "cash") {
      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        dueDate: today,
      }));
    }
  }, [invoiceState.paymentMethodType]);

  // const firstUpdate = React.useRef(true);

  // React.useEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }
  //   setInvoiceState((invoiceState) => ({
  //     ...invoiceState,
  //     invoiceEndDate: "",
  //   }));
  // }, [invoiceState.invoiceStartDate]);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#000" : styles.color,
      backgroundColor: state.isSelected ? "#0075670d" : styles.color,
      borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
      "&:hover": {
        color: "#000000",
        backgroundColor: "#0075670d",
      },
    }),
    control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 0.16rem rgba(68, 68, 68, 0.135)" : 0,
      borderWidth: state.isFocused ? "1px" : "1px",
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      minHeight: "14px",
      maxHeight: "34px",
      fontSize: "0.8rem",
      marginBottom: "0.1rem",
      "&:hover": {
        borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      },
    }),
  };

  const typeOfDiscountVatCategoryOptions = [
    { label: "0%", value: "0" },
    { label: "6%", value: "6" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];

  const subseqValues = [
    { value: "", label: "Select" },
    { value: "NOINTERNET", label: "No Internet" },
    { value: "BOUNDBOOK", label: "Boundbook" },
    { value: "SERVICE", label: "Service" },
    { value: "TECHNICALERROR", label: "Technical Error" },
  ];

  const popOver = {
    NOINTERNET: t("hints.noInternet"),
    BOUNDBOOK: t("hints.boundBook"),
    SERVICE: t("hints.service"),
    TECHNICALERROR: t("hints.TechnicalError"),
  };



  const handleInvoiceDataChange = (e, label, val) => {
    let key = e ? e.target.name : label;
    let value = e ? e.target.value : val;
    if (key == "rate") {
      let count = countDecimals(value);
      if (count > 6) return;
    }

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      [key]: value,
    }));
  };

  const handleInvoiceChange = (value, key) => {
    const keys = key.split(".");

    switch (keys.length) {
      case 1:
        setInvoiceState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: value,
        }));
        break;
      case 2:
        setInvoiceState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...invoiceState[keys[0]],
            [keys[1]]: value,
          },
        }));
        break;
      case 3:
        setInvoiceState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...invoiceState[keys[0]],
            [keys[1]]: {
              ...invoiceState[keys[0]][keys[1]],
              [keys[2]]: value,
            },
          },
        }));
        break;
      default:
        return;
    }
  };

  const handleSelectedBankAccount = (account) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      bankAccount: account,
    }));
  };

  React.useEffect(() => {
    let value = invoiceState.subSequenceDelivType;
    if (value && invoiceState?.issueDate) {
      let date = new Date(invoiceState?.issueDate);
      if (value === "BOUNDBOOK") {
        date = func.dateBeforeSomeDays(11, date);
      } else if (value === "SERVICE" || value === "TECHNICALERROR") {
        date = func.dateBeforeSomeDays(1, date);
      } else {
        date = func.getMinIssueDate();
      }
      setMinIssueDate(date);
    }
  }, []);

  const handleDiscountChange = (key, value) => {
    const discountValue = value;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      discount: {
        ...invoiceState.discount,
        [key]: discountValue,
      },
    }));
  };


  let check =
    invoiceState.badDebtInv ||
    invoiceState.sumInv ||
    invoiceState.correctiveInv;

    
  // const addBankAccount = ({history}) => {
  //   // console.log({ history })
  //   history.push(`/companies/${company.id}/bank-accounts/add`);
  // };

  // companies/bank-accounts/add
  // to={`/companies/${state.company.id}/bank-accounts/add`}

  const bankAccount = invoiceState?.bankAccount;


  return (
    <div className="d-flex flex-column">
      <label className="field-label mt-1">
        {t("company.operatorCode")}
        <PopOver title={t("company.operatorCode")} />
      </label>

      <input
        type="text"
        name="operator"
        className="form-control form-control-sm"
        value={invoiceState?.opCode}
        readOnly={true}
      />

      <label className="field-label mt-1">
        {fullCompany?.companyAttributes?.find(
          (a) => a?.attribute?.param == "internalId"
        )?.value ?? t("home.internalId")}
        <PopOver title={t("hints.internalId")} />
      </label>
      {/* <a href="#" class="tip">Link<span>This is the CSS tooltip showing up when you mouse over the link</span></a> */}
      <input
        name="internalId"
        type="text"
        title="ID e sistemit te brendshem"
        className={`form-control form-control-sm tip`}
        disabled={disabled}
        value={invoiceState.internalId}
        onChange={handleInvoiceDataChange}
      />
      <label className="field-label mt-1">
        {t("invoice.nr")}
        <PopOver title={t("hints.invoiceNumber")} />
        <span className="text-danger" style={{fontSize:"11px"}}>
          {" "}
          {process.env.REACT_APP_ALLOW_INVOICE_NR === "true"
            ? "*"
            : t(["invoice.add"])}
        </span>
      </label>
      <input
        name="number"
        type="text"
        className={`form-control form-control-sm ${
          process.env.REACT_APP_ALLOW_INVOICE_NR === "true"
            ? "required"
            : "readonly"
        }`}
        disabled={process.env.REACT_APP_ALLOW_INVOICE_NR !== "true" || disabled}
        value={invoiceState.number}
        onChange={handleInvoiceDataChange}
      />

      {/* <label className="field-label">
          {t("invoice.issueDate")}

          <span className="text-danger"> *</span>
          <PopOver title={t("hints.issueDate")} />
        </label>
        <DatePicker
          ref={issueDateRef}
          selected={invoiceState.issueDate}
          disabled={disabled}
          dateFormat="dd/MM/yyyy"
          name="issueDate"
          autoComplete="off"
          minDate={minIssueDate ? minIssueDate : func.getMinIssueDate()}
          showDisabledMonthNavigation
          onChange={handleIssueDateChange}
          customInput={<input className="form-control form-control-sm" />}
        /> */}

      {/* <label className="field-label">
          {t("invoice.paymentDate")}

          <span className="text-danger"> *</span>
          <PopOver title={t("hints.paymentDate")} />
        </label>
        <DatePicker
          selected={invoiceState.dueDate}
          disabled={disabled}
          dateFormat="dd/MM/yyyy"
          name="dueDate"
          autoComplete="off"
          minDate={invoiceState.issueDate}
          showDisabledMonthNavigation
          onChange={handleDueDateChange}
          customInput={<input className="form-control form-control-sm" />}
        /> */}

      <label className="field-label mt-1">
        {t("invoice.contractNo")}
        <PopOver title={t("hints.contractNo")} />
      </label>
      <input
        name="contract"
        type="text"
        className={`form-control form-control-sm`}
        disabled={disabled}
        value={invoiceState.contract}
        onChange={handleInvoiceDataChange}
      />

      <label htmlFor="discount" className="field-label mt-1">
        {t("invoice.discount")}

        <PopOver title={t("hints.invoiceDiscount")} />
      </label>
      <div className="input-group">
        <input
          type={disabled ? "text" : "number"}
          step="0.01"
          min="0.01"
          id="discount"
          name="discount"
          className="form-control form-control-sm"
          disabled={disabled}
          value={
            disabled
              ? invoiceState.discount
                ? formatNumber(invoiceState.discount?.value || null)
                : ""
              : invoiceState.discount?.value
          }
          onChange={(e) => handleDiscountChange("value", e.target.value)}
        />
        <div className="input-group-append dropup">
          <button
            className={
              "btn btn-sm btn-outline-primary dropdown-toggle" +
              (disabled ? " disabled" : "") 
            }
            style={{height:"29px"}}
            disabled={disabled}
            // disabled={true}
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {invoiceState?.discount?.type =="Percentage"?"%":invoiceState?.currency}
          </button>
          <div className="dropdown-menu">
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleDiscountChange("type", "Percentage");
              }}
            >
              {t("invoice.percentage")}
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleDiscountChange("type", "value");
              }}
            >
              {t("invoice.value")}
            </a>
          </div>
        </div>
      </div>
      <label htmlFor="discountVat" className="field-label">
        {t("invoice.discountVat")}
        <PopOver title={t("hints.discountVat")} discountVat />
      </label>

      <Select
        styles={customStyles}
        id="discountVat"
        name="discountVat"
        options={typeOfDiscountVatCategoryOptions}
        value={
          typeOfDiscountVatCategoryOptions.find(
            (dVat) => dVat.value === invoiceState.discount.vat
          ) || null
        }
        onChange={(e) => handleDiscountChange("vat", e.value)}
        isDisabled={disabled}
      />
      {invoiceState.discount.vat === "0" && (
        <div className="form-group">
          <label htmlFor="vatLabel" className="field-label">
            {t("item.vatLabel")}
            <span className="text-danger"> *</span>
            <PopOver title={t("hints.vatType")} />
          </label>

          <Select
            styles={customStyles}
            type="text"
            id="vatLabel"
            name="vatLabel"
            options={vatTypeOptions}
            value={
              vatTypeOptions.find(
                (VatC) => VatC.value === invoiceState.discount.vatLabel
              ) || ""
            }
            onChange={(e) => handleDiscountChange("vatLabel", e.value)}
            isDisabled={disabled}
          />
        </div>
      )}
      <label
        htmlFor="bankAccount"
        className="field-label d-flex align-items-center mt-1"
      >
        {t("company.bankAccount")}
        <PopOver title={t("hints.bankAccount")} />
        {/* <button
          className="btn btn-light bg-white shadow-sm btn-sm btn-custom ml-auto"
          onClick={addBankAccount}
        >
          {t("subscription.add")}
        </button> */}
        <button onClick={()=>setbankaccShow(true)} className="btn btn-light bg-white shadow-sm btn-sm btn-custom ml-auto">
            {t("subscription.add")}
        </button>
      </label>

      <Dialog
          header={t("bankAccount.add")}
          visible={bankAccShow}
          onHide={() => setbankaccShow(false)}
          style={{ width: "30vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <AddBankAccount modal={true} setbankaccShow={setbankaccShow} onBankAdded={()=>{setbankaccShow(false);getCompany()}} />
        </Dialog>

      {disabled ? (
        <input
          className="form-control form-control-sm"
          width="30"
          value={
            bankAccount ? `${bankAccount.bankName} | ${bankAccount.iban}` : ""
          }
          disabled
        />
      ) : (
        <Select
          styles={customStyles}
          options={
            bankAccounts
            ?.filter(
              (bankAccount) =>
                bankAccount.currency === invoiceState.currency &&
                bankAccount.active === true || bankAccount.value=="none"
            )
            .map((account) => ({
              label: account.value=="none"?"Select...": `${account.bankName} | ${account.iban}`,
              value:account.value=="none"?"":account.id,
            }))}
          value={{
            label: bankAccount 
              ? `${bankAccount.bankName} | ${bankAccount.iban}`
              : "Select",
            value: bankAccount ? bankAccount.id : "",
          }}
          isSearchable={true}
          onChange={(account) => {
            handleSelectedBankAccount(
              bankAccounts.find(
                (bankAccount) => bankAccount.id === account.value || account.value=="none"
              ) ?? null
            );
          }}
          name="bankAccount"
          id="bankAccount"
        />
      )}
    </div>
  );
};

export default DemoSideMenu;
