import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEye,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import func from "../../../services/functions";
import { Preloader, ThreeDots } from "react-preloader-icon";
import { Link } from "react-router-dom";
import { getStore } from "../../../AppContext";
import TooltipOverlay from '../../global/TooltipOverlay'
import CellInput from "../../PrimeTable/CellInput";
import { paymentOptions } from "../../../services/data";
import DatePicker from "react-datepicker";

const moment = require("moment");

const generatePurchasesColumns = ({
  purchasesColumns,
  t,
  i18n,
  purchaseRole,
  allTcr,
  cisStatusOptions,
  internalStatusOptions,
  priorityOptions,
  currencyOptions,
  tcrList,
  filters,
  handleParamsChange,
  getFilteredResults
}) => {
  
  const { user: [user], company: [fullCompany] } = getStore()
  let columns = purchasesColumns != null ? purchasesColumns?.split(",") : null;
  
  const prioritySortList={
    "Low":1,
    "Medium":2,
    "High":3
  }

  const statusSortList = {
    "Draft":5,
    "Approval":4,
    "Refused":3,
    "Accepted":2,
    "Finance":1,
  };

  const cisStatusList = {
    "DELIVERED":6,
    "ACCEPTED":5,
    "REFUSED":4,
    "OVERPAID":3,
    "PAID":2,
    "PARTIALLY_PAID":1,
  };

  const value = tcrList().filter((tcr) =>
    filters.params.purchaseUnit.find(
      (tcrValue) => tcrValue === tcr.value
    )
  ) || ""


  const periodFilter = (filterOptions) => {
    let selected = filters?.params?.["period"]
    return (
        <div>
            <DatePicker
                id="periodDate"
                placeholderText={t("queue.selectDate")}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                onChange={(date) => {
                    let value = new Date(date).setHours(2, 0, 0, 0)
                    filterOptions.filterCallback(value)
                    value = moment(value).format("M/yyyy")
                    handleParamsChange(null, "period", value)
                    getFilteredResults({ data: { ...filters, params: { ...filters.params, "period": value } } })
                }
                }
                customInput={
                    <div className="form-control d-flex justify-content-center align-items-center" style={{ width: 150, position: "relative" }}>
                        <FontAwesomeIcon icon={faCalendar} />
                        <div className="ml-1">
                            {filters?.params?.["period"] ? filters?.params?.["period"] : t("vat.none")}
                        </div>
                        {selected && <div
                            onClick={(e) => {
                                e.stopPropagation()
                                filterOptions.filterCallback("")
                                handleParamsChange(null, "period", null)
                                getFilteredResults({ data: { ...filters, params: { ...filters.params, "period": null } } })
                            }}
                            style={{ position: "absolute", zIndex: 99999, top: 6, right: 10, padding: "0px 5px", cursor: "pointer", fontSize: 16, fontWeight: 400 }}>
                            {`\u2715`}
                        </div>}
                    </div>
                }
            />
        </div>
    )
}


  return [
    {
      header: "Status",
      style:{minWidth: "150px",maxWidth:"150px"},
      field: "internalStatus",
      body: (invoice) =>  invoice?.internalStatus ?? "",
      sortFunction:(rowA,rowB)=>func.columnCustomSort(rowA,rowB,statusSortList,"internalStatus"),
      default:true,
      persisted:true,
      filter: false,
      filterType: "MULTISELECT",
      filterConfig: {
        options: internalStatusOptions,
        showCount: true
      }    
    },
    {
      header:  t(["purchases.cisStatus"]),
      style:{minWidth: "180px",maxWidth:"180px"},
      field: "purchaseStatus",
      body: invoice => invoice?.purchaseStatus ?? "",
      sortFunction:(rowA,rowB)=>func.columnCustomSort(rowA,rowB,cisStatusList,"purchaseStatus"),
      default:true,
      filter: false,
      filterType:"MULTISELECT",
      filterConfig:{
        options: cisStatusOptions,
        showCount:true
      }    
    },
    {
      header: t(["home.actions"]),
      style:{minWidth: "120px",maxWidth:"120px"},
      field: "actions",
      body: (invoice) => (
        <>
          {purchaseRole.id != 14 && <Link
            to={"/edit-purchase/" + invoice.id}
            className={"nav-link p-0 d-flex text-primary align-items-center mr-3"}
          >
            <FontAwesomeIcon icon={faEye} className="mx-1" />
            {t("invoice.view")}
          </Link>}
        </>
      ),
      persisted: true    
    },
    {
      header: t("purchases.supplierName"),
      style:{minWidth: "220px",maxWidth:"220px"},
      field:"connectionTransactional.name",
      format: (invoice) =>invoice?.connectionTransactional?.name ?? "",
      body: (invoice) => {
          return invoice?.connectionTransactional?.name ?? ""
      },
      sortable:true,
      default:true,
      filter:true,
      filterConfig:{
        splitKey:false
      }    
    },
    {
      header: t("invoice.sellerNuis"),
      field: "sellerTin",
      style:{minWidth: "180px",maxWidth:"180px"},
      default:true,
      filter: true,
      // filterConfig: {like:true}   
    },


    // {
    //   header: t(["invoice.print"]),
    //   field: 'print',
    //   style:{minWidth: "150px",maxWidth:"150px"},
    //   body: invoice => (
    //     <>
    //       {invoice?.purchaseInvoiceExtracted?.eic &&
    //         <a
    //           href="#"
    //           className={"nav-link p-0 d-flex-align-items-center ml-1" + (loadingPdf ? " disabled" : "")}
    //           onClick={(e) => { e.preventDefault(); showInvoicePdf(invoice.purchaseInvoiceExtracted.eic) }}>
    //           < img src="https://profisc.al/wp-content/uploads/2021/10/Asset-12.svg" title={t('invoice.electronicInv')} style:{minWidth=,maxWidth:"minWidth""3}8" height="32" className="mr-1" />

    //         </a>}
    //       {/* */}
    //     </>
    //   ),
    // },

    {
      header: t(["table.issueDatetime"]),
      field: "recordDateTime",
      style:{minWidth: "180px",maxWidth:"180px"},
      sortable: true,
      formattedExportValue: true,
      body: (invoice) =>
        invoice.recordDateTime
          ? moment(invoice.recordDateTime).format("DD/MM/YYYY HH:mm")
          : "",
      default: true,
      filter: true,
      filterType:"DATE"    
    },
    {
      header: t(["invoice.extracted"]),
      style:{minWidth: "100px",maxWidth:"100px"},
      field: "extracted",
      format: (invoice) => (invoice?.purchaseInvoiceExtracted?.extracted ? invoice?.purchaseInvoiceExtracted?.extracted  : ""),
      body: (row) =>
        row.extracted ? (
          <div className="ml-3 text-success font-weight-bold">
            {t("company.yes")}
          </div>
        ) : (
          <div className="ml-3 text-danger font-weight-bold">
            {t("company.no")}
          </div>
        ),
      filter: true,
      filterType:"TRISTATECHECKBOX",
    },
    {
      header: t(["home.totAll"]),
      style:{minWidth:"170px",maxWidth:"170px"},
      field: "amount",
      sortable: true,
      body: (invoice) =>  invoice.amount ? func.getFormattedMoneyValue(invoice.amount) : 0,
      default:true,
      align:"right"    
    },
    
    {
      header: t(["home.totCurrency"]),
      style:{minWidth:"170px",maxWidth:"170px"},
      field: "amountCurrency",
      sortable: true,
      formattedExportValue:true,
      body: (invoice) => {
        if (invoice.amount && invoice.exRate) {
          const result = (invoice.amount / invoice.exRate);
          return func.getFormattedMoneyValue(result);
        }
        return "";
      },
      default:true,
      align:"right"    
    },
    
    {
      header: t(["home.exchangeRate"]),
      style:{minWidth:"140px",maxWidth:"140px"},
      field: "exRate",
      default:true,  
    },
    {
      header: t(["invoice.currency"]),
      style:{minWidth: "100px",maxWidth:"100px"},
      field: "currency",
      body: (invoice) => (invoice?.currency ? invoice?.currency  : ""),
      default:true,
      filter: false,
      filterType: "MULTISELECT",
      filterConfig: {
        options: currencyOptions,
        showCount: true
      }        
    },
    {
      header: t("purchases.activities"),
      style:{minWidth: "140px",maxWidth:"140px"},
      field:"purchaseApprovals",
      format:()=>"",
      body: (invoice) =>{
        let rejections=invoice?.purchaseApprovals?.reduce((sum,invoice)=>sum+invoice?.rejections,0)
        let approvals=invoice?.purchaseApprovals?.reduce((sum,invoice)=>sum+invoice?.approvals,0)
        let returnCount=invoice?.purchaseApprovals?.reduce((sum,invoice)=>sum+invoice?.resetNr,0);
        
        return (
          <div className="d-flex">
            {returnCount>0 &&  <TooltipOverlay show={20} hide={20} title="Returns"><div className={`p-1 badge badge-warning mr-3`} style={{cursor:"pointer"}}> { returnCount }</div></TooltipOverlay>}
            {rejections>0 &&  <TooltipOverlay show={20} hide={20} title="Rejections"><div className={`p-1 badge badge-danger mr-3`} style={{cursor:"pointer"}}> { rejections }</div></TooltipOverlay>}
            {approvals>0 &&  <TooltipOverlay show={20} hide={20} title="Approvals"><div className={`p-1 badge badge-success mr-3`} style={{cursor:"pointer"}}> { approvals }</div></TooltipOverlay>}
          </div>
        )
      },
    },
    {
      header: t(["invoice.docNumber"]),
      field: "docNumber",
      style:{minWidth: "180px",maxWidth:"180px"},
      sortable:true,
      formattedExportValue:true,
      format: (invoice)=>func?.preventExcelFormatFn(invoice?.docNumber || ""),
      body: (invoice) => invoice?.docNumber || "",
      default:true,
      filter: true,
      filterConfig: {like:true}
    },
    {
      header: t("invoice.priority"),
      field: "priority",
      style:{minWidth: "120px",maxWidth:"120px"},
      body: (invoice) =>invoice?.priority ?? "",
      sortFunction:(rowA,rowB)=>func.columnCustomSort(rowA,rowB,prioritySortList,"priority"),    
      filter: true,
      filterType: "MULTISELECT",
      filterConfig: {
        options: priorityOptions,
        showCount: true
      }   
    },
    {
      header: "EIC",
      field: "eic",
      style:{minWidth: "180px",maxWidth:"180px"},
      body: invoice => <CellInput value={invoice?.eic} width={180}/>,
      default:true,
      filter:true,
      filterConfig:{
        like:true
      }    
    },
    {
      header: t("invoice.IIC"),
      field: "iic",
      style:{minWidth: "180px",maxWidth:"180px"},
      body:invoice=>invoice?.iic,
      body: invoice => <CellInput value={invoice?.iic} width={180}/>,
      filter: true,
    },
    {
      header: t("invoice.FIC"),
      field: "fic",
      style:{minWidth: "180px",maxWidth:"180px"},
      format:invoice=>invoice?.fic,
      body: invoice => <CellInput value={invoice?.fic} width={180} />,
      filter:true
    },
    {
      header: t("purchases.purchaseUnit"),
      style:{minWidth: "180px",maxWidth:"180px"},
      field: "purchaseUnit",
      format: (invoice) =>allTcr?.find(tcr=>tcr.value===invoice?.purchaseUnit)?.label??"",
      body: invoice => (<CellInput value={allTcr?.find(tcr => tcr.value === invoice?.purchaseUnit)?.label ?? ""} width={180} />),
      default:true,
      filter:true,
      filterType:"MULTISELECT",
      filterConfig:{
        options: tcrList(),
        value: tcrList()?.filter((tcr) =>
          filters?.params?.purchaseUnit?.find(
            (tcrValue) => tcrValue === tcr.value
          )
        )?.map(opt => opt.value) || "",
        searchable:true
      }    
    },
    {
      header: t("invoice.supplierCode"),
      style:{minWidth: "180px",maxWidth:"180px"},
      field: "supplierCode",
      filter: true,
      filterConfig:{
        like:true
      }
    },
    fullCompany?.workflow?.id == 2 && {
      header: t("purchases.dispatchUser"),
      style: { minWidth: "140px", maxWidth: "140px" },
      field: "dispatcherUser",
      default: true,
    },
    {
      header: t("purchases.approveRejectUser"),
      style:{minWidth:"180px",maxWidth:"180px"},
      field: "acceptRejectUser",
      default:true,  
    },
    {
      header:  t("invoice.agreementNo"),
      field: "agreement",
      filter: true,
      filterConfig:{
        like:true
      },
      style:{minWidth: "190px",maxWidth:"190px"},
    },
    {
      header: t("purchases.agreementDate"),
      field: "agreementDate",
      filter: true,
      style:{minWidth: "180px",maxWidth:"180px"},
      formattedExportValue: true,
      body: invoice => {let date=invoice.agreementDate; return date? moment( date ).format("DD/MM/YYYY") : ""}, 
      filterType:"DATE" 
    },
    {
      header: t("purchases.poNo"),
      field: "internalPo",
      filter: true,
      filterConfig:{
        like:true
      },
      style:{minWidth: "150px",maxWidth:"150px"},
    },
    {
      header: t("purchases.poDate"),
      field: "internalPoDate",
      filter:true,
      style:{minWidth: "150px",maxWidth:"150px"},
      formattedExportValue: true,
      body: invoice => {let date=invoice?.internalPoDate;return date? moment(date).format("DD/MM/YYYY HH:mm") : ""},  
      filterType:"DATE" 
    },
    {
      header: t("purchases.paymentDatePurchases"),
      field: "dueDateTime",
      style:{minWidth: "170px",maxWidth:"170px"},
      sortable: true,
      formattedExportValue: true,
      body: invoice => invoice.dueDateTime ? moment(invoice.dueDateTime).format("DD/MM/YYYY") : "",
      filter:true,
      filterType: "DATE",
    },
    {
      header: t("purchases.expectedPayment"),
      field: "paymentRequestedDate",
      style:{minWidth: "200px",maxWidth:"200px"},
      formattedExportValue: true,
      body: invoice => {let date=invoice?.paymentRequestedDate;return date ? moment(date).format("DD/MM/YYYY") : ""},  
    },
    {
      header: t("purchases.expectedAcceptanceDate"),
      field: "expectedAcceptanceDate",
      style:{minWidth: "220px",maxWidth:"220px"},
      formattedExportValue: true,
      body: invoice => invoice?.expectedAcceptanceDate ? moment(invoice?.expectedAcceptanceDate).format("DD/MM/YYYY") : "",
    },
    {
      header: t("invoice.receivedDate"),
      field: "recordDate",
      style:{minWidth: "220px",maxWidth:"220px"},
      formattedExportValue: true,
      body: invoice => invoice?.recordDate ? moment(invoice?.recordDate).format("DD/MM/YYYY") : "",
    },
    {
      field:"paymentType",
      header:"Cash/NonCash",
      style: { minWidth: "150px", maxWidth: "150px" },
      filter:true,
      filterType:"DROPDOWN",
      filterConfig:{
        options: paymentOptions,
      }
    },
    {
      field:"impCustDecNum",
      header:t("books.impCustDecNum"),
      style: { minWidth: "200px", maxWidth: "200px" },
      filter:true
    },
    // {
    //   field:"einvoice",
    //   header:"E-Invoice",
    //   style: { minWidth: "120px", maxWidth: "120px" },
    //   dataType:"boolean",
    //   filter:true,
    //   filterType:"TRISTATECHECKBOX",
    //   filterConfig:{
    //     field:"isEinvoice"
    //   }
    // },
    {
      header: t("queue.period"),
      style: { minWidth: "180px", maxWidth: "180px" },
      field: 'period',
      default: true,
      format: (invoice)=>func?.preventExcelFormatFn(invoice?.period || ""),
      filter: true,
      filterElement: periodFilter
    },
    {
      field: "iicRef",
      header: "IIC Ref",
      style: { minWidth: 200, maxWidth: 300 },
      filter: true,
  },
    {
      field: "dateRef",
      header: t("purchases.correctiveInvoiceDate"),
      style: { minWidth: 200, maxWidth: 300 },
      formattedExportValue: true,
      body: invoice => invoice?.dateRef ? moment(invoice?.dateRef).format("DD/MM/YYYY") : "",
      filter: true,
    },
    ...(fullCompany?.showMyPurchaseDownloadedStatus ? [{
      header: t(["invoice.apiDownloaded"]),
      style:{minWidth: "180px",maxWidth:"180px"},
      field: "downloaded",
      format: (invoice) => (invoice?.downloaded ?? ""),
      body: (row) =>
          row.downloaded ? (
              <div className="ml-3 text-success font-weight-bold">
                {t("company.yes")}
              </div>
          ) : (
              <div className="ml-3 text-danger font-weight-bold">
                {t("company.no")}
              </div>
          ),
    }]:[]
    ),
  ];
};

export default generatePurchasesColumns;