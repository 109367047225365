import React, { useState, useRef } from "react";
import Select from "react-select"

const noop = () => {
    // no operation (do nothing real quick)
};

const ReactSelect = ({
    SelectComponent,
    required,
    onChange,
    isDisabled,
    value,
    ...props
}) => {
    const [stateValue, setStateValue] = useState(value || "");
    const selectRef = useRef(null);

    const getValue = () => {
        if (value !== undefined) return value;
        return stateValue || "";
    };

    const enableRequired = !isDisabled;

    const handleChange = (newValue, actionMeta) => {
        onChange(newValue, actionMeta);
        setStateValue(newValue);
    };

    return (
        <div>
            <Select {...props} ref={selectRef} onChange={handleChange} />
            {enableRequired && (
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{
                        opacity: 0,
                        width: "100%",
                        height: 0,
                        position: "absolute"
                    }}
                    value={getValue() || ''}
                    onChange={noop}
                    onFocus={() => selectRef.current.focus()}
                    required={required}
                />
            )}
        </div>
    );
};

export default ReactSelect;
