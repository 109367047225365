import React from 'react'

import mainAxios from '../../services/mainAxios'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import Loader from '../Layout/Loader'
import Page404 from '../Page404'
import {useTranslation, withTranslation} from "react-i18next";
import { useAppContext } from '../../AppContext'
import { Dialog } from 'primereact/dialog';

const EditCompanyCertificate = ({visibleCertificate, setVisibleCertificate,company,setCompany}) => {
    const {t,i18n} = useTranslation('translations');
    const [state, setState] = React.useState({
        company: null
    })
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if(!company) return 
        setState(state => ({
            ...state,
            company: {
                ...company,
                certificate: {
                    file: null,
                    name: null,
                    type: "PKCS12",
                    alias: "1",
                    password: "",
                    keyPass: "",
                }
            },
            nuis:company.nuis
          
        }))
    }, [company?.id])
    
    const editCertificate = (e) => {
        e.preventDefault()
        setLoading(true)
        const password = state?.company?.certificate?.password
        mainAxios.post('apiEndpoint/saveOrUpdate', {
            object: "Certificate",
            content: {
                company: {
                    id: state?.company?.id
                },
                ...state?.company?.certificate,
                keyPass:password               
            },
            nuis:state.nuis

        }).then(res => {
            if(res?.data?.status === true) {
                setCompany((company)=>({...company,certificate:res.data.content[0]}))
                setVisibleCertificate(false)
                toast.success(t(['toast.updateCertificate']), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    containerId: "all_toast"
                })
            }
            setLoading(false)
        })
    }

    const handleCertificate = (e) => {
        let file = e.target.files[0]

        if(file) {
            const reader = new FileReader();

            reader.onload = (readerEvent) => {
                let binaryString = readerEvent.target.result
                setState(state => ({
                    ...state,
                    company: {
                        ...state.company,
                        certificate: {
                            ...state.company.certificate,
                            file: btoa(binaryString),
                            name: file.name
                        }
                    }
                }))
            }
            reader.readAsBinaryString(file)
        }
    }
    console.log({state})
    return ( 
            state.company ?
            <Dialog 
                header={t('company.editCertificate')} 
                visible={visibleCertificate} 
                style={{ width: '80vw', maxWidth: '400px' }} 
                onHide={() => setVisibleCertificate(false)}>
                <div className="row">
                    <form className="col-12 col-xl-12 col-lg-10 col-sm-8" onSubmit={editCertificate}>
                        <div className="card border-0 mb-4">
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="certificate">{t('company.certificate')}</label>
                                    <div className="custom-file">
                                        <input 
                                            type="file" 
                                            className="custom-file-input"
                                            id="certificate"
                                            name="certificate"
                                            placeholder="Upload Certificate"
                                            onChange={handleCertificate} 
                                            required={state.company.certificate?.file ? false : true}
                                        />
                                        <label className="custom-file-label" htmlFor="certificate">{state?.company?.certificate?.name ? state?.company?.certificate.name : t(['invoice.choose'])}</label>
                                    </div>
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="certificate_alias">Alias</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="certificate_alias" 
                                        name="certificate_alias"
                                        placeholder="Certificate Name" 
                                        autoComplete={"new-password"}
                                        value={state.company.certificate.alias}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState(state => ({...state, company: {...state.company, certificate: {...state.company.certificate, alias: value}}}))
                                        }}
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="certificate_password">Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="certificate_password" 
                                        name="certificate_password" 
                                        placeholder="Certificate Password" 
                                        autoComplete={"new-password"}
                                        value={state.company.certificate.password}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState(state => ({...state, company: {...state.company, certificate: {...state.company.certificate, password: value}}}))
                                        }}
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="key_pass">Key Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="key_pass" 
                                        name="keyPass" 
                                        placeholder="Key Password" 
                                        value={state.company.certificate.keyPass}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState(state => ({...state, company: {...state.company, certificate: {...state.company.certificate, keyPass: value}}}))
                                        }}
                                    />
                                </div> */}
                                <button 
                                    className="btn btn-primary mt-3"
                                    disabled={loading}
                                    style={{minWidth: "100px"}}
                                    type="submit">
                                    {loading ? <Loader size="xs" color="white"/> : 
                                    (<>
                                        <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
                                    </>)}
                                </button>
                            </div>
                        </div>
                    </form> 
                </div>
            </Dialog>
            :
            <Page404/>
    )
}

export default EditCompanyCertificate