import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Layout/Loader";
import Table from "./Table";
import { useAppContext } from "../AppContext";
import mainAxios from "../services/mainAxios";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const CertificateExpirationDate = () => {
  const moment = require("moment")
  const [certificate, setCertificate] = useState([]);
  const { t } = useTranslation("translations");
  const company = localStorage.getItem("selectedCompany");

  useEffect(() => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCertificateExpirationDates",
      })
      .then((res) => {
        if (res.data.status === true) {
          setCertificate(res.data.content);
        }
      });
  }, [company]);

  const sendEmail=(params)=>{
    mainAxios.post("/notifications/send",{
      params: {
        ...params,notifSubject:"CERTIFICATEEXPIRATION"
      },
    }).then((res)=>{
      if(res.data.status==true){
        toast.success(t(["email.successConfirmationEmail"]), {
          containerId: "all_toast",
        });
      }
    })
  }

  const columns = [
    {
      name: `${t("subscription.expirationDate")}`,
      selector: "expirationDate",
      sortable: true,
      format: certificate => certificate?.expirationDate ? moment(certificate?.expirationDate).format("DD/MM/YYYY") : "",
    },

    {
      name: `${t("item.name")}`,
      selector: "name",
      sortable: true,
    },

    {
      name: `${t("invoice.Nuis")}`,
      selector: "nuis",
      sortable: true,
    },

    {
      name: `${t("email.email")}`,
      selector: "email",
      sortable: true,
    },
    {
      name: `${t("email.sendEmail")}`,
      cell: (certificateExpiration)=>{
        const {email="",firstLastName="",expirationDate="",name="", mfa="EMAIL"} =certificateExpiration
        return (<div onClick={()=>sendEmail({companyName:name,receiver:email,firstLastName,expirationDate, mfa})} style={{cursor:"pointer"}}>
        {false ? (
          <Loader size="xs" color="white" />
        ) : (
          <>
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2 text-primary" />
            {t("invoice.send")}
          </>
        )}
      </div>)
      }
    }
  ];

  return certificate ? (
    <>
      <div className="d-flex justify-content-between align-items-center my-2">
        <h3 className="d-flex align-items-center">
          {t("common.certificateExpirationDates")}
        </h3>
      </div>
      <div className="card border-0 shadow">
        <div className="card-body">
          <Table
            columns={columns}
            data={[...certificate]}
            searchableKeys={["expirationDate", "name", "nuis", "email"]}
          />
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default CertificateExpirationDate;
