import React, { useLayoutEffect ,useState} from "react";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mainAxios from "../../../services/mainAxios";
import Select from "react-select";
import selectStyle from "../../../design/selectStyle";
import { toast } from "react-toastify";
import Loader from "../../Layout/Loader";
import Page404 from "../../../components/Page404";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../AppContext";
const bigDecimal = require("js-big-decimal");

const EditCategories = ({id,onUpdateCategory}) => {
  const { t, i18n } = useTranslation("translations");
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();

  const isEdit=!!id

  const defaultCategory = {
    name: "",
    code: "",
    type: 1,
    active: true,
    company: selectedCompany,
  };
  const [categoryItem, setCategoryItem] = React.useState(defaultCategory);

  const [errors, setErrors] = useState({
    name: null,
  });

  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);

  React.useEffect(() => {
    if(!isEdit) {
      setPageLoading(false)
      return
    }
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCategory",
        params: {
          id: String(id),
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCategoryItem(
            res.data.content[0],
          );
        }
        setPageLoading(false);
      });
  }, []);



  const activeOptions = [
    { label: t("company.yes"), value: true },
    { label: t("company.no"), value: false },
  ];


  const typeOptions = [
    { label: "Normal", value: 1 },
  ];

  const addEditCategory = (e) => {
    e.preventDefault();
    setLoading(true);

    const { name, code } = categoryItem;
    if (!name) {
      setErrors((errors) => {
        return { ...errors, name: "toast.categoryNameError" };
      });
    }
    if (!code) {
      setErrors((errors) => {
        return { ...errors, code: "toast.categoryCodeError" };
      });
    }
    console.log({categoryItem})

    if (!name || !code) {
      setLoading(false);
      return;
    }

    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "Category",
        content: {
          ...categoryItem,
        },
        nuis: user.companiesRights.find(
          (categoryItem) => categoryItem.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === true) {
          onUpdateCategory()
          // toast.success(t(["toast.successCategory"]), {
          //   containerId: "all_toast",
          // });
        }
      });
  };

  const handleChange = (e, selectKey, selectValue) => {
    const key = e ? e.target.name : selectKey;
    const value = e ? e.target.value : selectValue;

    if (errors[key]) {
      setErrors(prevState => { return { ...prevState, [key]: null } })
    }
    setCategoryItem({
      ...categoryItem,
      [key]: value,

    });
  };



  return !pageLoading ? (
    categoryItem ? (
      <div className="row">
        <form
          className="col-xl-12 col-lg-6 col-md-6 col-sm-8 col-12 mx-auto"
          onSubmit={addEditCategory}
        >
          <div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="category_name">{t("item.name")}</label>
                    <span className="text-danger"> *</span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      } `}
                      name="name"
                      value={categoryItem?.name}
                      onChange={handleChange}
                    />

                    {errors.name && (
                      <div className="invalid-feedback">
                        {t(errors.name)}
                        </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="code">{t("item.code")}</label>
                    <span className="text-danger"> *</span>
                    <input
                      type="text"
                      className={`form-control ${errors.code ? "is-invalid" : ""
                        } `}
                      value={categoryItem.code}
                      name="code"
                      id="code"
                      onChange={handleChange}
                    />
                    {errors.code && (
                      <div
                        className="invalid-feedback"
                      >
                        {t(errors.code)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="category_type">
                      {t("itemsCategory.categoryType")}
                    </label>
                    <span className="text-danger"> *</span>
                    <Select
                      styles={selectStyle}
                      name="type"
                      options={typeOptions}
                      value={typeOptions.find(
                        (el) => el.value ===categoryItem.type
                      )}
                      onChange={(e) =>handleChange(null,"type",e.value)
                      }
                      required
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="category_active">
                      <span className="text-danger"> *</span>
                      {t("itemsCategory.active")}
                    </label>
                    <Select
                      styles={selectStyle}
                      name="active"
                      required
                      options={activeOptions}
                      value={activeOptions.find(
                        (el) => el.value === categoryItem.active
                      )}
                      onChange={(e) =>handleChange(null,"active",e.value)}
                    />
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary mt-3"
                disabled={loading}
                style={{ minWidth: "100px" }}
                type="submit"
              >
                {loading ? (
                  <Loader size="xs" color="white" />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {isEdit? t("common.save"):t("navbar.create")}
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <Page404 />
    )
  ) : (
    <Loader />
  );
};

export default EditCategories;
