// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import React, { useEffect } from 'react'
import { css, jsx } from "@emotion/react"
import Collapse from "@kunukn/react-collapse"
import cx from "clsx"
import '../../../design/custom.scss';

const AccordionHeader = ({
    heading,
    secondaryHeading,
    headingRight,
    setIsOpen,
    isOpen,
    transitionDuration,
    quantityChanged,
    setQuantityChanged,
    currentIndex,
    sameItemIndex={sameItemIndex},
}) => {

    React.useEffect(()=>{
        if(quantityChanged){
            setTimeout(()=>{
                setQuantityChanged(false)
            },[300])
        }
    },[quantityChanged])

    return (
        <button
            aria-expanded={isOpen}
            onClick={() => {
                setIsOpen(!isOpen)
            }}
            className="accordion__toggle"
            css={css`
                border: none;
                display: flex;
                align-items: center;
                font-size: 100%;
                margin: 0;
                padding: 20px;
                /* setting padding gives better anim than setting height */
                padding-top: ${secondaryHeading ? "9px" : "20px"};
                padding-bottom: ${secondaryHeading ? "9px" : "20px"};
                min-height: ${isOpen ? "80px" : "60px"};
                position: relative;
                width: 100%;
                text-align: left;
                background: white;
                font-family: inherit;
                outline: none;

                &:focus:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: -2px;
                    right: -2px;
                    border: 3px solid #007567;
                    z-index: 1;
                }
                &:focus:not(.focus-visible):after {
                    display: none;
                }
            `}>
            <div
                css={css`
                    flex: 1;
                `}
            >
                <span
                    css={css`
                        display: block;
                        line-height: 1.25;
                        font-size: 16px;
                        font-weight: bold;
                    `}
                >
                    {heading}
                </span>
                {secondaryHeading && (
                    <span
                        css={css`
                            margin-top: 2px;
                            display: block;
                            line-height: 1.25;
                            font-size: 16px;
                        `}
                    >
                        {secondaryHeading}
                    </span>
                )}
            </div>
            {headingRight && (
                    <span
                    className={quantityChanged && currentIndex==sameItemIndex?"quantity-changed-enter-active":!quantityChanged && currentIndex==sameItemIndex?"quantity-changed-exit-active":""}
                        css={css`
                            margin-top: 2px;
                            display: block;
                            line-height: 1.25;
                            font-size: 16px;
                            color: #007567;
                        `}
                    >
                        {headingRight}
                    </span>
                )}
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 32 32"
                        css={css`
                    color: #007567;
                    margin-left: 15px;
                    width: 20px;
                    height: 20px;
                    transition: transform ${transitionDuration} cubic-bezier(0, 1, 0, 1);
                    transform: rotate(${isOpen ? ".5turn" : "0"});
                `}
                    >
                        <path
                            fill="currentColor"
                            d="M29.602 8.002l-13.6 11.562-13.6-11.562-2.4 2.752 16 13.764 16-13.764z"
                        />
                    </svg>
                    <div
                        className="accordion__divider"
                        css={css`
                    position: absolute;
                    top: 0px;
                    height: 1px;
                    left: 20px;
                    right: 20px;
                    background: #e4e5e4;
                    transition: 260ms;
                    display: block;
                `}
                    />
                </button>
            );
};

            const Accordion = ({
                openAccordion,
                children,
                heading,
                secondaryHeading,
                headingRight,
                transitionDuration,
                t,
                quantityChanged,
                setQuantityChanged,
                currentIndex,
                sameItemIndex,
                overflow,
                marginBottom="40px",
                padding="1px"


}) => {

    const [isOpen, setIsOpen] = React.useState(false)


    useEffect(() => {
                setIsOpen(openAccordion)
            }, [openAccordion])

            return (
            <div
                className={cx("accordion", {
                    "accordion--is-open": isOpen,
                    "accordion--is-closed": !isOpen
                })}
                css={css`
            position: relative;
            transition: ${transitionDuration};`}>
                <AccordionHeader
                    {...{
                        heading,
                        secondaryHeading,
                        headingRight,
                        transitionDuration,
                        setIsOpen,
                        isOpen,
                        quantityChanged,
                        setQuantityChanged,
                        currentIndex,
                        sameItemIndex,
                        marginBottom,
                        padding
                    }
                    }
                />
                <Collapse
                    isOpen={isOpen}
                    style={{ transitionDuration ,overflow:overflow?"visible":"hidden"}}
                
                    aria-hidden={isOpen ? "false" : "true"}>
                    <div
                        style={{ "marginBottom": marginBottom, "padding":padding}}>
                        {children}
                    </div>
                </Collapse>
            </div>
            );
};

            export default Accordion;
