import React, { useState, useEffect  } from "react";
import { useAppContext } from "../../AppContext";
import mainAxios from "../../services/mainAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Page404 from "../Page404";
import GeneralPurchaseInfo from "./GeneralPurchaseInfo";
import Loader from "../Layout/Loader";
import InternalInfo from "./InternalInfo";
import { TabView, TabPanel } from 'primereact/tabview';
import { toast } from "react-toastify";
import PurchaseDocumentPreview from "./PurchaseDocumentPreview";
import PurchaseHistory from "./PurchaseHistory";
import SplitPane, { Pane } from "react-split-pane";
import PurchaseComments from "./PurchaseComments";
import Swal from "sweetalert2"
import Tooltip from "react-bootstrap/Tooltip";
import TooltipOverlay from "../global/TooltipOverlay";


const EditPurchase = (props) => {
  const { t } = useTranslation("translations");
  const { user: [user],selectedCompany: [selectedCompany], company: [fullCompany]} = useAppContext();
  const [loading, setLoading] = useState(false);
  const [purchaseAttachments, setPurchaseAttachments] = useState([]);
  const purchaseId = props.match.params.id;
  const [invoice, setInvoice] = React.useState(null);
  const [pageLoading, setPageLoading] = React.useState(true);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const [reoladPage,setReloadPage]=useState(false)
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    mainAxios
      .post("endpoint/v2/getPurchaseInvoiceById", {
        value: purchaseId,
      })
      .then((res) => {
        const content = res?.data?.content?.[0]
        if(content){
          setPageLoading(false);
          setInvoice(res.data.content[0]);
        }

      });
  }, [purchaseId, selectedCompany,reoladPage]);

   const handleAction= ({title=""}) => {
      return Swal.fire({
        title,
        icon: 'warning',
        width: '800px',
        showCancelButton: true,
        confirmButtonColor: '#007567',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then(res=>{
        return res
      })
  }
  


  const extractPdf = () => {
    setLoading(true);
    mainAxios
      .post("/endpoint/v2/extractPdf", {
        params: {
          purchaseInvoice: purchaseId,
        },
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          setInvoice(res.data.content[0]);
        }
      });
  };
  const savePurchaseInvoice = (action) => {
    let toastMessage;
   
      switch (action?.action) {
        case "save":
          toastMessage = t(["invoice.purchaseInvoiceSaved"])
          break;
        case "submit for approval":
          toastMessage = t(["purchases.submitforApproval"])
        default:
          toastMessage = t(["purchases.defaultAction"])
      }
      if((invoice?.purchaseInvoiceExtracted?.purchaseUnit==null || invoice?.purchaseInvoiceExtracted?.purchaseUnit=="") && user.purchaseRole.id!=13){
        toast.error("Purchase Unit should have a value", {
            containerId: "all_toast"
        })
        return;
    }
    setLoading(true);
    let purchaseInvoiceExtracted=invoice?.purchaseInvoiceExtracted;
    mainAxios
      .post("/endpoint/v2/saveMyPurchaseInvoiceInfo", {
        attachements: [...purchaseAttachments],
        comment: invoice?.comment ?? "",
        status: invoice?.myPurchaseStatus?.name ?? "",
        value: invoice?.eic ?? "",
        workflowStatus: action?.statusTo ?? "",
        action:action?.action ?? "",
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
        purchaseInvoiceExtracted: {
          ...purchaseInvoiceExtracted,
          // id: purchaseInvoiceExtracted?.id ?? "",
          // currency: purchaseInvoiceExtracted?.currency ?? "",
          // exRate: purchaseInvoiceExtracted?.exRate ?? "",
          // valueWoVat: purchaseInvoiceExtracted?.valueWoVat ?? "",
          // vatValue: purchaseInvoiceExtracted?.vatValue ?? "",
          // valueWVat: purchaseInvoiceExtracted?.valueWVat ?? "",
          receivedDate: invoice?.recordDate ?? "",
          priority:
            purchaseInvoiceExtracted?.priority?.name != null
              ? { name: purchaseInvoiceExtracted.priority.name }
              : null,
          // purchaseUnit: invoice?.purchaseInvoiceExtracted?.purchaseUnit ?? "",
          // expectedAcceptanceDate:
          //   purchaseInvoiceExtracted?.expectedAcceptanceDate ?? "",
          // paymentRequestedDate:purchaseInvoiceExtracted?.paymentRequestedDate ?? "",
          // supplierInvoiceInfo:
          //   purchaseInvoiceExtracted?.supplierInvoiceInfo ?? "",
          // iic:purchaseInvoiceExtracted?.iic ?? "",
          // fic:purchaseInvoiceExtracted?.fic ?? "",

          agreementInfo:purchaseInvoiceExtracted?.agreementInfo ?{
            id:purchaseInvoiceExtracted.agreementInfo?.id,
            no: purchaseInvoiceExtracted.agreementInfo?.no ?? null,
            name: purchaseInvoiceExtracted.agreementInfo?.name ?? "",
            date: purchaseInvoiceExtracted.agreementInfo?.date ?? "",
          }:null,
          internalPo:purchaseInvoiceExtracted?.internalPo? {
            id:purchaseInvoiceExtracted.internalPo?.id,
            no: purchaseInvoiceExtracted.internalPo?.no ?? null,
            name: purchaseInvoiceExtracted.internalPo?.name ?? "",
            date: purchaseInvoiceExtracted.internalPo?.date ?? "",
          }:null,

        },
      })
      .then((res) => {
        setLoading(false);
          // setInvoice(res.data.content[0]);
          setReloadPage((state)=>!state);
          setPurchaseAttachments([]);
          toast.success(toastMessage, {
            containerId: "all_toast",
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (e, selectKey, selectValue, length) => {
    const key = e ? e?.target?.name : selectKey;
    let value = e ? e?.target?.value : selectValue;

    let keys = key.split(".");

    value = length != undefined ? value.slice(0, length) : value;
    switch (keys.length) {
      case 1:
        setInvoice((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: value,
        }));
        break;
      case 2:
        setInvoice((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...(invoiceState[keys[0]] ? {...invoiceState[keys[0]]}:{}),
            [keys[1]]: value,
          },
        }));
        break;
      case 3:
        setInvoice((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...(invoiceState[keys[0]] ? {...invoiceState[keys[0]]}:{}),
            [keys[1]]: {
              ...(invoiceState[keys[0]] && invoiceState[keys[0]][keys[1]] ?{...invoiceState[keys[0]][keys[1]]}:{}),
              [keys[2]]: value,
            },
          },
        }));
        break;
      default:
        return;
    }
  };

  const buildActionButton = ({action}) => {
    let name = (action.charAt(0).toUpperCase() + action.slice(1)) ?? "";
    switch (action) {
      case "accept":
        return{
          color:"btn-primary",
          name
        }
      case "return":
        return {
          color: "btn-danger",
          name
          //name: t("status.return")
        };
      case "reject":
        return {
          color: "btn-danger",
          name
        }
      case "Approve CIS": return {
        color: "btn-success",
        name: t("purchases.approveCis")
      }
      case "Reject CIS":
        return {
          color: "btn-danger",
          name: t("purchases.rejectCis")
        }
      case "submit for approval":
        return {
          color: "btn-success",
          name
        }
      default:
        return {
          color: "btn-secondary",
          name,
        };
    }
  };

   const staticActions=()=>{
    return (
      <>
      <div className="extracted">
      {!invoice?.extracted && invoice?.conditions?.allowExtraction && (
        <button
          className={"btn btn-info mx-3" + (loading ? " disabled" : "")}
          onClick={() => {
            extractPdf();
          }}
        >
          <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
          {t("invoice.extractXml")}
        </button>
      )}
    </div>
      {invoice?.conditions?.allowSave && <button
          className={`btn btn-primary mr-3`}
          disabled={loading}
          style={{ minWidth: "100px" }}
          onClick={() => {
            savePurchaseInvoice({action:"save",statusTo:invoice?.workflowStatus?.name});
          }}
        >
          {loading ? <Loader size="xs" color="white" /> : t("common.save")}
        </button>}
        {invoice?.conditions?.allowReturn && <button
          className={`btn btn-warning mr-3`}
          disabled={loading}
          style={{ minWidth: "100px" }}
          onClick={() => {
            savePurchaseInvoice({action:"return",statusTo:invoice?.workflowStatus?.name});
          }}
        >
          {loading ? <Loader size="xs" color="white" /> : t("status.return")}
        </button>}
    </>
    )
  }
  


  const actions = () => {
    let conditions = invoice?.conditions;
    if ( conditions) {
      let queue = ["reject","Approve CIS","return","submit for approval"];
      let oldActions=[...conditions.actionStatusToList];
      let newActions=[];
      queue.forEach((actionInTurn)=>{
        oldActions.forEach((action,index)=>{
          if(actionInTurn==action.action){
            newActions.push(action);
            oldActions.splice(index,1);
            return;
          }
        })
      })
      newActions=[...newActions,...oldActions];
      


      return newActions.map((action,index) => {
        let actionButton = buildActionButton(action);
        return (
          <button
            key={index}
            className={`btn ${actionButton.color} mr-3`}
            disabled={loading}
            style={{ minWidth: "100px" }}

            onClick={() => {
              if(action?.action=="Reject CIS" || action?.action =="Approve CIS"){
                handleAction({title:action?.action=="Reject CIS"?t(['notification.reject']):t(['notification.approve'])}).then(res=>{
                  if(res?.isConfirmed){
                    savePurchaseInvoice(action);
                  }
                })
              }else{
                savePurchaseInvoice(action);
              }

            }}
          >
            {loading ? <Loader size="xs" color="white" /> : actionButton.name}
          </button>
        );
      });
    }
    return <></>;
  };

  let actionButtons = actions();
  let staticButtons = staticActions();

  const buildStatus=()=>{
    let {name,id}=invoice?.workflowStatus ?? {};
    switch(id){
      case 1:return {
        badge:"secondary",
        name:"Draft"
      }//draft
      case 3:return {
        badge:"info",
        name: t("purchases.finance")
      };//finance
      case 4:return  {
        badge:"success",
        name: t("queue.accepted")
      }//accepted
      case 5:return {
        badge:"danger",
        name: t("purchases.refused")
      }//refused
      default:return {badge:"info",name
    
    };
    }
  }

  const buildCisStatus=()=>{
    switch(invoice?.myPurchaseStatus?.id){
      case 1:return "success";//ACCEPTED
      case 2:return "danger";//REFUSED
      case 3:return  "info";//PAID
      case 4:return "info"//PARTIALLY_PAID
      case 5:return "DELIVERED"//DELIVERED
      case 6  :return "OVERPAID"//OVERPAID
      default:return "info";
    }
  }

  const purchaseApprovals=()=>{
    let rejections=invoice?.purchaseApprovals?.reduce((sum,invoice)=>sum+invoice?.rejections,0)
    let approvals=invoice?.purchaseApprovals?.reduce((sum,invoice)=>sum+invoice?.approvals,0)
    return (
    <div className="d-flex">
      <div className="alert alert-danger mr-3 mb-0 py-1 px-3">{t("purchases.rejected")}   <span className="badge badge-danger">{ rejections ?? 0}</span></div><></>
      <div className="alert alert-success mr-3 mb-0 py-1 px-3">{t("purchases.approved")}  <span className="badge badge-success">{approvals ?? 0}</span></div><></>
    </div>
    )
  }
  const [isDrag,setIsDrag]=useState(false)
  let status=buildStatus()
  return !pageLoading ? (
    invoice ? (
      <div className="my-4">
        <div className="d-flex align-items-center justify-content-between  ml-4 mb-2">
          <div className="d-flex align-items-center justify-content-center ml-2">
            <h3 className="mb-0 mr-4">{t("invoice.editPurchase")}</h3>
            {invoice?.workflowStatus?.name &&(
            <TooltipOverlay title="Workflow Status" show={50} hide={50}
            >
            <div style={{fontSize:18,cursor:"default"}} className={`text-center badge badge-${status?.badge ?? ""}`}>
                {status?.name}
              </div>
              </TooltipOverlay>
            )}
            {fullCompany?.showMyPurchaseDownloadedStatus && (invoice?.downloaded ? (
                <div className="alert alert-success ml-3 mb-0 py-1 px-3">{t("invoice.apiDownloaded")} </div>)
                : (<div className="alert alert-secondary ml-2 mb-0 py-1 px-3">{t("invoice.apiNotDownloaded")} </div>))
            }
          </div>
          {purchaseApprovals()}
          <div className="d-flex align-items-center ml-4">
          <div className="extracted">
            </div>
            {staticButtons}
            {actionButtons}
          </div>
        </div>
          {isDesktop?
          <div style={{height:"100vh"}} className="mt-4">
          <SplitPane
            defaultSize="55%"
            minSize={0}
            style={{ height: "auto" }}
            split="vertical"
            onDragStarted={()=>setIsDrag(true)}
            onDragFinished={()=>setIsDrag(false)}
          >
            <div className="mx-4 mb-4">
              <GeneralPurchaseInfo invoice={invoice} />
              <InternalInfo invoice={invoice} handleChange={handleChange} />
              <div className="purchase-tab purchase-nav">
                <TabView className="mb-4 shadow ">
                  <TabPanel header={t("invoice.historyPurchase")}   headerStyle={{fontSize:"17px"}}>
                    <PurchaseHistory purchaseHistorics={invoice?.purchaseHistorics} />
                  </TabPanel>
                  <TabPanel
                  header={t("purchases.entryInfo")} headerStyle={{fontSize:"17px"}}
                  >
                    <PurchaseComments
                      invoice={invoice}
                      handleChange={handleChange}
                      purchaseAttachments={purchaseAttachments}
                      setPurchaseAttachments={setPurchaseAttachments}
                    />
                  </TabPanel>
                </TabView>
                </div>
              <div className="d-flex justify-content-end align-items-center">
                {staticButtons}
                {actionButtons}
              </div>
            </div>
            <div className="mx-4">
              <Pane className="">
                {
                  <PurchaseDocumentPreview
                  isDrag={isDrag}
                  purchaseAttachments={invoice?.purchaseAttachments}
                  isDesktop={isDesktop}
                  purchaseId={purchaseId}
                />
                }

              </Pane>
            </div>
          </SplitPane>
          </div>
          :
        (
          /* mobile */
        <div className="mt-4">
          <GeneralPurchaseInfo invoice={invoice} />
          <InternalInfo invoice={invoice} handleChange={handleChange} />
          <PurchaseHistory purchaseHistorics={invoice?.purchaseHistorics} />
          <PurchaseDocumentPreview
            purchaseAttachments={invoice?.purchaseAttachments}
            isDesktop={isDesktop}
            purchaseId={purchaseId}
            isDrag={isDrag}
            
          />
          <PurchaseComments
            invoice={invoice}
            handleChange={handleChange}
            purchaseAttachments={purchaseAttachments}
            setPurchaseAttachments={setPurchaseAttachments}
          />
          <div className="d-flex align-items-center ml-4">
            {staticButtons}
            {actionButtons}
          </div>
        </div>
        )}
      </div>
    ) : (
      <Page404 />
    )
  ) : (
    <Loader />
  );
};

export default EditPurchase;
