const selectStyle = {
  option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#000" : styles.color,
      backgroundColor: state.isSelected ? "#0075670d" : styles.color,
      borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
      "&:hover": {
          color: "#000000",
          backgroundColor: "#0075670d",
      }
  }),
  control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 0.1rem rgba(68, 68, 68, 0.135)" : 0,
      borderWidth: state.isFocused ? "2px" : "1px",
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      zIndex: state.isFocused ? 2 : 1, // Set z-index based on focus state
      "&:hover": {
          borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      }
  })
};


export const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#000" : styles.color,
    backgroundColor: state.isSelected ? "#0075670d" : styles.color,
    borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#0075670d",
    }
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? "0 0 0 0.16rem rgba(68, 68, 68, 0.135)" : 0,
    borderWidth: state.isFocused ? "2px" : "1px",
    borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
    "&:hover": {
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
    }
  })
};

export const errorSelectStyle = {
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? "0 0 0 0.16rem rgba(255, 0, 0, 0.15)" : 0,
    borderColor: state.isFocused ? "red" : "red",
    "&:hover": {
      borderColor: state.isFocused ? "red" : "red",
    },
  }),
};


export const uomStyle = {
  ...customStyles,
  control: (...props) => ({
    ...customStyles.control(...props),
    minHeight: '31px',
    height: '31px',
    borderRadius:"6px"
  }),
  menu:(base)=>({...base,width:300}),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '27px',
    padding: '0px 6px',
    marginBottom: '4px'
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '27px',
    marginBottom: '4px'

  }),
};

export default selectStyle;