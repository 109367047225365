import { Dialog } from 'primereact/dialog';
import React, { useEffect } from 'react'
import { useState } from 'react';
import Select from "react-select";

const EditSeller = ({
    invoiceState,
    setInvoiceState,
    setVisibleSeller,
    customStyles,
    countries,
    handleSellerIDTypeChange,
    buyerIDTypes,
    visibleSeller,
    t,
}) => {

    const [invoiceStateSeller, setInvoiceStateSeller] = useState(invoiceState);

    const handleInvoiceSellerChange = (e, selectKey, selectValue, length) => {
      const key = e ? e.target.name : selectKey;
      let value = e ? e.target.value : selectValue;
      value = length != undefined ? value.slice(0, length) : value;
      setInvoiceStateSeller((prevInvoiceStateSeller) => ({
        ...prevInvoiceStateSeller,
        parties: {
          ...prevInvoiceStateSeller.parties,
          seller: {
            ...prevInvoiceStateSeller.parties.seller,
            [key]: value,
          },
        },
      }));
    };
  
    const handleSellerIDTypeChangeInternal = (sellerIDType) => {
      setInvoiceStateSeller((prevInvoiceStateSeller) => ({
        ...prevInvoiceStateSeller,
        parties: {
          ...prevInvoiceStateSeller.parties,
          seller: {
            ...prevInvoiceStateSeller.parties.seller,
            sellerIDType,
          },
        },
      }));
    };
  
    const saveEditedSeller = (sellerIDType) => {
      handleSellerIDTypeChange(sellerIDType);
      setInvoiceState((prevInvoiceState) => ({
        ...prevInvoiceState,
        parties: {
          ...prevInvoiceState.parties,
          seller: invoiceStateSeller.parties.seller,
          sellerIDType,
        },
      }));
    };
  
    const footerContent = (
      <div>
        <button
          onClick={() => {
            saveEditedSeller();
            setVisibleSeller(false);
          }}
          type="button"
          className="btn btn-success"
        >
         {t('common.save')}
        </button>
      </div>
    );
  
    return (
        <Dialog
            header={t("invoice.editSeller")}
            visible={visibleSeller}
            onHide={() => setVisibleSeller(false)}
            style={{ width: "30vw", }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            footer={footerContent}
        >
        <div className="container">
  <div className="row">
  <div className="col-md-12">
      <div className="form-group">
        <label className="field-label">{t("taxpayer.name")}</label>
        <div className="input-length-validation">
          <input
            name="name"
            type="text"
            className="form-control"
            value={invoiceStateSeller.parties.seller.name}
            onChange={(e) => handleInvoiceSellerChange(e, null, null, 200)}
          />
          <div className={`length-text ${invoiceStateSeller?.parties?.seller?.name?.length == 200 ? "text-danger" : ""}`}>
            {invoiceStateSeller?.parties?.seller?.name?.length}/200
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-12">
      <div className="form-group">
        <label className="field-label">{t("table.sellerIDType")}</label>
        <Select
          menuPosition="fixed"
          styles={customStyles}
          options={buyerIDTypes}
          value={buyerIDTypes.find((type) => {
            return (
              type.label ===
              invoiceStateSeller.parties.seller.sellerIDType
            );
          })}
          isSearchable={true}
          onChange={(sellerIDType) =>
              handleSellerIDTypeChangeInternal(sellerIDType.label)
          }
          name="sellerIDType"
        />
      </div>
    </div>

    <div className="col-md-12">
      <div className="form-group">
        <label className="field-label">
          {invoiceStateSeller?.parties?.seller?.sellerIDType ?? "TIN"}
        </label>
        <div className="input-length-validation">
          <input
            name="tin"
            type="text"
            style={{ paddingRight: 30 }}
            className="form-control required"
            value={invoiceStateSeller.parties.seller.tin || ""}
            onChange={(e) => handleInvoiceSellerChange(e, null, null, 20)}
          />
          <div className={`length-text ${invoiceStateSeller?.parties?.seller?.tin?.length == 20 ? "text-danger" : ""}`}>
            {invoiceStateSeller?.parties?.seller?.tin?.length}/20
          </div>
        </div>
      </div>
    </div>




    <div className="col-md-12">
      <div className="form-group">
        <label className="field-label">{t("taxpayer.address")}</label>
        <div className="input-length-validation">
          <input
            name="address"
            type="text"
            className="form-control"
            value={invoiceStateSeller.parties.seller.address}
            onChange={(e) => handleInvoiceSellerChange(e, null, null, 400)}
          />
          <div className={`length-text ${invoiceStateSeller?.parties?.seller?.address?.length == 400 ? "text-danger" : ""}`}>{invoiceStateSeller.parties.seller.address.length}/400</div>
        </div>
      </div>
    </div>
    <div className="col-md-12">
      <div className="form-group">
        <label className="field-label">{t("taxpayer.city")}</label>
        <div className="input-length-validation">
          <input
            name="town"
            type="text"
            className="form-control"
            value={invoiceStateSeller?.parties?.seller?.town}
            onChange={(e) => handleInvoiceSellerChange(e, null, null, 200)}
          />
          <div className={`length-text ${invoiceStateSeller?.parties?.seller?.town?.length == 200 ? "text-danger" : ""}`}>{invoiceStateSeller?.parties?.seller?.town?.length}/200</div>
        </div>
      </div>
    </div>
    <div className="col-md-12">
      <div className="form-group">
        <label className="field-label">{t("taxpayer.country")}</label>
        <Select
          menuPosition="fixed"
          styles={customStyles}
          className="basic-single p-0"
          onChange={(countryObj) => handleInvoiceSellerChange(null, "country", countryObj.value)}
          isSearchable={true}
          name="country"
          options={countries}
          value={
            countries.find(
              (country) =>
                country.value === invoiceStateSeller?.parties?.seller?.country
            ) || ""
          }
          defaultValue={countries[1]}
        />
      </div>
    </div>
  </div>
</div>

        </Dialog>

    )

}

export default EditSeller