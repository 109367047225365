import { useRef, useEffect, useState, useMemo } from 'react';

const useOnScreen=(ref,depedencies=[])=> {
    const [isIntersecting, setIntersecting] = useState(false)
    const firstUpdate = useRef(true)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => {

            if (entry.isIntersecting) {
                setIntersecting(entry.isIntersecting)
            }
        }
    ), [ref])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setIntersecting(false);
    }, [...depedencies])

    useEffect(() => {
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [])



    return isIntersecting
}

export default useOnScreen;
