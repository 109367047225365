import React from 'react'
import Loader from '../Layout/Loader'
import mainAxios from '../../services/mainAxios'
import func from '../../services/functions'
import { useAppContext } from '../../AppContext'
import useStateWithCallback from '../../services/useStateWithCallback'
import { toast } from 'react-toastify'

import {useTranslation, withTranslation} from "react-i18next";

const moment = require("moment")

const Parameters = () => {
    
    const {t,i18n} = useTranslation('translations');
    const [services, setServices] = React.useState(null)
    const { user: [user], selectedCompany: [selectedCompany] } = useAppContext()
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setLoading(true)
        mainAxios.post('/apiEndpoint/search', {
            object: "GetConfigData",
            params: {
                configEntity: "company",
                entityId: selectedCompany + ""
            }
        }).then(res => {
            setServices(res.data.content)
            setLoading(false)
        })
    }, [selectedCompany])

    const handleServiceChange = (service, checked) => {
        mainAxios.post('/apiEndpoint/saveOrUpdate', {
            object: "SetConfigData",
            content: {
                ...service,
                configValue: checked ? "1" : "0"
            },
            nuis: user.companiesRights.find(
                (company) => company.id === parseInt(selectedCompany)
              )?.nuis
        }).then(res => {
            if(res?.data?.status === true) {
                setServices(services.map(stateService => stateService.id === service.id ? {...stateService, configValue: checked ? "1" : "0"} : stateService))
                toast.success(t(['toast.updateService']), {
                    containerId: "all_toast"
                })
            }
        })
    }

    return (
        !loading ? 
        <>
            <h3 className="mt-4 mb-4">{t('navbar.parameters')}</h3>
            <div className="card border-0 shadow">
                <div className="card-body">
                    {services?.map(service => (
                        <div className="custom-control custom-switch mb-3">
                            <input 
                                type="checkbox" 
                                className="custom-control-input" 
                                id={service.configName}
                                checked={parseInt(service.configValue)}
                                onChange={(e) => handleServiceChange(service, e.target.checked)}/>
                            <label className="custom-control-label" htmlFor={service.configName}>
                                {service.configName.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") === "Enable Download Pdf"?t(['parameters.enablePdf']) :
                                service.configName.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") === "Enable Fiscalization"?t(['parameters.enableFiscalization']) :
                                service.configName.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") === "Enable Einvoice"?t(['parameters.enableEinvoice']) :
                                service.configName.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") === "Only Fiscalization"?t(['parameters.onlyFiscalization']):
                                service.configName.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") === "Only Public Entity"?t(['parameters.onlyPublicEntity']):""}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </>
        :
        <Loader/>
    )
}

export default Parameters