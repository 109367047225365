import {
  faCheck,
  faEdit,
  faPlus,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Loader from "../Layout/Loader";
import mainAxios from "../../services/mainAxios";
import { useTranslation } from "react-i18next";
import Table from "../Table";
import { toast } from "react-toastify";
import { useAppContext } from "../../AppContext";
import Select from "react-select";
import selectStyle from "../../design/selectStyle";

const MySuppliers = () => {
  const [editClients, setEditClients] = useState([]);
  const [clients, setClients] = useState([]);

  const [sellerInputClass, setSellerInputClass] = useState("");
  const [buyer, setBuyer] = useState("");
  const [inputClass, setInputClass] = React.useState("");
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const [edit, setEdit] = useState([]);
  const [open, setOpen] = useState([]);

  // const [purchaseUnits, setPurchaseUnits] = useState([]);

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const { t } = useTranslation("translations");
  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });

  const defaultClient = {
    albanianBusinesses: {
      name: null,
      tin: null,
      address: null,
      town: null,
    },
    email: null,
    nickname: null,
    phoneNo: null,
    purchaseUnit: null,
  };

  //   const [newSupplier, setnewSupplier] = useState(defaultClient);

  const getFilteredResults = (pageSize, page = 1) => {
    // mainAxios.post("/endpoint/v2/getCompanyClients", {
    mainAxios
      .post("/apiEndpoint/search", {
        object: "GetCompanyClients",
        value: null,
        type: "basic",
        pagination: {
          pageSize,
          pageNumber: page,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          setEditClients(res.data.content);
          setClients(res.data.content);
          setTableRows({
            rowsPerPage: pageSize,
            currentPage: page,
            totalRows: res?.data?.totalSize,
          });
        }
      });
  };

  const getExpandButtons = () =>
    document.querySelectorAll(
      "[aria-label='Expand Row'], [aria-label='Collapse Row']"
    );

  // React.useEffect(() => {
  //   if (editClients.length > itemCount && getExpandButtons()?.length > 0) {
  //     const buttons = getExpandButtons();
  //     buttons[buttons.length - 1].click();
  //   }
  //   itemCount = editClients.length;
  // }, [editClients?.length]);

  const editClient = (clientId) => {
    setEdit({ ...edit, [clientId]: true });
    const index = editClients.findIndex((c) => c.id === clientId);
    const buttons = getExpandButtons();
    if (buttons[index].ariaLabel === "Expand Row") buttons[index].click();
  };

  const tcrList = React.useMemo(() => {
    let tcrList = [];
    company.branches
      .reduce(
        (acc, branch) =>
          branch?.tcrTypes?.length > 0 ? acc.concat(branch.tcrTypes) : acc,
        []
      )
      .forEach((tcr) => {
        if (tcr?.allowPurchase) {
          let sellerName =
            company?.branches?.find(
              (branch) => branch?.businUnitCode == tcr?.businUnitCode
            )?.sellerName ?? "";
          tcrList.push({
            label: tcr.tcrCode + " - " + sellerName,
            value: tcr.tcrCode,
          });
        }
      });
    return tcrList.length > 0
      ? [{ label: "Select", value: null }].concat(tcrList)
      : tcrList;
  }, [selectedCompany]);

  React.useEffect(() => {
    getFilteredResults(10, 1);
  }, [selectedCompany]);

  const columns = [
    {
      name: "NUIS",
      width: "125px",
      format: (client) => client?.albanianBusinesses?.tin || "",
      sortable: true,
      selector: "albanianBusinesses.tin",
    },
    {
      name: `${t("item.name")}`,
      width: "250px",
      format: (client) => client.albanianBusinesses.name || "",
      selector: "albanianBusinesses.name",
      sortable: true,
    },
    {
      name: `${t("purchases.purchaseUnit")}`,
      format: (client) => client.purchaseUnit || "",
      selector: "purchaseUnit",
      // sortable: true,
    },
    {
      name: `${t("customers.address")}`,
      // format:((client)=>client.albanianBusinesses.address || ""),
      selector: "albanianBusinesses.address",
      width: "350px",
      sortable: true,
      cell: (row) =>
        row?.albanianBusinesses?.address ? (
          <input
            type="text"
            onFocus={(event) => event.target.select()}
            value={row.albanianBusinesses.address || ""}
            onChange={() => {}}
            style={{
              border: 0,
              userSelect: "text",
              fontSize: "13px",
              width: "335px",
            }}
          />
        ) : (
          ""
        ),
    },
    {
      name: `${t("customers.town")}`,
      width: "125px",
      format: (client) => client.albanianBusinesses.town || "",
      selector: "albanianBusinesses.town",
      sortable: true,
    },
    {
      name: `${t("customers.country")}`,
      width: "80px",
      format: (client) => client.albanianBusinesses.country || "",
      selector: "albanianBusinesses.country",
      sortable: true,
    },
    {
      name: `${t("invoice.nickName")}`,
      selector: "nickname",
      sortable: true,
    },
    {
      name: `${t("users.phone")}`,
      selector: "phoneNo",
      sortable: true,
    },
    {
      name: `${t("email.email")}`,
      selector: "email",
      sortable: true,
    },
    {
      name: t(["home.actions"]),
      width: "180px",
      sortable: true,
      cell: (client) => (
        <>
          <div
            className="text-primary mr-2"
            style={{ cursor: "pointer" }}
            onClick={() => editClient(client.id)}
          >
            <FontAwesomeIcon icon={faEdit} className="mr-1" />
            {t("company.edit")}
          </div>
          {/* <div
            className="text-danger"
            style={{ cursor: "pointer" }}
            onClick={null}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-1" />
            {t("common.delete")}
          </div> */}
        </>
      ),
    },
  ];

  const handleClientChange = (itemId, key, value, length) => {
    value = length != undefined ? value.slice(0, length) : value;
    const index = editClients.findIndex((item) => item.id === itemId);

    setEditClients((editClients) => [
      ...editClients.slice(0, index),
      {
        ...editClients[index],
        [key]: value,
      },
      ...editClients.slice(index + 1),
    ]);
  };
  // const collapseClient = (clientId) => {
  //   const index = editClients.findIndex((client) => client.id === clientId);
  //   getExpandButtons()[index].click();
  // };
  const addSupplier = () => {
    setEditClients((editClients) => [
      {
        id: -1,
        newSupplier: true,
        ...defaultClient,
      },
      ...editClients,
    ]);
    setTimeout(() => {
      const buttons = getExpandButtons();
      buttons[0].click();
    }, [50]);
  };

  const saveClient = (data) => {
    let body = data;

    let parsedBody = {
      ...(body?.newSupplier ? {} : { id: body.id }),
      company: {
        id: selectedCompany,
      },
      albanianBusinesses: {
        id: data.albanianBusinesses.id,
      },
      email: body?.email,
      nickname: body?.nickname,
      phoneNo: body?.phoneNo,
      purchaseUnit: body?.purchaseUnit,
    };
    mainAxios
      .post("/apiEndpoint/saveOrUpdate", {
        object: "CompanyHasBusiness",
        content: parsedBody,
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
        value: null,
        type: "basic",
      })
      .then((res) => {
        if (res.data.status === true) {
          if (body?.newSupplier) {
            setEditClients((editClients) => {
              return [
                {
                  ...res.data.content[0],
                },
                ...editClients.slice(1),
              ];
            });
            toast.success(t(["mySuppliers.newSupplier"]), {
              containerId: "all_toast",
            });
          } else {
            setEditClients((editClients) => {
              return editClients.map((ec) => {
                if (ec.id == body.id) {
                  return res.data.content[0];
                }
                return { ...ec };
              });
              // return [
              //   ...editClients.slice(0, clientIndex),
              //   {
              //     ...res.data.content[clientIndex],
              //   },
              //   ...editClients.slice(clientIndex + 1),
              // ];
            });
            setClients((clients) => {
              return clients.map((c) => {
                if (c.id == body.id) {
                  return res.data.content[0];
                }
                return { ...c };
              });
            });
            toast.success(t(["mySuppliers.updateSupplier"]), {
              containerId: "all_toast",
            });
          }
          setBuyer("");
          setSellerInputClass("");
        }
      });
    setEdit({ ...edit, [body.id]: false });
  };
  const handleTaxPayerChange = (e) => {
    let value = e.target.value;

    setBuyer(value);
    if (inputClass.length > 0) setInputClass("");
  };

  const handleSellerTaxPayerSearch = () => {
    let buyerNipt = buyer.split(" ").join("");
    if (
      buyerNipt &&
      !new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(buyerNipt)
    ) {
      toast.error(t(["toast.incorrectNipt"]), { containerId: "all_toast" });
      setSellerInputClass("error");
      return;
    }

    setSellerInputClass("loading");
    mainAxios
      .post("apiEndpoint/search", {
        object: "SearchNIPT",
        value: buyerNipt,
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          if (res.data.content.length === 0) {
            toast.warning(t(["toast.noTaxPayer"]), {
              containerId: "all_toast",
            });
            setSellerInputClass("error");
          } else {
            const { source, country, ...restTaxPayer } = res.data.content[0];
            setEditClients((editClients) => {
              return [
                {
                  ...editClients[0],
                  albanianBusinesses: restTaxPayer,
                },
                ...editClients.slice(1),
              ];
            });
            setSellerInputClass("success");
            setEdit({ ...edit, [-1]: true });
          }
        } else {
          setSellerInputClass("error");
        }
      })
      .catch((e) => {
        setSellerInputClass("error");
      });
  };

  const cancelAdd = () => {
    let clnts = editClients.filter((c) => !c.newSupplier);
    setEditClients(clnts);
  };

  const cancelEdit = (clientId) => {
    let client = clients.find((client) => client.id == clientId);
    setEdit({ ...edit, [clientId]: false });
    setEditClients((state) => {
      return state?.map((cl) => {
        if (cl?.id == client?.id) {
          return { ...client };
        }
        return { ...cl };
      });
    });
  };

  const renderItem = (data) => {
    const client = data;

    let editable = edit[data.id] ?? false;

    if (!client) return <></>;
    return (
      <div className="border-bottom p-3">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveClient(data);
          }}
        >
          <div className="row">
            {client?.newSupplier && (
              <div className="col-md-3">
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="form-label">
                      {t("navbar.search")} (NIPT)
                      <span className="text-danger"> *</span>
                      {/* <PopOver title="Text NIPT" /> */}
                    </label>
                    <div className="input-group position-relative">
                      <input
                        name="buyer"
                        type="text"
                        className={
                          "form-control required" +
                          (sellerInputClass === "error"
                            ? " border-danger"
                            : sellerInputClass === "success"
                            ? " border-success"
                            : "")
                        }
                        value={buyer}
                        onChange={handleTaxPayerChange}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text cursor-pointer"
                          onClick={handleSellerTaxPayerSearch}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                      {sellerInputClass === "loading" && (
                        <div className="input-icon input-loader">
                          <Loader size="xs" />
                        </div>
                      )}
                      {sellerInputClass === "error" && (
                        <div className="input-icon text-danger">
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      )}
                      {sellerInputClass === "success" && (
                        <div className="input-icon text-success">
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={client?.newSupplier ? "col-md-2" : "col-md-3"}>
              <div className="form-group">
                <label htmlFor={`nickname` + client.id}>
                  {t("invoice.nickName")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  disabled={
                    client?.newSupplier &&
                    client?.albanianBusinesses?.name === null
                      ? true
                      : !editable
                  }
                  required
                  type="text"
                  name={`nickname` + client.id}
                  id={`nickname` + client.id}
                  className="form-control"
                  value={client.nickname ?? ""}
                  onChange={(e) =>
                    handleClientChange(client.id, "nickname", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={client?.newSupplier ? "col-md-2" : "col-md-3"}>
              <div className="form-group">
                <label htmlFor={`phoneNo` + client.id}>
                  {t("users.phone")}
                </label>
                <div className="input-length-validation">
                  <input
                    disabled={
                      client?.newSupplier &&
                      client?.albanianBusinesses?.name === null
                        ? true
                        : !editable
                    }
                    type="number"
                    name={`phoneNo` + client.id}
                    id={`phoneNo` + client.id}
                    className="form-control"
                    value={client.phoneNo ?? ""}
                    onChange={(e) =>
                      handleClientChange(
                        client.id,
                        "phoneNo",
                        e.target.value,
                        15
                      )
                    }
                  />
                  <div
                    className={`length-text ${
                      client?.phoneNo?.length == 50 ? "text-danger" : ""
                    }`}
                  >
                    {client?.phoneNo?.length}/15
                  </div>
                </div>
              </div>
            </div>
            <div className={client?.newSupplier ? "col-md-2" : "col-md-3"}>
              <div className="form-group">
                <label htmlFor={`email` + client.id}>{t("email.email")}</label>
                <div className="input-length-validation">
                  <input
                    disabled={
                      client?.newSupplier &&
                      client?.albanianBusinesses?.name === null
                        ? true
                        : !editable
                    }
                    type="email"
                    name={`email` + client.id}
                    id={`email` + client.id}
                    className="form-control"
                    value={client.email ?? ""}
                    onChange={(e) =>
                      handleClientChange(client.id, "email", e.target.value, 50)
                    }
                  />
                  <div
                    className={`length-text ${
                      client?.email?.length == 50 ? "text-danger" : ""
                    }`}
                  >
                    {client?.email?.length}/50
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3"
            // style={open?.[client?.id] ? { marginBottom: 90 }: {}}
            >
              <div className={"form-group"
              //  + open?.[client?.id] ? " select-h-s" : ''
            }>
                <label htmlFor={`purchaseUnit` + client.id}>
                  {t("purchases.purchaseUnit")}
                </label>
                <div className="input-length-validation">
                  <Select
                    onMenuOpen={() => setOpen({...open, [client.id]: true})}
                    onMenuClose={() => setOpen({...open, [client.id]: false})}
                    menuPlacement="auto"
                    menuPortalTarget={document.querySelector("body")}
                    isDisabled={
                      client?.newSupplier &&
                      client?.albanianBusinesses?.name === null
                        ? true
                        : !editable
                    }
                    styles={selectStyle}
                    options={tcrList}
                    value={
                      tcrList.find(
                        (purchaseUnit) =>
                          purchaseUnit.value === client?.purchaseUnit
                      ) ?? ""
                    }
                    isSearchable={true}
                    onChange={(purchaseUnit) =>
                      handleClientChange(
                        client.id,
                        "purchaseUnit",
                        purchaseUnit.value
                      )
                    }
                    // isDisabled={![8, 13].includes(user?.purchaseRole?.id)}
                    placeholder={t("invoice.select")}
                    name="purchaseUnit"
                  />
                  {/* <div
                    className={`length-text ${
                      client?.email?.length == 50 ? "text-danger" : ""
                    }`}
                  >
                    {client?.email?.length}/50
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {client?.newSupplier || edit[client.id] ? (
            <button
              disabled={
                client?.newSupplier &&
                client?.albanianBusinesses?.name?.length === 0
              }
              type="submit"
              className="btn btn-primary"
              // onClick={() => saveClient(data)}
            >
              {t("common.save")}
            </button>
          ) : (
            <></>
          )}
          {client?.newSupplier || edit[client.id] ? (
            <button
              type="cancel"
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={() =>
                client?.newSupplier ? cancelAdd() : cancelEdit(client?.id)
              }
            >
              {t("common.cancel")}
            </button>
          ) : (
            <></>
          )}
        </form>
      </div>
    );
  };

  const handleRowsPerPage = (pageSize, page) => {
    getFilteredResults(pageSize, page);
  };

  return editClients ? (
    <>
      <div className="d-flex justify-content-between align-items-center my-2">
        <h3 className="d-flex align-items-center p-0 m-0">
          {t("navbar.mySuppliers")}
          {/* <small className="ml-3">
          <ExportCsv
            data={editClients}
            columns={columns}
            onClick={()=>{}}
          />
          </small> */}
        </h3>
        <div className="page-actions">
          <button
            disabled={editClients[0]?.newSupplier}
            className="btn btn-sm btn-primary"
            onClick={() => addSupplier()}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            {t("mySuppliers.addSupplier")}
          </button>
        </div>
      </div>
      <div className="card border-0 shadow">
        <div className="card-body">
          <Table
            columns={columns}
            data={[...editClients]}
            renderItem={(data) => renderItem(data)}
            searchableKeys={[
              "tin",
              "name",
              "address",
              "town",
              "email",
              "nickname",
            ]}
            enablePagination={{
              paginationServer: true,
              totalRows: tableRows?.totalRows,
              onChangeRowsPerPage: (e) => {
                setTableRows({
                  ...tableRows,
                  rowsPerPage: e,
                });
                handleRowsPerPage(e, tableRows?.currentPage);
              },
              onChangePage: (number, page) => {
                setTableRows({
                  ...tableRows,
                  currentPage: number,
                });
                handleRowsPerPage(tableRows?.rowsPerPage, number);
              },
            }}
          />
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default MySuppliers;
