import React from 'react'
import Loader from './Loader'

const RequestLoader = () => {

    return (
        <div className="request-loader">
            <Loader/>
        </div>
    )
}

export default RequestLoader