import React from "react";

import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import mainAxios from "../../../services/mainAxios";
import Loader from "../../Layout/Loader";

const ImportItems = ({ user, selectedCompany, swal ,reloadPage}) => {
  const { t, i18n } = useTranslation("translations");
  const [state, setState] = React.useState({
    file: null,
  });

  const [loading, setLoading] = React.useState(false);

  const submitInvoice = (e) => {
    e.preventDefault();
    setLoading(true);

    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "UploadCategoryItems",
        content: {
          file: state.file,
        },
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            containerId: "all_toast",
          });
          reloadPage();
          swal.close();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const extension =
      file && file.name.split(".")[file.name.split(".").length - 1];
    if (extension && extension === "csv") {
      const reader = new FileReader();

      reader.onload = (readerEvent) => {
        let binaryString = readerEvent.target.result;
        setState((state) => ({
          ...state,
          file: btoa(binaryString),
          name: file.name,
          extension,
        }));
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <form
      className="text-left"
      onSubmit={submitInvoice}
      id="upload_invoice_modal"
    >
      <div>
        <div>
          <div className="form-group">
            <div className="alert alert-warning py-1" role="alert">

              {t("common.formatdocument")}
            </div>
            <label htmlFor="itemsFile">{t("invoice.file")}</label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="file"
                name="file"
                placeholder="Upload File"
                accept=".csv"
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={handleFile}
              />
              <label className="custom-file-label" htmlFor="itemsFile">
                {state.name ? state.name : t(["invoice.choose"])}
              </label>
            </div>
          </div>
          <button
            className="btn btn-primary mt-3"
            // disabled={loading || !state.file}
            style={{ minWidth: "80px" }}
            type="submit"
          >
            {loading ? (
              <Loader size="xs" color="white" />
            ) : (
              <>
                <FontAwesomeIcon icon={faUpload} className="mr-2" />
                {t("common.import")}
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ImportItems;
