import { HexColorPicker, HexColorInput } from "react-colorful";
import useClickOutside from "../../utils/useClickOutside";
import React,{useState,useEffect,useRef} from 'react';
import './global.scss';

const PopoverPicker = ({ color, onChange, saveTemplate,disabled }) => {
    const popover = React.useRef();
    const [isColorPickerOpen, toggleColorPicker] = React.useState(false);
    const close = React.useCallback(() => { toggleColorPicker(false); saveTemplate() }, [saveTemplate],);
    const [value, setValue] = useState(color);
    useClickOutside(popover, close);

    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }
        const indicator = setTimeout(() => {
            onChange(value);
        }, 400);
    
        return () => {
          clearTimeout(indicator);
        };
      }, [value]);

      const pickerStyle = {
        filter: disabled ? 'grayscale(100%)' : 'none',
      };

      return (
        <div className="picker">
          <div
            className="swatch"
            style={{ backgroundColor: color, ...pickerStyle }}
            onClick={() => { if (!disabled) toggleColorPicker(true) }}
          />
    
          {isColorPickerOpen && (
            <div className="popover" style={{ position: "relative", ...pickerStyle }} ref={popover}>
              <HexColorPicker color={color} onChange={setValue} />
              <HexColorInput color={color} onChange={setValue} />
            </div>
          )}
        </div>
      );
};

export default PopoverPicker;