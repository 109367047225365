import { Preloader, Bars } from "react-preloader-icon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DashboardCard } from "../global/CommonComponents";

const PurchasesDashboard = ({ title,purchaseStatsTotals,loading }) => {
  const { t } = useTranslation("translations");
  const cardData = [
    {
      id:1,
      count: purchaseStatsTotals?.cisDeliveredStatusInvoices,
      label: "Delivered",
      color:"#f7c649",
      borderLeft:"0.30rem solid #f7c649",
    },
    {
      id:2,
      count: purchaseStatsTotals?.cisPartiallyPaidStatusInvoices,
      label: "Partially Paid",
      color:"#4e73df",
      borderLeft:"0.30rem solid #4e73df",
    },
    {
      id:3,
      count: purchaseStatsTotals?.cisPaidStatusInvoices,
      label: "Paid",
      color:"#36c9c6",
      borderLeft:"0.30rem solid #36c9c6",

    },
    {
      id:4,
      count: purchaseStatsTotals?.cisAcceptedStatusInvoices,
      label: "Accepted",
      color:"#007567",
      borderLeft:"0.30rem solid #007567"
    },
    {
      id:5,
      count: purchaseStatsTotals?.cisRejectedStatusInvoices,
      label: "Rejected",
      color:"#e74a3b",
      borderLeft:"0.30rem solid #e74a3b"
    },
  ];

  const card = ({label, count, borderLeft, color,id}) => {
    return (
      <div key={id} style={{borderColor:"#eeeeee", borderLeft}} className="card mb-3">
        <div className="row p-3 no-gutters justify-content-between align-items-center">
          <div style={{ color, fontSize: "19px" }} className="font-weight-bold mb-1">
            {label}
          </div>
          <div className="text-right h4 mb-0 font-weight-bold text-gray-800"  style={{fontSize: "20px" }}>
            {count ?? 0}
          </div>
        </div>
      </div>
    );
  };

  return (
    <DashboardCard title={title}>
      <div className="w-100">
      {loading ? (
          <div className="d-flex justify-content-center h-100 align-items-center ">
            <Preloader
              use={Bars}
              size={90}
              strokeWidth={6}
              duration={1000}
              strokeColor="rgba(209, 231, 221, 1)"
            />
          </div>
        ) : cardData?.length > 0 ? (cardData.map((data) => {
          return card(data);
        })
        ) : (
          <div className="list-group h-100 list-widget">
            <h2 className="d-flex justify-content-center h-100 align-items-center text-muted">
              {t("dashboard.noData")}
            </h2>
          </div>
        )}
      </div>
      </DashboardCard>

  );
};
export default PurchasesDashboard;
