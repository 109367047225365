import 'react-app-polyfill/ie11';
import React from 'react';

import ReactDOM from 'react-dom';
import App from './App';
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
// import "node_modules/primeflex/primeflex.css";


import './design/main.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import TRANSLATIONS_ALB from "./translations/alb/translations.json";
import TRANSLATIONS_EN from "./translations/en/translations.json";
import reactDebugHooks from 'react-debug-hooks'

reactDebugHooks(React)

i18next
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({ 
     interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',  
   resources: {
     en: {
       translations: TRANSLATIONS_EN
     },
     alb: {
       translations: TRANSLATIONS_ALB
     }
   }
 });
 
 i18next.changeLanguage("en");

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
