import DatePicker from "react-datepicker";
import React from "react";
import { useTranslation } from "react-i18next";
import selectStyle from "../../design/selectStyle";
import Select from "react-select";
import func from "../../services/functions";
import InternalPoCombo from "./columns/internalPOCombo";
import InternalAgreementCombo from "./columns/internalAgreementCombo";
import { useAppContext } from "../../AppContext";


const InternalInfo = ({ invoice, handleChange }) => {
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );


  const { t } = useTranslation("translations");

  const priorityOptions = [
    {label : t("invoice.selectOptions"),value:""},
    { label: t("invoice.low"), value: "Low" },
    { label: t("invoice.medium"), value: "Medium" },
    { label: t("invoice.high"), value: "High" },
  ];

  const tcrList = React.useMemo(() => 
  {

    let tcrList=[]
    company.branches
    .reduce((acc,branch)=> branch?.tcrTypes?.length>0?acc.concat(branch.tcrTypes):acc,[])
    .forEach(tcr=>{
      if(tcr?.allowPurchase){
      let sellerName=company?.branches?.find((branch)=>branch?.businUnitCode==tcr?.businUnitCode)?.sellerName ?? ""
        tcrList.push({label:tcr.tcrCode +" - "+ sellerName,value:tcr.tcrCode})
      }
    })
    return tcrList.length>0 ?[{label:"Select",value:null}].concat(tcrList):tcrList
  },[selectedCompany])


  return (
    <div className="card border-0 shadow mb-4">
      <div className="card-header border-0">
        <h3 className="card-title mb-0">{t("invoice.internalInfo")}</h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("invoice.receivedDate")}</label>
              <DatePicker
                selected={invoice?.recordDate}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                name="receivedDate"
                disabled
                showDisabledMonthNavigation
                onChange={(date) => handleChange(null, "recordDate", date)}
                customInput={<input className="form-control" />}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group input-length-validation">
              <label htmlFor="supplierCode">{t("invoice.supplierCode")}</label>
              <input
                type="text"
                className="form-control"
                id="supplierCode"
                name="supplierCode"
                value={invoice?.purchaseInvoiceExtracted?.supplierCode ?? ""}
                onChange={(e) => handleChange("", "purchaseInvoiceExtracted.supplierCode", e.target.value, 15)}
              />
              <div 
              className={`length-text ${invoice?.supplierCode?.length == 15 ? "text-danger" : ''}`}>
                {invoice?.purchaseInvoiceExtracted?.supplierCode?.length ?? 0}/15</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="currency">{t("invoice.currency")}</label>
              <input
                type="text"
                className="form-control"
                name="currency"
                id="currency"
                value={invoice?.purchaseInvoiceExtracted?.currency ?? ""}
                disabled

              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="exRate">{t("invoice.exchangeRate")}</label>
              <input
                type="text"
                className="form-control"
                id="exRate"
                name="exRate"
                value={invoice?.purchaseInvoiceExtracted?.exRate ?? ""}
                onChange={handleChange}
                disabled
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group input-length-validation">
              <label htmlFor="supplierInvoiceInfo">{t("purchases.supplierInvoiceInfo")}</label>
              <textarea
                type="text"
                className="form-control"
                id="supplierInvoiceInfo"
                name="supplierInvoiceInfo"
                disabled
                rows="1"
                value={invoice?.purchaseInvoiceExtracted?.supplierInvoiceInfo ?? ""}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="priority.name">
                {t("invoice.priorityCombo")}
              </label>
              <Select
                styles={selectStyle}
                options={priorityOptions}
                placeholder={t("invoice.select")}
                value={priorityOptions.find(
                  (priority) =>
                  priority.value === invoice?.purchaseInvoiceExtracted?.priority?.name)}
                isSearchable={true}
                onChange={(priority) =>
                  handleChange(null, "purchaseInvoiceExtracted.priority.name", priority.value)
                }
                name="priority.name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="valueWoVat">
                {t("item.totalWithoutVat")}
              </label>
              <input
                type="text"
                id="valueWoVat"
                name="valueWoVat"
                className="form-control"
                disabled
                value={func.formatNumber(invoice?.purchaseInvoiceExtracted?.valueWoVat)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="vatValue">Vat Value</label>
              <input
                type="text"
                id="vatValue"
                name="vatValue"
                className="form-control"
                disabled
                value={func.formatNumber(invoice?.purchaseInvoiceExtracted?.vatValue)}
              /> 
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <div className="form-group">
                <label htmlFor="valueWVat">
                  {t("item.totalWithVat")}
                </label>
                <input
                  type="text"
                  id="valueWVat"
                  name="valueWVat"
                  className="form-control"
                  disabled
                  value={func.formatNumber(invoice?.purchaseInvoiceExtracted?.valueWVat)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="purchaseUnit">{t("purchases.purchaseUnit")}</label>
              <Select
                styles={selectStyle}
                options={tcrList}
                value={tcrList.find(
                  (purchaseUnit) =>
                    purchaseUnit.value === invoice?.purchaseInvoiceExtracted?.purchaseUnit
                )}
                isSearchable={true}
                onChange={(purchaseUnit) =>
                  handleChange("", "purchaseInvoiceExtracted.purchaseUnit", purchaseUnit.value)
                }
                isDisabled={![8,13].includes(user?.purchaseRole?.id)}
                placeholder={t("invoice.select")}
                name="purchaseUnit"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("purchases.paymentRequestedDate")}</label>
              <DatePicker
                selected={invoice?.dueDateTime}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                name="dueDateTime"
                disabled
                showDisabledMonthNavigation
                customInput={<input className="form-control" />}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">{t("purchases.expectedAcceptanceDateInternal")}</label>
              <DatePicker
                selected={invoice?.purchaseInvoiceExtracted?.expectedAcceptanceDate}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                name="purchaseInvoiceExtracted.expectedAcceptanceDate"
                minDate={ invoice?.dueDateTime ?? ""}
                showDisabledMonthNavigation
                onChange={(expectedAcceptanceDate) =>
                  handleChange(
                    null,
                    "purchaseInvoiceExtracted.expectedAcceptanceDate",
                    expectedAcceptanceDate
                  )
                }
                customInput={<input className="form-control" />}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">

              <InternalPoCombo
                invoice={invoice}
                handleChange={handleChange}
              />

            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">

              <InternalAgreementCombo
                invoice={invoice}
                handleChange={handleChange}
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalInfo;
