import React, { useEffect, useState } from "react";
import AdditionalStatisticBox from "../Statistics/AdditionalStatisticBox";
import mainAxios from "../../services/mainAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Layout/Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext";

const AdditionalSaleStatistics = () => {
  const [stats, setStats] = useState({});
  const [actionLoading, setActionLoading] = useState({
    action: "",
    loading: false,
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("translations");

  const {user:[user]}=useAppContext()

  useEffect(() => {
    setLoading(true);
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetAdditionalSaleStatistics",
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          setStats(res.data.content[0]);
        }
      });
  }, []);

  const agentAction = (action) => {
    setActionLoading({ action, loading: true });
    mainAxios
      .post("ukt/agent/" + action, {})
      .then((res) => {
        setActionLoading({ action, loading: false });
        if (res.data.status) {
          const message = res.data.message || t("common.actionSuccessfully");
          toast.success(message, {
            containerId: "all_toast",
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        setActionLoading({ action, loading: false });
      });
  };

  const queueNumbersRequest=()=>{
    setActionLoading({ action: "queueInvoices", loading: true });
    mainAxios.post("/ukt/agent/queue/invoices",{})
    .then(res=>{
      setActionLoading({ action:"queueInvoices", loading: false });
      if(res.data.status){
        const content = res.data.message
        toast.info(content, {
          containerId: "all_toast"
        })
      }
    })
    .catch(err=>{
      setActionLoading({ action: "queueInvoices", loading: false });
    })
  }

  const getErrorInvoices = () => {
    setActionLoading({ action: "errorInvoices", loading: true });
    mainAxios.post("/ukt/agent/error/invoices", {})
      .then(res => {
        setActionLoading({ action: "errorInvoices", loading: false });
        const content = res?.data?.content
        if (res.data.status && content.length>0) {
          const headerRow = Object.keys(content[0]) 
          

          const data = content.

          toast.info(content, {
            containerId: "all_toast"
          })
        }
      })
      .catch(err => {
        setActionLoading({ action: "errorInvoices", loading: false });
      })
  }

  if (loading) return <Loader />;

  const buttonLoading = ({content, action}) => {
    if (actionLoading.action === action && actionLoading.loading) {
      return <Loader size="xs" />;
    }
    return content;
  };

  return (
    <div
      className="h-full d-flex flex-column justify-content-around"
      style={{ padding: "0px 60px" }}
    >
      <div className="d-flex flex-column justify-items-center align-items-center">
        <div className="w-full">
          <div className="row">
            <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
              <AdditionalStatisticBox
                header={"# " + (stats?.lastDayTotInvoicesNo ?? 0)}
                description={t("additionalSaleStats.lastDayTotInvoicesNo")}
                icon={<FontAwesomeIcon icon={faReceipt} />}
              />
            </div>

            <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
              <AdditionalStatisticBox
                header={"# " + (stats?.lastDaySuccessInvoicesNo ?? 0)}
                description={t("additionalSaleStats.lastDaySuccessInvoicesNo")}
                icon={<FontAwesomeIcon icon={faReceipt} />}
              />
            </div>

            <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
              <AdditionalStatisticBox
                header={"# " + (stats?.lastDayErrorInvoicesNo ?? 0)}
                description={t("additionalSaleStats.lastDayErrorInvoicesNo")}
                icon={<FontAwesomeIcon icon={faReceipt} />}
              />
            </div>
            <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
              <AdditionalStatisticBox
                header={"# " + (stats?.lastMinuteTotInvoicesNo ?? 0)}
                description={t("additionalSaleStats.lastMinuteTotInvoicesNo")}
                icon={<FontAwesomeIcon icon={faReceipt} />}
              />
            </div>

            <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
              <AdditionalStatisticBox
                header={"# " + (stats?.lastMinuteSuccessInvoicesNo ?? 0)}
                description={t(
                  "additionalSaleStats.lastMinuteSuccessInvoicesNo"
                )}
                icon={<FontAwesomeIcon icon={faReceipt} />}
              />
            </div>

            <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
              <AdditionalStatisticBox
                header={"# " + (stats?.lastMinuteErrorInvoicesNo ?? 0)}
                description={t("additionalSaleStats.lastMinuteErrorInvoicesNo")}
                icon={<FontAwesomeIcon icon={faReceipt} />}
              />
            </div>
          </div>
        </div>
      </div>
      {user?.role?.id===1 && <div >
        <h3>{t("additionalSaleStats.agent")}</h3>
        <div className="d-flex flex-wrap">
          <button
            style={{ width: 150 }}
            className="btn btn-primary mb-2 mr-2"
            onClick={() => agentAction("start")}
            disabled={actionLoading.loading}
          >
            {buttonLoading({content:"Start",action: "start"})}
          </button>
          <button
            style={{ width: 150 }}
            className="btn btn-danger mb-2 mr-2"
            onClick={() => agentAction("stop")}
            disabled={actionLoading.loading}
          >
            {buttonLoading({content:"Stop",action: "stop"})}
          </button>
          <button
            style={{ width: 150 }}
            className="btn btn-warning mb-2 mr-2"
            onClick={() => agentAction("resend/invoices")}
            disabled={actionLoading.loading}
          >
            {buttonLoading({content:"Resend",action: "resend"})}
          </button>
          {/* <button
            style={{ width: 150 }}
            className="btn btn-info mb-2"
            onClick={() => queueNumbersRequest()}
            disabled={actionLoading.loading}
          >
            {buttonLoading({ content: t("additionalSaleStats.invoicesNumber"), action:"queueInvoices"})}
          </button>
          <button
            style={{ width: 150 }}
            className="btn btn-info mb-2"
            onClick={() => getErrorInvoices()}
            disabled={actionLoading.loading}
          >
            {buttonLoading({ content: t("additionalSaleStats.invoicesNumber"), action:"errorInvoices"})}
          </button> */}
        </div>
      </div>}
    </div>
  );
};

export default AdditionalSaleStatistics;
