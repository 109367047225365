// Sidebar.js
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice, faMoneyCheckAlt, faSearch, faBookOpen, faReceipt, faCashRegister, faCog, faMobileScreenButton, faGauge, faDashboard, faGlobe, faCircleInfo, faEnvelope, faPhone, faWrench, faBuilding, } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { useAppContext } from '../../AppContext';
import { Dialog } from "primereact/dialog";
import UploadInvoice from '../Invoice/UploadInvoice';
import UploadPayment from '../Invoice/UploadPayment';
import { PanelMenu } from 'primereact/panelmenu';
import { useHistory } from 'react-router-dom'
import useClickOutside from '../../utils/useClickOutside';
import UseScreenSize from '../../utils/UseSizeScreen';

const Sidebar = () => {

    const [openUploadInvoice, setOpenUploadInvoice] = useState(false);
    const [openUploadPayment, setOpenUploadPayment] = useState(false);
    const { t, i18n } = useTranslation("translations");
    const {
        user: [user],
        sideBarExpanded: [expanded, setExpanded],
        selectedCompany = { selectedCompany },
        company: [fullCompany],
    } = useAppContext();

    const sidebarRef = useRef()
    const router = useHistory();
    const { width } = UseScreenSize()
    
    const iconComponent = (icon) => {
        return (
            <div className='d-flex justify-content-center' style={{ minWidth: 17 }}>
                <FontAwesomeIcon className='fontIcon' icon={icon} />
            </div>
        )
    }

    const saveLanguage = (val) => {
        localStorage.setItem("language", val);
    };

    const handleClickOutside = (event) => {
        setExpanded({ hover: false })
    };

    const onPathSetExpandFalseForMobile = () => {
        if (width <= 1280) return setExpanded(false)
    }

    useClickOutside(sidebarRef, handleClickOutside);

    const data = [
        {
            visible: [1, 2, 3, 4, 5, 7].includes(user.role.id),
            label: t("navbar.dashboard"),
            command: () => {
                router.push('/');
                onPathSetExpandFalseForMobile()
            },
            id: 1,
            icon: () => iconComponent(faDashboard),
        },
        {
            visible: [1, 2, 3, 4, 5, 7].includes(user.role.id),
            label: t("navbar.fiscalizedInv"),
            command: () => {
                router.push('/sale-invoices');
                onPathSetExpandFalseForMobile()
            },
            id: 1,
            icon: () => iconComponent(faFileInvoice),
        },
        {
            label: t("navbar.purchases"),
            command: () => {
                router.push('/purchases-new');
                onPathSetExpandFalseForMobile()
            },
            visible: [1, 2, 4, 5, 6, 7, 8].includes(user.role.id) && user?.purchaseRole?.id !== 12,
            id: 2,
            icon: () => iconComponent(faMoneyCheckAlt),
        },
        {
            visible: [1, 2, 4, 5, 6, 7].includes(user.role.id),
            label: t("navbar.search"),
            id: 3,
            icon: () => iconComponent(faSearch),
            items: [
                {
                    icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.taxPayers"),
                    command: () => {
                        router.push('/search-tax-payers');
                        onPathSetExpandFalseForMobile()
                    },
                    id: 1002
                },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.invoices"), command: () => { router.push('/search-invoices', onPathSetExpandFalseForMobile()) }, id: 1003 },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.expCertificate"), command: () => { router.push('/search-certificates'); onPathSetExpandFalseForMobile() }, id: 1004, visible: [1].includes(user.role.id), className: "truncate-text" }
            ]
        },
        {
            visible: ([1, 2, 4, 5, 6, 7].includes(user.role.id) || [8,10,11].includes(user?.purchaseRole?.id)),
            label: t("navbar.myBooks"),
            id: 4,
            icon: () => iconComponent(faBookOpen),
            items: [
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.salesBook"), command: () => { router.push('/books/sales'); onPathSetExpandFalseForMobile() }, id: 1005, visible: [1, 2, 3, 4, 5, 6, 7, 8].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.purchasesBook"), command: () => { router.push('/books/purchases'); onPathSetExpandFalseForMobile() }, id: 1006, visible: ([1, 2, 3, 4, 5, 6, 8].includes(user?.role?.id) || [8,10,11].includes(user?.purchaseRole?.id) )},
            ]
        },
        {
            visible: [1, 2, 4, 5, 7, 15,16].includes(user.role.id),
            label: t("navbar.myInvoices"),
            id: 5,
            icon: () => iconComponent(faReceipt),
            items: [
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.myInvoices"), command: () => { router.push('/my-invoices'); onPathSetExpandFalseForMobile() }, id: 1007, visible: [1, 2, 4, 5, 7, 15].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.createInvoice"), command: () => { router.push('/create-invoice'); onPathSetExpandFalseForMobile() }, id: 1008, visible: [1, 2, 4, 15].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.uploadInvoice"), command: () => { setOpenUploadInvoice(true); onPathSetExpandFalseForMobile() }, id: 1009, visible: [1, 2, 3, 4, 5].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.MyWTNs"), command: () => { router.push('/view-wtn'); onPathSetExpandFalseForMobile() }, id: 1010, visible: [1, 2, 4, 5, 7,16].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.createWTN"), command: () => { router.push('/create-wtn'); onPathSetExpandFalseForMobile() }, id: 1011, visible: [1, 2, 4, 5,16].includes(user.role.id) },
            ]
        },
        {
            visible: [1, 2, 4, 5, 15].includes(user.role.id),
            label: "My POS",
            id: 6,
            icon: () => iconComponent(faCashRegister),
            items: [
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: "My Pos", command: () => { router.push('/pos-transactions/create'); onPathSetExpandFalseForMobile() }, id: 1012, visible: [1, 2, 4, 5, 7, 15].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("invoice.transactions"), command: () => { router.push('/pos-transactions'); onPathSetExpandFalseForMobile() }, id: 1013, visible: [1, 2, 4, 5, 15].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.cashRegister"), command: () => { router.push('/cashregister'); onPathSetExpandFalseForMobile() }, id: 1014, visible: [1, 2, 4, 5, 15].includes(user.role.id) },
            ]
        },
        {
            visible: width >= 1280 ? false : true,
            label: t("navbar.support"),
            icon: () => iconComponent(faCircleInfo),
            id: 7,
            items: [
                {
                    label: 'profisc@tetrapro.al',
                    icon: () => iconComponent(faEnvelope),
                    url: "mailto:profisc@tetrapro.al",
                    id: 21312,
                },
                {
                    label: '+355 44 508 855',
                    icon: () => iconComponent(faPhone),
                    url: "tel:+35544508855",
                    id: 21312,
                },
                {
                    label: '+355 067 700 1100',
                    icon: () => iconComponent(faPhone),
                    url: "tel:+355677001100",
                    id: 21312,
                },
                {
                    label: t("navbar.technicalAssistance"),
                    icon: () => iconComponent(faWrench),
                    url: "https://tetra-solutions.atlassian.net/servicedesk/customer/portal/4",
                    id: 21312,
                },
                {
                    label: 'ProFisc Help',
                    icon: () => iconComponent(faWrench),
                    url: 'https://help.profisc.al',
                    id: 21312,
                }
            ]
        },
        {
            visible: width >= 1280 ? false : true,
            label: t("navbar.language"),
            id: 7,
            icon: () => iconComponent(faGlobe),
            items: [
                {
                    label: 'Shqip',
                    icon: <img src="/albania-icon-round.png" className="mr-2" style={{ width: "20px" }} />,
                    command: () => {
                        i18n.changeLanguage("alb");
                        saveLanguage("alb");
                        onPathSetExpandFalseForMobile()
                    }
                },
                {
                    label: 'English',
                    icon: <img src="/england-icon-round.png" className="mr-2" style={{ width: "20px" }} alt="English" />,
                    command: () => {
                        i18n.changeLanguage("en");
                        saveLanguage("en");
                        onPathSetExpandFalseForMobile()
                    }
                },
            ]
        },
        {
            visible: [1, 2, 3, 4, 5, 6].includes(user.role.id),
            label: t("navbar.settings"),
            id: 8,
            icon: () => iconComponent(faCog),
            items: [
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.companies"), command: () => { router.push('/companies'); onPathSetExpandFalseForMobile() }, id: 1023, visible: [1, 2, 3].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.users"), command: () => { router.push('/users'); onPathSetExpandFalseForMobile() }, id: 1024, visible: [1, 2, 3].includes(user.role.id) },
                // { label: t("navbar.parameters"), command: () => router.push('/parameters'), id: 1025, visible:[1, 2, 3].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.customers"), command: () => { router.push('/customers'); onPathSetExpandFalseForMobile() }, id: 1026, visible: [1, 2, 3].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.mySuppliers"), command: () => { router.push('/my-suppliers'); onPathSetExpandFalseForMobile() }, id: 1027, visible: [1, 2, 3].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.sendNotification"), command: () => { router.push('/send-notification'); onPathSetExpandFalseForMobile() }, id: 1028, visible: [1, 2, 3].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.uploadPayment"), command: () => { setOpenUploadPayment(true); onPathSetExpandFalseForMobile() }, id: 1029, visible: [1, 2, 3, 4, 5].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.generateNslf"), command: () => { router.push('/generate-nslf'); onPathSetExpandFalseForMobile() }, id: 1030, visible: [1, 2, 3, 4, 5, 6, 7].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.generateWtnIc"), command: () => { router.push('/generate-wtnic'); onPathSetExpandFalseForMobile() }, id: 1035, visible: [1, 2, 3, 4, 5, 6, 7].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.logs"), command: () => { router.push('/logs'); onPathSetExpandFalseForMobile() }, id: 1031, visible: [1, 2, 3, 4, 5, 6, 7].includes(user.role.id) },
                // { label: t("logs.uktReports"), command: () => router.push('/ukt-report'), id: 1032, visible:[1].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.products"), command: () => { router.push('/items'); onPathSetExpandFalseForMobile() }, id: 1033, visible: [1, 2, 3, 4, 5, 6, 7].includes(user.role.id) },
                { icon: <FontAwesomeIcon icon={faCircle} className='circle-icon' />, label: t("navbar.itemsCategory"), command: () => { router.push('/items-category'); onPathSetExpandFalseForMobile() }, id: 1034, visible: [1, 2, 3, 4, 5, 6, 7].includes(user.role.id) },
            ]
        },
        {
            label: t("navbar.downloadApp"),
            id: 9,
            order: width >= 1280 ? "bottom" : 'top',
            icon: <FontAwesomeIcon className='fontIcon' icon={faMobileScreenButton} />,
            items: [
                {
                    label: "Google Play",
                    // url:"https://play.google.com/store/apps/details?id=com.tetrapro.profiscpro",
                    id: 1035,
                    icon: <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="24" viewBox="0 0 48 48">
                        <path fill="#4db6ac" d="M7.705,4.043C7.292,4.15,7,4.507,7,5.121c0,1.802,0,18.795,0,18.795S7,42.28,7,43.091c0,0.446,0.197,0.745,0.5,0.856l20.181-20.064L7.705,4.043z" />
                        <path fill="#dce775" d="M33.237,18.36l-8.307-4.796c0,0-15.245-8.803-16.141-9.32C8.401,4.02,8.019,3.961,7.705,4.043l19.977,19.84L33.237,18.36z" />
                        <path fill="#d32f2f" d="M8.417,43.802c0.532-0.308,15.284-8.825,24.865-14.357l-5.601-5.562L7.5,43.947C7.748,44.038,8.066,44.004,8.417,43.802z" />
                        <path fill="#fbc02d" d="M41.398,23.071c-0.796-0.429-8.1-4.676-8.1-4.676l-0.061-0.035l-5.556,5.523l5.601,5.562c4.432-2.559,7.761-4.48,8.059-4.653C42.285,24.248,42.194,23.5,41.398,23.071z" />
                    </svg>,
                    command: () => { window.open(`https://play.google.com/store/apps/details?id=com.tetrapro.profiscpro`, '_blank'); onPathSetExpandFalseForMobile() }
                },
                {
                    label: "App Store",
                    // url:"https://apps.apple.com/al/app/profisc/id6443799212",
                    icon: <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28" height="24" viewBox="0 0 256 256" style={{ fill: "#000000" }}>
                        <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}>
                            <g transform="scale(5.33333,5.33333)">
                                <path d="M40.084,32.613c-0.848,1.835 -1.254,2.655 -2.342,4.274c-1.521,2.264 -3.67,5.089 -6.326,5.109c-2.361,0.018 -2.971,-1.507 -6.176,-1.482c-3.204,0.016 -3.872,1.51 -6.237,1.484c-2.654,-0.022 -4.688,-2.568 -6.21,-4.826c-4.259,-6.34 -4.707,-13.768 -2.076,-17.721c1.861,-2.803 4.807,-4.449 7.572,-4.449c2.817,0 4.588,1.514 6.916,1.514c2.262,0 3.638,-1.517 6.896,-1.517c2.464,0 5.07,1.313 6.931,3.575c-6.09,3.262 -5.101,11.763 1.052,14.039z" fill="#383838" />
                                <path d="M30.046,12.072c1.269,-1.577 2.232,-3.804 1.882,-6.072c-2.069,0.138 -4.491,1.418 -5.905,3.075c-1.282,1.51 -2.345,3.752 -1.931,5.922c2.259,0.069 4.597,-1.233 5.954,-2.925z" fill="#383838" />
                                <path d="M36.736,20.421c-8.736,9.58 -16.736,0.58 -27.508,7.421c0.375,3.027 1.53,6.303 3.565,9.331c1.521,2.258 3.556,4.804 6.21,4.826c2.365,0.025 3.033,-1.469 6.237,-1.484c3.205,-0.024 3.814,1.5 6.176,1.482c2.656,-0.021 4.805,-2.846 6.326,-5.109c1.088,-1.619 1.494,-2.439 2.342,-4.274c-5.206,-1.926 -6.695,-8.3 -3.348,-12.193z" fill="#4d545b" />
                            </g>
                        </g>
                    </svg>,
                    id: 1036,
                    command: () => { window.open(`https://apps.apple.com/al/app/profisc/id6443799212`, '_blank'); onPathSetExpandFalseForMobile() }

                },
            ]
        },
    ];

    const itemsTop = data?.filter(item => item.order != "bottom")
    const itemsbottom = data?.filter(item => item.order == "bottom")

    return (
        <>
            <Dialog
                header={t("invoice.upload")}
                visible={openUploadInvoice}
                onHide={() => setOpenUploadInvoice(false)}
                style={{ width: "26vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <UploadInvoice
                    user={user}
                    selectedCompany={selectedCompany}
                />
            </Dialog>

            <Dialog
                header={t("navbar.uploadPayment")}
                visible={openUploadPayment}
                onHide={() => setOpenUploadPayment(false)}
                style={{ width: "26vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <UploadPayment
                    user={user}
                    selectedCompany={selectedCompany}
                    fullCompany={fullCompany}
                />
            </Dialog>
            <div className="custom-sidebar" >
                <div
                    ref={width <= 768 && expanded?.show ? sidebarRef : null}
                    onMouseEnter={() => !expanded.show && setExpanded({ hover: true, show: false })}
                    onMouseLeave={() => !expanded.show && setExpanded({ hover: false, show: false })}
                    className={`${expanded?.hover || expanded.show ? `${width <= 768 ? 'sidebar-expanded-mobile' : "sidebar-expanded"}` : `sidebar-collapsed ${width <= 768 && 'sidebar-collapsed-mobile'}`} border-right border-top bg-white`}
                    style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                >
                    <div style={{ flex: 1 }}>
                        <PanelMenu model={itemsTop} className="mt-2" />
                    </div>
                    <div style={{ flex: 0 }}>
                        <PanelMenu model={itemsbottom} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;