import { getStore } from "../AppContext";
import { addDays } from "date-fns";
import { toast } from "react-toastify";
import countries from "./countries.json";
import bigDecimal from "js-big-decimal";
import Swal from "sweetalert2";
import mainAxios from "./mainAxios";
import { buyerIDTypes } from "../components/CustomInvoices/WizardSteps/data";

const moment = require("moment");
const pluralize = require("pluralize");

export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

const getFormattedMoneyValue = (value,decimals=2) => {
  if (value === null) return "0,00";
  let fixedValue = parseFloat(value).toFixed(decimals);
  let formattedValue = fixedValue.split(".")[0];
  let contains = false;
  if (formattedValue.includes("-")) {
    formattedValue = formattedValue.split("-")[1];
    contains = true;
  }

  formattedValue = formattedValue.split("").reverse();

  formattedValue = formattedValue
    .map((char, index) =>
      index % 3 === 2 && index !== 0 && index !== formattedValue.length - 1
        ? "." + char
        : char
    )
    .reverse()
    .join("");
  formattedValue += fixedValue.split(".")[1]
    ? "," + fixedValue.split(".")[1]
    : ",00";
  return contains ? "-" + formattedValue : formattedValue;
};

const formatNumber = (number,decimals=2) => {
  return number || number === 0
    ? new Intl.NumberFormat("en-US").format(parseFloat(number).toFixed(decimals))
    : "";
};

const formattedPrice=(num,decimals=10)=>{
  return (num)?.toLocaleString(
    undefined,
    { maximumFractionDigits: decimals }
  ) ?? ""
}


const numberTobeToFixed3 = (number) => {
  return number || number === 0
    ? new Intl.NumberFormat("en-US").format(parseFloat(number).toFixed(3))
    : "";
};
const round = (num, decimalPlaces) => {
  const number = parseFloat(num);
  return !isNaN(number)
    ? Math.round((number + Number.EPSILON) * Math.pow(10, decimalPlaces)) /
        Math.pow(10, decimalPlaces) +
        ""
    : "";
};

const countDecimals = (value) => {
  if ((value % 1) != 0)
    return value.toString().split(".")[1].length;
  return 0;
};


const getDateFromToday = (days) => {
  let noOfDays = !isNaN(parseInt(days)) ? parseInt(days) : 0;
  let nowDate = new Date();
  nowDate = [
    ("0" + (nowDate.getMonth() + 1)).substr(-2),
    ("0" + nowDate.getDate()).substr(-2),
    nowDate.getFullYear(),
  ].join("/");
  nowDate = new Date(nowDate);
  nowDate.setDate(nowDate.getDate() + noOfDays);

  return nowDate;
};

const dateBeforeSomeDays = (daysBefore, date) => {
  let daysInLastMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    0
  ).getDate();
  if (date.getDate() <= daysBefore) {
    return new Date(
      new Date(new Date().setMonth(date.getMonth() - 1)).setDate(
        daysInLastMonth - (daysBefore - date.getDate())
      )
    );
  } else {
    return new Date(
      new Date(new Date().setMonth(date.getMonth())).setDate(
        date.getDate() - daysBefore
      )
    );
  }
};

const getDaysFromToday = (date) => {
  const nowDate = new Date();
  nowDate.setHours(0, 0, 0);
  const oneDay = 24 * 60 * 60 * 1000;
  let dateDifference = Math.round((nowDate - date) / oneDay);
  let output = "Today";

  if (dateDifference === 1) {
    output = "1 Day ago";
  } else if (dateDifference === -1) {
    output = "1 Day later";
  } else if (dateDifference > 0) {
    output = dateDifference + " Days ago";
  } else if (dateDifference < 0) {
    output = Math.abs(dateDifference) + " Days later";
  }
  return output;
};

const getMinIssueDate = () => {
  let newDate = new Date();
  if (newDate.getDate() < 11) {
    return new Date(
      new Date(new Date().setMonth(newDate.getMonth() - 1)).setDate(1)
    );
  } else {
    return new Date(new Date().setDate(1));
  }
};

const downloadPDF = (pdf) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = "abc.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const blobToFile = (theBlob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

const b64toURL = (base64, type, name) => {
  return URL.createObjectURL(blobToFile(b64toBlob(base64, type), name));
};

const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const getKeyFromLS = (key) => {
  let keys = key.split(".");

  let object = JSON.parse(localStorage.getItem(keys[0]));

  if (keys.length === 1) {
    return object;
  } else {
    keys.slice(1).forEach((key) => {
      object = object && object[key] ? object[key] : null;
    });
  }

  return object;
};

const getTitleByPath = (pathValue, index, arr) => {
  return pathValue === "edit"
    ? `Edit ${arr[index - 1]
        .split("-")
        .map((name) => capitalizeFirstLetter(pluralize.singular(name)))
        .join(" ")}`
    : pathValue
        .split("-")
        .map((name) => capitalizeFirstLetter(pluralize.singular(name)))
        .join(" ");
};


const array_chunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

const getInvoiceType = (
  fisc,
  eInvoice,
  ublInvoiceTypeCode,
  correctiveInvoiceType,
  einvoice,
  cisStatus
) => {
  const fiscStatus = parseInt(fisc);
  const eInvoiceStatus = eInvoice ? parseInt(eInvoice) : null;
  //mos ndryshoni renditjen cit. bruno sholla
  if(cisStatus == "T991") return 16
  else if(cisStatus == "TF991") return 15
  else if(cisStatus == "TE991") return 14
  else if(fiscStatus===100 && einvoice ===true && (eInvoiceStatus===56 || eInvoiceStatus===101 || eInvoiceStatus===8101)) return 13;
  else if (
    ublInvoiceTypeCode === "384" &&
    fiscStatus !== 100 &&
    fiscStatus !== -100
  )
    return 3;
  else if (
    ublInvoiceTypeCode === "384" &&
    eInvoiceStatus !== 100 &&
    eInvoiceStatus !== -100
  )
    return 4;
  else if ((eInvoiceStatus=== 100 ||eInvoiceStatus === -100) && einvoice === true) return 5;
  else if (
    fiscStatus !== -100 &&
    fiscStatus !== 100 &&
    (ublInvoiceTypeCode === "380" || ublInvoiceTypeCode === null)
  )
    return 7;
  else if (fiscStatus === -1 && einvoice === true) return 9;
  else if (fiscStatus === 100 && eInvoiceStatus === -1) return 10;
  else if (
    eInvoiceStatus !== -100 &&
    eInvoiceStatus !== 100 &&
    einvoice === true
  )
    return 8;
  // else if(fiscStatus!== 200)
  else if ((fiscStatus === 100 || fiscStatus===-100) && einvoice === false) return 12;
};


const getQueueStatus = (
  fisc,
  eInvoice,
  ublInvoiceTypeCode,
  correctiveInvoiceType,
  einvoice,
  cisStatus
) => {
  const fiscStatus = parseInt(fisc);
  const eInvoiceStatus = eInvoice ? parseInt(eInvoice) : null;
  //mos ndryshoni renditjen cit. bruno sholla
  if (cisStatus == "T991") return 16
  else if (cisStatus == "TF991") return 15
  else if (cisStatus == "TE991") return 14
  else if (fiscStatus === 100 && einvoice === true && (eInvoiceStatus === 56 || eInvoiceStatus === 101 || eInvoiceStatus === 8101)) return 13;
  else if (eInvoiceStatus === 100 && einvoice === true) return 1;
  else if (fiscStatus === -100 && einvoice === false) return 2;
  else if (
    ublInvoiceTypeCode === "384" &&
    fiscStatus !== 100 &&
    fiscStatus !== -100
  )
    return 3;
  else if (
    ublInvoiceTypeCode === "384" &&
    eInvoiceStatus !== 100 &&
    eInvoiceStatus !== -100
  )
    return 4;
  else if (eInvoiceStatus === -100 && einvoice === true) return 5;
  else if (
    fiscStatus !== -100 &&
    fiscStatus !== 100 &&
    (ublInvoiceTypeCode === "380" || ublInvoiceTypeCode === null)
  )
    return 7;
  else if (fiscStatus === -1 && einvoice === true) return 9;
  else if (fiscStatus === 100 && eInvoiceStatus === -1) return 10;
  else if (
    eInvoiceStatus !== -100 &&
    eInvoiceStatus !== 100 &&
    einvoice === true
  )
    return 8;
  // else if(fiscStatus!== 200)
  else if (fiscStatus === 100 && einvoice === false) return 12;
};

// This will require the total invoice discount and an array of items
// Values from the item object will be quantity, priceWithoutVat, priceWithVat, discount, vatCategory
// For each item, we need to calculate discountPriceWithoutVat, discountPriceWithVat, totalWithoutVat, totalWithVat for that specific item
// After calculating all the items, we need to calculate the Invoice Totals
// Totals(invoiceTotalWithoutVat, invoiceTotalDiscount, invoiceDiscountedTotalWithoutVat, invoiceTotalVat, invoiceTotalWithVat)
const calculateInvoiceData = (state) => {
  const { items, discount } = state;

  const bigDecimal = require("js-big-decimal");

  const subtract = bigDecimal.subtract;
  const add = bigDecimal.add;
  bigDecimal.subtract = (number1, number2) => {
    number1 = number1 === "-0" ? "0" : number1;
    number2 = number2 === "-0" ? "0" : number2;
    return subtract(number1, number2);
  };
  bigDecimal.add = (number1, number2) => {
    number1 = number1 === "-0" ? "0" : number1;
    number2 = number2 === "-0" ? "0" : number2;
    return add(number1, number2);
  };


  const calculatedItems = items.map((item) => {
    var {
      unitPrice,
      priceWithoutVat,
      priceWithVat,
      quantity,
      discountType,
      discountValue,
      vatCategory,
    } = item;

    let discount;
    if (discountType === "Percentage") {
      discount = bigDecimal.multiply(
        bigDecimal.multiply(unitPrice, bigDecimal.divide(discountValue, 100)),
        quantity
      );
    } else {
      discount = discountValue;
    }
    if(item.quantity<0 && discount > 0){
      discount=bigDecimal.subtract(0,discount);
    }
    let totalWithoutVat = bigDecimal.multiply(quantity, unitPrice);

    totalWithoutVat = bigDecimal.subtract(totalWithoutVat, discount);
    
    totalWithoutVat = totalWithoutVat === "-0" ? "0" : totalWithoutVat;
    let vat = round(bigDecimal.multiply(
      bigDecimal.divide(vatCategory, 100),
      totalWithoutVat
    ),2);
    let totalWithVat = bigDecimal.add(totalWithoutVat, vat);
      return {
        ...item,
        totalWithoutVat:round(totalWithoutVat, 2),
        totalWithVat: round(totalWithVat, 2),
      };

  });

  const invoiceTotalWithoutVat = calculatedItems.reduce((sum, item) => {
    let discount;
    if (item.discountType === "Percentage") {
      discount = bigDecimal.multiply(
        bigDecimal.multiply(
          item.unitPrice,
          bigDecimal.divide(item.discountValue, 100)
        ),
        item.quantity
      );
    } else {
      discount = item.discountValue;
    }

    if(item.quantity<0 && discount > 0 ){
      discount=bigDecimal.subtract(0,discount);
    }

    return round(bigDecimal.add(
      sum,
      bigDecimal.subtract(
        bigDecimal.multiply(item.unitPrice, item.quantity),
        discount
      )
    ),2);
  }, 0);

  let invoiceTotalDiscount="0";
  if (discount.type === "Percentage") {
    // console.log({discountFromApi:discount.value})
    invoiceTotalDiscount = bigDecimal.multiply(
      invoiceTotalWithoutVat,
      bigDecimal.divide(discount.value, 100)
    );

  } else {
    invoiceTotalDiscount = discount?.value?.length> 0 ?discount.value:"0";
    const cancelledInvoice = calculatedItems.find(item=>item.quantity.includes("-") )
    if (cancelledInvoice){
      invoiceTotalDiscount = bigDecimal.subtract(0, invoiceTotalDiscount);
    }
  }



  if(parseFloat(invoiceTotalWithoutVat)<0 && discount.type=="Value"){
    invoiceTotalDiscount= bigDecimal.subtract(0,invoiceTotalDiscount); 
  }
  const invoiceDiscountedTotalWithoutVat=bigDecimal.subtract(//discount without vat
    invoiceTotalWithoutVat,
    invoiceTotalDiscount
  );
  //grouped vat calculation
  // const vat = calculatedItems.map((item) => { return { vatCategory: item.vatCategory, totalWithoutVat:  item.totalWithoutVat } });
//   const groupedVats = Array.from(vat.reduce(
//     (m, { vatCategory, totalWithoutVat }) => m.set(vatCategory, bigDecimal.add((m.get(vatCategory) || 0), totalWithoutVat)), new Map
// ), ([vatCategory, totalWithoutVat]) => ({ vatCategory, totalWithoutVat }));

    let invoiceTotalVat= calculatedItems.reduce((sum,item)=>{
      const currVat = round(bigDecimal.multiply(
        bigDecimal.divide(item.vatCategory, 100),
        item.totalWithoutVat
      ),2)
      return bigDecimal.add(
        sum,
        currVat
      )
    },0)

  if(parseFloat(invoiceTotalDiscount)>0  || parseFloat(invoiceTotalDiscount)<0 ){
    let discountVat=round(bigDecimal.multiply(
      invoiceTotalDiscount,
      bigDecimal.divide(discount.vat, 100)
    ),2)

      invoiceTotalVat=bigDecimal.subtract(invoiceTotalVat,discountVat)
  }

  const invoiceTotalWithVat = round(
    bigDecimal.add(invoiceDiscountedTotalWithoutVat, invoiceTotalVat),
    2
  );

  return {
    items: calculatedItems,
    invoiceTotalWithoutVat: round(invoiceTotalWithoutVat, 2),
    invoiceTotalDiscount: round(invoiceTotalDiscount, 2),
    invoiceDiscountedTotalWithoutVat: round(
      invoiceDiscountedTotalWithoutVat,
      2
    ),
    invoiceTotalVat: round(invoiceTotalVat, 2),
    invoiceTotalWithVat: round(invoiceTotalWithVat, 2),
  };
};

const parseCustomInvoiceRequest = (state) => {
  //console.log({parseCustomInvoiceRequest: state})
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = getStore();
  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  const { discount, items, ...rest } = state;
  let parsedRequest = {
    ...rest,
    items: items.map((item) => ({
      ...item,
      company: company.id,
      vatCategory: item.vatCategory,
    })),
    param1: state?.param1 ? state?.param1.trim() : "",
    param2: state?.param2 ? state?.param2.trim() : "",
  };

  if (discount?.value?.length > 0) parsedRequest.discount = discount;

  //console.log({parseCustomInvoiceRequest: parsedRequest})
  return parsedRequest;
};

const parseCustomInvoiceResponse = (state) => {
  if (state) {
    const {
      user: [user],
      selectedCompany: [selectedCompany],
    } = getStore();
    const company = user.companiesRights.find(
      (company) => company.id === parseInt(selectedCompany)
    );

    let { parties, discount, items, invoiceHistorics, ...rest } = state;
    if (!discount) {
      discount = {
        type: "Percentage",
        value: "",
        vat: company.issuerInVat ? "20" : "0",
        vatLabel: null,
      };
    }

    return {
      ...rest,
      parties: {
        ...parties,
        buyer: {
          ...parties.buyer,
          country: getFullCountry(parties.buyer.country),
        },
        seller: {
          ...parties.seller,
          country: getFullCountry(parties.seller.country),
        },
      },
      discount,
      invoiceHistorics,
      attachments: invoiceHistorics
        .reduce((acc, next) => acc.concat(next.historicAttachments), [])
        .map((attachment) => {
          const { fileName, extension, insertToUbl, attachmentPath } =
            attachment;
          return {
            name: fileName,
            extension,
            insertToUbl,
            path: attachmentPath,
            file: null,
          };
        }),
      items: items.map((item) => ({ ...item, vatCategory: item.vatCategory })),
    };
  } else return null;
};


const validateMultiPayment = ({state,toast}) => {
  const newState={...state}
  const { paymentMethod, invoiceTotalWithVat } = newState

  if (paymentMethod){
    if (paymentMethod.length == 1){
      const parsedMethod = {
        ...paymentMethod[0],
        paymentAmount: invoiceTotalWithVat
      }
      newState.paymentMethod = [parsedMethod]

    } else if (paymentMethod.length>1){
      const paymentTotal = paymentMethod.reduce((acc, currPayment) =>  bigDecimal.add(acc ,currPayment.paymentAmount) ,0)
      if (paymentTotal !== invoiceTotalWithVat){
        toast.error("Payment amount should be equal with invoice total", {
          containerId: "all_toast",
        });
        return null
      }
      return newState
    }
  }
  return newState;

}

const getFullCountry = (shortCode, showDefault = true) => {
  return (
    countries.find(
      (country) =>
        country.shortCode === shortCode || country.value === shortCode
    )?.value || (showDefault ? "Albania" : "")
  );
};


const columnCustomSort = (rowA, rowB,sortList,selector) => {
  let rowAKey=getPropByPath(rowA,selector)
  let rowBKey=getPropByPath(rowB,selector)
  let rowAPropertyOrder= sortList[rowAKey]
  let rowBPropertyOrder= sortList[rowBKey]
  if (rowAPropertyOrder > rowBPropertyOrder || !rowBPropertyOrder ) {
      return 1;
  }

  if (rowBPropertyOrder > rowBPropertyOrder  ) {
      return -1;
  }

  return 0;
};

const getPropByPath=(obj, propString) =>{
  if (!propString)
    return obj;

  var prop, props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined && candidate!=null ) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

const openEmailModal = (tempCustomer, iic, t) => {
  const { user: [user], selectedCompany: [selectedCompany] } = getStore()
  Swal.fire({
    title: t(["email.sendInvoice"]),
    html: `<input type="email" id="swal-input1" class="form-control" style="margin-top: 10px;">`
    +
    `<label id="swal-error1" style="display: none;"></label>`
    ,
    inputValue: tempCustomer?.email ?? "",
    allowOutsideClick: false,
    showCancelButton: true,
    cancelButtonColor: "#c82333",
    cancelButtonText: t(["common.cancel"]),
    confirmButtonText: t(["email.send"]),
    confirmButtonColor: "#007567",
    didOpen: () => {
      let swalInput1=document.getElementById("swal-input1")
      swalInput1.value = tempCustomer?.email ?? "";
      swalInput1.placeholder = t(["email.enterEmail"])
    },
    preConfirm: () => {
      let a1 = document.getElementById('swal-input1').value
      if(new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(a1)){
        return true;
      } else {
        let errorDiv=document.getElementById("swal-error1")
        let swalInput1=document.getElementById("swal-input1")
        errorDiv.innerHTML="*" + t(["email.invalid"])
        errorDiv.style=`margin:5px 0px 0px 0px;width:100%;padding-top:4px;padding-bottom:4px;`
        errorDiv.classList.add("alert", "alert-danger")
        swalInput1.classList.add("is-invalid")
        return false;
      }
    }
  }).then((conf) => {
    if (conf.isConfirmed) {
      let a1 = document.getElementById('swal-input1').value
      // return;
      mainAxios
        .post("apiEndpoint/saveOrUpdate", {
          object: "CustomerEmailNotification",
          content: {
            iic: iic,
            email: a1,
            senderCompany: selectedCompany,
            customerIdL2: tempCustomer?.id ?? "",
            // updateCustomerEmail: a2,
          },
          company: selectedCompany,
          nuis: user.companiesRights.find(
            (company) => company.id === parseInt(selectedCompany)
          )?.nuis,
        })
        .then((res) => {
          // setLoading(false);
          if (res?.data?.status === true) {
            toast.success(t(["email.willBeSent"]), {
              containerId: "all_toast",
            });
          }
        });
    } else return;
  });
};

const capitalizeFirstLetter=(string) => {
  return (string?.charAt(0)?.toUpperCase() ?? "" )+( string?.slice(1)?.toLowerCase() ?? "");
}

const sendEmailPdf = (iic, customer, buyerNuis, t) => {
  const { selectedCompany: [selectedCompany] } = getStore()
  if(!customer){
    let tempCustomer;
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCustomer",
        params: {
          company: selectedCompany,
          tin: buyerNuis,
        },
        company: selectedCompany,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          tempCustomer = res.data.content[0];
        }
        openEmailModal(tempCustomer, iic, t)
      });
  } else {
    openEmailModal(customer, iic, t)
  }
};

const camelToFlat=(camel)=>{
  const label =camel.match(/([A-Z]?[^A-Z]*)/g).join(" ")

  return(( label?.[0]?.toUpperCase() ?? "") + (label?.slice(1) ?? ""))
}

const debounce = (func, wait, immediate) => {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const swalAlert = async ({ title,text="",t,...props }) => {
  return Swal.fire({
    title,
    text,
    confirmButtonText: t("company.yes"),
    confirmButtonColor: "#007567",
    showCancelButton: true,
    cancelButtonText: t("company.no"),
    ...props
  }).then((res) => {
    if (res.isConfirmed) {
      return true;
    } else {
      return false
    }
  });
}



export const getNestedValue = (obj, path) => {
  const props = path.split('.');
  let currentObj = obj;

  for (let i = 0; i < props.length; i++) {
    const prop = props[i];

    if (Array.isArray(currentObj) && /^\d+$/.test(prop)) {

      const index = parseInt(prop, 10);
      if (currentObj[index] !== undefined) {
        currentObj = currentObj[index];
      } else {
        return undefined; 
      }
    } else if (currentObj && prop in currentObj) {
      currentObj = currentObj[prop];
    } else {
      return undefined; // Property not found
    }
  }

  return currentObj;
};

export const isEmpty = (value) => {
  if (value === null || value === "") return true;
  return false;
};

  
export const validateInvoice = ({ setErrors, toast, state, validateOnlyItems = false, setVisible,validateOnlyParties, autoSaveState })=>{
  const validationErrors = {};

  let validateFields = [
    { value: "issueDate", label: "Issue Date" },
    { value: "dueDate", label: "dueDate" },
    { value: "buyer", label: "buyer" },
    { value: "parties.buyer.tin", label: "tin" },
    { value: "parties.buyer.town", label: "town" },
    { value: "parties.buyer.country", label: "country" },
    { value: "parties.buyer.address", label: "address" },
    { value: "parties.buyer.name", label: "buyerName" },
  ];

  const transportFields = [state?.vehiclePlate, state?.transporter, state?.destinationAddress]

  const hasTransport=transportFields.some(field=>!isEmpty(field))
  if(hasTransport){
    validateFields=validateFields.concat([
      { value: "vehiclePlate", label: "Vehicle Plate" },
      { value: "transporter", label: "Transporter Name" },
      { value: "destinationAddress", label: "Destination Address" },
    ])
  }

  const validatedItems = [
    { value: "code", label: "Item code" },
    { value: "name", label: "Item name" },
    { value: "quantity", label: "Item quantity" },
    { value: "unitOfMeasureId", label: "Item unitOfMeasureId" },
    { value: "unitPrice", label: "Unit Price" },
    // { value: "priceWithVat", label: "Item priceWithVat" },
    // { value: "priceWithoutVat", label: "Item priceWithoutVat" },
  ]
  
  if (validateOnlyItems==false){
  validateFields.forEach((valid) => {
    const label = `${valid?.label} is Required`;
    const empty = isEmpty(func.getNestedValue(state, valid.value))
    if (validateOnlyParties ? valid.value.includes("parties") && empty:empty ){
      validationErrors[valid?.value] = label;
    }
  });
  }

    if (!validateOnlyParties){
    validatedItems.forEach(valid => {
      const items = state?.items ?? []
      items.forEach((item, index) => {
        const label = `${valid.label} is Required`;

      if (isEmpty(func.getNestedValue(item, valid?.value))) {
        validationErrors[index + "_" + valid?.value] = label;
      }
    })
  })
  if (!autoSaveState){
    setErrors(validationErrors);
  }
  const valErrors = Object.keys(validationErrors)
  const allParties=validateFields.filter(item=>item.value.includes("parties"))

  if (valErrors.length > 0) {
    //open buyer modal if it has buyer errors and there is no other errors left
    if (!autoSaveState && setVisible &&  allParties.length!=valErrors.filter(key=>key.includes("parties")).length && valErrors.find(key => key.includes("parties"))){
      setVisible(true)
    }
    if (!autoSaveState){
      toast.error("Please complete required fields", {
        containerId: "all_toast",
      });
    }

    return false;
  }
  return true
}
}

export const validateInvoiceWtn = ({setErrors, state,t }) => {

  const validationErrors = {};

  const nuisValidation=(nuis)=>{
    const test=!(/[A-Za-z][0-9Oo]{8}[A-Za-z]$/.test(nuis))
    return test
  }

  const validateFields = [
    { value: "code", label: "Code" },
    { value: "name", label: "Name" },
    { value: "unitOfMeasure", label: "Unit of measure" },
    { value: "quantity", label: "Quantity" },
    { value: "packNum", label: "Pack num" },
    { value: "vehicle.vehiclePlate", label: "Vehicle plate" },
    { value: "valueOfGoods", label: "Value of goods" },
    { value: "parties.transporter.name", label: "Name" },
    { value: "parties.transporter.address", label: "Address" },
    { value: "parties.address.0.address", label: "Address" },
    { value: "address.0.address", label: "Address" },
    { value: "address.1.address", label: "Address" },
    { value: "address.0.date", label: "Address" },
    { value: "address.1.date", label: "Address" },
    { value: "parties.transporter.idNum", label: t("toast.incorrectNipt"),validation:nuisValidation },
  ];

 
  validateFields.forEach((valid) => {
    const label = `${valid?.label} is Required`;
    if (valid.validation ? valid.validation(func.getNestedValue(state, valid.value)) :isEmpty(func.getNestedValue(state, valid.value)))
      validationErrors[valid?.value] = valid.validation ? valid.label:label;
  });

  setErrors(validationErrors);
  console.log({validationErrors})
  if(Object.keys(validationErrors).length > 0) return true
  return false
}

const isNumeric=(value)=> {
  return /^-?\d+$/.test(value);
}

const exportToCsv=(filename, rows,formatDate) => {
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString();
      if (formatDate && row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      };
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"';
      if (j > 0)
        finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const translateLabel=(englishLabel,albanianLabel,i18n)=>{
  return i18n.language == "alb" ? albanianLabel : englishLabel
}

export const loadingAction =async ({loadingMessage="Please wait while we process your request...",successMessage,func}) =>{
  const loadingToastId=toast.loading(loadingMessage,{
    containerId: "all_toast",
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: false,
  })
  await func()
  toast.dismiss(loadingToastId)

}

export const partyToCustomer=({party})=>{
  let type = buyerIDTypes?.find((type) => type.label == party?.buyerIDType)?.value ?? "";

  const {tin="",name="",address="",town="",email="",country} = party ?? {}
  return { tin,name, address,town,email,country: getFullCountry(country, false),type}
}


export const removePropertiesRecursive = (obj, pathsToPreserve = []) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(item => removePropertiesRecursive(item, pathsToPreserve));
  }
  const newObj = { ...obj };
  for (const key in newObj) {
    if (pathsToPreserve.includes(key)) {
      continue;
    }
    newObj[key] = removePropertiesRecursive(newObj[key], pathsToPreserve);
    if (key === 'id' && newObj[key] && pathsToPreserve.includes(`${key}.${newObj[key]}`)) {
      continue;
    }
    if (key === 'id' && !pathsToPreserve.some(path => path.startsWith(`${key}.`))) {
      delete newObj[key];
    }
  }
  return newObj;
};

const preventExcelFormatFn = (value) => {
  return "\t" + value
}

export const globalHandleChange = (props) => {
  let {key,value,setState,length }=props
  console.log({length})
  if(length) value=value.slice(0,50)

  const keys=key?.split(".")
  switch (keys?.length) {
    case 1:
      setState((state) => ({
        ...state,
        [keys[0]]: value,
      }));
      break;
    case 2:
      setState((state) => ({
        ...state,
        [keys[0]]: {
          ...(state[keys[0]] ? {...state[keys[0]]}:{}),
          [keys[1]]: value,
        },
      }));
      break;
    case 3:
      setState((state) => ({
        ...state,
        [keys[0]]: {
          ...(state[keys[0]] ? {...state[keys[0]]}:{}),
          [keys[1]]: {
            ...(state[keys[0]] && state[keys[0]][keys[1]] ?{...state[keys[0]][keys[1]]}:{}),
            [keys[2]]: value,
          },
        },
      }));
      break;
    default:
      return;
  }
};

const func = {
  getBuildDate,
  getFormattedMoneyValue,
  formatNumber,
  round,
  getDateFromToday,
  getDaysFromToday,
  dateBeforeSomeDays,
  getMinIssueDate,
  downloadPDF,
  toBase64,
  b64toBlob,
  blobToFile,
  b64toURL,
  setCookie,
  getCookie,
  parseJwt,
  getKeyFromLS,
  capitalizeFirstLetter,
  getTitleByPath,
  array_chunks,
  getInvoiceType,
  getQueueStatus,
  calculateInvoiceData,
  parseCustomInvoiceRequest,
  parseCustomInvoiceResponse,
  getFullCountry,
  numberTobeToFixed3,
  countDecimals,
  columnCustomSort,
  getPropByPath,
  formattedPrice,
  sendEmailPdf,
  camelToFlat,
  debounce,
  swalAlert,
  getNestedValue,
  isEmpty,
  validateMultiPayment,
  validateInvoiceWtn,
  isNumeric,
  exportToCsv,
  partyToCustomer,
  preventExcelFormatFn,
  globalHandleChange

};
export default func;
