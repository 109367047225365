import React from 'react'
import func from '../../../services/functions'

const FilePreview = ({filePreview,isDesktop}) => {
    let output  =  (
        <div className="d-flex align-items-center justify-content-center my-5 h-100">
            Cannot preview this type of file
        </div>
    );
    switch(filePreview.documentType) {
        case "pdf": 
            if(isDesktop) output = (
                <embed height="700px" width="100%" src={func.b64toURL(filePreview.base64, "application/pdf", filePreview.name)}/>
            )
            break;
        case "png":
            output = (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <img height="500px" width="100%" src={func.b64toURL(filePreview.base64, "image/png", filePreview.name)}/>
                </div>
            )
            break;
        case "jpg": 
            output = (
                <div className="d-flex align-items-center justify-content-center h-100">
                    <img height="500px" width="100%" src={func.b64toURL(filePreview.base64, "image/jpeg", filePreview.name)}/>
                </div>
            )
            break;
        // case "xlsx":
        //     output = (
        //         <div className="d-flex align-items-center justify-content-center h-100 position-relative">
        //             <div className="position-absolute" style={{top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>Cannot preview this type of file</div>
        //             <embed height="500px" width="100%" src={func.b64toURL(filePreview.base64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", filePreview.name)}/>
        //         </div>
        //     )
        //     break;
        case "txt":
            output = (
                    <embed style={{height:500,overflow:"visible", width:"100%"}} src={func.b64toURL(filePreview.base64, "text/plain", filePreview.name)}/>
            )
            break;
        default:
            return output;
    }
    
    return output
}

export default FilePreview