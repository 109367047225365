import React from "react";
import mainAxios from "../../services/mainAxios";
import {
  faCheck,
  faLocationArrow,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../Layout/Loader";
import { useAppContext } from "../../AppContext";
import Swal from "sweetalert2";

import { useTranslation, withTranslation } from "react-i18next";
const UploadInvoice = ({ user, selectedCompany, swal }) => {
  const { t, i18n } = useTranslation("translations");
  const [state, setState] = React.useState({
    file: null,
    name: null,
    extension: "",
    einvoice: false,
    paymentMethodType: "cash",
  });

  const [customExtension, setCustomExtension] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const submitInvoice = (e) => {
    e.preventDefault();
    setLoading(true);

    mainAxios
      .post("apiEndpoint/sendAndReceive", {
        object: "RegisterEinvoiceRequest",
        content: {
          file: state.file,
          extension: customExtension ? customExtension : state.extension,
          paymentMethodType: state.paymentMethodType,
          einvoice: state.einvoice,
        },
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(t(["notification.success"]), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            containerId: "all_toast",
          });
          swal.close();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const extension =
      file && file.name.split(".")[file.name.split(".").length - 1];
    if (
      extension &&
      (extension === "xml" ||
        extension === "svd" ||
        extension === "kvd" ||
        extension === "xvd")
    ) {
      const reader = new FileReader();

      reader.onload = (readerEvent) => {
        let binaryString = readerEvent.target.result;
        setState((state) => ({
          ...state,
          file: btoa(binaryString),
          name: file.name,
          extension,
        }));
      };
      reader.readAsBinaryString(file);
    }
  };
  const toggleEinvocie = () => {
    const { einvoice } = state;

    if (!einvoice) {
      setState((state) => ({
        ...state,
        einvoice: !einvoice,
      }));
    } else {
      Swal.fire({
        title: t(["notification.einvoice"]),
        icon: "warning",
        width: "800px",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value == true) {
          setState((state) => ({
            ...state,
            einvoice: !einvoice,
          }));
        }
      });
    }
  };

  return (
    <form
      className="text-left"
      onSubmit={submitInvoice}
      id="upload_invoice_modal"
    >
      <div>
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-6 form-group">
              <label>{t("hints.paymentMethod")}</label>
              <select
                className="form-control"
                value={state.paymentMethodType}
                onChange={(e) =>
                  setState({ ...state, paymentMethodType: e.target.value })
                }
              >
                <option value="cash">Cash</option>
                <option value="NonCash">Non Cash</option>
              </select>
            </div>
            <div className="form-group col-md-6 mt-4">
              <div className="d-flex align-items-center w-100">
                <div className="custom-control custom-checkbox mr-sm-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isEinvoice"
                    name="isEinvoice"
                    checked={!state.einvoice}
                    onChange={toggleEinvocie}
                  />
                  <label
                    style={{ paddingTop: "3px", paddingLeft: "2px" }}
                    htmlFor="isEinvoice"
                    className="custom-control-label"
                  >
                    {t("parameters.onlyFiscalization")}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
          <div className="d-flex align-items-center w-100">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input p-0 m-0"
                id="customExtension"
                name="customExtension"
                value="FiscInvoice"
                defaultChecked={customExtension ? true : false}
                onChange={(e) => {
                  setCustomExtension(e.target.checked ? "FiscInvoice" : null);
                }}
              />
              <label 
                    style={{ paddingTop: "3px", paddingLeft: "2px" }}
              
              className="custom-control-label" htmlFor="customExtension">
                {t("invoice.fiscInvoice")}
              </label>
            </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="certificate">{t("invoice.file")}</label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="file"
                name="file"
                placeholder="Upload File"
                accept="text/xml,.svd,.kvd,.xvd"
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={handleFile}
              />
              <label className="custom-file-label" htmlFor="certificate">
                {state.name ? state.name : t(["invoice.choose"])}
              </label>
            </div>
          </div>
          <button
            className="btn btn-primary mt-3"
            // disabled={loading || !state.file}
            style={{ minWidth: "80px" }}
            type="submit"
          >
            {loading ? (
              <Loader size="xs" color="white" />
            ) : (
              <>
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                {t("invoice.send")}
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default UploadInvoice;
