export const typeOfPaymentMeansNonCash = (t)=>[

    { "label": t("invoice.transfer").toUpperCase(), "paymentMeans": "31", paymentMeansValue: "TRANSFER"},
    { "label": t("invoice.account").toUpperCase(), "paymentMeans": "42", paymentMeansValue: "ACCOUNT" },
    { "label": t("invoice.other").toUpperCase(), "paymentMeans": "ZZZ", paymentMeansValue: "OTHER" },
    { "label": t("invoice.factoring"), "paymentMeans": "30", paymentMeansValue: "FACTORING" },
    { "label": t("invoice.compensation"), "paymentMeans": "97", paymentMeansValue: "COMPENSATION" },
    { "label": "Kind", "paymentMeans": "97", paymentMeansValue: "KIND" },
    { "label": t("invoice.waiver"), "paymentMeans": "ZZZ", paymentMeansValue: "WAIVER" },

];
 
export const typeOfPaymentMeansCash =(t)=> [

    { "label": "BANKNOTE", "paymentMeans": "10", paymentMeansValue: "BANKNOTE", value: "10BANKNOTE" },
    { "label": "BANK CARD", "paymentMeans": "48", paymentMeansValue: "CARD", value: "48CARD" },
    { "label": "CHECK", "paymentMeans": "10", paymentMeansValue: "CHECK", value: "10CHECK" },
    // { "label": "SVOUCHER", "paymentMeans": "10", paymentMeansValue: "SVOUCHER", value: "10SVOUCHER" },
    // { "label": "COMPANY", "paymentMeans": "10", paymentMeansValue: "COMPANY", value: "10COMPANY" },
    { "label": "ORDER", "paymentMeans": "10", paymentMeansValue: "ORDER", value: "10ORDER" },

];

export const buyerIDTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
    { label: "TAX", value: "5" },
    { label: "SOC", value: "6" },
  ];


export const typeOfVatCategoryOptions = [
    { label: "0%", value: "0" },
    { label: "6%", value: "6" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];