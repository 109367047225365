import { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import func from '../../../services/functions'; // import your utility functions

export const useExportData = ({ fetchFunc, showExportToast = true, dateFormat = 'MM/DD/YYYY MM:HH' }) => {
  const [loadingExport, setLoadingExport] = useState(false);

  const exportData = async () => {
    setLoadingExport(true);
    let toastId=null
    if (showExportToast) {
        toastId=toast.loading('Exporting to csv...', {
        containerId: 'all_toast',
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
      });
    }

    const data = await fetchFunc();

    if (!data) {
      if (showExportToast) {
        toast.dismiss(toastId);
      }
      toast.info('No Data to export', { containerId: 'all_toast' });
      setLoadingExport(false)
      return;
    }

    if (data?.length == 0) toast.info('No data found', { containerId: 'all_toast' });

    let { id, ...headerRowKeys } = data[0];
    headerRowKeys = Object.keys(headerRowKeys);
    const headerRow = headerRowKeys.map((key) => func.camelToFlat(key));
    const exportRows = [headerRow];

    data.forEach((obj) => {
      const row = [];
      headerRowKeys.forEach((key) => {
        const value = obj[key];
          row.push(value);
      });
      exportRows.push(row);
    });

    func.exportToCsv(moment().format('MM/DD/YYYY_MM:HH:ss'), exportRows,false);

    if (showExportToast) {
      toast.success('Export was successful', { containerId: 'all_toast' });
    }
    toast.dismiss(toastId);

    setLoadingExport(false);
  };

  return { exportData, loadingExport };
};
