import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

const generalStyle = { borderRadius: "40%", width: "19px", height: "19px", transform: "scale(0.80)" }
const PopOver = ({ title, customStyle,className="" }) => {


  const renderTooltip = (props, title) => {

    return (
      <Tooltip id="button-tooltip" {...props}>
        {title}
      </Tooltip>
    );
  };


  return (
    <OverlayTrigger
      placement="auto"
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => renderTooltip(props, title)}
    >
      <Button
        variant="outline-dark"
        size="sm"
        tabIndex="-1"
        className={`d-inline-flex align-items-center justify-content-center border mb-1 ml-1 ${className}`}
        style={customStyle ? customStyle : generalStyle}
      >
        i
      </Button>
    </OverlayTrigger>
  );
};

export default PopOver;
