import React from "react";

const AdditionalStatisticBox = ({ header, header2, description, icon, total }) => {
    return (
        <div className="card flex-fill border-0 shadow" style={{height:140}}>
            <div className="card-body py-4 d-flex align-items-center justify-between">
                    <div className="media-body">
                        <h3 className="mb-2 ml-2 ">{header}</h3>
                        <h3 className="mb-2  text-primary">{header2}</h3>
                        {total && (
                            <h3 className="mb-2">
                                <span className="mr-2 text-primary">💰︎</span>
                                {total}
                            </h3>
                        )}
                        <p className="mb-2">{description}</p>
                    </div>
                    <div className="d-inline-block ml-3">
                        <div className="stat">{icon}</div>
                    </div>
            </div>
        </div>
    );
};

export default AdditionalStatisticBox;
