import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Loader from '../Layout/Loader'
import mainAxios from '../../services/mainAxios'
import Table from '../Table'

import {useTranslation} from "react-i18next";
const moment = require("moment")

const Subscriptions = () => {
    const {t,i18n} = useTranslation('translations');
    const [subscriptions, setSubscriptions] = React.useState(null)

    React.useEffect(() => {
        mainAxios.post('apiEndpoint/search', {
            object: "GetSubscription"
        }).then(res => {
            if(res) {
                setSubscriptions(res.data.content)
            }
        })
    }, [])

    const columns = [
        {
            name: t(['invoice.name']),
            selector: 'name',
            sortable: true,
            format: subscription => subscription.name ?? ""
        },
        {
            name: t(['home.date']),
            selector: 'recordDate',
            width: "200px",
            sortable: true,
            format: subscription => subscription.recordDate ? moment(subscription.recordDate).format("DD/MM/YYYY HH:mm") : ""
        },
        {
            name: t(['company.active']),
            sortable: true,
            cell: subscription => subscription.active ? t(['company.yes']) : t(['company.no'])
        },
        {
            name: t(['subscription.duration']),
            selector: 'duration',
            width: "200px",
            sortable: true,
            format: subscription => subscription.validFrom ? moment(subscription.validFrom).format("DD/MM/YYYY HH:mm") : ""
        },
        {
            name: t(['subscription.expirationDate']),
            selector: 'expirationDate',
            width: "200px",
            sortable: true,
            format: subscription => subscription.validTo ? moment(subscription.validTo).format("DD/MM/YYYY HH:mm") : ""
        },
        {
            name: t(['subscription.sentInvoiceMaxNo']),
            selector: 'sentInvoiceMaxNo',
            sortable: true
        },
        {
            name: t(['subscription.receivedInvoiceMaxNo']),
            selector: 'receivedInvoiceMaxNo',
            sortable: true
        },
        {
            name: t(['subscription.maxUserNo']),
            selector: 'maxUserNo',
            sortable: true
        },
        {
            name: t(['subscription.maxCompanyNo']),
            selector: 'maxCompanyNo',
            sortable: true
        },
        {
            name: t(['subscription.type']),
            selector: 'subscriptionType',
            sortable: true,
            format: subscription => subscription.subscriptionType ? subscription.subscriptionType.type === "S" ? t(['subscription.single']) :t(['subscription.multiple']): ""
        },
        {
            name: t(['subscription.value']),
            selector: 'value',
            sortable: true,
            format: subscription => `${subscription.value} (${subscription.currency})`
        },
        {
            name: t(['invoice.currency']),
            selector: 'currency',
            sortable: true
        },
        {
            name: t(['home.actions']),
            cell: subscription => (
                <Link 
                    to={"/subscriptions/edit/" + subscription.id} 
                    className="nav-link p-0">
                    <FontAwesomeIcon icon={faEdit} className="mr-1" />
                    {t('common.edit')}
                </Link>
            )
        }
    ];


    return (
        subscriptions ? 
        <>
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="mt-4 mb-4">{t('subscription.subscriptions')}</h3>
                <div className="page-actions">
                    <Link
                        to="/subscriptions/add"
                        className="btn btn-primary">
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        {t('subscription.add')}
                    </Link>
                </div>
            </div>
            <div className="card border-0 shadow">
                <div className="card-body">
                <Table
                        columns={columns} 
                        data={[...subscriptions]}
                        searchableKeys={["id", "recordDate"]}
                        defaultSortField="recordDate"
                        defaultSortAsc={false}
                        /* expandable */
                    />
                </div>
            </div>
        </>
        :
        <Loader/>
    )
}

export default Subscriptions