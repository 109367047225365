import React, { useEffect, useState } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import mainAxios from '../../services/mainAxios'
import func from '../../services/functions'
// Demo styles, see 'Styles' section below for some notes on use.
import Table from '../Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faBuildingColumns } from '@fortawesome/free-regular-svg-icons'
import useOnScreen from '../global/hooks/useOnScreen'
import { useAppContext } from '../../AppContext'
import { DashboardCard } from '../global/CommonComponents'



const TopTenCustomers = ({title, fromDate, toDate}) => {
  const {
    selectedCompany: [selectedCompany],
  } = useAppContext();

const limit=10
const { t } = useTranslation("translations")
const [customers,setCustomers]=useState([])

const topTenCustomerRef = React.useRef()
const isVisible = useOnScreen(topTenCustomerRef, [selectedCompany])


useEffect(() => {
  if (!isVisible) return
  mainAxios.post('/endpoint/v2/reports/customers', {
    fromDate,
    toDate,
    params:{
      fiscInvoiceType:"NONCASH",
      limit
    },  
}).then(res => {
  if (res.data.status && res.data.content) {
    setCustomers(res.data.content)
  }
})
}, [isVisible])

const columns=[
  {
        name:"  ",
        width: "2%",
        cell: customers => (
          <FontAwesomeIcon
              icon={faBuilding}
              size={'lg'}
              className={`ml-2`}
              color={"#1cbb8c"}
          />)
  },
    {
    name: t(["topten.customerName"]),
    width:"31%",
    selector: "buyerName",
  },
  {
    name: t(["topten.buyerNuis"]),
    selector: "buyerNuis",
    width:"13%"
  },
  {
    name: t(["topten.purchaseNo"]),
    selector: "purchaseNo",
    width: "15%",
  },
  {
    name: t(["topten.purchaseTot"]),
    width:"17%",
    selector: "purchaseTot",
    format: customers => func.getFormattedMoneyValue(customers.purchaseTot),
    style:{
      color: "green",
      textAlign:"right",
      fontWeight: "630",
    }
  }
]
  
  return (
    <DashboardCard ref={topTenCustomerRef} title={title}>
          <div className="col-lg-12">
            <Table
            columns={columns}
            data={customers}
            disablePagination={true}
            customStyles={{
              cells: {
                style: {
                  fontSize:"12px"
                },
            },
            }}
            />
          </div>
    </DashboardCard>
  )
}

export default memo(TopTenCustomers)