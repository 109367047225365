import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { useClickOutside } from 'primereact/hooks';
import { Messages } from 'primereact/messages';
import React, { useMemo, useState } from 'react';
import { useRef } from 'react';
import mainAxios from '../../services/mainAxios';
import { useAppContext } from '../../AppContext';
import { useEffect } from 'react';


const moment = require("moment");



const CisMessage = ({ objectType }) => {
  const cisInfoRef = useRef();
  const [cisMsgRes,setCisMessage]=useState({loading:false,message:null})
  const {
    selectedCompany: [selectedCompany],
  } = useAppContext();

  const cisAlertMessage = useMemo(() => {
  const cisMsg = cisMsgRes?.message;
    if (cisMsg) {
      return (
        <div className='d-flex gap-2 align-items-center'>
          {cisMsg.recordDate && (
            <div className='' style={{fontWeight:"600"}}>
              CIS Last Update: <span style={{textDecoration:"underline"}}>{moment(cisMsg.recordDate).format('DD/MM/YYYY HH:MM')} </span> <span className='ml-2'>|</span>
            </div>
          )}
          {cisMsg.schedulerNextExecution && (
            <div style={{fontWeight:"600"}}>
              CIS Next Update: <span style={{textDecoration:"underline"}}>{moment(cisMsg.schedulerNextExecution).format('DD/MM/YYYY HH:MM')} </span> {cisMsg?.retryNextExecutionTime && <span className='ml-2'>|</span>}
            </div>
          )}
          {cisMsg?.retryNextExecutionTime && (
            <div style={{fontWeight:"600"}}>
              CIS Retry: <span style={{textDecoration:"underline"}}>{moment(cisMsg.retryNextExecutionTime).format('DD/MM/YYYY HH:MM')} </span>
            </div>
          )}
        </div>
      );
    }
    return null;
  }, [cisMsgRes]);

  const getInvoicesLog=()=>{
    mainAxios.post('apiEndpoint/search', {
      object:"GetInvoicesRequestLogV",
      params:{
        type:objectType 
      },
      company: selectedCompany
    }).then(res=>{
      if(res?.status === 200){
        setCisMessage((cis)=>({loading:false,message:res?.data?.content?.[0]}));
    }
    })
    .catch(err=>{
      setCisMessage((cis)=>({loading:false,message:null}));
    })

  }

  useEffect(()=>{
    getInvoicesLog()
  },[])

  return <div className='cis-info-container d-flex align-items-center'>

    <div
      onClick={() => {
        if(cisInfoRef.current.clear){
          cisInfoRef.current.clear();
        }
        cisInfoRef.current.show([
          { 
          severity: 'success', 
          content:cisAlertMessage, 
          closable: true,
          sticky:true,

        },
      ]);
    // }
       }}
      className={`btn btn-sm btn-outline-success ml-2 cis-info-btn mr-2`}
    >
      <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
      CIS Info
    </div>
    <Messages ref={cisInfoRef}/>
  </div>;
};

export default CisMessage;


