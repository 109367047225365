import React from "react"
import Loader from "./Layout/Loader"
import mainAxios from "../services/mainAxios"

const TableExpandable = ({ data, expandableKeys, renderItem, async, config }) => {
    const [state, setState] = React.useState({
        data: null,
        loading: true
    })
    
    React.useEffect(() => {
        if(!async) {
            setState(state => ({
                ...state,
                data: data,
                loading: false
            }))
        }
        else {
            mainAxios.post(config.url, {
                ...config.data(config.key.split(".").reduce((obj, key) => obj[key], data))
            }).then(res => {
                if(res?.data?.status === true) {
                    setState(state => ({
                        ...state,
                        data: {...data, ...res.data.content[0], id: data.id},
                        loading: false
                    }))
                }
                else {
                    setState(state => ({
                        ...state,
                        loading: false
                    }))
                }
            })
        }
    }, [])
    
    const output = renderItem ? renderItem(data) : state.data ? 
    <div className="expandable-items">
        <div className="row">
            {expandableKeys?.map((key, index) => {
                if(key.expandableColumns && state.data[key.name]?.length) {
                    return (
                        <div key={index} className="col-lg-6">
                            {key.value && <h4>{key.value}</h4>}
                            <table className="table table-bordered mt-3 mb-0">
                                <thead>
                                    <tr>
                                        {key.expandableColumns.map((column, index) => (
                                            <th scope="col" key={index}>{column.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data[key.name].map((row, index) => {
                                        return (
                                            <tr key={index}>

                                                {key.expandableColumns.map((column, index) => {
                                                        
                                                    const cellOutput = column.format ? column.format(row[column.selector]) : row[column.selector]
                                               
                                                    return (
                                                        <td key={index}>

                                                            <>
                                                            {cellOutput}
                                                          
                                                            </>

                                                        </td>
                                                    )
                                                })}

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )
                }
                else if(key.expandableColumns && !state.data[key.name]?.length ) {
                    return <div key={index} className="col-lg-6 p-3">No data</div>
                }
                else {
                    let object = state.data
                    if(key?.name) {
                        let keys = key.name.split(".")
                        object = state.data[keys[0]]
                        if(keys.length !== 1) {
                            keys.slice(1).forEach(key => {
                                object = object && object[key] ? object[key] : null
                            })
                        }
                        if(key.format) object = key.format(object)
                    }
                    else {
                        object = key.format(object)
                    }
                    return (
                        expandableKeys.length > 1 ?
                        <div key={index} className={expandableKeys.length > 3 ? "col-lg-4 col-12 py-2" : "col-12 mb-3"}>
                            {key.value ?
                                <div className="row">
                                    <div className={expandableKeys.length > 3 ? "col-lg-4 col-12" : "col-2"}>
                                        <strong>{key.value}: </strong>
                                    </div>
                                    <div className="col">
                                        {object || ""}
                                    </div>
                                </div>
                                :
                                object || ""
                            }
                        </div>
                        :
                        <div key={index} className="col-lg-12">
                            {key.value ?
                            <>
                                <div>
                                    <strong>{key.value}: </strong>
                                </div>
                                <div>
                                    {object || ""}
                                </div>
                            </>
                            :
                            object || ""}
                        </div>
                    )
                }
            })}
        </div>
    </div>
    : 
    <div className="p-3">No data</div>

    
    return ( 
        async ?
            !state.loading ?
                output
            :
            <Loader/>
        :
        output
    )
}

export default TableExpandable