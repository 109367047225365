import React, { useEffect, useMemo, useState } from "react";
import { Chart } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Preloader, Bars } from "react-preloader-icon";
import { useAppContext } from "../../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import useOnScreen from "../global/hooks/useOnScreen";
import { DashboardCard } from "../global/CommonComponents";

const SalesAndPurchases = ({ title, sessionData, currentPurchaseStats, state, getFilteredPurchaseResults, getTotals, getFilteredResult, groupedTotalsByBranch }) => {
  const { t, i18n } = useTranslation("translations");
  const {
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const language = i18n.language;

  const [prevPeriod, setPrevPeriod] = useState({
    name: "previousQuarter",
    fromDate: moment(sessionData?.fromDate).subtract(3, "months")._d,
    toDate: moment(sessionData?.toDate).subtract(3, "months")._d,
  })

  const [previousStats, setPreviousStats] = useState({
    purchases: null,
    sales: null,
    salesTotalsByBranch: null
  })

  const salesAndPurchaseRef = useRef(null)

  const isVisible = useOnScreen(salesAndPurchaseRef, [selectedCompany])
 

  //previous period sales stats
  useEffect(() => {
    if (!(sessionData.fromDate && sessionData.toDate) || !isVisible) return;

    const { fromDate, toDate } = prevPeriod;
    getFilteredResult(fromDate, toDate).then(
      (res) => {
        if (res.data.status && res.data.content) {
          let filtered = res.data.content.filter((stat => stat.tcrCode !== stat.businessUnitCode))
          let allTotals = getTotals(filtered);
          let totalsByBranch = groupedTotalsByBranch(filtered);
          setPreviousStats((state) => ({
            ...state,
            sales: allTotals,
            salesTotalsByBranch: totalsByBranch
          }));
        }
      }
    );
  }, [prevPeriod, isVisible]);

  let currentSalesStats = useMemo(() => {
    if (sessionData?.businessUnitCode) {
      return state.totalsByBranch.find(
        (branch) => branch.businessUnitCode === sessionData.businessUnitCode
      );
    } else {
      return state.allTotals;
    }
  }, [sessionData.businessUnitCode, state.allTotals]);

  let previousSalesStats = useMemo(() => {

    if (sessionData?.businessUnitCode) {

      return previousStats.salesTotalsByBranch?.find(
        (branch) => branch?.businessUnitCode === sessionData?.businessUnitCode
      );
    } else {
      return previousStats.sales;
    }
  }, [sessionData.businessUnitCode, previousStats?.sales]);

  const formatDate = (date) => {
    return moment(date).format("DD MMM YYYY")
  }
  const previousPurchasesStats = previousStats.purchases
  let periodDiff = moment(sessionData?.toDate).diff(sessionData?.fromDate, "days")
  let previousCertainPeriod = language == "alb" ? `${periodDiff} ditet e meparshme` : `Previous ${periodDiff} days`
  let sales= [
    {
      x:`${formatDate(prevPeriod.fromDate)}-${formatDate(prevPeriod.toDate)} (${prevPeriod.name!="previousCertainPeriod"?t(`dashboard.${prevPeriod.name}`):previousCertainPeriod })`,
      y:(previousSalesStats?.cashTaxInclusiveAmount ?? 0) + (previousSalesStats?.noncashTaxInclusiveAmount ?? 0),
      no:previousSalesStats?.successInvoicesNo ?? 0
    },
    {
      x:`${formatDate(sessionData.fromDate)}-${formatDate(sessionData.toDate)} (${t("dashboard.currentPeriod")})`,
      y:(currentSalesStats?.cashTaxInclusiveAmount ?? 0) + (currentSalesStats?.noncashTaxInclusiveAmount ?? 0),
      no:currentSalesStats?.successInvoicesNo ?? 0
    }
  ]


  const data = {
    labels: [
      `${formatDate(prevPeriod.fromDate)}-${formatDate(prevPeriod.toDate)} (${prevPeriod.name!="previousCertainPeriod"?t(`dashboard.${prevPeriod.name}`):previousCertainPeriod })`,
      `${formatDate(sessionData.fromDate)}-${formatDate(sessionData.toDate)} (${t("dashboard.currentPeriod")})`
    ],
    datasets: [
      {
        label: "Sales",
        data: sales,
        backgroundColor: ["#1cbb8c", "#fac091"],
        hoverBorderColor: "#ececec",
        hoverBorderWidth: 3,
        spacing: 4,
      },
    ],
  };
  const config = {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 90,
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        usePointStyle: true,
        callbacks:{
          label: function(tooltipItem, data) {
            const dataset=tooltipItem?.dataset?.data?.[tooltipItem?.dataIndex]
            return `Total (Value): ${dataset.y?? ""}`;


          },
          afterLabel: function(tooltipItem) {
            const dataset=tooltipItem?.dataset?.data?.[tooltipItem?.dataIndex]
            return `Number: ${dataset.no ?? ""}`;
         }
        }
      },
    },

  };


  const handlePeriodChange = (period) => {
    switch (period) {
      case "previousQuarter":
        setPrevPeriod((state) => ({
          ...state,
          name: "previousQuarter",
          fromDate: moment(sessionData?.fromDate).subtract(3, "months")._d,
          toDate: moment(sessionData?.toDate).subtract(3, "months")._d,
        }))
        break;
      case "previousYear":
        setPrevPeriod((state) => ({
          ...state,
          name: "previousYear",
          fromDate: moment(sessionData?.fromDate).subtract(1, "years")._d,
          toDate: moment(sessionData?.toDate).subtract(1, "years")._d,
        }))
        break;
      case "previousCertainPeriod":
        setPrevPeriod((state) => ({
          ...state,
          name: "previousCertainPeriod",
          fromDate: moment(sessionData?.fromDate).subtract(periodDiff, "days")._d,
          toDate: moment(sessionData?.fromDate).subtract(1, "days")._d,
        }))
        break;
    }
  }



  return (
    <DashboardCard ref={salesAndPurchaseRef} title={title}>
      <div className="w-100">
        <div className="d-flex justify-content-end">
          <div className="dropdown">
            <button style={{ backgroundColor: "#fff", border: "2px solid #d1e7dd" }} className="btn btn-dashboard dropdown-toggle d-flex flex-wrap align-items-center justify-content-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <FontAwesomeIcon icon={faCalendarAlt} className="text-primary mr-2" />
              {prevPeriod.name != "previousCertainPeriod" ? t(`dashboard.${prevPeriod.name}`) : previousCertainPeriod}
              <div className="ml-2 p-1" style={{ fontSize: 13, backgroundColor: "#edf5f1" }}>{moment(prevPeriod.fromDate).format("DD/MM/yyyy")}-{moment(prevPeriod.toDate).format("DD/MM/yyyy")}</div>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { handlePeriodChange("previousCertainPeriod") }}>
                {previousCertainPeriod}
              </div>
              <div className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { handlePeriodChange("previousYear") }}>{t("dashboard.previousYear")}</div>
              <div className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { handlePeriodChange("previousQuarter") }}>{t("dashboard.previousQuarter")} </div>
            </div>
          </div>
        </div>
        {data ? (
          <div className="h-100 pb-5">
            <Bar data={data} options={config} />
          </div>
        ) : (
          <div className="list-group list-widget">
            <h2 className="d-flex justify-content-center h-100 align-items-center text-muted">
              {t("dashboard.noData")}
            </h2>
          </div>
        )}
        </div>
    </DashboardCard>
  );
};

export default SalesAndPurchases;
