import React, { useCallback, useRef, useState } from 'react';
import { storiesOf } from '@storybook/react';
import DataTable, { defaultThemes } from "react-data-table-component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Loader from "./Layout/Loader";

const EditableCell = ({ row, index, column, col, onChange }) => {

    const [value, setValue] = useState(row[column.selector]);

    const handleOnChange = e => {
        setValue(e.target.value);
        // eslint-disable-next-line no-unused-expressions
        onChange?.(e);
    };

    if (column?.editing) {
        return (
            <input
                type="text"
                className="form-control"
                name={column.selector}
                onChange={handleOnChange}
                value={value}
            />
        );
    }

    if (col.cell) {
        return col.cell(row, index, column);
    }
    return row[column.selector];
};

export const EditableTable = ({ data, columns, getEditedData }) => {
    const { t, i18n } = useTranslation('translations');
    const [innerData, setInnerData] = useState(data);
    const [editingId, setEditingId] = useState('');
    let formData = useRef({}).current;
    const isEditing = record => record.id === editingId;

    const formOnChange = event => {
        const nam = event.target.name;
        const val = event.target.value;

        formData = {
            ...formData,
            [nam]: val,
        };
    };

    const edit = record => {
        setEditingId(record.id);
    };

    const cancel = () => {
        setEditingId('');
    };

    const save = item => {
        const payload = { ...item, ...formData };
        const tempData = [...innerData];


        const index = tempData.findIndex(item => editingId === item.id);
        if (index > -1) {

            const item = tempData[index];
            tempData.splice(index, 1, {
                ...item,
                ...payload,
            });
            setEditingId('');
            getEditedData(item, payload);
            setInnerData(tempData);
        }
    };

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            cell: (row, index, column) => {
                const editing = isEditing(row);
                return (
                    <EditableCell
                        row={row}
                        index={index}
                        column={{ ...column, editing }}
                        col={col}
                        onChange={formOnChange}
                    />
                );
            },
        };
    });


    const createColumns = useCallback(() => {
        return [
            ...mergedColumns,
            {
                name: 'Actions',
                allowOverflow: true,
                //width: '200px',
                cell: row => {
                    const editable = isEditing(row);
                    if (editable) {
                        return (
                            <div>
                                <button
                                    type="button"
                                    style={{ padding: '0.2em', marginRight: '0.5em' }}
                                    className="btn btn-primary"
                                    onClick={() => save(row)}
                                >
                                    <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
                                </button>
                                <button
                                    type="button"
                                    style={{ padding: '0.2em' }}
                                    className="btn btn-secondary"
                                    onClick={cancel}
                                >
                                    {t('common.cancel')}
                                </button>
                            </div>
                        );
                    }
                    return <button
                        type="button"
                        onClick={() => edit(row)}
                        className="btn btn-outline-info rounded-pill"
                    >
                        edit
                    </button>;
                },
            },
        ];
    }, [mergedColumns]);

    return (
        <DataTable
            //title=""
            columns={createColumns()}
            data={innerData}
            defaultSortField="title"

        />
    );
};

storiesOf('Editable', module)
    .add('Editable Row', EditableTable);