import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mainAxios from '../../../services/mainAxios'
import Loader from '../../Layout/Loader'
import { toast } from 'react-toastify'
import { useTranslation, withTranslation } from "react-i18next";
import { useAppContext } from '../../../AppContext'
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
import selectStyle from '../../../design/selectStyle'
import ReactSelect from '../../ReactSelect'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { subscriptionStatus as statuses } from '../../../services/data';
import func, { globalHandleChange } from '../../../services/functions'
import { useSubscriptionRequests } from '../../../requests/SubscriptionsRequests'
import { AddonsList } from './Addons'



const AddEditSubscription = ({ company, subscription, onSubscriptionChange }) => {
  const isEdit = !!subscription?.id
  const { t } = useTranslation('translations');
  const [subscriptions, setSubscriptions] = useState([])
  const [pageLoading, setPageLoading] = useState(false)
  const [state, setState] = useState(
    {
      companyId: company.id,
      subscriptionId: subscription?.id,
      startDate: new Date(moment()),
      endDate: new Date(moment().add(6, 'months')),
      period: 12,
      notes: "",
      addons: []
      // "noInvoicesTot": 100,
      // "noTcrsTot": 10,
      // "noUsersTot": 5,
    }
  )
  const [addons,setAddons]=useState([])
  const { saveCompanySubscription, getSubscriptionPackages,getSubscriptionById, actionLoading } = useSubscriptionRequests({ company, onSubscriptionChange })

  useEffect(()=>{
    (async () => {
      if(!subscription?.id) return 
      setPageLoading(true)
      const content=await getSubscriptionById(subscription.id)
      console.log({content})
      if(content){
        setPageLoading(false)
        setState(content)
      }
    })();
  },[company,subscription])
console.log({state})

  useEffect(() => {
    if (state.startDate && state.period != null) {
      const newEndDate = moment(state.startDate).add(state.period, 'months');
      setState(prevState => ({
        ...prevState,
        endDate: newEndDate.toDate()
      }));
    }
  }, [state.startDate, state.period]);


  useEffect(() => {
    (async () => {
      let subs = await getSubscriptionPackages()
      setSubscriptions(subs ?? [])
    })();
  }, [company?.id])


  const handleSubmit = (e) => {
    e.preventDefault();
    saveCompanySubscription({ state })
  };


  const handleChange = (key, value, length) => globalHandleChange({ key, value, setState, length })


  let periodOptions = [
    {
      label: "6 muaj",
      value: 6
    },
    {
      label: "1 vit",
      value: 12
    },
    {
      label: "2 vite",
      value: 24
    },
    {
      label: "3 vite",
      value: 36
    },
    {
      label: "Other",
      value: null
    }
  ]

  const mappedStatuses = useMemo(() => {
    return Object.keys(statuses).map(statusKey => {
      return {
        label: func.capitalizeFirstLetter(statusKey),
        value: statuses[statusKey]
      }
    }) ?? []
  }, [])


  const checkCorporate = useCallback((value) => {
    const currSub = subscriptions?.find(sub => sub.id == state.subscriptionId)?.[value] ?? ""
    return (state?.subscriptionId == 6 && currSub == 0) ? '\u221E' : currSub
  }, [state.subscriptionId, subscriptions])

  if (pageLoading) return <Loader />

  return (
    <div className="row mt-4 mx-0">
      <form className=" mx-auto" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="first_name">{t("subscription.name")}</label>
              <Select
                styles={selectStyle}
                className="w-100"
                options={subscriptions}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.name}
                value={subscriptions.find(sub => sub.id == state.subscriptionId)}
                onChange={(sub) => handleChange("subscriptionId", sub.id)}
                name="name"
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="noInvoice">{t("navbar.invoices")}</label>
              <input
                type="text"
                className="form-control"
                value={checkCorporate("noInvoice")}
                onChange={() => { }}
                disabled />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="noUsers">{t("navbar.users")}</label>
              <input
                type="text"
                className="form-control"
                value={checkCorporate("noUsers")}
                onChange={() => { }}
                disabled />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="noTcrs">TCR</label>
              <input
                type="text"
                className="form-control"
                value={checkCorporate("noTcrs")}
                onChange={() => { }}
                disabled />
            </div>
          </div>
          {isEdit && <div className="col-12">
            <div className="form-group">
              <label htmlFor="subStatus">Status</label>
              <Select
                styles={selectStyle}
                className="w-100"
                options={mappedStatuses}
                value={mappedStatuses.find(status => status.value == state?.statusId)}
                onChange={(sub) => handleChange("statusId", sub.value)}
                name="subStatus"
              />
            </div>
          </div>}
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="first_name">{t("subscription.subPeriod")}</label>
              <Select
                // required={true}
                styles={selectStyle}
                className="w-100"
                options={periodOptions}
                value={periodOptions.find(opt => opt.value == state.period)}
                getOptionValue={(opt) => opt.value}
                onChange={(sub) => handleChange("period", sub.value)}
                name="period"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="startDate">{t("invoice.sDate")}</label>
              <DatePicker
                required={true}
                disabled={state.period != null}
                selected={state?.startDate}
                dateFormat="dd/MM/yyyy"
                onChange={date => handleChange('startDate', date)}
                customInput={<input className="form-control form-control-sm" />}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="endDate">{t("invoice.endDate")}</label>
              <DatePicker
                required={true}
                disabled={state.period != null}
                selected={state?.endDate}
                dateFormat="dd/MM/yyyy"
                onChange={date => handleChange('endDate', date)}
                customInput={<input className="form-control form-control-sm" />}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="notes">{t("item.notes")}</label>
              <textarea
                rows={2}
                type="text"
                maxLength={50}
                name="notes"
                value={state?.notes}
                onChange={(e) =>
                  handleChange("notes", e?.target?.value)
                }
                className="form-control"
              />
              <div className={`length-text ${state?.notes?.length == 50 ? "text-danger" : ""}`}>
                {state?.notes?.length ?? "0"}/50
              </div>
            </div>
          </div>
          {isEdit && state?.addons?.length>0 && ( <>
            <h3 className='ml-3'>Addons</h3>
          <AddonsList addons={addons} disabled={true}/>
          </>)}


        </div>
        <button
          type="submit mt-3"
          disabled={actionLoading}
          style={{ minWidth: "100px" }}
          className="btn btn-primary">
          {actionLoading ? <Loader size="xs" color="white" /> :
            (<>
              <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
            </>)}
        </button>
      </form>
    </div>
  )
}

export default AddEditSubscription
