import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CountUp from 'react-countup';
import func from '../../services/functions';

const StatisticsInfo = ({ allTotals }) => {
const { t } = useTranslation('translations')  

const card=(text,no,value,borderColor="#fbd3b2",showValue=true)=>{
  return (
    <div className="col-12 col-sm-6 col-xl d-flex">
      <div
        className="card-dashboard info-box-2"
        style={{ borderBottom: `6px solid ${borderColor}` }}
      >
        <div className="body w-100">
          <div className="d-flex">
            <div className="mr-3">{<FontAwesomeIcon icon={faFileInvoice} style={{color:"#c8cad7"}}/>}
            </div>
            <div className="content w-100">
              <div className="d-flex justify-content-between w-100">
              <div className="text" style={{ fontWeight: 450 }}>
                {text}
              </div>
              <div style={{ fontSize: "17px" }}>
                    <CountUp
                      start={0}
                      end={no}
                      delay={0}
                      duration={0.7}
                      prefix="#"
                      style={{ fontSize: "12px" }}
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  </div>
              </div>

              <div className="number">
                <div className="d-flex flex-column">
                 {showValue && <div className="font-weight-bold">
                    <CountUp
                      start={0}
                      end={parseFloat(value)}
                      delay={0}
                      duration={0.7}
                      separator="."
                      decimals={2}
                      suffix="L"
                      decimal="," 
                    />
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
return (
<div className="row flex-fill">
  {card("Total",allTotals?.successInvoicesNo ?? 0,((allTotals?.cashTaxInclusiveAmount ?? 0 )+(allTotals?.noncashTaxInclusiveAmount ?? 0)),"#92cddc")}
  {card(t("invoice.eInvoicesOption"),allTotals?.einvoicesNo ?? 0,allTotals?.einvoiceTaxInclusiveAmount ?? 0,"#fbd3b2")}
  {card("Cash",allTotals?.cashInvoicesNo ?? 0,allTotals?.cashTaxInclusiveAmount ?? 0,"#a092b0")}
  {card("Non-Cash",allTotals?.noncashInvoicesNo ?? 0,(allTotals?.noncashTaxInclusiveAmount ?? 0)-(allTotals?.einvoiceTaxInclusiveAmount ?? 0),"#36b9cc")}
    {card(t("invoice.corrective"), (allTotals?.cashCorrectiveNo ?? 0) + (allTotals?.noncashCorrectiveNo ?? 0),allTotals?.correctiveTotalWithVat ?? 0,"#95b3d7")}
    {card( t("home.error"),allTotals?.errorInvoicesNo ?? 0,0, "#f56582",false)
  }
</div>
  )
}

export default StatisticsInfo


