import React from "react";
import mainAxios from "../../services/mainAxios";
import {
    faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../Layout/Loader";

import Select from "react-select";


import { useTranslation } from "react-i18next";
import selectStyle from "../../design/selectStyle";
import PopOver from "../global/PopOver";



const UploadPayment = ({ user, selectedCompany, fullCompany, swal }) => {
    const { t, i18n } = useTranslation("translations");
    const [loading, setLoading] = React.useState(false);

    const company = user.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
    );

    const branchesOptions =
        company &&
        company.branches.map((data, index) => ({
            ...data,
            label: data.sellerName + " - " + data.sellerAddress,
            value: data.businUnitCode,
        }));

    const branch =
        branchesOptions.find((branch) => branch.default) ||
        branchesOptions[0];

    const tcrCode = branch.tcrTypes?.[0]?.tcrCode || "";



    const [state, setState] = React.useState({
        tcrCode,
        businessUnitCode:branch.businUnitCode || "",
        opCode: user?.userCompanyOperator?.find(
            (uco) => parseInt(uco.company) === parseInt(selectedCompany)
        )?.operator,
        internalId:null,
        file: null,

    });


    let tcrList = [
        ...new Set(
            state?.businessUnitCode ?(branchesOptions.find(bu=>bu.businUnitCode===state.businessUnitCode)?.tcrTypes?.map(tcr=>tcr.tcrCode) ?? []):(branch.tcrTypes?.map(tcr=>tcr.tcrCode) ?? [])
        )
    ]?.map(tcrCode => ({
        label: tcrCode,
        value: tcrCode,
    }))

    if (!tcrList) tcrList = [{
        label: state.tcrCode,
        value: state.tcrCode,
    }]
  
  const [name,setName]=React.useState("")

    const submit = (e) => {
        e.preventDefault();
        setLoading(true);

        mainAxios
            .post("apiEndpoint/registerPayment", {
                object: "RegisterPayment",
                content: {
                    ...state
                },
                nuis: user.companiesRights.find(
                    (company) => company.id === parseInt(selectedCompany)
                )?.nuis,
            })
            .then((res) => {
                if (res?.data?.status === true) {
                    toast.success(t(["navbar.uploadSuccess"]), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        containerId: "all_toast",
                    });
                    swal.close();
                }else if(res?.data?.error){
                    toast.error(res?.data?.message, { containerId: "all_toast" });
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleFile = (e) => {
        const file = e.target.files[0];
        const extension =
            file && file.name.split(".")[file.name.split(".").length - 1];
        if (
                extension ==="txt" || extension ==="json"
        ) {
            const reader = new FileReader();

            reader.onload = (readerEvent) => {
                let binaryString = readerEvent.target.result;
                setName(file.name)
                setState((state) => ({
                    ...state,
                    file: btoa(binaryString),
                }));
            };
            reader.readAsBinaryString(file);
        }
    };


    const handleInvoiceBusinessUnitCodeChange = (businessUnitCode) => {
        let tcrCode = branchesOptions.find(branch=>branch.businUnitCode===businessUnitCode)?.tcrTypes[0]?.tcrCode ?? ""
        
          setState(state => ({
          ...state,
          businessUnitCode,
          tcrCode
        }))
      }

    const handleChange = (key, value) => {

        setState((state) => ({
            ...state,
            [key]: value,
        }));
    };
    return (
        <form
            className="text-left"
            onSubmit={submit}
            id="upload_invoice_modal"
        >
            <div>

                <div className="card-body">
                <div className="form-group">
                        <label> {t('home.branches')} <PopOver title={t("hints.branches")} /></label>
                        <Select
                            styles={selectStyle}
                            options={branchesOptions}
                            isSearchable={true}
                            onChange={branch => handleInvoiceBusinessUnitCodeChange(branch.businUnitCode)}
                            value={branchesOptions.find(branch => branch.value === state.businessUnitCode)}
                            name="businessUnitCode"
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('home.tcr')}<PopOver title={t('hints.tcr')} /></label>
                        <Select
                            styles={selectStyle}
                            options={tcrList}
                            isSearchable={true}
                            onChange={tcr => handleChange("tcrCode", tcr.value)}
                            value={tcrList?.find(tcr => tcr.value === state.tcrCode) || ""}
                            name="tcrCode"
                        />
                    </div>
                    <div className="form-group">
                    <label>{t('company.operatorCode')}<PopOver title={t('company.operatorCode')} /></label>
                        <input type="text" name="operator" onChange={(e)=>handleChange("opCode",e.target.value)} className="form-control" value={state?.opCode ?? ""} />        
                    </div>
                    <div className="form-group">
                    <label>{fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? t('home.internalId')}<PopOver title={fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? t("hints.internalId")} /></label>
                        <input type="text" name="operator" value={state?.internalId ?? ""} onChange={(e)=>handleChange("internalId",e.target.value)} className="form-control"/> 
                      </div>
                    <div className="form-group">
                        <label htmlFor="certificate">{t("invoice.file")}</label>
                        <div className="custom-file">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="file"
                                name="file"
                                placeholder="Upload File"
                                accept=".txt,.json"
                                onClick={(e) => {
                                    e.target.value = null;
                                }}
                                onChange={handleFile}
                            />
                            <label className="custom-file-label" htmlFor="certificate">
                                {name ? name : t(["invoice.choose"])}
                            </label>
                        </div>
                    </div>
                    <button
                        className="btn btn-primary mt-3"
                        // disabled={loading || !state.file}
                        style={{ minWidth: "80px" }}
                        type="submit"
                    >
                        {loading ? (
                            <Loader size="xs" color="white" />
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                                {t("invoice.send")}
                            </>
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default UploadPayment;