import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React,{ useMemo } from "react";
import { useTranslation } from "react-i18next";
import func from "../../services/functions";
import { DashboardCard } from "../global/CommonComponents";

function CashBalance({ title , sessionData, todayTotalsByBranch, todayTotals }) {
  const { t } = useTranslation("translations");



let totalsToDisplay = useMemo(()=>{
 return sessionData?.businessUnitCode?todayTotalsByBranch?.filter(
  (stat) => stat?.businessUnitCode === sessionData?.businessUnitCode
)[0] : todayTotals
},[sessionData?.businessUnitCode,todayTotals])


  return (
    <DashboardCard title={title}>
          <div className="table-responsive">
            <table className="table mt-4 table-hover">
              <thead>
                <tr>
                  <th className="border-0"></th>
                  <th className="border-0"></th>
                  <th className="border-0">No.</th>
                  <th className="border-0 text-right"> {t("invoice.value")} </th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <th>
                    <FontAwesomeIcon icon={faCircle} className="text-success" />
                  </th>
                  <td>{t("dashboard.initialBalance")} </td>
                  <td>
                    <span>{totalsToDisplay?.cashInitNo ?? 0}</span>
                  </td>
                  <td className="text-right">{func.getFormattedMoneyValue(totalsToDisplay?.cashInit ?? 0)}L</td>
                </tr>
                <tr>
                  <th>
                    <FontAwesomeIcon icon={faCircle} style={{color: "#92cddc"}}/>
                  </th>
                  <td>{t("cashRegister.deposit")}</td>
                  <td>
                    <span>{totalsToDisplay?.cashDepositNo ?? 0}</span>
                  </td>
                  <td className="text-right">{func.getFormattedMoneyValue(totalsToDisplay?.cashDeposit ?? 0)}L</td>
                </tr>
                <tr>
                  <th>
                    <FontAwesomeIcon icon={faCircle} className="text-warning" />
                  </th>
                  <td>{t("dashboard.withdrawDash")}</td>
                  <td>
                    <span>{totalsToDisplay?.cashWithdrawNo ?? 0}</span>
                  </td>
                  <td className="text-right">{func.getFormattedMoneyValue(totalsToDisplay?.cashWithdraw ?? 0)}L</td>
                </tr>
                <tr>
                  <th>
                    <FontAwesomeIcon icon={faCircle} className="text-danger" />
                  </th>
                  <td>{t("dashboard.salesCash")}</td>
                  <td>
                    <span> {totalsToDisplay?.cashInvoicesNo ?? 0} </span>
                  </td>
                  <td className="text-right"> {func.getFormattedMoneyValue(totalsToDisplay?.banknoteCashTot ?? 0)}L </td>
                </tr>
                <tr>
                  <th>
                    <FontAwesomeIcon icon={faCircle} className="text-dark" />
                  </th>
                  <td>{t("invoice.corrective")}</td>
                  <td>
                    <span>{totalsToDisplay?.cashCorrectiveNo ?? 0}</span>
                  </td>
                  <td className="text-right">{func.getFormattedMoneyValue(totalsToDisplay?.cashCorrectiveTot ?? 0)}L</td>
                </tr>

              </tbody>
            </table>
                <div className="d-flex justify-content-end mt-0">
                  <div className="border p-2">
                 <span className="text-success font-weight-bold">{t("dashboard.totals")}: </span>  
                 {func.getFormattedMoneyValue((totalsToDisplay?.cashEnd ?? 0) +(totalsToDisplay?.banknoteCashTot ?? 0))}L
                  </div>
                </div>
          </div>
    </DashboardCard>
  );
}

export default CashBalance;
