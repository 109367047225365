import React from "react";
import { useTranslation } from "react-i18next";
import { TabView, TabPanel } from "primereact/tabview";
import PopOver from "../../global/PopOver";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import func from "../../../services/functions";
import { toast } from "react-toastify";
import mainAxios from "../../../services/mainAxios";
import { Form, Tab } from "react-bootstrap";
import DocumentPreview from "../../DocumentPreview";
import InvoiceHistoric from "../WizardSteps/InvoiceHistoric";

const FILE_SIZE_LIMIT = 1000 * 1024; // 500 KB

const DemoInvoiceTabsNAT = ({ invoiceState, invoiceState: { invoiceHistorics }, setInvoiceState, disabled, errors={} }) => {
  const { t, i18n } = useTranslation("translations");

  const handleNoteChange = (e, selectValue) => {
    let value = e ? e?.target?.value : selectValue;
    setInvoiceState((state) => ({
      ...state,
      paymentNote: value,
    }));
  };
  const handleTransportChange = (value, key) => {
    setInvoiceState((invoiceState) => {
      const content={
        ...invoiceState,
        hasTransport,
        [key]: value,
      }
      const { vehiclePlate, transporter, destinationAddress } = content
      const hasTransport = vehiclePlate?.length > 0 || transporter?.length > 0 || destinationAddress?.length > 0
      content.hasTransport = hasTransport
      return content
    });
  };

  const [filePreview, setFilePreview] = React.useState(null);
  const fileContainer = React.useRef();

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf,.docx,.xlsx,.xls,.txt,.xml,.png,.jpg,.jpeg",
    onDrop: async (files) => {
      let newFiles = [];
      let filesExceeded = 0;
      for (const file of files) {
        const extension =
          file && file.name.split(".")[file.name.split(".").length - 1];

        if (
          extension &&
          (extension === "xml" ||
            extension === "pdf" ||
            extension === "txt" ||
            extension === "xlsx" ||
            extension === "xls" ||
            extension === "png" ||
            extension === "jpg" ||
            extension === "jpeg")
        ) {
          let base64File = await func.toBase64(file);
          if (base64File.length * (3 / 4) < FILE_SIZE_LIMIT) {
            newFiles.push({
              file: base64File.substr(base64File.indexOf(",") + 1),
              name: file.name,
              extension,
              insertToUbl: false,
            });
          } else filesExceeded += 1;
        }
      }
      if (filesExceeded) {
        toast.warning(
          `${filesExceeded} File${
            filesExceeded === 1 ? "" : "s"
          } exceeded the limit! Limit is 1000 KB.`,
          { containerId: "all_toast" }
        );
      }
      const attachments = [...invoiceState.attachments, ...newFiles];

      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        attachments,
      }));
    },
  });

  const removeAttachment = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      attachments: [
        ...invoiceState.attachments.slice(0, index),
        ...invoiceState.attachments.slice(index + 1),
      ],
    }));
  };

  const previewFile = (attachment, e) => {
    e.stopPropagation();
    e.preventDefault();
    let base64 = attachment.file;
    if (base64) {
      setFilePreview({
        base64,
        name: attachment.name,
        extension: attachment.extension,
      });
      setTimeout(() => {
        fileContainer.current.classList.toggle("open");
      }, 0);
    } else {
      mainAxios
        .post("apiEndpoint/search", {
          object: "GetFileAttachment",
          value: attachment.path,
        })
        .then((res) => {
          if (res?.data?.status === true) {
            base64 = res.data.content[0];
            setFilePreview({
              base64,
              name: attachment.name,
              extension: attachment.extension,
            });
            setTimeout(() => {
              fileContainer.current.classList.toggle("open");
            }, 100);
          }
        });
    }
  };

  const closePreview = () => {
    setTimeout(() => {
      setFilePreview(null);
    }, 150);
    fileContainer.current.classList.toggle("open");
  };

  const addForUbl = (index) => {
    const attachment = invoiceState.attachments[index];
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      attachments: [
        ...invoiceState.attachments
          .slice(0, index)
          .map((attachment) => ({ ...attachment, insertToUbl: false })),
        {
          ...attachment,
          insertToUbl: true,
        },
        ...invoiceState.attachments
          .slice(index + 1)
          .map((attachment) => ({ ...attachment, insertToUbl: false })),
      ],
      invoiceHistorics: invoiceState.invoiceHistorics?.map(
        (invoiceHistoric) => ({
          ...invoiceHistoric,
          historicAttachments: [
            ...invoiceHistoric.historicAttachments.map((historicAttachment) => {
              return {
                ...historicAttachment,
                insertToUbl:
                  historicAttachment.attachmentPath === attachment.path
                    ? true
                    : false,
              };
            }),
          ],
        })
      ),
    }));
  };

  const attachments = invoiceState.attachments;
  const thumbs = attachments.map((attachment, index) => {
    const file = new File([attachment.file], attachment.name);
    return (
      <div className="d-flex flex-column">
        <div key={index} className="attachment">
          {file.name}
          {attachment.insertToUbl && (
            <div className="attachment__selected">
              <FontAwesomeIcon
                icon={faCertificate}
                className="attachment__selected--icon text-primary"
              />
            </div>
          )}
          <div className="attachment__actions">
            <button
              className="attachment__actions--button bg-secondary"
              onClick={(e) => previewFile(attachment, e)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <button
              className="attachment__actions--button bg-danger"
              onClick={(e) => removeAttachment(e, index)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
        {attachment.insertToUbl ? (
          <h5 className="my-auto text-center">{t("invoice.attached")}</h5>
        ) : (
          <button
            className="btn btn-sm btn-primary btn-block"
            onClick={() => addForUbl(index)}
          >
            {t("invoice.attach")}
          </button>
        )}
      </div>
    );
  });
  const transporterError = errors?.vehiclePlate || errors?.transporter || errors?.destinationAddress 
  return (
    <div>
      <TabView>
        <TabPanel header={t("item.notes")}>
            <div className="align-items-center">
              <div className="input-length-validation">
                <textarea
                  rows={4}
                  id="paymentNote"
                  type="text"
                  maxLength={200}
                  disabled={disabled}
                  name="paymentNote"
                  value={invoiceState?.paymentNote}
                  onChange={(e) =>
                    handleNoteChange(null, e.target.value, 200)
                  }
                  className="form-control"
                />
                <div className={`length-text ${invoiceState?.paymentNote?.length == 200 ? "text-danger" : ""}`}>
                  {invoiceState?.paymentNote?.length ?? "0"}/200
                </div>
              </div>

            </div>

        </TabPanel>
        <TabPanel header={t("purchases.attachments")}>
          <div>

            <div
              {...getRootProps({ className: "dropzone" })}
              disabled={disabled}
            >
              <input {...getInputProps()} />
              <p> {t("attachment.dragdrop")}</p>
            </div>
            <div className="attachment__container">{thumbs}</div>
            <div className="pdf-container" ref={fileContainer}>
              {filePreview && (
                <div className="pdf-viewer">
                  <div className="pdf-overlay" onClick={closePreview} />
                  <div className="pdf-content">
                    <DocumentPreview filePreview={filePreview} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel header={<div style={transporterError ? { color: "red",borderBottom:"red" } : {}}>Transport</div>}>
            <>
              <div className="row">
                <div className="col">
                  <label className="label-txt">
                    {t("invoice.transporterName")}
                    <span className="text-danger"> *</span>
                    <PopOver title="Name of the person doing the item transport" />
                  </label>
                  <input
                    name="transporter"
                    type="text"
                    className={`form-control form-control-sm ${errors?.transporter && "is-invalid"}`}
                    disabled={disabled}
                    value={invoiceState?.transporter}
                    onChange={(e) =>
                      handleTransportChange(e?.target?.value, "transporter")
                    }
                  />
                </div>
                <div className="col">
                  <label className="label-txt">
                    {t("invoice.vehiclePlate")}
                    <span className="text-danger"> *</span>
                    <PopOver title="Plate of the vehicle being used for transport" />
                  </label>
                  <input
                    name="vehiclePlate"
                    type="text"
                    className={`form-control form-control-sm ${errors?.vehiclePlate && "is-invalid"}`}
                    disabled={disabled}
                    value={invoiceState.vehiclePlate}
                    onChange={(e) =>
                      handleTransportChange(e?.target?.value, "vehiclePlate")
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="label-txt">
                    {t("invoice.destinationAddress")}
                    <span className="text-danger"> *</span>
                    <PopOver title="Address where the items are being transported to" />
                  </label>
                  <textarea
                    rows="2"
                    name="destinationAddress"
                    type="text"
                  className={`form-control form-control-sm ${errors?.destinationAddress && "is-invalid"}`}
                    disabled={disabled}
                    value={invoiceState?.destinationAddress}
                    onChange={(e) =>
                      handleTransportChange(
                        e?.target?.value,
                        "destinationAddress"
                      )
                    }
                  />
                </div>
              </div>
            </>
     
        </TabPanel>
        {
          invoiceHistorics ? 
          <TabPanel header='History'>
          <InvoiceHistoric invoiceState={invoiceState} />
          </TabPanel>:
          null
        }
       
      </TabView>
    </div>
  );
};

export default DemoInvoiceTabsNAT;
