import React from "react"
import { useAppContext } from "../../AppContext";

export const globalFilterStatisticCard = (
    typeOptions,
    { title, height = "75px", cardClasses = "grid-4", type = "toggle", showCount = true, userRoles,singleSelect, filters,user,handleParamsChange,getFilteredResults,onChange} = {
    }
  ) => {
    let params = { ...filters.params };

    return (
      <>
        <div className={`card card-fill filter-card`}>
          {title && <div className="filters-header">
            <span className="header-underline">{title}</span>
            </div>}
          <div
            className={`filters-list-group ${cardClasses}`}
            style={{ height }}
          >
            {typeOptions.map((opt) => {
              if (opt?.userRoles && !opt?.userRoles.includes(user?.role?.id)) return <></>
              const onChangeHandle = () => {
                if (opt.disabled) return;
                if (found) {
                  let value = opt?.multiSelect
                    ? [...params[opt.key]].filter((val) => val !== opt.value)
                    : null;
                    
                    if (opt?.isBoolean) value = "false";
                  handleParamsChange(null, opt.key, value);
                  if (opt?.excludeIfNull) { //needed in case we doen't need to send property to back if false
                    delete params[opt.key]
                    getFilteredResults({
                      data: {
                        ...filters,
                        params: { ...params },
                      }
                    });
                  } else {
                    getFilteredResults({
                      data: {
                        ...filters,
                        params: { ...params, [opt.key]: value },
                      }
                    });
                  }
                } else {
                  let value = opt?.multiSelect
                    ? [...(params[opt.key] ? params[opt.key] : []), opt.value]
                    : opt.value;
                    if (opt?.isBoolean) value = "true";
                  handleParamsChange(null, opt.key, value);
                  getFilteredResults({
                    data: {
                      ...filters,
                      params: { ...params, [opt.key]: value },
                    }
                  });
                }
              };

              let found = opt?.multiSelect
                ? params[opt.key]?.some((optValue) => optValue == opt.value)
                : opt.value == params[opt.key];
                if (opt?.isBoolean)
                    found = params[opt.key] == "true" ? true : false;
              return (
                <li
                  key={opt.value + opt?.key}
                  className={`filters-list-group-item ${opt?.className ?? ""}  `}
                  onClick={onChange?()=>onChange({opt,found,params,singleSelect}):onChangeHandle}
                >
                  {type == "checkbox" && (
                    <div className="custom-control pl-4 custom-checkbox mb-0">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="showDeleted"
                        checked={found}
                        onChange={() => { }}
                      />
                      <label
                        style={{
                          paddingTop: "2px",
                          paddingLeft: "6px",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        className="custom-control-label"
                      >
                        {opt.label}
                      </label>
                    </div>
                  )}
                  {type == "toggle" && (
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={found}
                        onChange={() => { }}
                      />
                      <label
                        className="custom-control-label"
                        style={{ fontSize: "14px" }}
                      >
                        {opt.label}
                      </label>
                    </div>
                  )}
                  {opt?.showCount == undefined && <span className={`badge rounded-pill rounded-pill-bg ${opt?.roundPillStyle}`} >
                    {opt?.count ?? 0}
                  </span>}
                </li>
              );
            })}
          </div>
        </div>
      </>
    );
  };
