import React, { useState, useRef } from "react";
import { Link, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mainAxios from "../../services/mainAxios";
import Loader from "../Layout/Loader";
import { toast } from "react-toastify";
import Page404 from "../Page404";
import { useAppContext, usePersistedState } from "../../AppContext";
import func from "../../services/functions";
import RequestLoader from "../Layout/RequestLoader";
import { useTranslation } from "react-i18next";
import "./companies.scss";
import axios from "axios";
import TooltipOverlay from "../global/TooltipOverlay";
import PurchaseNotifications from "./PurchaseNotifications";
import { TabView, TabPanel } from 'primereact/tabview';
import Users from "../Setup/Users/Users";
import Tcrs from "./Tcr/Tcrs";
import OperatorsCis from "../Setup/Users/OperatorsCis";
import Subscriptions from "./Subscriptions/Subscriptions";
import BankAccounts from "./BankAccounts/BankAccounts";
import Company from "./Company/Company";
import CompanyAttributes from "./CompanyAttributes";
import Branches from "./Branches/Branches";
import OldSubscriptions from "./Subscriptions/OldSubscriptions";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';



const ViewCompany = (props) => {
  const { t, i18n } = useTranslation("translations");
  const {
    company: [fullCompany, setFullCompany],
    user: [user],
    selectedCompany: [selectedCompany]
  } = useAppContext();
  const companyId = props.match.params.id;
  const companiesLength=props?.location?.state

  const [currentCompany, setCurrentCompany] = useState(null)
  const company = fullCompany?.id == companyId ? fullCompany : currentCompany
  const setCompany = fullCompany?.id == companyId ? setFullCompany : setCurrentCompany

  const [requestLoader, setRequestLoader] = useState(false);
  const [viewEditBranch, setViewEditBranch] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [logoLoading, setLogoLoading] = useState(true)
  const [filePreview, setFilePreview] = useState(null);
  const [sideBarVisible, setSideBarVisible] = usePersistedState("true", "sideBarViewCompany1");

  const getCompany = ({ reload = false, reloadParameters = true } = {}) => {
    if (!fullCompany?.id) return;
    if (((companyId == fullCompany?.id) && reload == false)) {
      if (reloadParameters) getLogo(fullCompany);
      setPageLoading(false)
      return;
    }
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCompany",
        value: "",
        params: {
          id: companyId,
        },
      })
      .then((res) => {
        setPageLoading(false)
        if (res.data.status) {
          const content = res.data.content[0]
          if (reloadParameters) getLogo(content);
          setCompany(res.data.content[0])
        }
      })
  }

  const editCompany = ({ newCompany } = {}) => {
    return mainAxios.post('apiEndpoint/saveOrUpdate', {
      object: "Company",
      content: newCompany ?? {
        ...company
      }, nuis: user.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
      )?.nuis,
    }).then(res => {
      if (res?.data?.status === true) {
        toast.success(t(['toast.updateCompany']), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          containerId: "all_toast"
        })
        return true
      }
      return false
    })
      .catch(err => {
        return false
      })
  }

  React.useEffect(() => {
    setFilePreview(null)
    getCompany()
  }, [fullCompany?.id, companyId]);



  const getLogo = (content) => {
    setLogoLoading(true)
    let config = {
      headers: {
        Authorization: "Bearer " + func.getCookie("token"),
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_JAVA_SERVER_URL}apiEndpoint/search`,
        {
          object: "GetCompanyLogo",
          nuis: content.nuis,
          company: content.id,
          value: "",
        },
        config
      )
      .then((res) => {
        setLogoLoading(false)
        if (
          res.data !== "" &&
          res.data.status !== false &&
          res?.data?.content.length > 0
        ) {
          setFilePreview({ base64: res?.data?.content[0], uploaded: true });
        }
      });
  };
  const handleCompanyInputs = (e, selectKey, selectValue) => {
    const key = e ? e.target.name : selectKey
    const value = e ? e.target.value : selectValue
    let content = { ...company,[key]: value }
    setCompany(content)
    return content
  }

  const tabViewRef = useRef(null);
  const sideBarToggle = ({ ...props } = {}) => (
    <div className="side-bar-handle" style={{ ...props }}>
      <div className="side-bar-handle-content ml-3" onClick={() => {
        setSideBarVisible(barVisible => barVisible === 'false' ? 'true' : 'false');
        if (tabViewRef.current) {
          tabViewRef.current.offsetWidth = tabViewRef.current.offsetWidth;
        }
      }}>
        <FontAwesomeIcon icon={sideBarVisible === 'false' ? faChevronRight : faChevronLeft} />
      </div>
    </div>
  );


  return !pageLoading ? (
    company ? (
      <div className="row mt-2">
        {sideBarVisible=="true" && <section className="col-md-4 page-container company-section" >
          <Company
            company={company}
            setCompany={setCompany}
            handleCompanyInputs={handleCompanyInputs}
            filePreview={filePreview}
            setPageLoading={setPageLoading}
            setFilePreview={setFilePreview}
            logoLoading={logoLoading}
            editCompany={editCompany}
          />
        </section>}

        <div className={`${sideBarVisible =="true"?"col-md-8":"col-md-12"} `}>
        {sideBarToggle({left:"-10px",zIndex:40})}
          <div className="card border-0 shadow mb-4">
            <div className="card-body">
            {/* activeIndex={activeTab} onTabChange={onTabChange} */}
            <TabView id="branches" ref={tabViewRef} scrollable={sideBarVisible === 'true'} key={sideBarVisible}>
              {[1,2].includes(user?.role?.id) && <TabPanel header={t("company.branches")}>
                  <Branches company={company} setCompany={setCompany} getCompany={getCompany} viewEditBranch={viewEditBranch} setViewEditBranch={setViewEditBranch} />
                </TabPanel>}
                {[1,2].includes(user?.role?.id) && <TabPanel header={"TCR"}>
                  <Tcrs company={company} setCompany={setCompany} getCompany={getCompany} />
                </TabPanel>}
                {[1].includes(user?.role?.id) &&
                  <TabPanel header={t("navbar.users")}>
                    <Users companyId={company?.id} />
                  </TabPanel>
                }
                {[1].includes(user?.role?.id) && <TabPanel header={t("navbar.operatorsCis")} >
                  <OperatorsCis company={company} />
                </TabPanel>}
                <TabPanel header={t("company.bankAccounts")}>
                  <BankAccounts company={company} getCompany={getCompany} setCompany={setCompany} />
                </TabPanel>
                <TabPanel header={t("company.parameters")}>
                  <CompanyAttributes company={company} getCompany={getCompany} setCompany={setCompany} handleCompanyInputs={handleCompanyInputs} editCompany={editCompany} />
                </TabPanel>
                {[1].includes(user?.role?.id) &&
                <TabPanel header={t("company.advanSettings")}>
                  <CompanyAttributes company={company}  isAdvanced={true}  getCompany={getCompany} setCompany={setCompany} handleCompanyInputs={handleCompanyInputs} editCompany={editCompany} />
                </TabPanel>}
                <TabPanel header={t("company.purchaseNotifications")}>
                  <div className="mt-3">
                    <PurchaseNotifications />
                  </div>
                </TabPanel>
                {[1].includes(user?.role?.id) &&<TabPanel header={t("navbar.subscriptions")}>
                  <div className="mt-3">
                    <Subscriptions handleCompanyInputs={handleCompanyInputs} editCompany={editCompany} company={company} getCompany={getCompany} setCompany={setCompany} />
                  </div>
                </TabPanel>}
                { [1].includes(user?.role?.id) &&
                <TabPanel header={t("subscription.oldSubscriptions")}>
                  <div className="mt-3">
                    <OldSubscriptions handleCompanyInputs={handleCompanyInputs} editCompany={editCompany} company={company} getCompany={getCompany} setCompany={setCompany} />
                  </div>
                </TabPanel>}
              </TabView>
            </div>
          </div>

        </div>
        {requestLoader && <RequestLoader />}
      </div>
    ) : (
      <Page404 />
    )
  ) : (
    <Loader />
  );
};

export default withRouter(ViewCompany);
