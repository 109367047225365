import React from 'react'

import { faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import Loader from '../Layout/Loader'
import { withRouter } from 'react-router'
import { useTranslation, } from "react-i18next";
import Axios from 'axios'
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from './react-simple-captcha'
import Lottie from "lottie-react";
import onlineBank from '../../design/assets/ProfiscLogin.json'

const ForgetPassword = (props) => {

    const { t, i18n } = useTranslation('translations');
    const [name, setName] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [captchaState, setCaptchaState] = React.useState({
        captcha: "",
        canLogin: false,
        error: null
    })

    const handleCaptchaChange = (e) => {
        const captcha = e.target.value
        setCaptchaState(captchaState => ({
            ...captchaState,
            captcha
        }))
    }
    React.useLayoutEffect(() => {
        const resetCaptchaLink = document.querySelector("#reload_href")
        resetCaptchaLink.innerHTML = `
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sync-alt"
                class="svg-inline--fa fa-sync-alt fa-w-16 "
                role="img" xmlns="http://www.w3.org/2000/svg"
                viewbox="0 0 512 512">
                <path
                    fill="currentColor"
                    d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z">
                </path>
            </svg>
        `
        loadCaptchaEnginge(6);
    }, [])
    const sendConfirmationEmail = (e) => {
        e.preventDefault();
        const baseUrl = process.env.REACT_APP_JAVA_SERVER_URL
        if (name.length === 0) {
            toast.error(t(["toast.pleaseProvideName"]), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                containerId: "all_toast"
            });
            return;
        }
        const captchaNeeded = true
        if ((captchaNeeded && validateCaptcha(captchaState.captcha.toLowerCase())) || !captchaNeeded) {
            setCaptchaState(captchaState => ({
                ...captchaState,
                error: null
            }))
            setLoading(true)
            Axios.get(baseUrl + 'general/sendConfirmationEmail?recipient=' + name,
            ).then(res => {
                // console.log({ res })
                if (res?.status === 200) {
                    setLoading(false)
                    if (res?.data.status === true) {
                        toast.success(t(['email.successConfirmationEmail']), {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            progress: undefined,
                            containerId: "all_toast"
                        })
                        props.history.push('/')
                    }
                }
                if (res.status === 200 && !res.data.status) {
                    toast.error(t("login.userNotFound"), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        containerId: "all_toast"
                    })
                }
            })
                .catch(error => {
                    setLoading(false)
                    toast.error("Try Again", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        containerId: "all_toast"
                    })
                })
        } else {
            setCaptchaState(captchaState => ({
                ...captchaState,
                error: t(['toast.captchaError']),
                captcha: ""
            }))
        }
    }


    return (
        <div className="authincation d-flex">
            <div className="login-aside text-center d-flex flex-column flex-row-auto">
                <div className="d-flex flex-column-auto flex-column  fade-in-effect">
                    <a href="#" className="go-back" onClick={(e) => { e.preventDefault(); props.history.goBack() }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </a>
                    <div className="text-center mb-5 pt-5">
                    <img src="/Logo-Profisc-2.png" alt="" style={{width:"280px", height:"90px"}} />
                    </div>
                    <h2 className="mb-4 text-center">Zgjidhje Smart per Fiskalizimin!</h2>
                </div>
                <Lottie className="aside-image mt-4" animationData={onlineBank} loop={false} />
            </div>

            <div
                className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
                <div className="d-flex justify-content-center h-100 align-items-center">
                    <div className="authincation-content style-2">
                        <div className="row no-gutters">
                            <div className="col-xl-12 tab-content">
                                <div className="auth-form form-validation">
                                    <form className="h-100 d-flex flex-column justify-content-between">
                                        <h1 className="text-center mb-4 text-black">
                                        {t('login.resettingPassword')}
                                        </h1>
                                        <p className="text-center pt-3">{t("login.resetPasswordDescription")}</p>

                                        <div className="form-group font-weight-bold position-relative">
                                            <input
                                                type="text"
                                                className="form-control py-4"
                                                id="name"
                                                name="name"
                                                placeholder={t('common.username')}
                                                value={name.to}
                                                onChange={(e) => setName(e.target.value)}
                                                required />
                                        </div>
                                        <div className="captcha d-flex align-items-center flex-wrap mb-3">
                                            <LoadCanvasTemplate />
                                            <div className="px-0 d-flex flex-wrap mt-3 mr-2">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    style={{ width: 198 }}
                                                    value={captchaState.captcha}
                                                    onChange={handleCaptchaChange} />
                                            </div>
                                        </div>
                                        <div className={"alert alert-danger px-1 py-1 order-3 text-center" + (captchaState.error ? "" : " d-none")}>
                                            {captchaState.error}
                                        </div>



                                            <div className="d-flex align-items-center text-center form-group mb-3">
                                                <button
                                                    className="btn submit btn-primary btn-block"
                                                    disabled={loading}
                                                    onClick={sendConfirmationEmail}
                                                    type="submit">
                                                    {loading ? <Loader size="xs" color="white" /> :
                                                        (<>
                                                       {t('email.send')}
                                                        </>)}
                                                </button>
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ForgetPassword)