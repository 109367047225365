import React, { useState } from 'react';
import DateRange from '../../global/DateRange';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../AppContext';
import Swal from "sweetalert2";
import mainAxios from '../../../services/mainAxios';
import { toast } from 'react-toastify';
import { swalAlert } from '../../../services/functions';

const OldSubscriptions = ({ company, setCompany, handleCompanyInputs,editCompany }) => {
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const isRoleDisabled = user?.role?.id != 1
  const { t } = useTranslation("translations");
  const [invoicesNumberLoading,setInvoicesNrLoading]=useState(false)

  const saveNewInvoicesNr =async  () => {
    const res=await swalAlert({title:"Are you sure you want to update Invoices Number?",t})
    if(res){
      setInvoicesNrLoading(true)
      mainAxios
          .post("apiEndpoint/saveOrUpdate", {
          object: "Company",
          content: {
              ...company,
          },
          })
          .then((res) => {
          if (res) {
              setCompany(res.data.content[0]);
              toast.success("Invoices Number Successfully Changed", {
              containerId: "all_toast",
              });
          }
          setInvoicesNrLoading(false) 
          })
          .catch(err=>{
            setInvoicesNrLoading(false) 
          })
    }
    };
 
  
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center" style={{ height: 60 }}>
        <div className="font-weight-bold text-dark align-middle" style={{ fontSize: 15 }}>
          {t("company.beggingingExpDate")}
        </div>
        <div style={{ minWidth: "22%", maxWidth: "22%", marginRight:"30%"}} className="d-flex justify-content-stretch align-items-center w-100">
          <DateRange
            showYearDropdown
            disabled={user.role.id != 1}
            fromDate={company.beginningDate}
            toDate={company.expirationDate}
            showDateMenu={false}
            isDisabled={isRoleDisabled}
            onChange={(dates) => {
              let fromDate = dates[0];
              let toDate = dates[1];
              setCompany((state) => ({
                ...state,
                beginningDate: fromDate,
                expirationDate: toDate,
              }));
            }}
          />
        </div>
      </div>
      <hr className="m-0" />

      {user.role.id === 1 && (
        <div className="d-flex justify-content-between align-items-center" style={{ height: 60 }}>
          <div className="font-weight-bold text-dark align-middle" style={{ fontSize: 15 }}>
            {t("company.invoicesNumber")}
          </div>
          <div style={{ minWidth: "22%", maxWidth: "22%", marginRight:"30%" }} className="d-flex justify-content-stretch align-items-center w-100">
            {user.role.id === 1 ? (
              <div className="input-group">
                <input
                  type="number"
                  step="1"
                  min="1"
                  id="invoicesNr"
                  name="invoicesNr"
                  className="form-control"
                  value={company.invoicesNr || ""}
                  onChange={(e)=>handleCompanyInputs(null,"invoicesNr",e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-sm btn-outline-primary"
                    type="button"
                    disabled={invoicesNumberLoading}
                    onClick={saveNewInvoicesNr}
                  >
                    {t("common.save")}
                  </button>
                </div>
              </div>
            ) : (
              company.invoicesNr
            )}
          </div>
     
        </div>
        
      )}
      <hr className="m-0" />
      <div className="d-flex justify-content-between align-items-center" style={{ height: 60 }}>
  <div className="font-weight-bold text-dark align-middle" style={{ fontSize: 15 }}>
    {t("company.active")}
  </div>
  <div style={{ minWidth: "22%", maxWidth: "22%", marginRight: "30%" }} className="d-flex justify-content-stretch align-items-center w-100">
  <div className="custom-control custom-switch mb-3">
         <input 
             type="checkbox" 
             className="custom-control-input" 
             id="company_status"
             checked={company.active}
             disabled={isRoleDisabled}
             onChange={(e) => { const newCompany =  handleCompanyInputs(null, 'active', e.target.checked); editCompany({newCompany})}}/>
         <label className="custom-control-label" htmlFor='company_status'>
         </label>
     </div>
  </div>
</div>

      
    </div>
  );
};

export default OldSubscriptions;
