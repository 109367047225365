import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppContext } from "../../AppContext";
import mainAxios from "../../services/mainAxios";
import Loader from "../Layout/Loader";
import Page404 from "../Page404";

export default function ViewTcrTypes(props) {
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const { t } = useTranslation("translations");

  const [state,setState]=React.useState(null)
  const [tcrLoading,setTcrLoading]=React.useState({id:null,loading:false});
  const [loading,setLoading]=React.useState(true);
  const mainTcrData = state?.tcrTypes.find(
    (data) => data.businUnitCode === state.businUnitCode
  );
  const removeDuplicatedDataFromTcr = state?.tcrTypes.filter((tcr, i) => {
    return (
      state?.tcrTypes
        .map(
          (val) => val.softCode && val.tcrCode && val.type && val.operatorCode
        )
    //     .indexOf(tcr.softCode && tcr.tcrCode && tcr.type && tcr.operatorCode) ==
    //   i
    );
  });

  React.useEffect(() => {
    const branchId = props.match.params.branchId;
    const companyId = props.match.params.companyId;
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetBranches",
        value: "",
        params: {
          id: branchId,
        },
        company:companyId
      })
      .then((res) => {
        if (res?.data?.status===true) {
          setState((state)=>res.data.content[0])
        } 
        setLoading(false);
      })
      .catch(e=>setLoading(false))
  }, []);

const updateTcr=(tcrType) => {
  const tcrIndex=state.tcrTypes.findIndex((tcr)=>tcr.id==tcrType.id)

  setTcrLoading({id:tcrType.id,loading:true})
  
  mainAxios.post('apiEndpoint/saveOrUpdate', {
    object: "UpdateTCR",
    nuis: user.companiesRights.find(
      (company) => company.id === parseInt(selectedCompany)
    )?.nuis,
    content: {
      id:tcrType?.id,
      value:null,
      issuerNUIS:tcrType?.issuerNUIS,
      businUnitCode: tcrType?.businUnitCode,
      tcrIntID:tcrType?.tcrIntID,
      allowInvoice: tcrType?.allowInvoice,
      allowPurchase: tcrType?.allowPurchase,
    },
}).then((res)=>{
  setTcrLoading({id:tcrType.id,loading:false})
  if(res?.data?.status === true) {
    toast.success(t(['toast.tcrUpdated']), { containerId: "all_toast" })
  }
})
}

const handleChange = (e, selectKey, selectValue,tcrId) => {
  const tcrIndex=state.tcrTypes.findIndex((tcr)=>tcr.id==tcrId)
  if(tcrIndex<0) return;
  const key = e ? e.target.name : selectKey;
  let value = e ? e.target.value : selectValue;
  setState((state)=>{
    return {
      ...state,
      tcrTypes:[
        ...state.tcrTypes.slice(0,tcrIndex),
        {
          ...state.tcrTypes[tcrIndex],
          [key]:value
        },
        ...state.tcrTypes.slice(tcrIndex+1),

      ]
    }
  })

};
return !loading ? (
  state ? (
    <div className="row mt-5">
    <div className="col-md-8 p-0 mx-auto">
      <div className="card border-0 shadow mb-4">
        <div className="card-header border-0">
          <h3>Tcr Types</h3>
        </div>
        <div className="card-body p-0">
          <div className="row m-0 pt-4">
            <div className="col-md-4">
              <div className="form-group">
                <label>Business Unit Code</label>
                <input
                  type="text"
                  class="form-control"
                  value={mainTcrData?.businUnitCode}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Issuer NUIS</label>
                <input
                  type="text"
                  class="form-control"
                  value={mainTcrData?.issuerNUIS}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Maintainer Code</label>
                <input
                  type="text"
                  class="form-control"
                  value={mainTcrData?.maintainerCode}
                  disabled
                />
              </div>
            </div>
          </div>
          {/* {state?.tcrTypes?.map((data,index) =>(
                         <Fragment key={index}>
                              <div className="row" key={index}>
                              <div className="col-md-4">
                                  <div className="form-group">
                                      <label>Business Unit Code</label>
                                      <input  type="text"  class="form-control"  value={data?.businUnitCode} disabled/>
                                  </div>
                              </div>
                              <div className="col-md-4">
                                  <div className="form-group">
                                      <label>Issuer NUIS</label>
                                      <input type="text"  class="form-control" value={data?.issuerNUIS} disabled/>
                                  </div>
                              </div>
                              <div className="col-md-4">
                                  <div className="form-group">
                                      <label>Maintainer Code</label>
                                      <input  type="text"  class="form-control"  value={data?.maintainerCode} disabled/>
                                  </div>
                              </div>
                           
                          </div>
                         </Fragment>
                      ))} */}
          <div
            style={{
              borderBottom: " 1px solid rgba(0, 0, 0, 0.1) ",
              width: "100%",
              margin: "15px auto",
            }}
          ></div>
          {state.tcrTypes.map((data, index) => (
            <Fragment key={index}>
              <div className="row tcr-type-strip m-0 mb-2 " key={index}>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Operator Code</label>
                    <input
                      type="text"
                      class="form-control"
                      value={data?.operatorCode}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Tcr Code</label>
                    <input
                      type="text"
                      class="form-control"
                      value={data?.tcrCode}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Soft Code</label>
                    <input
                      type="text"
                      class="form-control"
                      value={data?.softCode}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Type</label>
                    <input
                      type="text"
                      class="form-control"
                      value={data?.type}
                      disabled
                    />
                  </div>
                </div>

                <div className="d-flex flex-wrap align-items-center ml-3 mb-4 mb-md-2" style={{gap:"20px"}}>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"allowPurchase"+data.id}
                      checked={data.allowPurchase}
                      onChange={(e)=>handleChange("", "allowPurchase", e.target.checked,data.id)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"allowPurchase"+data.id}
                    >
                      {/* {t(["bankAccount.activate"])} */}
                      Allow Purchase
                    </label>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"allowInvoice"+data.id}
                      checked={data.allowInvoice}
                      onChange={(e)=>handleChange("", "allowInvoice", e.target.checked,data.id)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"allowInvoice"+data.id}
                    >
                      {/* {t(["bankAccount.activate"])} */}
                      Allow Invoice
                    </label>
                </div>
                  <button
                  className="btn btn-outline-primary btn-sm"
                  disabled={tcrLoading.id==data.id && tcrLoading?.loading}
                  onClick={()=>updateTcr(data)}
                  >
                  {tcrLoading.id==data.id && tcrLoading?.loading ? <Loader size="xs" color="white" /> : "Save"}
                  </button>
                </div>



              </div>
             </Fragment>
          ))}
          <button
            className="btn btn-primary m-3"
            onClick={(e) => {
              e.preventDefault();
              props.history.goBack();
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  </div>
) : (
    <Page404 />
  )
) : (
  <Loader />
);
}