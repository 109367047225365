import React, { useState } from "react"
import Table from "../Table"

import mainAxios from '../../services/mainAxios'

import "react-datepicker/dist/react-datepicker.css"
import { useAppContext } from "../../AppContext"
import { toast } from "react-toastify"
import Loader from "../Layout/Loader"

import { useTranslation, withTranslation } from "react-i18next";

const TaxPayers = () => {

    const { t, i18n } = useTranslation('translations');
    const [state, setState] = useState({
        filters: {
            object: "GetTaxpayersRequest",
            value: ""
        }
        
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [taxPayers, setTaxPayers] = useState([])

    const { selectedCompany: [selectedCompany,] } = useAppContext()

    const getFilteredResults = () => {
        setLoading(true)

        console.log("filters", state.filters.value)
        //console.log("matches",new RegExp("[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}").test(value));
        const correctNIPT = new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(state?.filters?.value);
        console.log({correctNIPT})
        //console.log({correctNIPT})
        if(!correctNIPT) {
            setError(true)
            setLoading(false)
        } else {
            mainAxios.post('apiEndpoint/search', state.filters).then(res => {
                if (res?.data?.status === true) {
                    setTaxPayers(res.data.content)
                    setError(false)
                    if (res.data.content.length === 0) toast.warning(t(['toast.noTaxPayer']), { containerId: "all_toast" })
                }
                else {
                    setTaxPayers([])
                }
                setLoading(false)
            }).catch(e => {
                setLoading(false)
            })
        }

        // if (state.filters) {
            
        //     if (correctNIPT) {
        //         mainAxios.post('apiEndpoint/search', state.filters).then(res => {
        //             if (res?.data?.status === true) {
        //                 setTaxPayers(res.data.content)
        //                 if (res.data.content.length === 0) toast.warning(t(['toast.noTaxPayer']), { containerId: "all_toast" })
        //             }
        //             else {
        //                 setTaxPayers([])
        //             }
        //             setLoading(false)
        //         }).catch(e => {
        //             setLoading(false)
        //         })
        //     }
        //     else {

        //     }
        // }

    }

    React.useEffect(() => {
        if (state.filters.value.length > 0) getFilteredResults()
    }, [selectedCompany])

    const columns = [
        {
            name: t(['taxpayer.taxpName']),
            selector: 'name',
            sortable: true
        },
        {
            name: 'NIPT',
            selector: 'tin'
        },
        {
            name: t(['taxpayer.country']),
            selector: 'country',
            sortable: true
        },
        {
            name: t(['taxpayer.town']),
            selector: 'town',
            sortable: true
        },
        {
            name: t(['taxpayer.address']),
            selector: 'address',
            width: '450px',
            sortable: true
        }
    ];

    const setNipt = (e) => {
        const value = e.target.value
        //[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}
        setState(state => ({
            ...state, filters: { ...state.filters, value }
        }))


    }

    return (
        <>
            <h3 className="my-2">{t('navbar.taxPayers')}</h3>
            <div className="card border-0 shadow">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4 col-md-3 col-lg-2 col-6">
                            <label className="w-100">NIPT:</label>
                            <input
                                className="form-control"
                                placeholder={t('taxpayer.nipt')}
                                name="nipt"
                                value={state.filters.value}
                                onChange={setNipt} />
                        </div>
                        <div className="col-sm-4 col-md-3 col-lg-2 col-6 d-flex align-items-end">
                            <button
                                className="btn btn-sm btn-primary"
                                disabled={loading}
                                style={{ minWidth: "80px" }}
                                onClick={() => getFilteredResults()}>
                                {loading ? <Loader size="xs" /> : t(['navbar.search'])}
                            </button>
                        </div>

                    </div>
                    
                    <div className="mt-3 form-text text-danger">
                        <p>{!error ? "" : "Please enter the correct NIPT format, ex: K72017027P"}</p>
                    </div>
                    <div className="reports mt-2">
                        <Table
                            columns={columns}
                            data={[...taxPayers]}
                            expandableKeys={[
                                { name: 'address', value: t(['taxpayer.address']) }
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaxPayers