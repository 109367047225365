import { faDownload, faFileUpload, faUpload } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react'
import Loader from './Layout/Loader';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as xlsx from 'xlsx';
import { toast } from 'react-toastify';
import selectStyle from '../design/selectStyle';
import { useAppContext } from '../AppContext';
import Select from "react-select"
import { typeOfVatCategoryOptions } from './CustomInvoices/WizardSteps/data';


const ImportExcel = ({ header = "Import Excel",buttonLabel="Import Items",onItemsImport,requiredColumns,isWtn,vatTypeOptions }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { t, i18n } = useTranslation("translations");
  const { unitsOfMeasure } = useAppContext()
  const [headersInvalid,setHeadersInvalid]=useState([])
  const [showMore,setShowMore]=useState(false)
  let unitsOfMeasureDefault =
  unitsOfMeasure?.find((unitsOfMeasure) => unitsOfMeasure?.code === "C62") ??
  null;

  const defaultState={
    file: null,
    unitOfMeasureId:unitsOfMeasureDefault,
    vatCategoryDefault:"20",
    importedItems:null,
    headers:null
  }

  const [state, setState] = React.useState(defaultState);


  const handleFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const headers = [];
  
        // Get headers
        const range = xlsx.utils.decode_range(worksheet['!ref']);
  
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = { c: C, r: range.s.r }; // Assuming headers are in the first row
          const headerCell = xlsx.utils.encode_cell(cellAddress);
          if(worksheet?.[headerCell]?.v){
            headers.push(worksheet[headerCell].v);
          }
        }
  
        // Get sheet data excluding headers
        const json = xlsx.utils.sheet_to_json(worksheet);
        setState((prevState) => ({
          ...prevState,
          importedItems: json,
          name: file.name,
          headers: headers,
        }));
        itemsColsInvalid([...headers])

      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const itemsColsInvalid=(incomingHeaders)=>{
    let _headersInvalid = []
    let headers=incomingHeaders ?? state?.headers
    requiredColumns.forEach(reqCol => {
      if (!headers.includes(reqCol)) {
        _headersInvalid.push(reqCol)
      }
    }
    )
    setHeadersInvalid(_headersInvalid)

    if(_headersInvalid.length==0){
      return false;
    }
    return true
  }

   const onSubmit=()=>{
    if(!state?.importedItems && !state?.headers){
      toast.warning(t("hints.selectFile"), {
        containerId: "all_toast",
      });
    }else{
      if(itemsColsInvalid()==true) return 
      onItemsImport({...state,unitsOfMeasureDefault:state?.unitOfMeasureId ?? unitsOfMeasureDefault})
      setState(defaultState)
      setDialogOpen(false)
    }
  }

  const description= i18n.language == "alb"
  ? "Kolonat description, vatCategory, unitOfMeasureId nese lihen bosh do te marrin vlerat default qe jane perzgjedhur te Defaults"
  : "Columns description, vatCategory ,unitOfMeasureId if left empty will get the default values in the default section"

  return (
    <>
      <Dialog
        header={header}
        visible={dialogOpen}
        onHide={() => setDialogOpen(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        className="container mx-auto"
      >
        <div className="form-group">
          <div className="alert alert-info py-1" role="alert">
            {t("common.formatdocument")},Excel
            {!isWtn && 
            <>
              <br />
              <br />
              {!isWtn && description}
              <br />
              <br />
              {vatTypeOptions && <div>
                {t("invoice.vatCategoryZero")} <span style={{ textDecoration: "underline" }}>vatExemptionReason</span>{t("invoice.possibleValues")}<span style={{ textDecoration: "underline", cursor: "pointer", display: "block" }} onClick={() => setShowMore(more => !more)}>{!showMore ? t("invoice.more") : t("invoice.less")}</span>
                {showMore && <div>
                  <div>Bosh</div>
                  {vatTypeOptions.slice(1).map(vat => {
                    return <div>
                      {vat.label}:   <span style={{ fontWeight: "700" }}>{vat.value}</span>
                    </div>
                  })}
                </div>}
              </div>}
            </>
            }

          </div>
          <label htmlFor="customerFile">{t("invoice.file")}</label>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="file"
              name="file"
              placeholder="Upload File"
              accept=".csv,.xlsx"
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={handleFile}
            />
            <label className="custom-file-label" htmlFor="customerFile">
              {state.name ? state.name : t(["invoice.choose"])}
            </label>
          </div>
          {headersInvalid?.length>0 && <div class="alert alert-danger py-2 mt-3" role="alert">
            {t("invoice.columnsDontExist")}<br/>
            {headersInvalid.map(field=><div style={{textDecoration:"underline",display:"inline",marginRight:10}}>{field} </div>)}
            {t("invoice.followTheExample")}
          </div>}
        </div>
        <h3 style={{fontSize:14}}>Defaults

        </h3>
        <div className="row">
        <div className="col-12">
          <label className="w-100" htmlFor="uiType">
                {t("item.measure")}
              </label>
              <Select
                options={unitsOfMeasure}
                value={state.unitOfMeasureId ?? unitsOfMeasureDefault}
                onChange={(option) => {setState((state)=>({...state,unitOfMeasureId:option})) }}
                styles={selectStyle}
                getOptionLabel={(option=>option?.code + '-'+ (
                  i18n.language == "alb"
                ? option?.albanianName
                : option?.englishName
                ))}
                getOptionValue={opt=>opt}
              />
          </div>
          {!isWtn && <div className="col-12">
          <label className="w-100" htmlFor="uiType">
                { t("item.vatCategory")}
              </label>
              <Select
                options={typeOfVatCategoryOptions}
                value={typeOfVatCategoryOptions.find(opt=>opt.value===state.vatCategoryDefault)}
                getOptionValue={opt=>opt.value}
                onChange={(option) => {setState((state)=>({...state,vatCategoryDefault:option.value})) }}
                styles={selectStyle}
              />
          </div>}
        </div>
        <button
          className="btn btn-primary mt-3"
          onClick={onSubmit}
          // disabled={loading || !state.file}
          style={{ minWidth: "80px" }}
          type="button"
        >
          <FontAwesomeIcon icon={faUpload} className="mr-2" />
          {t("common.import")}
        </button>
      </Dialog>
      {/* <small className="ml-3"> */}
        <button
          onClick={(e) => { setDialogOpen(true);setState(defaultState) }}
          className="w-full w-100 btn btn-light bg-white shadow-sm btn-sm text-primary d-flex align-items-center py-1 ml-3"
        >
          <FontAwesomeIcon icon={faFileUpload} className="mr-2" />
          <div
            onClick={(e) => { }}
            className="text-dark"
          >
          {t("invoice.importItems")}
          </div>
        </button>
      {/* </small> */}
    </>

  )
}

export default ImportExcel