import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

const GeneratPurchaseInfo = ({ invoice }) => {
  const inputRef = useRef(null);
  const { t, i18n } = useTranslation("translations");

  const daysRemaining=()=>{
    let today = moment()
    
    let paymentRequestedDate = moment(invoice.dueDateTime)
    let daysLeft = paymentRequestedDate.diff(today, 'days')
    if(daysLeft < 0){
      return 0;
    }else{  
    return daysLeft
  }
}
   
  return (
    <div className="row">
      <div className="col-md-12 d-flex">
        <div className="card border-0 shadow mb-4 p-0 w-100">
          <div className="card-header">
            <h3 className="card-title mb-0">
              {t("invoice.generalPurchaseInfo")}
            </h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 d-flex">
                <div className=" w-100 mb-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="supplierInfo"> {t("invoice.suppliersInfo")}</label>
                        <input
                          type="text"
                          name="supplierInfo"
                          id="supplierInfo"
                          className="form-control"
                          value={( invoice?.connectionTransactional?.name ?? "") +( invoice?.connectionTransactional?.tin?"," + (invoice?.connectionTransactional?.tin ?? ""):"") }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="docNumber">
                          {t("invoice.docNumber")}
                        </label>
                        <input
                          type="text"
                          name="docNumber"
                          id="docNumber"
                          className="form-control"
                          value={invoice?.docNumber ?? ""}
                          onChange={()=>{}}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="supplierInfo">
                          {t("invoice.invoiceDate")}
                        </label>
                        <input
                          type="text"
                          name="invoiceDate"
                          id="invoiceDate"
                          className="form-control"
                          value={moment(invoice?.recordDateTime).format("DD/MM/YYYY")  ?? ""}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="supplierInfo">{t("home.amount")}</label>
                        <input
                          type="text"
                          name="ammount"
                          id="ammount"
                          className="form-control"
                          value={invoice?.amount ?? ""}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="daysRemaining">{t("invoice.daysRemain")}</label>
                        <input
                          type="text"
                          name="daysRemaining"
                          id="daysRemaining"
                          className="form-control"
                          value={invoice?.dueDateTime?daysRemaining():""}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="cisStatusUnique">{t("purchases.cisStatus")}</label>
                        <input
                          type="text"
                          name="cisStatusUnique"
                          id="cisStatusUnique"
                          className="form-control"
                          value={invoice?.myPurchaseStatus?.name ?? ""}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="cisStatusUnique">FIC</label>
                        <input
                          type="text"
                          name="cisStatusUnique"
                          id="cisStatusUnique"
                          className="form-control"
                          value={invoice?.purchaseInvoiceExtracted?.fic ?? ""}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="eicGeneral">EIC</label>
                        <input
                          type="text"
                          name="eicGeneral"
                          id="eicGeneral"
                          className="form-control"
                          value={invoice?.eic ?? ""}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="iicGeneral"> {t("invoice.IIC")}</label>
                        <input
                          type="text"
                          name="iicGeneral"
                          id="iicGeneral"
                          className="form-control"
                          value={invoice?.purchaseInvoiceExtracted?.iic ?? ""}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratPurchaseInfo;
