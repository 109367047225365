import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mainAxios from "../../services/mainAxios";
import Loader from "../Layout/Loader";
import DatePicker from "react-datepicker";
import moment from "moment";

const GenerateNslf = () => {
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany, setSelectedCompany],
  } = useAppContext();
  const { t } = useTranslation("translations");
  const [loading, setLoading] = React.useState(false);

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const defaultParams = {
    issuerNuis: "",
    dateTimeCreated: "",
    invoiceNumber: "",
    businessUnitCode: "",
    tcrCode: "",
    softCode: "",
    totalPrice: "",
  };

  const [errors, setErrors] = useState(defaultParams);

  const [nslf,setNslf]=useState(null)

  const [params, setParams] = useState({
    ...defaultParams,
    issuerNuis: company.nuis,

  });

  const handleChange = (e, selectKey, selectValue, length) => {
    const key = e ? e?.target?.name : selectKey;
    let value = e ? e?.target?.value : selectValue;
    if (errors[key]) {
      setErrors((prevState) => {
        return { ...prevState, [key]: null };
      });
    }

    setParams({
      ...params,
      [key]: value,
    });
  };

  const validateCode=(code)=>{
    return new RegExp("[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}").test(code);
  } 

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      issuerNuis,
      dateTimeCreated,
      invoiceNumber,
      businessUnitCode,
      tcrCode,
      softCode,
      totalPrice,
    } = params;
    
    let checkNiptFormat=new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(issuerNuis);

    let checkError =
      !issuerNuis ||
      !dateTimeCreated ||
      !invoiceNumber ||
      !businessUnitCode ||
      !validateCode(businessUnitCode) || 
      !tcrCode ||
      !softCode ||
      !totalPrice || 
      !validateCode(tcrCode) ||
      !checkNiptFormat;
    if (!checkNiptFormat || !issuerNuis) {
      setErrors((errors) => {
        return { ...errors, issuerNuis: "toast.incorrectNiptForCustomer" };
      });
    }
    if (!validateCode(tcrCode) || !tcrCode) {
      setErrors((errors) => {
        return { ...errors, tcrCode: "nslf.tcrCodeError" };
      });
    }
    if (!validateCode(softCode) || !softCode) {
      setErrors((errors) => {
        return { ...errors, softCode: "nslf.softCodeError" };
      });
    }
    if (!dateTimeCreated) {
      setErrors((errors) => {
        return { ...errors, dateTimeCreated: "nslf.dateTimeCreatedError" };
      });
    }

    if (!invoiceNumber) {
      setErrors((errors) => {
        return { ...errors, invoiceNumber: "nslf.invoiceNumberError" };
      });
    }

    if (!validateCode(businessUnitCode) || !businessUnitCode  ) {
      setErrors((errors) => {
        return { ...errors, businessUnitCode: "nslf.businessUnitCodeError" };
      });
    }
    if (!totalPrice) {
      setErrors((errors) => {
        return { ...errors, totalPrice: "nslf.totalPriceError" };
      });
    }

    if (checkError) {
      setLoading(false);

      return;
    }
    setLoading(true);
    let parsedDate=moment(params.dateTimeCreated).format()
    mainAxios
      .post("/apiEndpoint/generateNSLFAndWTNIC", {
        object: "NSLF",
        params:{
          ...params,
          dateTimeCreated:parsedDate
        },
        nuis: user.companiesRights.find(
          (data) => data.id === parseInt(selectedCompany)
        )?.nuis,
        company: selectedCompany,
        type: "basic",
      })
      .then((res) => {
        if (res?.data?.status === true) {
          setNslf(res.data.content[0])
          toast.success(t(["toast.nslfGenerated"]), {
            containerId: "all_toast",
          });

          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };
  return (
    <div className="row mt-5">
      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-12 mx-auto">
        <div className="card border-0 shadow mb-4">
          <div className="card-header border-0">
            <h3 className="mb-0">{t("navbar.generateNslf")}</h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="params_issuerNuis">
                    {t("nslf.issuerNuis")}
                  </label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.issuerNuis ? "is-invalid" : ""
                    } `}
                    name="issuerNuis"
                    value={params.issuerNuis}
                    onChange={handleChange}
                  />

                  {errors.issuerNuis && (
                    <div className="invalid-feedback">
                      {t(errors.issuerNuis)}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="params_dateTimeCreated">
                    {t("nslf.dateTimeCreated")}
                  </label>
                  <span className="text-danger"> *</span>
                  <DatePicker
                    selected={params.dateTimeCreated}
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    name="dateTimeCreated"
                    showTimeSelect
                    onChange={(date) =>
                      handleChange(null, "dateTimeCreated", date)
                    }
                    customInput={
                        <input
                          className={`form-control ${
                            errors.dateTimeCreated ? "is-invalid" : ""
                          } `}
                        />
                    }
                  />
                  {errors.dateTimeCreated && (
                    <div className="invalid-feedback">
                      {t(errors.dateTimeCreated)}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="params_invoiceNumber">
                    {t("nslf.invoiceNumber")}
                  </label>
                  <span className="text-danger"> *</span>
                  <input
                    type="number"
                    className={`form-control ${
                      errors.invoiceNumber ? "is-invalid" : ""
                    } `}
                    name="invoiceNumber"
                    value={params.invoiceNumber}
                    onChange={handleChange}
                  />

                  {errors.invoiceNumber && (
                    <div className="invalid-feedback">
                      {t(errors.invoiceNumber)}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="params_businessUnitCode">
                    {t("nslf.businessUnitCode")}
                  </label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.businessUnitCode ? "is-invalid" : ""
                    } `}
                    name="businessUnitCode"
                    value={params.businessUnitCode}
                    onChange={handleChange}
                  />

                  {errors.businessUnitCode && (
                    <div className="invalid-feedback">
                      {t(errors.businessUnitCode)}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="params_tcrCode">{t("nslf.tcrCode")}</label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.tcrCode ? "is-invalid" : ""
                    } `}
                    name="tcrCode"
                    value={params.tcrCode}
                    onChange={handleChange}
                  />
                  {errors.tcrCode && (
                    <div className="invalid-feedback">{t(errors.tcrCode)}</div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="params_softCode">{t("nslf.softCode")}</label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.softCode ? "is-invalid" : ""
                    } `}
                    name="softCode"
                    value={params.softCode}
                    onChange={handleChange}
                  />
                  {errors.softCode && (
                    <div className="invalid-feedback">{t(errors.softCode)}</div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="params_totalPrice">
                    {t("nslf.totalPrice")}
                  </label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.totalPrice ? "is-invalid" : ""
                    } `}
                    name="totalPrice"
                    value={params.totalPrice}
                    onChange={handleChange}
                  />
                  {errors.totalPrice && (
                    <div className="invalid-feedback">
                      {t(errors.totalPrice)}
                    </div>
                  )}
                </div>
              </div>
              {nslf && <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="params_softCode">NSLF</label>
                  <span className="text-danger"> *</span>
                   <input
                    type="text"
                    className={`form-control`}
                    name="nsfl"
                    value={nslf}
                    disabled={true}
                    onChange={()=>{}}
                  />
                </div>
              </div>}
            </div>

            <button className="btn btn-primary" onClick={handleSubmit}>
              {loading ? (
                <Loader size="xs" color="white" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  {t("navbar.create")}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateNslf;
