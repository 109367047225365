import React, { memo } from "react";

const CellInput = ({ value, width = 240 }) => {
  if (value?.length > 0) {
    return (
      <input
        type="text"
        onFocus={(event) => event.target.select()}
        value={value ?? ""}
        style={{
          border: 0,
          userSelect: "text",
          fontSize: "13px",
          width,
          backgroundColor: "transparent",
        }}
        onChange={() => {}}
      />
    );
  }
  return <></>;
};

export default memo(CellInput);
