import { DataTable } from "primereact/datatable";
import React, { useCallback, useRef } from "react";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { locale, addLocale } from "primereact/api";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { MultiSelect } from "primereact/multiselect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown, faFilterCircleXmark, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { InputText } from 'primereact/inputtext';
import func from "../../services/functions";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { Button } from 'primereact/button';
import "./prime_table.scss"
import ReactDOM from 'react-dom';
import { Checkbox } from "primereact/checkbox";
import { MyMultiSelect } from "./AddRemoveColumns";


const PrimeTable = ({
  filterDisplay = "row",
  dataKey,
  defaultFilters,
  columns = [],
  filters: [filters, setFilters] = [],
  showGlobalSearch = false,
  searchableKeys = [],
  tableRows: [tableRowsOutside, setTableRowsOutside] = [],
  headerButtonsLeft,
  headerButtonsRight,
  selectedColumns: [selectedColumns, setSelectedColumns] = [],
  showFilters = false,
  responsiveLayout,
  children,
  value,
  customRowReorderHandle,
  onClearFilter,
  clearFilters = true,
  onRemoteDataChange,
  showSelectColumns=true,
  columnDeps=[],
  showHeader=true,
  likeValues=true,
  stripedRows=true,
  simpleTable=false,
  rowReorder=true,
  paginator=true,
  isColSort=true,
  defaultSortCriteria=null,
  ...props
}) => {


  const [filterRefresh,setFilterRefresh]=useState(null)
  const { t, i18n } = useTranslation("translations");
  const { debounce, swalAlert } = func;
  const [tableRowsInside,setTableRowsInside]=useState({
    totalRows: value?.length ?? 0,
    rowsPerPage: 10,
    currentPage: 1,
  })
  const [globalFilterValue, setGlobalFilterValue] = useState("")
  const [loading, setLoading] = useState(false);

  const tableRows = tableRowsOutside ?? { ...tableRowsInside, totalRows: value?.length ?? 0, }
  const setTableRows=setTableRowsOutside??setTableRowsInside

  const onSort =(data)=>{
    // const newSortDirection = filters.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    let newTableRows=tableRows
    setTableRows(tableRows=>{
      newTableRows = {
        ...tableRows,
        sortCriteria:{field: data.sortField,direction:data.sortOrder === 1 ? 'ASC' : 'DESC' }
      };
      if (
        data.sortOrder == 1 &&
        tableRows?.sortCriteria?.direction == "DESC" &&
        tableRows?.sortCriteria?.field === data.sortField
      ) {
        if(defaultSortCriteria?.field ==tableRows?.sortCriteria?.field){
          newTableRows.sortCriteria = null;
        }else{
          newTableRows.sortCriteria = defaultSortCriteria;
        }
      }
      return newTableRows
    });
    if (onRemoteDataChange)
      onRemoteDataChange({ tableRows: newTableRows });
  }

  const totalRows =  tableRows.totalRows

  useEffect(() => {
    addLocale("alb", {
      startsWith: "Fillon me",
      contains: "Përmban",
      notContains: "Nuk Përmban",
      endsWith: "Përfundon me",
      equals: "E Barabartë",
      notEquals: "Jo të Barabartë",
      noFilter: "Pa Filtër",
    });
  }, []);

  useEffect(() => {
    locale(i18n.language);
  }, [i18n.language]);

  const clearFilter = useCallback(() => {
    setFilters({...filters,params:defaultFilters.params});
    onRemoteDataChange({ filters: defaultFilters })
    setGlobalFilterValue("");
  }, [filters]);

  const newColumns=useMemo(()=>{
    if (!selectedColumns) return [];
    return columns.filter(col => selectedColumns?.every((sCol=>sCol.field!==col?.field))).map(foundCol=>({...foundCol,show:!!foundCol.persisted,newCol:true}))

  },[selectedColumns, i18n.language, filters, ...columnDeps])

  const currentColumns = useMemo(() => {
    if (!selectedColumns) return [];
    let currCols = []
    selectedColumns.forEach(sCol=>{
      const currCol=columns.find(col=>col.field===sCol.field)
      if(currCol)currCols.push({...currCol,show:sCol.show})
    })
    return currCols
  }, [selectedColumns, i18n.language, filters, ...columnDeps]);

  let columnsToDisplay=useMemo(()=>!selectedColumns?[]:currentColumns.filter(fCol=>fCol.show || fCol.persisted),[currentColumns])

  let tableColumns = selectedColumns ?  columnsToDisplay: columns;

  const handleFiltersChange = ({ key, value, active = false, like, splitKey=true }) =>{
    let keys
    if(splitKey){
      keys = key.split(".")
    }else {
      keys=[key]
    }
    const newValue = like && value!=null ? `<L>${value}<L>`:value
    let newFilters=filters
    switch(keys.length){
      case 1:newFilters = {
        ...filters,
        params: {
          ...filters.params,
          [keys[0]]: newValue,
        }
      }
        break
      case 2: newFilters = {
        ...filters,
        params: {
          ...filters.params,
          [keys[0]]: {
            ...filters.params[keys[0]],
            [keys[1]]: newValue
          },
        }
      }
        break
      default: return
    }
    const newTableRows = {
      ...tableRows
    }
    setFilters(newFilters);
    if (tableRows.pageSize != 1) {
      setTableRows((tableRows) => ({ ...tableRows, currentPage: 1 }))
      newTableRows.currentPage = 1
    }

    if (onRemoteDataChange && (active || value==null || typeof value=="string" || typeof value==="number")) onRemoteDataChange({ filters: newFilters, tableRows:newTableRows })
  }
  const handleFilterDebounce = useCallback(
    debounce(
      async ({ e, selectedKey, selectedValue, multiSelect, like, splitKey=true }) => {
        const key = e ? e.target.name : selectedKey;
        const value = e ? e.target.value : selectedValue;
        handleFiltersChange({ key, value, multiSelect, like, splitKey })
      },
      [250]
    ),
    [filters]
  );

  const inputFilter = useCallback(
    ({ filterOptions, showClear = true, multiselect=false,like=likeValues,splitKey=true,...props }) => {
      const field=props.field ?? filterOptions.field
      return (
        <input
          className="p-inputtext p-component p-column-filter input-filter-clearable"
          onChange={(e) => {
            filterOptions.filterCallback(e.target.value);
            const value = e.target.value?.length == 0 ? null : e.target.value
            handleFilterDebounce({ selectedKey: field, selectedValue: value,multiselect,like,splitKey});
          }}
        />
      );
    },
    [filters]
  );

  const triStateFilter = useCallback(
    ({ filterOptions,...props }) => {
      const field = props.field  ?? filterOptions?.field
      let value = filters?.params?.[field]


      if(value){
        value = props?.type === "BOOL"?value : value == "true"
      }
      return (
        <div className="d-flex justify-content-center">
        <TriStateCheckbox
          value={value}
          onChange={(e) => {
            filterOptions.filterCallback(e.value);
            let value = props?.type === "BOOL" ? e.value : (e.value ? "true" : "false")
            value=props?.value ?? value
            handleFiltersChange({key:field, value:e.value == null ? null : value,active:true})
          }}
        />
        </div>

      );
    },
    [filters]
  );

  const verifiedBodyTemplate = useCallback((rowData, field) => {
    return (
      <div className="d-flex justify-content-center align-items-center">
      <i
        className={classNames("pi", {
          "true-icon pi-check-circle success": rowData?.[field],
          "false-icon pi-times-circle danger": !rowData?.[field],
        })}
      ></i>
      </div>

    );
  }, []);

  const dropdownFilter = useCallback(
    ({ filterOptions, filterKey, showClear = true,options,showReset=true,splitKey,resetLabel, ...props }) => {
      const field = filterKey ?? filterOptions?.field

      const dropdownOptions= showReset ?[{
        [props?.optionValue ?? "value"]:null,
        [props?.optionLabel ?? "label"]: resetLabel ??t("vat.none")
    },].concat(options) :options

      return (
        <Dropdown
          value={filters?.params?.[field]}
          style={{ width: "100%", height: "35px", fontSize: "14px" }}
          onChange={(e) => {
            handleFiltersChange({key:field ,value:e.value,splitKey})
            filterOptions.filterCallback(e.value);
          }}
          options={dropdownOptions}
          {...props}
        />
      );
    },
    [filters]
  );

  const itemTemplate = (option) => {
    return <div className="w-full d-flex justify-content-between align-items-center">
      <div>
        {option.label}
      </div>
      <div className="badge bg-primary rounded-pill" style={{marginLeft:10}}>
        {option?.count ?? 0}
      </div>
    </div>
  }

  const multiSelectFilter = useCallback(
    ({ filterOptions, showClear = true, showCount = false, dataKey = "value", optionLabel = "label",...props }) => {
      const value = filters?.params?.[filterOptions.field]



      return (
        <MultiSelect
          filter={true}
          options={filterOptions}
          value={value}
          dataKey={dataKey}
          optionLabel={optionLabel}
          onChange={(e)=>{
            const data= e.value
            filterOptions.filterCallback(data);
            handleFiltersChange({key:filterOptions.field, value:data,active:true})
          }}
          {...showCount && { itemTemplate}}
          {...props}
          selectAll={false}
        />

      );
    },
    [filters]
  );

  const dateFilterTemplate = useCallback(
    ({ filterOptions, format = 'DD/MM/YYYY', placeholder = 'dd/mm/yyyy',minMaxDate=true, ...props }) => {
      const field=props.field ?? filterOptions.field
      // let value = filters?.params?.[filterOptions?.field]?.value;
      // const parsedSelected = value ? moment(value, format).toDate():null
      const minMaxDateProps = minMaxDate ? {
        minDate: filters.fromDate,
        maxDate: filters.toDate,
        footerTemplate: () => (<div className="d-flex alert align-items-center alert-warning" role="alert" style={{ maxWidth: "100%" }}>
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          <div style={{ fontSize: "14px" }}>
            {t("common.calendarAlert")}
          </div>
        </div>)
      } : {}

      return (
        <Calendar
          style={{ maxWidth: 200 }}
          onInput={(e) => {
            const val = e?.target?.value;
            const check = val === '' || val === undefined;
            filterOptions.filterCallback(e.target.value);

            if (check) handleFiltersChange({key:field, value:e.value ? moment(e.value).format(format) : null});
          }}
          onClearButtonClick={(e) => handleFiltersChange({key:field, value:null })}
          onChange={(e) => {
            if (moment(e.value).format(format) !== 'Invalid date')
              handleFilterDebounce({selectedKey:field,selectedValue: e.value ? moment(e.value).format(format) : ""});
            filterOptions.filterCallback(e?.value ??"");
          }}
          showButtonBar
          dateFormat={"dd/mm/yy"}
          placeholder={placeholder}
          {...props}
          {...minMaxDateProps}
        />
      );
    },
    [filters]
  );

  const buildFilterElement = useCallback(
    (column) => {

      if (column?.filter == false || simpleTable) return {};
      if (column?.filterType === "DROPDOWN") {
        return {
          filterElement: (filterOptions) =>
            dropdownFilter({
              filterOptions,
              placeholder: column?.filterPlaceholder ?? "",
              ...column.filterConfig,
            }),
        };

      } else if (column?.filterType === "MULTISELECT") {
        return {
          filterElement: (filterOptions) => multiSelectFilter({
            filterOptions,
            placeholder: column?.filterPlaceholder ?? "",
            ...column.filterConfig,
          })
        }
      }

      else if (column?.filterType === "TRISTATECHECKBOX") {
        return {
          filterElement: (filterOptions) => triStateFilter({ filterOptions, ...column.filterConfig }),
        };
      } else if (column.filterType === 'DATE') {
        return {
          filterElement: (filterOptions) =>
            dateFilterTemplate({
              filterOptions,
              filterType: column.filterType,
              ...column.filterConfig,
            }),
        };
      } else {
        return {
          filterElement: (filterOptions) =>
            inputFilter({
              filterOptions,
              placeholder: column?.filterPlaceholder ?? "",
              ...column.filterConfig,
            }),
        };
      }
    },
    [filters]
  );

  const mappedTableColumns = useMemo(() => {
    return tableColumns.map((column) => {
      return {
        filter: false,
        showFilterMenu: false,
        showClearButton: false,
        ...buildFilterElement(column),
        ...(column?.dataType === "boolean"
        ? { body: (row) => verifiedBodyTemplate(row, column?.bodyConfig?.field ?? column.field) }
        : {}),
        ...column,
      };
    });
  }, [tableColumns]);

  const refreshTableFilters = () => {
    //workaround to refresh filters after column selection since prime react throws an error inside the library
    setFilterRefresh(true)
    setTimeout(() => {
      setFilterRefresh(false)
    }, 200)
  }

  const handleGlobalFilter = useCallback(
    debounce(
      async (value) => {
        if(onRemoteDataChange){
          onRemoteDataChange({searchQuery:value})
        }
      },
      [250]
    ),
    []
  );

  const renderHeader = useCallback(() => {
    return (
      <div className="d-flex justify-content-between align-items-center flex-wrap" >
        <div className="d-flex align-items-center p-0">
          {headerButtonsLeft}
        </div>
        <div className="d-flex align-items-center p-0">
            {headerButtonsRight}
            {clearFilters && (
                <div
                    disabled={loading}
                    style={{width:"155px",height:34}}
                    // label={t("queue.clearFilters")}
                    className="btn btn-outline-primary open-modal-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        document.querySelectorAll(".input-filter-clearable,.p-calendar> .p-inputtext ").forEach(i => i.value = "")
                        if(onClearFilter) onClearFilter();
                        clearFilter();
                    }}
                >

              <FontAwesomeIcon icon={faFilterCircleXmark} className="mr-2" />
              {t("queue.clearFilters")}
            </div >
          )
          }
          {showSelectColumns && <div className="col ml-1">

            <MyMultiSelect columns={columns} newColumns={newColumns} currentColumns={currentColumns} selectedColumns={currentColumns} setSelectedColumns={setSelectedColumns} onChange={()=>{refreshTableFilters()}}/>
          </div>}
          {showGlobalSearch && (
            <span className="p-input-icon-left align-self-end">
              <i className="pi pi-search" />
              <div className="table-global-search">
              <InputText
                style={{ maxHeight: 35,width:200 }}
                value={globalFilterValue}
                onChange={(e)=>{const value=e.target.value;
                  setGlobalFilterValue(value);
                  if(searchableKeys?.length==0) handleGlobalFilter(value)
                }
                }
                placeholder="Search..."
              />
              {globalFilterValue?.length>0 &&
              <div className="close-container" onClick={()=>{setGlobalFilterValue(""); if(searchableKeys?.length==0){ handleGlobalFilter("")}}}>
                <FontAwesomeIcon icon={faTimes} size={"lg"} className="close-icon"  />
              </div>
              }
              </div>

            </span>
          )}
        </div>
      </div>
    );
  });

  const header = renderHeader();
  const showHeaderCheck = ((headerButtonsRight || headerButtonsLeft || selectedColumns || showGlobalSearch) && showHeader)

  const handlePagination = (e) => {
    setTableRows((tr) => ({
      ...tr,
      rowsPerPage: e.value,
      currentPage: 1
    }));
    if (onRemoteDataChange) {
      onRemoteDataChange({
        tableRows: {
          ...tableRows,
          rowsPerPage: e.value,
          currentPage: 1
        }
      })
    }

  };

  const handlePageChange = (option) => {
    let currentPage = tableRows.currentPage
    setTableRows((tr) => {
      if (option === "next") {
        if (tableRows.rowsPerPage * tableRows.currentPage < totalRows) {
          currentPage = tr.currentPage + 1
        }
      } else if (option === "prev") {
        if (tableRows.rowsPerPage * (tableRows.currentPage - 1) >= 0) {
          currentPage = tr.currentPage - 1
        }
      } else if (option === "last") {
        if (tableRows.rowsPerPage * tableRows.currentPage < totalRows) {
          currentPage = Math.ceil(totalRows / tableRows.rowsPerPage)
        }
      } else if (option === "first") {
        if (tableRows.currentPage > 1) {
          currentPage = 1
        }
      }

      return {
        ...tr,
        currentPage,
      }
    })

    if (onRemoteDataChange) { onRemoteDataChange({ tableRows: { ...tableRows, currentPage } }) }
  };

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 15, value: 15 },
        { label: 20, value: 20 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            {t("paginator.itemsPage")}:{" "}
          </span>
          <Dropdown
            value={tableRows.rowsPerPage}
            options={dropdownOptions}
            onChange={handlePagination}
            style={{ height: "35px", fontSize: "14px" }}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {totalRows === 0
            ? 0
            : tableRows.rowsPerPage * (tableRows.currentPage - 1) + 1}{" "}
          -{" "}
          {tableRows.rowsPerPage * tableRows.currentPage > totalRows
            ? totalRows
            : tableRows.rowsPerPage * tableRows.currentPage}{" "}
          {t("paginator.of")}{" "}{totalRows}
        </span>
      );
    },
    FirstPageLink: (options) => {
      let disabled = false;
      if (tableRows.currentPage === 1) {
        disabled = true;
      } else {
        disabled = false;
      }
      return (
        <div
          type="button"
          className={`p-paginator-prev p-paginator-element p-link ${disabled ? `p-disabled` : ""
            }`}
          onClick={() => handlePageChange("first")}
          disabled={false}
        >
          {options.element}
        </div>
      );
    },
    PrevPageLink: (options) => {
      let disabled = false;
      if (tableRows.rowsPerPage * (tableRows.currentPage - 1) + 1 === 1) {
        disabled = true;
      } else {
        disabled = false;
      }
      return (
        <div
          type="button"
          className={`p-paginator-prev p-paginator-element p-link ${disabled ? `p-disabled` : ""
            }`}
          onClick={() => handlePageChange("prev")}
          disabled={false}
        >
          {options.element}
        </div>
      );
    },
    NextPageLink: (options) => {
      let disabled = false;
      if (tableRows.rowsPerPage * tableRows.currentPage < totalRows) {
        disabled = false;
      } else {
        disabled = true;
      }
      return (
        <div
          type="button"
          className={`p-paginator-prev p-paginator-element p-link ${disabled ? `p-disabled` : ""
            }`}
          onClick={() => handlePageChange("next")}
          disabled={disabled}
        >
          {options.element}
        </div>
      );
    },
    LastPageLink: (options) => {
      let disabled = false;
      if (tableRows.rowsPerPage * tableRows.currentPage < totalRows) {
        disabled = false;
      } else {
        disabled = true;
      }
      return (
        <div
          type="button"
          className={`p-paginator-prev p-paginator-element p-link ${disabled ? `p-disabled` : ""
            }`}
          onClick={() => handlePageChange("last")}
          disabled={disabled}
        >
          {options.element}
        </div>
      );
    },
  };


  const globalFilteredData=useMemo(( )=>{
    if(searchableKeys?.length>0){
      if (globalFilterValue?.length == 0) return value
      return value.filter((val) => {
        const check = searchableKeys.some(key => val?.[key]?.toLowerCase().includes(globalFilterValue.toLowerCase()))
        return check
      }
      )
    }
    return value
  }, [value, globalFilterValue,showGlobalSearch])

  return (
    <>
    <div className="datatable-rowgroup-demo">
    <DataTable
      {...(filterRefresh ? { filters:[] } : {})}
      header={showHeaderCheck ?header:null}
      responsiveLayout="scroll"
      size="sm"
      filterDisplay={filterDisplay}
      className="white-header"
      paginator={paginator}
      paginatorTemplate={template2}
      paginatorClassName="justify-content-end"
      rows={tableRows.rowsPerPage}
      sortMode="single"
      stripedRows={stripedRows}
      sortField={tableRows?.sortCriteria?.field}
      sortOrder={tableRows?.sortCriteria?.direction ? (tableRows?.sortCriteria?.direction === 'ASC' ? 1 : -1):0}
      value={globalFilteredData}
      scrollable={true}
      onSort={onSort}
      // {...(setSelectedColumns ? { onColReorder } : {})}
      {...props}
    >
      {children}
      {mappedTableColumns.map((column) => (
        <Column  key={column.field} {...column} />
      ))}
      {props.reorderableRows && <Column rowReorder={rowReorder} />}
    </DataTable>
    </div>
    </>

  );
};

export const onColReorder = ({ reorder, setSelectedColumns ,childrenCount}) => {

  if (!reorder) {
    console.error('onColReorder called without reorder argument');
    return;
}
  
    const { dragIndex, dropIndex } = reorder
    setSelectedColumns((selectedColumns) => {
      let _selectedCols = [...selectedColumns];
      const temp = _selectedCols[dragIndex - childrenCount];
      _selectedCols.splice(dragIndex - childrenCount, 1);
      _selectedCols.splice(dropIndex - childrenCount, 0, temp);
      return _selectedCols;
    });
  }
  
export const verifiedBodyTemplate = (check) => {
  return (
    <div className="d-flex justify-content-center">
    <i
      className={classNames("pi", {
        "true-icon pi-check-circle success": check,
        "false-icon pi-times-circle danger": !check,
      })}
    ></i>
    </div>

  );
}



export default PrimeTable;
