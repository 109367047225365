import React from "react";
import TableItems from "./TableItems";

import DataTable from "react-data-table-component";
import { storiesOf } from "@storybook/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import func from "../services/functions";
import Loader from "./Layout/Loader";

const Table = ({
  noHeader = false,
  columns,
  data,
  disablePagination,
  expandableRowsComponent,
  expandableKeys,
  renderItem = null,
  selectable = false,
  rowSelectCritera,
  selectionActions,
  searchableKeys,
  searchPlaceholder,
  defaultSortField,
  defaultSortAsc,
  async,
  config,
  enablePagination,
  defaultExpandedRow = false,
  setSearchQuery,
  clearSearch,
  addRemoveColumns,
  conditionalRowStyles,
  seachInputClasses="",
  actionLoading=false,
  ...props
}) => {
  const { t, i18n } = useTranslation("translations");
  const [filterText, setFilterText] = React.useState("");

  if (searchableKeys && searchableKeys.length > 0) {
    data = data.filter((invoice) => {
      return Object.entries(invoice).find(
        ([key, value]) =>{
          let searchableKey="";
          searchableKeys.forEach((searchKey)=>{
            if(searchKey.includes(key)) searchableKey=searchKey;
          });
          let newValue=searchableKey.length>0 ?func.getPropByPath(invoice,searchableKey) :"";
          return ((typeof newValue === "string" || typeof newValue === "number") &&
          (newValue + "").toLowerCase().includes(filterText.toLowerCase()));
        })
        ? true
        : false;
    });
  }

  let dataTableProps = {
    columns,
    data,
  };


  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(
    () =>
      selectionActions?.map((action, index) => (
        <a
          href="#"
          className="btn btn-outline-primary mr-3"
          key={index}
          onClick={(e) => {
            e.preventDefault();
            action.callback(selectedRows);
          }}
        >{actionLoading ? <Loader size="xs" /> : action.name}
        </a>
      )),
    [data, selectedRows, actionLoading]
  );

  if (selectable) {
    dataTableProps.selectableRows = true;
    dataTableProps.contextActions = contextActions;
    dataTableProps.onSelectedRowsChange = handleRowSelected;
    dataTableProps.selectableRowDisabled = rowSelectCritera;
  }

  if (expandableRowsComponent) {
    dataTableProps.expandableRowsComponent = expandableRowsComponent;
    dataTableProps.expandableRows = true;
  }

  if (expandableKeys || renderItem) {
    dataTableProps.expandableRowsComponent = (
      <TableItems
        expandableKeys={expandableKeys}
        renderItem={renderItem}
        async={async}
        config={config}
      />
    );
    dataTableProps.expandableRows = true;
  }

  if (enablePagination) {
    dataTableProps.paginationServer = enablePagination?.paginationServer;
    dataTableProps.onChangePage = enablePagination?.onChangePage;
    dataTableProps.onChangeRowsPerPage = enablePagination?.onChangeRowsPerPage;
    dataTableProps.paginationTotalRows = enablePagination?.totalRows
  }

  if (defaultSortField) {
    dataTableProps.defaultSortField = defaultSortField;
    if (defaultSortAsc === false) {
      dataTableProps.defaultSortAsc = defaultSortAsc;
    }
  }

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
    if(clearSearch!==undefined){
      clearSearch();
    }
  };

  const handleSearch=(e)=>{
    setFilterText(e.target.value);
    if(setSearchQuery!==undefined){
      setSearchQuery(e.target.value)
    }
  }

  return (
    <>
    <div className="row justify-content-end ">
    {searchableKeys && searchableKeys.length > 0 && (
          <div className={`col-md-2 col-sm-4 col-6 ${seachInputClasses} align-items-end`}>
            <div className="position-relative">
              <input
                type="text"
                className="form__input"
                placeholder={searchPlaceholder || t(["subscription.search"])}
                value={filterText}
                onChange={(e) => {handleSearch(e)}}
              />
              {filterText.length > 0 && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="form__clear"
                  onClick={handleClear}
                />
              )}
            </div>
          </div>
      )}
    {addRemoveColumns && addRemoveColumns} 
    </div>

      <DataTable
        highlightOnHover
        pagination={disablePagination ? false : true}
        persistTableHead
        noHeader={noHeader}
        expandableRowExpanded={
          defaultExpandedRow ?
          ((row) => {
            return row.tcrTypes.length > 0
              ? (row.defaultExpanded = true)
              : (row.defaultExpanded = false);
          }):()=>{}
        }
        conditionalRowStyles={conditionalRowStyles}
        {...dataTableProps}
        {...props}
      />
    </>
  );
};

// storiesOf("Expandable Rows", module).add("Custom Expanded Component", Table);
// storiesOf("Pagination", module).add("Basic", Table);
// storiesOf("Selectable Rows", module).add("Selected Row Toggle", Table);
// storiesOf("Selectable Rows", module).add("Disabled Row", Table);

export default Table;
