import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import {
  faCheck,
  faSearch,
  faTimes,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../Layout/Loader";
import func, { validateInvoice } from "../../../services/functions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import mainAxios from "../../../services/mainAxios";
import AsyncSelect from "react-select/async";
import { Tooltip } from "primereact/tooltip";
import EditCustomer from "../../Setup/EditCustomers";
import { Dialog } from "primereact/dialog";
import CreateCustomer from "../../Setup/Users/CreateCustomer";
import countries from "../../../services/countries.json";
import EditSeller from "./EditSeller";
import Customers from "../../Setup/Customers";

const DemoParties = ({ invoiceState, setInvoiceState, disabled, errors , setErrors,visible, setVisible}) => {

  const inputRef = useRef(null);
  const inputRefSeller = useRef(null);
  const { t } = useTranslation("translations");
  const [inputClass, setInputClass] = React.useState("");
  const [sellerInputClass, setSellerInputClass] = React.useState("");
  const [seller, setSeller] = React.useState("");

  const [visibleSeller,setVisibleSeller]=useState(false)
  const [addBuyer, setAddBuyer] = useState(false);
  const [buyer, setBuyer] = React.useState("");
  const [customers, setCustomers] = React.useState("");


  const handleTaxPayerChange = (e) => {
    let value = e.target.value;

    setBuyer(value);
    if (inputClass.length > 0) setInputClass("");
  };
  const handleTaxPayerSearch = () => {
    let buyerNIPT = buyer.split(" ").join("");
    setBuyer(buyerNIPT);

    if (!disabled) {
      if (new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(buyerNIPT)
      ) {
        if (
          invoiceState.parties.seller.tin === buyerNIPT &&
          invoiceState.profileId != "P10" &&
          invoiceState.profileId != "P12"
        ) {
          toast.error(t(["toast.diffNipt"]), { containerId: "all_toast" });
          setInputClass("error");
        } else {
          setInputClass("loading");
          mainAxios
            .post("apiEndpoint/search", {
              object: "GetTaxpayersRequest",
              value: buyerNIPT,
            })
            .then((res) => {
              if (res?.data?.status === true) {
                if (res.data.content.length === 0) {
                  toast.warning(t(["toast.noTaxPayer"]), {
                    containerId: "all_toast",
                  });
                  setInputClass("error");
                } else {
                  const taxPayer = res.data.content[0];

                  let { name, town, address, country, tin } = taxPayer;
                  name = name ? name.slice(0, 99) : null;
                  town = town ? town.slice(0, 99) : null;
                  address = address ? address.slice(0, 200) : null;
                  country = country ? func.getFullCountry(country,false) : null;

                  const customer = {
                    name,
                    town,
                    address,
                    country,
                    type: "1",
                    tin,
                  };

                  setInvoiceState((invoiceState) => ({
                    ...invoiceState,
                    parties: {
                      ...invoiceState.parties,
                      buyer: {
                        ...taxPayer,
                        name,
                        town,
                        address,
                        country,
                        buyerIDType: "NUIS",
                      },
                    },
                    customer,
                  }));
                  setInputClass("success");
                }
              } else {
                setInputClass("error");
              }
            })
            .catch((e) => {
              setInputClass("error");
            });
        }
      } else {
        toast.error(t(["toast.incorrectNipt"]), { containerId: "all_toast" });
        setInputClass("error");
      }
    }
  };
  const loadOptions = async (name, callback) => {
    let customers=[];
    if (name.length > 2) {
      return await mainAxios
        .post("apiEndpoint/search", {
          object: "GetCustomer",
          value: null,
          params: {
            customerLiveSearch: name,
            // name
          },
          // company: 1,
        })
        .then((res) => {
          if(res.data.status){
            customers = res.data.content.map((el) => {
              return {
                ...el,
                label: el.name + " - " + el.tin,
                value: el.name,
              };
            });
          }
          return customers;
        });
    }
  };
  const loadOptionsForSeller = async (name) => {

    let customers=[];
    if (name.length > 2) {
      return await mainAxios
      .post("/apiEndpoint/search", {
        object: "GetCompanyClients",
        value: null,
        type: "basic",
        params : {
        "albanianBusinesses.name": name, 
        }
      })
        .then((res) => {
          if(res.data.status){
            customers = res.data.content.map((el) => {
              const { name,tin } = el.albanianBusinesses
              return {
                ...el,
                label: name + " - " + tin,
                value: name,
              };
            });
          }

          return customers;
        });
    }
  };

  const sellerIDTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
    { label: "TAX", value: "5" },
    { label: "SOC", value: "6" },
  ];

  const buyerIDTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
    { label: "TAX", value: "5" },
    { label: "SOC", value: "6" },
  ];

  const handleSellerTaxPayerChange = (e) => {
    let value = e.target.value;

    setSeller(value);
    if (inputClass.length > 0) setSellerInputClass("");
  };

  const handleSellerIDTypeChange = (sellerIDType) => {
    const { parties, ...rest } = invoiceState;
    let newSeller = parties.seller;
    newSeller.sellerIDType = sellerIDType;

    let newParties = parties;

    newParties.seller = newSeller;

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      parties: newParties,
    }));
  };


  const handleInvoiceBuyerChange = (
    e,
    selectKey,
    selectValue,
    length,
    reason
  ) => {
    let key = e ? e.target.name : selectKey;
    let value = e ? e.target.value : selectValue;
    if (key === "buyer") {
      let customer = value;
      if (customer?.label && customer?.value) {
        delete customer["label"];
        delete customer["value"];
      }
      let type = customer?.type
        ? buyerIDTypes.find((type) => type.value == customer.type).label
        : "";
      let invoiceContent={}
      setInvoiceState((invoiceState) => {
        invoiceContent = {
          ...invoiceState,
          parties: {
            ...invoiceState.parties,
            buyer: {
              tin: customer?.tin ?? "",
              name: customer?.name ?? "",
              address: customer?.address ?? "",
              country: func.getFullCountry(customer?.country, false),
              town: customer?.town ?? "",
              email: customer?.email ?? null,
              buyerIDType: invoiceState.einvoice
                ? buyerIDTypes[0].label
                : !invoiceState?.einvoice && customer?.type
                  ? type
                  : buyerIDTypes[1].label,
            },
          },
          customer: customer ?? {
            tin: "",
            name: "",
            address: "",
            country: "",
            town: "",
            type: "1",
            email: null,
          },
        }
        return invoiceContent
      });
      if(selectValue){
        validateInvoice({ setErrors, state: invoiceContent,setVisible,validateOnlyParties:true })
      }
      inputRef.current.blur();
      inputRef.current.focus();
    } else {
      if (reason?.action === "input-blur" || reason?.action === "menu-close")
        return;
      if (reason?.action === "set-value") return;
      value = length != undefined ? value.slice(0, length) : value;

      let customerKey = key;
      let customerValue = value;

      if (key == "buyerIDType") {
        customerKey = "type";
        customerValue = buyerIDTypes.find((type) => type.label == value).value;
      }
      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        parties: {
          ...invoiceState.parties,
          buyer: {
            ...invoiceState.parties.buyer,
            [key]: value,
          },
        },
      }));
    }
  };


  const handleSellerTaxPayerSearch = () => {
    let sellerNIPT = seller.split(" ").join("");
    setSeller(sellerNIPT);

    if (!disabled) {
      if (new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(sellerNIPT)) {
        setSellerInputClass("loading");
        mainAxios
          .post("apiEndpoint/search", {
            object: "GetTaxpayersRequest",
            value: sellerNIPT,
          })
          .then((res) => {
            if (res?.data?.status === true) {
              if (res.data.content.length === 0) {
                toast.warning(t(["toast.noTaxPayer"]), {
                  containerId: "all_toast",
                });
                setSellerInputClass("error");
              } else {
                const taxPayer = res.data.content[0];
                let { name, town, address, country, tin } = taxPayer;
                name = name ? name.slice(0, 99) : null;
                town = town ? town.slice(0, 99) : null;
                address = address ? address.slice(0, 200) : null;
                country = country ? func.getFullCountry(country) : null;

                const customer = {
                  name,
                  town,
                  address,
                  country,
                  tin,
                  type: "1",
                };

                setInvoiceState((invoiceState) => ({
                  ...invoiceState,
                  parties: {
                    ...invoiceState.parties,
                    seller: {
                      ...taxPayer,
                      name,
                      town,
                      address,
                      country,
                      sellerIDType: "NUIS",
                    },
                  },
                  customer,
                }));
                setSellerInputClass("success");
              }
            } else {
              setSellerInputClass("error");
            }
          })
          .catch((e) => {
            setSellerInputClass("error");
          });
      } else {
        toast.error(t(["toast.incorrectNipt"]), { containerId: "all_toast" });
        setSellerInputClass("error");
      }
    }
  };



  const handleInvoiceSellerChange = (e, selectKey, selectValue, length, reason) => {

      let key = e ? e.target.name : selectKey;
      let value = e ? e.target.value : selectValue;
      if (key === "seller") {
        let customer=value
        if(customer?.label && customer?.value){
          delete customer["label"]
          delete customer["value"]
        }
        setInvoiceState((invoiceState) => ({
          ...invoiceState,
          parties: {
            ...invoiceState.parties,
            seller: {
              tin: customer?.albanianBusinesses?.tin ?? "",
              name: customer?.albanianBusinesses?.name ?? "",
              address: customer?.albanianBusinesses?.address ?? "",
              country: func?.getFullCountry(customer?.albanianBusinesses?.country),
              town: customer?.albanianBusinesses?.town ?? "",
            },
          },
          customer:customer??{
            tin: "",
            name: "",
            address: "",
            country: "Albania",
            town: "",
            type: "1",
          }
        }));
        inputRefSeller.current.blur();
        inputRefSeller.current.focus();
      } else {
        if(reason?.action === "input-blur" || reason?.action === "menu-close") return
        if(reason?.action === "set-value") return
        value = length != undefined ? value.slice(0, length) : value;
      
        let customerKey=key
        let customerValue=value
      
        if(key=="sellerIDTypes"){
          customerKey="type"
          customerValue=sellerIDTypes.find(type=>type.label==value).value
        }
        setInvoiceState((invoiceState) => ({
          ...invoiceState,
          parties: {
            ...invoiceState.parties,
            seller: {
              ...invoiceState.parties.seller,
              [key]: value,
            },
          },
        }));
      }
  };


  // errors['parties.buyer.name'] == '' ?
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#000" : styles.color,
      backgroundColor: state.isSelected ? "#0075670d" : styles.color,
      borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
      "&:hover": {
        color: "#000000",
        backgroundColor: "#0075670d",
      },
    }),
    control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 0.16rem rgba(68, 68, 68, 0.135)" : 0,
      borderWidth: state.isFocused ? "1px" : "1px",
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",

      minHeight: "14px",
      maxHeight: "34px",
      fontSize: "0.8rem",
      marginBottom: "0.1rem",
      border: errors?.["parties.buyer.name"] ? "solid red 1px" : "",
      "&:hover": {
        borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
        border: errors?.["parties.buyer.name"] ? "solid red 1px" : "",
      },
    }),
  };

  const addEditCustomer = () => {
    const showEdit = (invoiceState?.customer?.id || invoiceState?.customer?.tin || invoiceState?.parties?.buyer?.name || invoiceState?.parties?.buyer?.tin) && (invoiceState?.status?.status? invoiceState?.status?.status!=="APPROVED":true)
    return (
      <div className="d-flex gap-2">
        <div>
          {invoiceState.selfInvoice ? (invoiceState?.parties.seller?.id  ) : (showEdit)  && (
            <>
              <Tooltip target=".custom-target-icon" />
              <i
                onClick={() => setVisible(true)}
                className="custom-target-icon pi pi-user-edit p-text-secondary p-overlay-badge"
                data-pr-tooltip={t("invoice.editBuyer")}
                data-pr-position="right"
                data-pr-my="left center-2"
                style={{ fontSize: "1.5rem", cursor: "pointer" }}
              ></i> 
            </>
          )}
        </div>
        <div>
          <Tooltip target=".custom-target-icon-plus" />
          <i
            onClick={() => setAddBuyer(true)}
            className="custom-target-icon-plus pi pi-user-plus text-success p-overlay-badge"
            data-pr-tooltip={t('invoice.addBuyer')}
            data-pr-position="right"
            data-pr-my="left center-2"
            style={{ fontSize: "1.35rem", cursor: "pointer" }}
          ></i>

          <i
            onClick={() => setCustomers(true)}
            className="custom-target-icon-plus pi pi-list p-overlay-badge ml-3"
            data-pr-tooltip={t('invoice.byerList')}
            data-pr-position="right"
            data-pr-my="left center-2"
            style={{ fontSize: "1.35rem", cursor: "pointer" }}
          ></i>
        </div>

        {<Dialog
          header={t("invoice.editBuyer")}
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: "30vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
         {visible && <EditCustomer
          errors={errors}
          setVisible={setVisible}
          fromInvoice={true}
            id={
              invoiceState?.customer?.id
                ? invoiceState?.customer?.id?.toString()
                : null
            }
            invoiceState={invoiceState}
            setInvoiceState={setInvoiceState}
            onCustomerUpdate={(customer) => {
              setErrors((errors)=>{
                const newErrors={}
                Object.keys(errors).forEach(key=>{
                  if(!key.includes("parties")){
                    newErrors[key]=errors[key]
                  }
                })
                return newErrors
              })
              handleInvoiceBuyerChange(null, 'buyer', customer);
              setVisible(false);
            }}
          />}
        </Dialog>}

        <Dialog
          header={t("invoice.addBuyer")}
          visible={addBuyer}
          onHide={() => setAddBuyer(false)}
          style={{ width: "30vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <CreateCustomer
            invoiceState={invoiceState}
            setInvoiceState={setInvoiceState}
            addBuyer={addBuyer}
            setAddBuyer={setAddBuyer}
            fromInvoice={true}
            onCustomerUpdate={(customer) => {
              setErrors((errors)=>{
                const newErrors={}
                Object.keys(errors).forEach(key=>{
                  if(!key.includes("parties")){
                    newErrors[key]=errors[key]
                  }
                })
                return newErrors
              })
            handleInvoiceBuyerChange(null, "buyer", customer);
            setAddBuyer(false);
            }}
            // onCustomerAddEdit={(customer) => {
            //   handleInvoiceBuyerChange(null, "buyer", customer);
            //   setAddBuyer(false);
            // }}
          />
        </Dialog>

        <Dialog
          header={t("taxpayer.buyerList")}
          visible={customers}
          onHide={() => setCustomers(false)}
          style={{ width: "70vw",minHeight:"88%" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          closable={true}
          dismissableMask={true}
        >
          <Customers customers={customers} />
        </Dialog>
      </div>
    );
  };

  const { town:buyerTown,country:buyerCountry,tin:buyerTin } = invoiceState?.parties?.buyer ?? {}
  const { town:sellerTown,country:sellerCountry,tin:sellerTin } = invoiceState?.parties?.seller ?? {}

  const correctionOfSelfInvoice = (invoiceState?.profileId == "P10" && invoiceState.correctionOfSelfInvoice && invoiceState?.type === "384")


  return (
    <div>
      <div className="d-flex justify-content-between">
        <Form.Label className="fw-bold header d-flex justify-content-between gap-1">
           <div>{t("invoice.seller")}</div>
           {(invoiceState.selfInvoice || correctionOfSelfInvoice )&&  (
            <div>
              <Tooltip target=".custom-target-icon" />
              <i
                onClick={() => setVisibleSeller(true)}
                className="custom-target-icon pi pi-user-edit p-text-secondary p-overlay-badge"
                data-pr-tooltip={t("invoice.editSeller")}
                data-pr-position="right"
                data-pr-my="left center-2"
                style={{ fontSize: "1.5rem", cursor: "pointer", marginLeft:'5px'}}
              ></i>
            </div>
          )}
         {visibleSeller && <EditSeller
            invoiceState={invoiceState}
            setInvoiceState={setInvoiceState}
            setVisibleSeller={setVisibleSeller}
            customStyles={customStyles}
            countries={countries}
            disabled={disabled}
            handleSellerIDTypeChange={handleSellerIDTypeChange}
            buyerIDTypes={buyerIDTypes}
            visibleSeller={visibleSeller}
            t={t}
          />}

        </Form.Label>
        <Form.Label className="fw-bold header ml-3 d-flex justify-content-between gap-1">
          <div>{t("invoice.buyer")}</div>
          {!invoiceState.selfInvoice && !correctionOfSelfInvoice && addEditCustomer(false) }
        </Form.Label>
      </div>

      <div className="row mx-2 mx-md-auto">
        <div className="col-12 col-md-6 pr-1 mr-0 pl-0 ml-0 border-top border-right">
          <div>
            <div >
              <label className="field-label mr-2">
                {t("navbar.search")} (NIPT)
              </label>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSellerTaxPayerSearch();
                }}
              >
                <div className="input-group position-relative">
                  <input
                    name="seller"
                    type="text"
                    disabled={(invoiceState.selfInvoice || correctionOfSelfInvoice ? disabled : true)}
                    className={
                      "form-control form-control-sm required" +
                      (sellerInputClass === "error"
                        ? " border-danger"
                        : sellerInputClass === "success"
                        ? " border-success"
                        : "")
                    }
                    value={invoiceState.selfInvoice || correctionOfSelfInvoice ? seller : ""}
                    onChange={handleSellerTaxPayerChange}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={
                        (invoiceState.selfInvoice || correctionOfSelfInvoice) && handleSellerTaxPayerSearch
                      }
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                  </div>
                  {sellerInputClass === "loading" && (
                    <div className="input-icon input-loader">
                      <Loader size="xs" />
                    </div>
                  )}
                  {sellerInputClass === "error" && (
                    <div className="input-icon text-danger">
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  )}
                  {sellerInputClass === "success" ? (
                    <div className="input-icon text-success">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
      
            <div>
      
            <div>
              <div className="field-label">{t("invoice.name")}</div>
             
              <div className="seller-invoice ">
              {
                 ( invoiceState.selfInvoice || correctionOfSelfInvoice) ?  
                <>
                    <AsyncSelect
                    styles={customStyles}
                    placeholder={t("taxpayer.buyername")}
                    name="name"
                    ref={inputRefSeller}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    className={`basic-multi-select valid`}
                    onChange={(value) => {
                      handleInvoiceSellerChange(null, "seller", value);
                    }}
                    isDisabled={(invoiceState.selfInvoice || correctionOfSelfInvoice ? disabled : true)}
                    // cacheOptions
                    // value={invoiceState.parties.seller.label}
                    inputValue={invoiceState?.parties?.seller?.name ?? ""}
                    loadOptions={(name)=>{const test=loadOptionsForSeller(name); return test}}
                    defaultOptions
                    onInputChange={(input, reason) =>
                      handleInvoiceSellerChange(null, "name", input, 200, reason)
                    }
                    isClearable={true}
                  /> 
                </>

              :
                <>
                  {invoiceState?.parties?.seller?.name}
                </>
              }
             
              </div>
            </div>
            <div>
              <div>
                <div className="field-label">{invoiceState.parties.seller.sellerIDType}</div>
                <div className="seller-invoice">
                  {invoiceState.parties.seller.tin}
                </div>
              </div>
            </div>
            <div>
              <div className="field-label">{t("customers.address")}</div>
              <div className="seller-invoice">
                {invoiceState.parties.seller.address}
              </div>
            </div>
            <div>
              <div className="field-label">{t("taxpayer.city")} / {t("taxpayer.country")}</div>
              <div className="seller-invoice">
                {sellerTown ?? ""}{sellerCountry && sellerTin?","+sellerCountry:""}
              </div>
            </div>
          </div>

          {/* <textarea
               name="address"
               type="text"
               className={`form-control form-control-sm `}
               //disabled={disabled}
               value={invoiceState.parties.seller.address}
               onChange={(e) => handleInvoiceSellerChange(e, null, null, 400)}
               disabled={!invoiceState.selfInvoice || disabled}
             /> */}

          {/* <div style={{position:"absolute", top:130, right:20}}  className={`length-text ${invoiceState?.parties?.seller?.address?.length == 400 ? "text-danger" : ""}`}>{invoiceState.parties.seller.address.length}/400</div> */}

          {/* <div
            style={{position:"absolute", top:150, right:20}}
            className={`length-text ${
              invoiceState?.parties?.seller?.tin?.length == 20
                ? "text-danger"
                : ""
            }`}
          >
            {invoiceState?.parties?.seller?.tin?.length}/20
          </div> */}
        </div>
        
        <div className="col-12 col-md-6 pr-0 mr-0 pl-1 ml-0 border-top">
          <div>
            <div>
              <label className="field-label">
                {t("navbar.search")} (NIPT)
                <span className="text-danger"> *</span>
                {/* <PopOver title="Text NIPT" /> */}
              </label>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleTaxPayerSearch();
                }}
              >
                <div className="input-group position-relative">
                  <input
                    name="buyer"
                    type="text"
                    className={
                      "form-control form-control-sm" +
                      (inputClass === "error"
                        ? " border-danger"
                        : inputClass === "success"
                        ? " border-success"
                        : "")
                    }
                    value={buyer}
                    disabled={invoiceState.selfInvoice || correctionOfSelfInvoice || disabled}
                    onChange={handleTaxPayerChange}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={
                        !invoiceState?.selfInvoice && handleTaxPayerSearch
                      }
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                  </div>
                  {inputClass === "loading" && (
                    <div className="input-icon input-loader">
                      <Loader size="xs" />
                    </div>
                  )}
                  {inputClass === "error" && (
                    <div className="input-icon text-danger">
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  )}
                  {inputClass === "success" && (
                    <div className="input-icon text-success">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div>
            <div>
              <div>
                <label className="field-label mt-1">
                  {t("taxpayer.buyername")}
                  {/* <PopOver title="Text Buyer Name" /> */}
                </label>
                <AsyncSelect
                  styles={customStyles}
                  placeholder={t("taxpayer.buyername")}
                  name="name"
                  ref={inputRef}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  className={`basic-multi-select valid`}
                  onChange={(value) => {
                    handleInvoiceBuyerChange(null, "buyer", value);
                  }}
                  cacheOptions
                  value={invoiceState.parties.buyer.label}
                  inputValue={invoiceState.parties.buyer.name}
                  loadOptions={loadOptions}
                  defaultOptions
                  onInputChange={(input, reason) =>
                    handleInvoiceBuyerChange(null, "name", input, 200, reason)
                  }
                  isClearable={true}
                  isDisabled={invoiceState.selfInvoice || correctionOfSelfInvoice || disabled}
                />
                {errors?.["parties.buyer.name"] && invoiceState?.parties?.buyer?.name?.length==0 && <FontAwesomeIcon icon={faCircleExclamation} style={{color: "#eb0000", position:'absolute',top:96,right:24}} />}
              </div>

              <div className="field-label">
                {" "}
                {invoiceState?.parties?.buyer?.buyerIDType || ""}
              </div>
              <div className="seller-invoice">
                {invoiceState.parties.buyer.tin || ""}
              </div>
            </div>

            <div>
              <div className="field-label">{t("taxpayer.address")}</div>
              <div className="seller-invoice">
                {invoiceState.parties.buyer.address || ""}
              </div>
            </div>
            <div>
              <div className="field-label">{t("taxpayer.city")} / {t("taxpayer.country")}</div>
              <div className="seller-invoice">
                {buyerTown ?? "-"}{buyerCountry && buyerTin ? "," + buyerCountry : ""}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default DemoParties;
