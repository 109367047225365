import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { purchaseAttachments } from './dummy_files/dummy';
import func from '../../services/functions'
import { faChevronLeft, faChevronRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilePreview from "./columns/FilePreview";
import mainAxios from "../../services/mainAxios";
import Loader from "../Layout/Loader";


const PurchaseDocumentPreview = ({
  purchaseAttachments,
  isDesktop,
  purchaseId,
  isDrag
}) => {
  const { t } = useTranslation("translations");
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0)
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [filePreview, setFilePreview] = useState(null)

  const decrementPage = () => {
    if (currentDocumentIndex > 0 && !loadingDocument) {
      setCurrentDocumentIndex(val => {
        val--;
        return val
      })
    }
  }

  const incrementPage = () => {
    if (currentDocumentIndex < purchaseAttachments?.length - 1 && !loadingDocument) {
      setCurrentDocumentIndex(val => {
        val++;
        return val
      })
    }
  }

  useEffect(() => {
    if (purchaseAttachments?.length > 0) {
      setLoadingDocument(true)
      let id = purchaseAttachments[currentDocumentIndex].id
      let documentType = purchaseAttachments[currentDocumentIndex].documentType
      let name=purchaseAttachments[currentDocumentIndex].name ?? Date.now()+"."+documentType

      mainAxios
        .post("endpoint/v2/getAtachementByte", {
          value:id
        })
        .then((res) => {
          setLoadingDocument(false)

          if (res?.data?.status) {
            setFilePreview({
              name,
              documentType,
              base64: res.data.content[0]
            })
          }
        });
    }

  }, [currentDocumentIndex, purchaseAttachments?.length,purchaseId])


  return (
    <div className="content-fluid card border-0 shadow mb-2 sticky">
      <div className="card-header">
        <h3 className="card-title">
          {t("invoice.documentPreview")}
        </h3>
      </div>

      <div className="card-body">
        {(purchaseAttachments?.length > 0) &&
          <div className="d-flex justify-content-between">
            <div style={{fontWeight:"700"}}>
              {filePreview?.name}
            </div>
          <div className="d-flex justify-content-end align-items-center">
            <div className="pagination mr-4 mb-3">
              <div className="page-count mr-3">
                {t("invoice.document")} {(currentDocumentIndex + 1)} of {purchaseAttachments?.length > 0 ? purchaseAttachments?.length : 1}
              </div>
              <div className="left mr-3">
                <FontAwesomeIcon onClick={decrementPage} size="1x" icon={faChevronLeft} className="mr-1" />
              </div>
              <div className="right mr-5">
                <FontAwesomeIcon onClick={incrementPage} size="1x" icon={faChevronRight} className="mr-1" />
              </div>
              {filePreview?.base64 && <a
                href={
                  "data:application/pkcs12;base64," +
                  filePreview?.base64
                }
                download={filePreview?.name ?? ""}
                className="nav-link d-inline-block p-0 mr-3"
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faDownload}
                    className="mr-2"
                  />
                  {t("company.download")}
                </div>
              </a>}
            </div>
          </div>
          </div>
          
          }
        {loadingDocument ?
          <Loader /> :
          isDrag && !loadingDocument && filePreview?
          (
            <div className="d-flex align-items-center justify-content-center h-100" style={{position: "relative"}}>
              <img height="500px" width="100%" src={"/pdf-blur.png"} style={{objectFit:"cover"}}/>
              <div style={{left:0,right:0,bottom:0,top:0,position:"absolute",backgroundColor:"black",opacity:0.3}}/>
            </div>
          ):
          !loadingDocument && filePreview?.documentType=="pdf" ? <FilePreview filePreview={filePreview} isDesktop={isDesktop}/>
            : <h5 className="text-center">{t("invoice.shouldExtractPdf")}</h5>
        }
      </div>
    </div>
  );
};

export default React.memo(PurchaseDocumentPreview);
