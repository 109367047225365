import React, { useState, useRef,useEffect, useMemo } from "react";

import Navbar from "./Navbar";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { useAppContext } from "../../AppContext";
import { Helmet } from "react-helmet";
import func from "../../services/functions";
import jwt_decode from "jwt-decode";
import axios from "axios";
import WebSocket from "../WebSocket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation, withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import mainAxios, { logout } from "../../services/mainAxios";
import SideBar from "./SideBar";
import UseScreenSize from "../../utils/UseSizeScreen";
import { faEdit, faSignOutAlt, faSyncAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import useClickOutside from "../../utils/useClickOutside";


const TITLE = "ProFisc" + process.env.REACT_APP_TITLE;

const Wrapper = (props) => {
  const { t, i18n } = useTranslation("translations");
  const [selected, setSelected] = useState("");
  const screenSize = UseScreenSize()
  const contentWrapper = useRef();
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany],
    company:[fullcompany,setFullCompany],
    getUnitOfmeasure,
    getStatus
  } = useAppContext();

  useEffect(() => {
    const token = func.getCookie("token");
    if (token) {
      let username;
      try {
        username = jwt_decode(token).sub;
      } catch (error) {
        username = "";
      }

      axios
        .post(
          process.env.REACT_APP_JAVA_SERVER_URL + "apiEndpoint/search",
          {
            object: "GetUser",
            value: null,
            type: "basic",
            params: JSON.stringify({ username }),
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res && res.data?.content?.[0]) {
            let fullUser = res.data.content[0];

            if (
              !fullUser.companiesRights.find(
                (company) => company.id === parseInt(selectedCompany)
              )
            ) {
              setUser(null);
            } else {
              delete res.data.content[0]["companyBranchesTcr"];
              setUser({...fullUser,username});
            }
          }
        });
    }
  }, []);


  useEffect(()=>{
    const token = func.getCookie("token");
    if(token && selectedCompany){
      getCompany(selectedCompany)
    }
  },[selectedCompany])


  React.useEffect(() => {
    const token = func.getCookie("token");
    if(token){
      getUnitOfmeasure()
      getStatus()
    }
  }, [])

  

  const getCompany=(companyId)=>{
    mainAxios.post('apiEndpoint/search', {
        object: "GetCompany",
        params: {
            id: companyId
        }
    }).then(res => {
        if(res.data.status) {
            setFullCompany(res.data.content[0])
        }
    })
  }


  if (user && !Object.keys(user).includes("companiesRights")) {
    localStorage.clear();
  }

  const currentCompany = user?.companiesRights?.find(
    (company) => company.id === parseInt(selectedCompany)
  )?.name;

  const flags = () => {
    return (
      <div className="d-flex align-items-center">
        <div className=" contact-info pl-2 ">
          <a
            href="#"
            className="nav-link p-0"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage("alb");
              saveLanguage("alb");
            }}
          >
            <img src="/albania-icon-round.png" style={{ width: "16px" }} />
          </a>
        </div>
        <div className="contact-info px-2">
          <a
            href="#"
            className="nav-link p-0"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage("en");
              saveLanguage("en");
            }}
          >
            <img src="/england-icon-round.png" style={{ width: "16px" }} />
          </a>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    let language = localStorage.getItem("language");

    if (language == "alb") {
      i18n.changeLanguage("alb");
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const saveLanguage = (val) => {
    localStorage.setItem("language", val);
  };

  const isLoggedIn=user && selectedCompany




  return (
     <div className="wrapper" >
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
{/*
      <div
        className={`top-bar d-flex justify-content-${
          currentCompany ? `between` : `end`
        }   bg-white w-100 align-items-center border py-sm-2 py-2 py-md-0 py-lg-0 px-4`}
        style={{ color: "#007567", height: "31px" }}
      >
        {currentCompany && (
          <div>
            <FontAwesomeIcon icon={faBuilding} className="mr-2" />
            <span>{currentCompany}</span>
          </div>
        )}
        <div className="d-flex justify-content- flex-wrap">
          <div className="d-flex contact-info px-2">
            <a
              href="https://apps.apple.com/al/app/profisc/id6443799212"
              className="nav-link p-0 my-auto font-weight-bold"
            >
              <img
                src="/app-store.png"
                style={{ width: " 90px" }}
                className="mr-1"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.tetrapro.profisc"
              className="nav-link p-0 my-auto font-weight-bold"
            >
              <img
                src="/play-store.png"
                style={{ width: " 90px" }}
                className="mr-1"
              />
            </a>
          </div>
          <div className="d-none d-sm-none d-md-inline d-lg-inline">
            {flags()}
          </div>

          <div className="contact-info px-2">
            <a
              href="mailto:support@tetra-solutions.atlassian.net"
              className="nav-link p-0 my-auto font-weight-bold"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
              support@tetra-solutions.atlassian.net
            </a>
          </div>
          <div className="contact-info px-2 ">
            <a
              href="tel:+35544508855"
              className="nav-link p-0 font-weight-bold"
            >
              <FontAwesomeIcon icon={faPhone} />
              +355 44 508 855
            </a>
          </div>
          <div className="contact-info px-2">
            <a
              href="tel:+355677001100"
              className="nav-link p-0 font-weight-bold"
            >
              <FontAwesomeIcon icon={faPhone} />
              +355 067 700 1100
            </a>
          </div>
          <div className="contact-info px-2">
            <a
              href="https://tetra-solutions.atlassian.net/servicedesk/customer/portal/4"
              target="_blank"
              className="nav-link p-0 font-weight-bold"
            >
              <FontAwesomeIcon icon={faWrench} className="mr-1" />
              {t("navbar.technicalAssistance")}
            </a>
          </div>
          <div className="contact-info px-2">
            <a
              href="https://help.profisc.al"
              target="_blank"
              className="nav-link p-0 font-weight-bold"
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="mr-1" />
              ProFisc Help
            </a>
          </div>
          <div className="d-inline d-sm-inline d-md-none d-lg-none">
            {flags()}
          </div>
        </div>
      </div> */}

      {isLoggedIn && (
        <div className="relative">
          <Navbar contentWrapper={contentWrapper} />
          <WebSocket />
        </div>
      )}


    <div  className="d-flex">
      <div className="p-0" >
      {isLoggedIn && (
        <SideBar currentCompany={currentCompany}  onChangeScree={()=> {
        }}  />
      )}
       </div>

      <div  className={`px-2 w-100 ${isLoggedIn?"content-wrapper":"p-0 m-0"} ${props.hideOverflow ? "overflow-hidden" : ""})`}
           ref={contentWrapper}>
              {props.children}
        </div>
        

      </div>


      <ToastContainer
        className={`custom-toast-width`}
        containerId="upload-invoice"
        enableMultiContainer
      />
    </div>
  );
};

export default withRouter(Wrapper);
