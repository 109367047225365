import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Preloader, Bars } from "react-preloader-icon";
import func from "../../services/functions";
import { DashboardCard } from "../global/CommonComponents";

const TcrCashBalance = ({ title, stats = [], sessionData, loading }) => {
  const { t } = useTranslation("translations");

  let filteredStats = useMemo(() => {
    return sessionData?.businessUnitCode
      ? stats?.filter(
          (stat) => stat?.businessUnitCode === sessionData?.businessUnitCode
        )
      : stats;
  }, [sessionData?.businessUnitCode, stats]);

  const colorArr = [
    "badge-success-dashboard",
    "badge-info-dashboard",
    "badge-warning-dashboard",
    "badge-alert-dashboard",
    "badge-danger-dashboard",
    "badge-light-dashboard",
  ];
  return (
    <DashboardCard title={title}>
        <div className="list-group h-100 w-100 list-widget custom-scroll-bar">
          {loading ? (
            <div className="d-flex justify-content-center h-100 align-items-center "
            >
              <Preloader
                use={Bars}
                size={90}
                strokeWidth={6}
                duration={1000}
                strokeColor="rgba(209, 231, 221, 1)"
              />
            </div>
          ) : filteredStats?.length > 0 ? (
            filteredStats?.map((stat, index) => {
              return (
                <div  key={stat.tcrCode}>
                  <span
                    className={`badge badge-dashboard mb-4 ${
                      colorArr[index % colorArr.length]
                    }`}
                  >
                    {func.getFormattedMoneyValue(
                      (stat?.cashEnd ?? 0) + (stat?.banknoteCashTot ?? 0)
                    )}
                    L
                  </span>
                  <FontAwesomeIcon
                    icon={faSitemap}
                    className="mr-3 text-"
                    style={{ color: "#d1e7dd" }}
                  />
                  {stat?.tcrCode ?? 0}
                </div>
              );
            })
          ) : (
            <div className="list-group h-100 list-widget"
            >
            <h2 className="d-flex justify-content-center h-100 align-items-center text-muted">{t("dashboard.noData")}</h2>
            </div>
          ) }
        </div>
    </DashboardCard>
  );
};

export default TcrCashBalance;
