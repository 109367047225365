import {
  faEdit,
  faPlus,
  faFileUpload,
  faFileDownload,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../Layout/Loader";
import mainAxios from "../../../services/mainAxios";
import Table from "../../Table";
import { useTranslation, withTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import ImportItems from "./ImportItems";
import { useAppContext } from "../../../AppContext";
import AlertCertificate from "../../global/AlertCertificate";
import PrimeTable from "../../PrimeTable/PrimeTable";
import EditItem from "./EditItem";
import { Dialog } from "primereact/dialog";
import ExportCsvPrime from '../../global/ExportCsvPrime'
import AddItem from "./AddItem";

const moment = require("moment");

const Items = () => {
  const [products, setProducts] = React.useState(null);
  const [deleting, setDeleting] = React.useState(false);
  const [reloadItems,setReloadItems]=React.useState(false)
  const defaultFilters = {
    active: null
  }
  const [filters, setFilters] = React.useState({
    params:{}
  });
  const [visibleItems, setVisibleItems] = React.useState({open:false,id:null});
  const [tableRows, setTableRows] = React.useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const { t } = useTranslation("translations");
  const company = localStorage.getItem("selectedCompany");
  const [reloadPage,setReloadPage]=React.useState(false);
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany, setSelectedCompany],
  } = useAppContext();

const [openImport, setOpenImport] = React.useState(false);

  React.useEffect(() => {
    getItems()
    }
, [company,reloadPage,reloadItems]);


  const deleteItem = (e) => {
    setDeleting(true);
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCompanyItems",
        value: null,
        type: "basic",
      })
      .then((res) => {
        if (res.data.status===true) {
          setProducts(res.data.content);
        }
      });
    }

    const handleCloseDialog = () => {
      setVisibleItems({open:false,id:null});
    };

  // const openUploadItems = (e) => {
  //   e.preventDefault();
  //   swal(
  //     <ImportItems user={user} selectedCompany={selectedCompany} swal={swal} reloadPage={()=>{setReloadPage((reload)=>!reload)}}/>,
  //     { buttons: false }
  //   );
  // };

  const updateItem = () => {
    setReloadItems(reloadItems => !reloadItems)
  };
  const getItems = ({ updatedFilters, rowsPerPage = 10, currentPage = 1, exportCsv = false, sortCriteria, searchQuery } = {}) => {
    if (updatedFilters && updatedFilters.params && updatedFilters.params.categories === null) {
        updatedFilters.params.categories = "";
    }
    return mainAxios
        .post("apiEndpoint/search", {
            object: "GetCompanyItems",
            value: null,
            type: "basic",
            params: updatedFilters?.params,
            pagination: exportCsv ? null : {
                pageSize: rowsPerPage,
                pageNumber: currentPage,
            },
            sortCriteria
        })
        .then((res) => {
            if (res.data.status === true) {
                setProducts(res.data.content);
                setTableRows((tableRows) => ({
                    ...tableRows,
                    totalRows: res?.data?.totalSize,
                }));
                return true
            }
            return false
        });
}



  const columns = [
    {
      header: `${t("item.code")}`,
      field: "code",
      filter:true,
      style:{ minWidth: '170px', maxWidth:"170px",height:"45px" },
      body: item=>(
        item.code?(
        <input type="text"
            onFocus={(event) => event.target.select()}
            value={item.code}
            style={{ border: 0,userSelect: "text", fontSize:"13px", width:"100px"}}
            onChange={()=>{}}
            />
        ):""
    ),
      sortable: true,
    },
    {
      field: 'actions',
      header: t(["home.actions"]),
      style:{ minWidth: '8rem', maxWidth:"8rem" },
      body: (products) => (
        <>
          <Link onClick={()=> setVisibleItems({open:true, id:products?.id})} className="nav-link p-0 text-primary mr-2">
            <FontAwesomeIcon icon={faEdit} className="mr-1" />
            {t("company.edit")}
          </Link>
          {/* <Link
             onClick={e =>{e.preventDefault(); deleteItem(products)}}
            className="nav-link p-0 text-danger">
            <FontAwesomeIcon icon={faTrash} className="mr-1" />
            {t("common.delete")}
          </Link> */}
        </>
      ),
    },
    // {
    //   header: `Category`,
    //   field: 'categoryCode',
    //   sortable: true,
    //   style:{ minWidth: '8rem', maxWidth:"8rem" },
    //   body: (value) => value.category.code
    // },
    {
      header: `${t("item.name")}`,
      field: "name",
      filter:true,
      style:{ minWidth: '10rem', maxWidth:"10rem" },
      body: item=>(
        item.name?(
        <input type="text "
            onFocus={(event) => event.target.select()}
            value={item.name}
            style={{ border: 0,userSelect: "text", fontSize:"13px", width:"full"}}
            onChange={()=>{}}
            />
        ):""
    ),
      sortable: true,
    },
    {
      header: `${t("item.measure")}`,
      field: "unitOfMeasure",
      style:{ minWidth: '10rem', maxWidth:"10rem" },
      sortable: true,
      body: (el) =>{
        if(el?.unitOfMeasureId?.albanianName){
          return el.unitOfMeasureId.albanianName
        }else if(el?.unitOfMeasure){
          return el.unitOfMeasure
        }else{
          return ""
        }
      },
    },
    {
      header: `${t("item.vatCategory")}`,
      field: "vatCategory",
      style:{ minWidth: '10rem', maxWidth:"10rem" },
      sortable: true,
      body: (percent) => percent.vatCategory + "%",
    },
    {
      header: `${t('item.itemCategory')}`,
      field: "categories",
      style:{ minWidth: '10rem', maxWidth:"10rem" },
      filter: true,
      filterConfig:{
        like:false,
      },
      body: (itemCategory) => itemCategory.category.name || "",
      format: (itemCategory) => itemCategory?.category?.name,
    },
    {
      header: `${t("item.totalLekWithoutVat")}`,
      field: "priceWovatAll",
      style:{ minWidth: '11rem', maxWidth:"11rem" },
      sortable: true,
    },
    {
      header: `${t("item.totalLekWithVat")}`,
      field: "priceWvatAll",
      style:{ minWidth: '12rem', maxWidth:"12rem" },
      sortable: true,
    },
    {
      header: `${t("item.totalEuroWithoutVat")}`,
      field: "priceWovatEur",
      style:{ minWidth: '12rem', maxWidth:"12rem" },
      sortable: true,
    },
    {
      header: `${t("item.totalEuroWithVat")}`,
      field: "priceWvatEur",
      style:{ minWidth: '11rem', maxWidth:"11rem" },
      sortable: true,
    },
    {
      header: `${t("item.totalDollarWithoutVat")}`,
      field: "priceWovatUsd",
      style:{ minWidth: '11rem', maxWidth:"11rem" },
      sortable: true,
    },
    {
      header: `${t("item.totalDollarWithVat")}`,
      field: "priceWvatUsd",
      style:{ minWidth: '11rem', maxWidth:"11rem" },
      sortable: true,
    },
    {
      header: `${t("item.description")}`,
      field: "description",
      style:{ minWidth: '11rem', maxWidth:"11rem" },
      sortable: true,
    },
    {
      header: `${t("item.vatReason")}`,
      field: "vatReason",
      style:{ minWidth: '11rem', maxWidth:"11rem" },
      sortable: true,
    },
    {
      header: `${t("item.investment")}`,
      field: "investment",
      style:{ minWidth: '11rem', maxWidth:"11rem" },
      sortable: true,
      body: (value) => (value.investment ? "True" : "False"),
    },
  ];


  return products ? (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-center my-2">
        <div className="d-flex flex-wrap align-items-center">
        <h3 className="d-flex align-items-center mb-0 p-0">
          {t("navbar.products")}
          <small className="ml-3">
             <ExportCsvPrime
              data={products}
              columns={columns}
              onClick={() => getItems({exportCsv:true,updatedFilters:filters})}
              excludedColumns={["expansionCol","actions"]}
            />
          </small>
          <small className="ml-3">
            <a
              onClick={(e) => setOpenImport(true)}
              className="btn btn-light bg-white shadow-sm btn-sm text-primary"
            >
              <FontAwesomeIcon icon={faFileUpload} className="mr-2" />
              <span className="text-dark">
                Import
              </span>
            </a>
          </small>
           <small className="ml-3">
            <a
              href={"/items_csv_template.csv"}
              className="btn btn-light bg-white shadow-sm btn-custom btn-sm text-primary"
              download="csv_import_template.csv"
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                CSV Import Template
              </div>
            </a>
          </small>
          <div>
          </div>
        <span>
        </span>
        </h3>
        <AlertCertificate/>
        </div>

        <div className="page-actions">
          <Link onClick={()=>setVisibleItems({ open:true })} className="btn btn-sm btn-primary">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            {t("item.addItem")}
          </Link>
        </div>
      </div>
      <div>
        
      <Dialog
          header={visibleItems.id ? t("item.editItem") : t("item.addItem")}
          visible={visibleItems?.open}
          onHide={() => setVisibleItems({open:false,id:null})}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
         {
          visibleItems?.id ?
            <EditItem setVisibleItems={setVisibleItems} updateItem={updateItem} id={visibleItems?.id} onClose={handleCloseDialog}   />
            :
             <AddItem setVisibleItems={setVisibleItems} updateItem={updateItem} id={visibleItems?.id} onClose={handleCloseDialog} />
         }
      </Dialog>

      <Dialog
          header={t("item.importItems")}
          visible={openImport}
          onHide={() => setOpenImport(false)}
          style={{ width: "25vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
       <ImportItems user={user} selectedCompany={selectedCompany} reloadPage={()=>{setReloadPage((reload)=>!reload)}}/>,
      </Dialog>

      </div>
      <div className="card border-0 shadow">
        <div className="card-body">
          <PrimeTable
            columns={columns}
            value={[...products]}
            responsiveLayout="scroll"
            filters={[filters, setFilters]}
            tableRows={[tableRows, setTableRows]}
            defaultFilters={defaultFilters}
            searchQuery={true}
            onFilter={(data) => {
              setFilters(data.filters);
            }}
            showFilters={true}
            reorderableColumns={true}
            clearFilters={true}
            className="white-header"
            showSelectColumns={false}
            headerButtonsRight
            onRemoteDataChange={(remoteData) => {
              const updatedFilters = remoteData?.filters
              const updatedTableData = remoteData?.tableRows ?? tableRows
              let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
              sortCriteria=sortCriteria?[sortCriteria]:null
                 getItems({updatedFilters, rowsPerPage, currentPage,sortCriteria,searchQuery:remoteData?.searchQuery})
            }}
            />
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default Items;
