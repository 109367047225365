import {
  faCalendarAlt,
  faFilePdf,
  faPlus,
  faReceipt,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Loader from "./Layout/Loader";
import func from "../services/functions";
import { mainAxios } from "../services/mainAxios";
import Bar from "./Statistics/Bar";
import { useAppContext, usePersistedState } from "../AppContext";
import colors from "../design/colors.json";
import StatisticBox from "./Statistics/StatisticBox";
import DoughnutPieTable from "./dashboard/DoughnutPieTable";
import Table from "./Table";
import { Link } from "react-router-dom";
import InvoiceStatus from "./Invoice/columns/InvoiceStatus";
import { useTranslation, withTranslation } from "react-i18next";
import DateRange from "./global/DateRange";
import selectStyle from "../design/selectStyle";
import Select from "react-select";

const moment = require("moment");

const Home = () => {
  const { t, i18n } = useTranslation("translations");

  const [pdfCount, setPdfCount] = useState(0);
  const [queues, setQueues] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const company = user?.companiesRights?.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  const [persistedData, setPersistedData] = usePersistedState(
    null,
    "sessionData"
  );

  const businessUnitCodes=React.useMemo(()=>{
    return [{
      label: t("invoice.businessUnitCode"),
      value: null,
    }].concat(company.branches.map((branch)=>{
      return {
        label: branch.businUnitCode  + " | " + branch.sellerName,
        value:  branch.businUnitCode,
      };
    }))
  },[selectedCompany])


  const filterParams = {
    businessUnitCode: businessUnitCodes.some(
      (branch) => branch?.value === persistedData?.businessUnitCode
    )
      ? persistedData?.businessUnitCode
      : "",
  };

  const defaultFilters = {
    fromDate:
      persistedData?.fromDate && persistedData?.toDate
        ? new Date(persistedData?.fromDate)
        : func.getDateFromToday(-2),
    toDate:
      persistedData?.fromDate && persistedData?.toDate
        ? new Date(persistedData?.toDate)
        : func.getDateFromToday(1),
    params: {
      ...filterParams,
    },
  };
  const [filters, setFilters] = React.useState(defaultFilters);

  const [totalSize, setTotalSize] = useState(0);
  React.useEffect(() => {
    getFilteredResults(defaultFilters);
  }, [selectedCompany]);
  const handleParamsChange = (e, label, val) => {
    const key = e ? e.target.name : label;
    const value = e ? e?.target?.value : val;

    setFilters((filters) => ({
      ...filters,
      params: {
        ...filters?.params,
        [key]: value,
      },
    }));
  };

  const getFilteredResults = (data) => {
    const requestFilters = data ? data : filters;
    setLoading(true);
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCount",
        fromDate: filters?.fromDate,
        toDate: filters?.toDate,
        params: {
          count: "pdfPpath",
        },
      })
      .then((res) => {
        if (res?.data?.status === true) {
          setPdfCount(res.data.content[0] || 0);

          mainAxios
            .post("apiEndpoint/search", {
              object: "GetQueue",
              ...requestFilters,
              params: {
                ...requestFilters.params,
                businessUnitCode:
                  requestFilters.params.businessUnitCode || null,
              },
              value: null,
              pagination: {
                pageSize: 5,
                pageNumber: 1,
              },
            })
            .then((res) => {
              if (res?.data?.status === true) {
                setQueues(res.data.content);
                setTotalSize(res?.data?.totalSize);
              } else {
                setQueues([]);
              }
              setLoading(false);
            });
        } else {
          setPdfCount(0);
          setQueues([]);
          setLoading(false);
        }
      });
  };

  const QueueColumns = [
    {
      name: t(["home.buyerName"]),
      selector: "buyerName",
      sortable: true,
      format: (queue) => queue.buyerName || "",
    },
    {
      name: "ERPID",
      selector: "buyerName",
      sortable: true,
      format: (queue) => queue.buyerName || "",
    },
    {
      name: t(["home.date"]),
      selector: "recordDate",
      sortable: true,
      format: (queue) =>
        queue.recordDate
          ? moment(queue.recordDate).format("DD/MM/YYYY HH:mm")
          : "",
    },
    {
      name: t(["home.status"]),
      selector: "fiscStatus",
      format: (queue) => (
        <InvoiceStatus
          fiscStatus={queue.fiscStatus}
          einvoiceStatus={queue.einvoiceStatus}
          ublInvoiceTypeCode={queue.ublInvoiceTypeCode}
          correctiveInvoiceType={queue.correctiveInvoiceType}
          einvoice={queue.einvoice}
        />
      ),
    },
    {
      name: t(["home.amount"]),
      selector: "total",
      sortable: true,
      format: (queue) =>
        queue.total
          ? func.getFormattedMoneyValue(queue.total) + " Lek"
          : "0 Lek",
    },
  ];

  // const successQueues = queues.filter(queue => (parseInt(queue.fiscStatus) === 100 && parseInt(queue.einvoiceStatus) === 100)
  // || parseInt(queue.fiscStatus) === -100).sort((curr, next) => next.recordDate - curr.recordDate)

  // const errorQueues = queues.filter(queue => (parseInt(queue.fiscStatus) !== 100 || parseInt(queue.einvoiceStatus) !== 100)
  // && parseInt(queue.fiscStatus) !== -100).sort((curr, next) => next.recordDate - curr.recordDate)

  const successQueues = queues.filter(
    (queue) =>
      ((parseInt(queue.fiscStatus) === 100 ||
        parseInt(queue.fiscStatus) === -100) &&
        queue.einvoice === false) ||
      ((parseInt(queue.einvoiceStatus) === 100 ||
        parseInt(queue.einvoiceStatus) === -100) &&
        queue.einvoice === true)
  );

  const errorQueues = queues.filter((queue) => !successQueues.includes(queue));

  const sentInvoicesData = {
    labels: [t(["home.successful"]), t(["home.error"])],
    colors: [colors.primary.light.full, colors.danger.normal.full],
    data: {
      columns: [t(["home.invoiceType"]), t(["home.invoice#"])],
      rows: [
        [
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faSquare}
              className="mr-2"
              style={{ color: colors.primary.light.full }}
            />
            {t("home.successful")}
          </div>,
          successQueues.length,
        ],
        [
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faSquare}
              className="mr-2"
              style={{ color: colors.danger.normal.full }}
            />
            {t("home.error")}
          </div>,
          errorQueues.length,
        ],
      ],
    },
    datasets: [
      {
        label: t(["home.nrInv"]),
        data: [successQueues.length, errorQueues.length],
        backgroundColor: [
          colors.primary.light.transparent,
          colors.danger.normal.transparent,
        ],
        borderColor: [colors.primary.light.full, colors.danger.normal.full],
        borderWidth: 1,
      },
    ],
  };

  const successData = successQueues.filter(
    (queue) =>
      new Date().getFullYear() === new Date(queue.recordDate).getFullYear()
  );
  let successDataByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  successData.forEach((queue) => {
    const month = new Date(queue.recordDate).getMonth();
    successDataByMonth[month]++;
  });
  const errorData = errorQueues.filter(
    (queue) =>
      new Date().getFullYear() === new Date(queue.recordDate).getFullYear()
  );
  let errorDataByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  errorData.forEach((queue) => {
    const month = new Date(queue.recordDate).getMonth();
    errorDataByMonth[month]++;
  });

  const stackedBarData = [
    {
      label: t(["common.nrSuccess"]),
      data: successDataByMonth,
      backgroundColor: colors.primary.light.full,
    },
    {
      label: t(["common.nrError"]),
      data: errorDataByMonth,
      backgroundColor: colors.danger.normal.full,
    },
  ];

  const userCanCreate = [1, 2, 4].includes(user.role.id);
  const DateInput = React.forwardRef((props, ref) => {
    return (
      <button
        ref={ref}
        className="btn btn-light bg-white shadow-sm dropdown-toggle btn-sm ml-2 btn-custom"
        onClick={props.onClick}
      >
        <FontAwesomeIcon icon={faCalendarAlt} className="text-primary mr-2" />
        {props.children}
      </button>
    );
  });

  // You can now get a ref directly to the DOM button:
  const ref = React.createRef();

  return !loading ? (
    <div className="row">
      <div className="col-12">
        <div className="row justify-content-between align-items-center mt-4 mb-4">
          <h3 className="mb-0 col-auto">{t("home.analytics")}</h3>
          <div className="ml-md-auto  mb-4 mb-md-0">
            <DateRange
              fromDate={filters?.fromDate}
              toDate={filters?.toDate}
              onChange={(dates) => {
                let fromDate = dates[0];
                let toDate = dates[1];
                if (fromDate && toDate) {
                  getFilteredResults({
                    fromDate,
                    toDate,
                    params: { ...filters?.params },
                  });
                }
                setFilters((filters) => ({
                  ...filters,
                  fromDate,
                  toDate,
                }));
                setPersistedData({ ...persistedData, fromDate, toDate });
              }}
            />
          </div>
          <div className="col-6 col-md-2 d-flex align-items-center">
            <label className="align-items-center mr-2 mb-0">BU</label>
            <Select
              styles={selectStyle}
              className="w-100"
              options={businessUnitCodes}
              value={
                businessUnitCodes.find(
                  (businessUnitCode) =>
                    businessUnitCode?.value ===
                    filters?.params?.businessUnitCode
                ) || ""
              }
              isSearchable={true}
              onChange={(businessUnitCode) => {
                handleParamsChange(
                  null,
                  "businessUnitCode",
                  businessUnitCode?.value
                );
                setPersistedData({
                  ...persistedData,
                  businessUnitCode: businessUnitCode.value,
                });
                getFilteredResults({
                  ...filters,
                  params: {
                    ...filters?.params,
                    businessUnitCode: businessUnitCode.value,
                  },
                });
              }}
              name="businessUnitCode"
            />
          </div>
          <div className="actions mr-3">
            {userCanCreate && (
              <Link to={"/create-invoice"} className="btn btn-primary">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                {t("navbar.create")}
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-xl d-flex mb-30">
            <StatisticBox
              header={"# " + totalSize}
              description={t("home.totalInvoices")}
              icon={<FontAwesomeIcon icon={faReceipt} />}
            />
          </div>
          <div className="col-12 col-sm-6 col-xl d-flex mb-30">
            <StatisticBox
              header={"# " + errorQueues.length}
              description={t("home.inQueue")}
              icon={<FontAwesomeIcon icon={faReceipt} />}
            />
          </div>
          <div className="col-12 col-sm-6 col-xl d-flex mb-30">
            <StatisticBox
              header={"# " + successQueues.length}
              description={t("home.sentInvoices")}
              icon={<FontAwesomeIcon icon={faReceipt} />}
            />
          </div>
          <div className="col-12 col-sm-6 col-xl d-flex mb-30">
            <StatisticBox
              header={"# " + pdfCount}
              description={t("home.downloadedPDF")}
              icon={<FontAwesomeIcon icon={faFilePdf} />}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-12 mb-30 d-flex">
            <DoughnutPieTable
              title={t("home.sentInvoices")}
              {...sentInvoicesData}
            />
          </div>
          <div className="col-xl-8 col-lg-6 col-12 mb-30 d-flex">
            <div className="card border-0 shadow w-100">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h5 className="card-title mb-0">{t("home.latest")}</h5>
                  <Link to="/sale-invoices" className="btn btn-outline-primary">
                    {t("common.viewAll")}
                  </Link>
                </div>
                <Table
                  columns={QueueColumns}
                  data={successQueues.slice(0, 5)}
                  defaultSortField="recordDate"
                  defaultSortAsc={false}
                />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-12 mb-30 d-flex">
                        <CurrenciesTable queues={queues}/>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mb-30 d-flex">
                        <Bar colors={colors} queues={queues}/>
                    </div> */}
        </div>
        <div className="row">
          <div className="col-xl-8 col-lg-6 col-12 mb-30 d-flex">
            <div className="card border-0 shadow w-100">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h5 className="card-title mb-0">{t("home.latestQueue")}</h5>
                  <Link to="/sale-invoices" className="btn btn-outline-primary">
                    {t("common.viewAll")}
                  </Link>
                </div>
                <Table
                  columns={QueueColumns}
                  data={errorQueues.slice(0, 5)}
                  defaultSortField="recordDate"
                  defaultSortAsc={false}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-30 d-flex">
            <Bar datasets={stackedBarData} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Home;
