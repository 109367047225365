import React, {useState} from "react"
import Table from "../Table"

import mainAxios from '../../services/mainAxios'

import "react-datepicker/dist/react-datepicker.css"
import { useAppContext } from "../../AppContext"
import { toast } from "react-toastify"
import Loader from "../Layout/Loader"
import {useTranslation, withTranslation} from "react-i18next";
import ReactJson from 'react-json-view'
import Select from "react-select"
import selectStyle, { customStyles } from "../../design/selectStyle"


const SearchInvoices = () => {
    
    const {t,i18n} = useTranslation('translations');
    const {selectedCompany: [selectedCompany],user:[user]} = useAppContext()
    
    const [params, setParams] = useState({
        fic: "",
        transactionType: "sales",
        company:parseInt(selectedCompany)
    })
    const [loading, setLoading] = useState(false)

    const [invoices, setInvoices] = useState([])


    const getFilteredResults = () => {
        setLoading(true)
        
        const {company,...restParams}=params
        console.log({company,user,params});
        mainAxios.post('apiEndpoint/test/from-cis',{
            params:{
            ...restParams
        },
        company,
        nuis: user.companiesRights.find(
            (c) => c.id === company
        )?.nuis
    
    }).then(res => {
        console.log({res});
        
            if(res?.status === 200) {
                setInvoices(res.data)
            }
            else {
                setInvoices([])
            }
            setLoading(false)
        })
        .catch(err=>{
            setLoading(false)
        })
    }

    
    return (
        <>
            <h3 className="my-2">{t('navbar.invoices')}</h3>
            <div className="card border-0 shadow">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4 col-md-3 col-lg-2 col-6">
                            <label className="w-100">FIC:</label>
                            <input 
                                className="form-control"
                                value={params.fic}
                                onChange={(e)=>{
                                    const value=e.target.value
                                    console.log({value})
                                    setParams((params)=>({...params,fic:value}))}}/>
                        </div>
                        {/* <div className="col-sm-4 col-md-3 col-lg-2 col-6">
                            <label className="w-100">Company:</label>
                            <Select
                                styles={selectStyle}
                                options={user?.companiesRights ?? []}
                                value={user?.companiesRights?.find(companyRight=>companyRight.id==params.company)}
                                getOptionValue={(companyRight=>companyRight.id)}
                                getOptionLabel={(companyRight=>companyRight.name)}
                                onChange={opt=>setParams(({...params,company:opt.id}))}
                            />                        
                        </div> */}
                        <div className="col-sm-4 col-md-3 col-lg-2 col-6 d-flex align-items-end">
                            <button 
                                className="btn btn-sm btn-primary"
                                disabled={loading}
                                style={{minWidth: "80px"}}
                                onClick={() => getFilteredResults()}>
                                {loading ? <Loader size="xs"/> : t(['navbar.search'])}
                            </button>
                        </div>
                    </div>
                    <div>
                        <ReactJson src={invoices} theme="ashes" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchInvoices
