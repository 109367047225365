import React, { useEffect, useMemo, useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mainAxios from '../../../services/mainAxios'
import Loader from '../../Layout/Loader'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";
import { useAppContext } from '../../../AppContext'
import 'react-phone-input-2/lib/style.css'
import { useSubscriptionRequests } from '../../../requests/SubscriptionsRequests'
import produce from 'immer'
import { globalHandleChange } from '../../../services/functions'



export const AddonsList=({addons=[],handleAddOns=()=>{},disabled=false}={})=>{
  return (
    addons.map(addOn=>{ 
      const isAddonBoolean=[5,6].includes(addOn.id)
      const value=addOn.unit
      if(isAddonBoolean) return (
        <div key={addOn.id} className="col-12 form-group custom-control custom-checkbox mr-sm-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id={addOn.id}
          checked={value===1}
          disabled={disabled}
          onChange={(e) =>handleAddOns({addOn,value:value===1?null:1})}
        />
        <label
          style={{ paddingTop: "3px", paddingLeft: "2px" }}
          htmlFor={addOn.id}
          className="custom-control-label"
        >
          {addOn?.name}
        </label>
      </div>
      )
      return (
          <div key={addOn.id} className="col-12">
              <div className="form-group">
                  <label htmlFor={addOn.id}>{addOn?.name} 
                  </label>
                  <input
                    id={addOn.id}
                      type="number"
                      className="form-control"
                      value={value}
                      disabled={disabled}
                      onChange={(e)=>{
                        const value=e.target.value;
                        handleAddOns({addOn,value:parseInt(value)
                      })}}
                      />
              </div>
          </div>
      )
  })

 )
}

const Addons = ({company,subscription,onSubscriptionChange}) => {
    const { t, i18n } = useTranslation('translations');
    const [pageLoading,setPageLoading]=useState(true)
    const [subscriptions,setSubscriptions]=useState([])
    const [possibleAddons,setPossibleAddons]=useState([])

    useEffect(() => {
        mainAxios.post('apiEndpoint/search', {
        object: "GetSubscriptionsAddons",
        company: company.id,
        }).then(res => {
        setPageLoading(false)
        if (res.data.status) {
          setPossibleAddons(res.data.content)
        }
        })
    }, [company?.id])

    const { getSubscriptionPackages,saveCompanySubscription,companySubscriptionsLoading,actionLoading } = useSubscriptionRequests({company})
    useEffect(()=>{
      (async () => {
        let subs=await getSubscriptionPackages()
        setSubscriptions(subs ?? [])
        })();
    },[company.id])


    const handleSubmit = (e) => {
        e.preventDefault();
        const parsedNewAddons=possibleAddons.filter(addon=>addon?.unit != null && addon?.unit !="" && addon.unit!=NaN)?.map(({id,name,uom,...restAddon})=>{
          return {
            ...restAddon,
            notes:"",
            addonsSubscriptionId:id,
          }
        }) ?? []

        if(parsedNewAddons?.length==0){
          toast.warning("Data is empty!", {
            containerId: "all_toast",
          });
          return ;
        }
        
        saveCompanySubscription({state:{
          ...subscription,
          addons:parsedNewAddons
        },
        onSubscriptionChange})
    };

    const handleAddOns = ({addOn,value}) => {
      setPossibleAddons(produce((draft) => {
        const foundAddon = draft.find(foundAddon=>foundAddon.id==addOn.id) 
        if(foundAddon){
          foundAddon.unit=value
        }else{
          draft.push({
            ...addOn,
            unit:value
          })
        }

      }))
    };

    if(pageLoading && possibleAddons?.length==0) return <Loader/>
    return (
        subscription?.id ?
            <div className="row mt-4 mx-0">
                <form className="col-12 mx-auto" onSubmit={handleSubmit}>
                <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="first_name">Subscription Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={subscription?.subscriptionName ?? ""}                                            
                                            onChange={()=>{}}
                                            disabled />
                                    </div>
                                </div>
                                <div>
                                  
                                </div>
                                <h3>Add Ons</h3>
                                 <AddonsList addons={possibleAddons} handleAddOns={handleAddOns}/>
                            </div>
                            
                            <button
                                type="submit mt-3"
                                disabled={actionLoading}
                                style={{ minWidth: "100px" }}
                                className="btn btn-primary">
                                {actionLoading ? <Loader size="xs" color="white" /> :
                                    (<>
                                        <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
                                    </>)}
                            </button>
                </form>
            </div>
            :
            <Loader />
    )
}

export default Addons
