import React from 'react'
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEye, faFile, faFileExcel, faFileImage, faFilePdf, faFileWord, faTimes } from '@fortawesome/free-solid-svg-icons';
import func from '../../../services/functions';
import DocumentPreview from '../../DocumentPreview';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

const FILE_SIZE_LIMIT = 5000 * 1024 // 500 KB

const PurchaseAttachment = ({ purchaseAttachments, setPurchaseAttachments, disabled }) => {

  const { t, } = useTranslation('translations');
  const [filePreview, setFilePreview] = React.useState(null)
  const fileContainer = React.useRef()

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf,.docx,.xlsx,.xls,.txt,.xml,.png,.jpg,.jpeg",
    onDrop: async (files) => {
      let newFiles = []
      let filesExceeded = 0
      for (const file of files) {
        const extension = file && file.name.split('.')[file.name.split('.').length - 1]

        if (extension && (extension === 'docx' || extension === 'xml' || extension === "pdf" || extension === "txt" || extension === "xlsx" || extension === "xls" || extension === "png" || extension === "jpg" || extension === "jpeg")) {
          let base64File = await func.toBase64(file)
          if (base64File.length * (3 / 4) < FILE_SIZE_LIMIT) {
            newFiles.push({
              file: base64File.substr(base64File.indexOf(',') + 1),
              name: file.name,
              type: extension,
            })
          }
          else filesExceeded += 1
        }
      }
      if (filesExceeded) {
        toast.warning(`${filesExceeded} File${filesExceeded === 1 ? "" : "s"} exceeded the limit! Limit is 5 mb.`, { containerId: "all_toast" })
      }

      setPurchaseAttachments((attachments) => [...attachments, ...newFiles]);
    }
  });



  const removeAttachment = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    setPurchaseAttachments(purchaseAttachments => (
      [
        ...purchaseAttachments?.slice(0, index),
        ...purchaseAttachments?.slice(index + 1)
      ]
    ))
  }

  const previewFile = (attachment, e) => {
    e.stopPropagation()
    e.preventDefault()
    let base64 = attachment?.file
    if (base64) {
      setFilePreview({
        base64,
        name: attachment?.name,
        extension: attachment?.type
      })
      setTimeout(() => {
        fileContainer.current.classList.toggle('open')
      }, 0)
    }
  }

  const closePreview = () => {
    setTimeout(() => {
      setFilePreview(null)
    }, 150);
    //console.log({fileContainer})
    fileContainer.current.classList.toggle('open')
  }


  const thumbs = purchaseAttachments.map((attachment, index) => {
    const placeholder = (type) => {
      if (type == "pdf") {
        return faFilePdf;
      } else if (type == "jpg" || type == "jpeg" || type == "png") {
        return faFileImage;
      } else if (type == "xlsx") {
        return faFileExcel;
      } else if (type == "docx") {
        return faFileWord;
      } else {
        return faFile;
      }
    }

    const file = new File([attachment.file], attachment.name);
    return (
      <div key={index} className="atachment d-flex flex-column justify-content-center align-items-center">
          <FontAwesomeIcon size="3x" icon={placeholder(attachment.type)} />
          <div className="atachment-name mx-1 text-center">
            {file.name}
          </div>
        <div className="d-flex mx-1 mt-3">
          <button
            className="attachment__actions--button bg-primary"
            onClick={(e) => previewFile(attachment, e)}>
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            className="attachment__actions--button bg-danger"
            onClick={(e) => removeAttachment(e, index)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>

    )
  })
  return (
    <div className="card border-0  mb-4 w-100" id="invoice_attachment">
      <div className="card-body">
        <div {...getRootProps({ className: "dropzone" })} disabled={disabled}>
          <input {...getInputProps()} />
          <p> {t('attachment.dragdrop')}</p>
        </div>
        <div className="attachment__container d-flex flex-wrap">{thumbs}</div>
        <div className="pdf-container" ref={fileContainer}>
          {filePreview &&
            <div className="pdf-viewer">
              <div className="pdf-overlay" onClick={closePreview} />
              <div className="pdf-content">
                <DocumentPreview filePreview={filePreview} />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PurchaseAttachment
