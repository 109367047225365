import React, { useMemo } from 'react'
import { useTranslation } from "react-i18next";
import PrimeTable from '../PrimeTable/PrimeTable'
import "../../design/custom.scss"
import func from '../../services/functions';
const TaxCategorization = ({ tempFilters = [], totalCount =0}) => {
    const { t } = useTranslation('translations');


    const stockBodyTemplate = (rowData) => {
        const style = {
            fontSize: 18,
            fontWeight: 900
        }

        return (
            <div style={rowData.label == "Total" ? { ...style } : {}}>
                {rowData.label}
            </div>
        );
    }

    const columns = [
        {
            field: "label",
            header: t("books.taxCategorization"),
            sortable: true,
            style: { minWidth: "180px", maxWidth: "180px" },
            body: stockBodyTemplate,
        },
        {
            field: "count1",
            header: t(["books.salesNumber"]),
            sortable: true,
            style: { minWidth: "180px", maxWidth: "180px" },
            body: (row) => <div>
                {row?.count1 ?? 0}
            </div>,
            align: "right"
        },
        {
            field: "count2",
            header: t(["books.salesWoVat"]),
            sortable: true,
            style: { minWidth: "180px", maxWidth: "180px" },
            body: (row) => func.getFormattedMoneyValue(row?.count2 ?? 0),
            align: "right"
        },
        {
            field: "count3",
            header: t(["books.vatAmount"]),
            sortable: true,
            style: { minWidth: "180px", maxWidth: "180px" },
            body: (row) => func.getFormattedMoneyValue(row?.count3 ?? 0),
            align: "right"
        },
    ]

    const filtersWithoutNoneValue = useMemo(() => {
        const [, ...rest] = tempFilters
        return rest
    }, [tempFilters])

    const totals = useMemo(() => {
        return filtersWithoutNoneValue.reduce(((prevValue, currValue) => {
            return {
                count2: (prevValue.count2 ?? 0) + (currValue.count2 ?? 0),
                count3: (prevValue.count3 ?? 0) + (currValue.count3 ?? 0),
            }
        }
        ), {
            count2: 0,
            count3: 0,
        })
    }, [tempFilters])

    const footer = () => {
        return <table className='p-datatable-tbody w-100 p-0' style={{minWidth:"100%"}}>
            <tbody className='w-100'>
                <tr role="row">
                    <td className='p-align-right' role="cell" style={{ minWidth: 180, maxWidth: 180 }}>Total</td>
                    <td className='p-align-right' role="cell" style={{ minWidth: 180, maxWidth: 180, textAlign: "right" }}></td>
                    <td className='p-align-right' role="cell" style={{ minWidth: 180, maxWidth: 180, textAlign: "right" }}>{func.getFormattedMoneyValue(totals?.count2)}</td>
                    <td className='p-align-right' role="cell" style={{ minWidth: 180, maxWidth: 180, textAlign: "right" }}>{func.getFormattedMoneyValue(totals?.count3)}</td>
                </tr>
            </tbody>
        </table>
    }

    return (
        <div style={{ padding: "20px 10px" }}>
            <PrimeTable
                footer={footer}
                // rowClassName={rowClass}
                value={filtersWithoutNoneValue}
                columns={columns}
                showFilters={false}
                paginator={false}
                filterDisplay={false}
                responsiveLayout="scroll"
            />
        </div>
    )
}

export default TaxCategorization