import React, { useRef } from "react";
import UploadInvoice from "../Invoice/UploadInvoice";
import { Link, withRouter } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import { faBars,faBarsStaggered, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faCog,
  faEdit,
  faExchangeAlt,
  faBuilding,
  faCircleInfo,
  faWrench,
  faPhone,
  faEnvelope,
  faSignOutAlt,
  faUser,
  faQuestionCircle,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { logout, setAuthToken } from "../../services/mainAxios";
import Swal from "sweetalert2";
import { useTranslation, withTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import UploadPayment from "../Invoice/UploadPayment";
import ChangeCompany from "./ChangeCompany";
import UseScreenSize from "../../utils/UseSizeScreen";
// import { Switch, useDarkreader } from 'react-darkreader';

/** USER ROLES
 * "Role Name: sysadmin,  ID: 1"
 * "Role Name: admin,     ID: 2"
 * "Role Name: useradmin, ID: 3"
 * "Role Name: Create ,   ID: 4"
 * "Role Name: Approve ,  ID: 5"
 * "Role Name: Purchase,  ID: 6"
 * "Role Name: View,      ID: 7"
 */
/*
Purchase Roles
dispatcher 8
none 12
approval 10
finance 11
owner 13
view 14
*/
const animateNavbar = () => {
  let tabsNewAnim = $("#navbarSupportedContent");
  let navs = $("#navbarSupportedContent .nav-item");
  let activeItemNewAnim = tabsNewAnim.find(".nav-item.active");
  let activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
  let activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
  let itemPosNewAnimTop = activeItemNewAnim.position();
  let itemPosNewAnimLeft = activeItemNewAnim.position();

  if (
    activeWidthNewAnimHeight &&
    activeWidthNewAnimWidth &&
    itemPosNewAnimTop &&
    itemPosNewAnimLeft
  ) {
    $(".hori-selector").css({
      display: "inline-block",
      top: itemPosNewAnimTop.top + "px",
      left: itemPosNewAnimLeft.left + "px",
      height:
        window.innerWidth < 1200
          ? activeWidthNewAnimHeight + "px"
          : activeWidthNewAnimHeight - 10 + "px",
      width:
        window.innerWidth < 1200
          ? activeWidthNewAnimWidth - 10 + "px"
          : activeWidthNewAnimWidth + "px",
    });
    if (navs.index(activeItemNewAnim) === 0 && window.innerWidth < 1200) {
      // $(".hori-selector .left").css({
      //     display: "none",
      // });
    }
  } else {
    $(".hori-selector").css({
      display: "none",
    });
  }
};

const Navbar = ({ contentWrapper, history }) => {
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany, setSelectedCompany],
    company: [fullCompany],
    sideBarExpanded:[expanded,setExpanded]
  } = useAppContext();
  const navbar = useRef();

  const { t, i18n } = useTranslation("translations");
  // const [isDark, { toggle }] = useDarkreader(false);

  React.useEffect(() => {
    let contentWrapperElement = contentWrapper.current;
    contentWrapperElement.style.height =
      window.innerWidth > 1200
        ? window.innerHeight - navbar.current.offsetHeight - 1 - 30 + "px"
        : "auto";

    setTimeout(function () {
      animateNavbar();
    }, 100);

    history.listen((location, action) => {
      setTimeout(function () {
        animateNavbar();
      }, 100);
    });

    $(window).on("resize", () => {
      setTimeout(function () {
        animateNavbar();
      }, 500);
      contentWrapperElement.style.height =
        window.innerWidth > 1200
          ? window.innerHeight - navbar.current.offsetHeight - 1 - 30 + "px"
          : "auto";
    });

    return () => {
      contentWrapperElement.style.height = "100%";
      $(window).off();
    };
  }, [t]);

  const handleLogout = () => {
    logout({setUser,request:true})
    history.push("/");
  };
  const {width,height} = UseScreenSize()

  const flags = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-start gap-3">
        <div className=" contact-info pl-2 ">
          <a
            href="#"
            className="nav-link p-0"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage("alb");
              saveLanguage("alb");
            }}
          >
            <img src="/albania-icon-round.png" style={{ width: "16px" }} />
          </a>
        </div>
        <div className="contact-info px-2">
          <a
            href="#"
            className="nav-link p-0"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage("en");
              saveLanguage("en");
            }}
          >
            <img src="/england-icon-round.png" style={{ width: "16px" }} />
          </a>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    let language = localStorage.getItem("language");

    if (language == "alb") {
      i18n.changeLanguage("alb");
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const saveLanguage = (val) => {
    localStorage.setItem("language", val);
  };


  const handleCompanyChange = (e) => {
    e.preventDefault();
    swal(
      <ChangeCompany
        swal={swal}
        // companyList={companyList}
        setSelectedCompany={setSelectedCompany}
        selectedCompany={selectedCompany}
        companyList={user?.companiesRights ?? []}
      />,
      {
        closeOnClickOutside: false,
        closeOnEsc: true,
        buttons: false,
        title: `${t("navbar.changeCompany")}`,
      }
    );
  };

  const hardReloadPage = () => {
    window.location.reload(true);
  };


  const openUploadInvoice = (e) => {
    e.preventDefault();
    swal(
      <UploadInvoice
        user={user}
        selectedCompany={selectedCompany}
        swal={swal}
      />,
      { buttons: false, className: "upload-swal-width" }
    );
  };

  const openUploadPayment = (e) => {
    e.preventDefault();
    swal(
      <UploadPayment
        user={user}
        selectedCompany={selectedCompany}
        fullCompany={fullCompany}
        swal={swal}
      />,
      { buttons: false, className: "upload-swal-width" }
    );
  };

  const location = history.location.pathname;

  return (
    <nav className={`navbar navbar-expand-xl ${process.env.REACT_APP_SERVER === 'DEMO' ? 'navbar-demo' : 'bg-primary'}`} ref={navbar}>
          <div className="text-white cursor-pointer ml-3" style={{width:"25px"}} onClick={() => setExpanded((state)=>({show:!state.show,hover:false}))}>
               {
                (expanded.show || expanded.hover) ? <FontAwesomeIcon icon={faBarsStaggered}  size="lg"  />
                :
                <FontAwesomeIcon icon={faBars}  size="lg" />
               }
            </div>
      <Link to="/" className="navbar-brand navbar-logo">
        <img src="/Logo-Profisc-New-2.png" className="img-fluid" alt="" style={{"userSelect":"none"}}/>
      </Link>
      {
        width >= 1280 &&
        <button
              className="navbar-toggler"
              onClick={() => animateNavbar()}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
      }
            
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <div className="hori-selector">
            <div className="left"></div>
            <div className="right"></div>
          </div>

          <li
            className={
              " dropdown" +
              (!location.includes("/users/edit/" + user.id) &&
                new RegExp(
                  [
                    "/mailto:profisc@tetrapro.al",
                    "/tel:+35544508855",
                    "/logs",
                    "/message-logs",
                    "/companies",
                    "/send-notification",
                    "/send-email",
                  ].join("|")
                ).test(location)
                ? " active"
                : "")
            }
          >
              <a
                href="#"
                className="nav-link dropdown-toggle"
                onClick={handleCompanyChange}
              >
                <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                {fullCompany?.name}
              </a>
          </li>
          <li
            className={
              "nav-item dropdown"}
          >
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                id="supportDropdown"
              >
                <FontAwesomeIcon icon={faCircleInfo}  />
                {t("navbar.support")}
              </a>

            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="supportDropdown"
            >
                <>
                <a
                  href="mailto:profisc@tetrapro.al"
                  className="dropdown-item "
                >
                  <FontAwesomeIcon icon={faEnvelope} color={"#005f54"} className="mr-1" />
                  profisc@tetrapro.al
                </a>

                  <a
                    href="tel:+35544508855"
                    className={"dropdown-item" }
                  >
                    <FontAwesomeIcon icon={faPhone} color={"#005f54"} />
                    +355 44 508 855
                  </a>

                  <a
                    href='tel:+355677001100'
                    className={
                      "dropdown-item"}
                  >
                  <FontAwesomeIcon icon={faPhone} color={"#005f54"} />
                   +355 067 700 1100
                  </a>
                </>
              <a
                href="https://shop.profisc.al/sq/helpdesk/profisc-2"
                className={
                  "dropdown-item" }
              >
                <FontAwesomeIcon icon={faWrench} color={"#005f54"} />

               {t("navbar.technicalAssistance")}
              </a>
              <a
                href="https://help.profisc.al"
                className={
                  "dropdown-item" }
              >
                <FontAwesomeIcon icon={faQuestionCircle} color={"#005f54"}  />
                ProFisc Help
              </a>

            </div>


          </li>
          
          <li
            className={
              "nav-item dropdown"}
          >
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                id="languageDropDown"
              >
                <FontAwesomeIcon className='' icon={faGlobe} />
                {/* {t("navbar.support")} */}
                {t("navbar.language")}
              </a>

                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="languageDropDown"
                >
                  <>
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={(e) => {
                      e.preventDefault();
                      i18n.changeLanguage("alb");
                      saveLanguage("alb");
                    }}
                  >
                    <img src="/albania-icon-round.png" className="mr-2" style={{ width: "20px" }} />
                      Shqip
                  </a>
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={(e) => {
                                    e.preventDefault();
                                    i18n.changeLanguage("en");
                                    saveLanguage("en");
                                }}
                  >
                     <img src="/england-icon-round.png" className="mr-2" style={{ width: "20px" }} alt="English" />
                      English
                  </a>
                </>
            </div>
          </li>
            {/* <Notifications/> */}

          <li
            className={
              "" +
              (new RegExp(["/users/edit/" + user.id].join("|")).test(location)
                ? " active"
                : "")
            }
          >
            <a
              href="#"
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              id="userDropdown"
            >
              <FontAwesomeIcon icon={faUser} />
              {user.firstName}
            </a>

            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="userDropdown"
            >
              <Link
                to={"/users/edit/" + user.id}
                className={
                  "dropdown-item" + (location === "/users" ? " active" : "")
                }
              >
                <FontAwesomeIcon icon={faEdit} className="text-primary" />
                {t("common.edit")}
              </Link>
              <a
                href="#"
                className="dropdown-item"
                onClick={hardReloadPage}
              >
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  className="text-primary"
                />
                {t("navbar.sync")}
              </a>
              <a
                href="#"
                className="dropdown-item"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
              >
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  className="navbar-logout-icon"
                />
                {t("navbar.logout")}
              </a>
            </div>
          </li>

        </ul>
      </div>
         {/* {[1, 2, 3].includes(user.role.id) && (
                      <>
                        <Link
                          to="/companies"
                          className={
                            "dropdown-item" +
                            (location === "/companies" ? " active" : "")
                          }
                        >
                          {t("navbar.companies")}
                        </Link>
                        <Link
                          to="/users"
                          className={
                            "dropdown-item" + (location === "/users" ? " active" : "")
                          }
                        >
                          {t("navbar.users")}
                        </Link>
                        <Link
                          to="/parameters"
                          className={
                            "dropdown-item" +
                            (location === "/parameters" ? " active" : "")
                          }
                        >
                          {t("navbar.parameters")}
                        </Link>

                        <Link
                          to="/customers"
                          className={
                            "dropdown-item" +
                            (location === "/customers" ? " active" : "")
                          }
                        >
                          {t("navbar.customers")}
                        </Link>
                      </>
                    )}
                    <Link
                      to="/my-suppliers"
                      className={
                        "dropdown-item" +
                        (location === "/my-suppliers" ? " active" : "")
                      }
                    >
                      {t("navbar.mySuppliers")}
                    </Link>
                    <div className="dropdown-divider"></div>
                    {[1].includes(user.role.id) && (
                      <>

                        <Link
                          to="/send-notification"
                          className={
                            "dropdown-item" +
                            (location === "/send-notification" ? " active" : "")
                          }
                        >
                          {t("navbar.sendNotification")}
                        </Link>
                      </>
                    )}
                    {[1, 2, 3, 4, 5].includes(user.role.id) && (
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={(e) => openUploadPayment(e)}
                      >
                        {t("navbar.uploadPayment")}
                      </a>
                    )}
                    {[1, 2, 3, 4, 5, 6, 7].includes(user.role.id) && (
                      <>
                        <Link
                          to="/generate-nslf"
                          className={
                            "dropdown-item" +
                            (location === "/generate-nslf" ? " active" : "")
                          }
                        >
                          {t("navbar.generateNslf")}
                        </Link>
                      </>
                    )}
                    {[1, 2, 3, 4, 5, 6, 7].includes(user.role.id) && (
                      <Link
                        to="/logs"
                        className={
                          "dropdown-item" + (location === "/logs" ? " active" : "")
                        }
                      >
                        {t("navbar.logs")}
                      </Link>
                    )}

                    {[1].includes(user.role.id) && (
                      <>
                        <Link
                          to="/ukt-report"
                          className={
                            "dropdown-item" +
                            (location === "/ukt-report" ? " active" : "")
                          }
                        >
                          {t("logs.uktReports")}
                        </Link>
                      </>
                    )}
                    <div className="dropdown-divider"></div>
                    {[1, 2, 3, 4, 5, 6, 7].includes(user.role.id) && (
                      <>
                        <Link
                          to="/items"
                          className={
                            "dropdown-item" + (location === "/items" ? " active" : "")
                          }
                        >
                          {t("navbar.products")}
                        </Link>

                        <Link
                          to="/items-category"
                          className={
                            "dropdown-item" +
                            (location === "/items-category" ? " active" : "")
                          }
                        >
                          {t("navbar.itemsCategory")}
                        </Link>
                      </>
                    )}
                    */}

                    {
                      width <= 1199 &&
                      <li
                      className={
                        "nav-item" +
                        (new RegExp(["/users/edit/" + user.id].join("|")).test(location)
                          ? " active"
                          : "")
                      }
                    >
                      <a
                        href="#"
                        className="user-settings dropdown-toggle"
                        data-toggle="dropdown"
                        id="userDropdown"
                      >
                        <FontAwesomeIcon icon={faUser} />
                        {user.firstName}
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="userDropdown"
                      >
                        <Link
                          to={"/users/edit/" + user.id}
                          className={
                            "dropdown-item" + (location === "/users" ? " active" : "")
                          }
                        >
                          <FontAwesomeIcon icon={faEdit} className="text-primary" />
                          {t("common.edit")}
                        </Link>
                        <a
                          href="#"
                          className="dropdown-item"
                          onClick={hardReloadPage}
                        >
                          <FontAwesomeIcon
                            icon={faSyncAlt}
                            className="text-primary"
                          />
                          {t("navbar.sync")}
                        </a>
                        <a
                              href="#"
                              className="dropdown-item"
                              onClick={handleCompanyChange}
                            >
                              <FontAwesomeIcon icon={faBuilding} className="navbar-logout-icon" />
                              {fullCompany?.name}
                            </a>
                        <a
                          href="#"
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            handleLogout();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faSignOutAlt}
                            className="navbar-logout-icon"
                          />
                          {t("navbar.logout")}
                        </a>
                        
                      </div>
                      </li>
                    }
         
    </nav>
  );
};

export default withRouter(Navbar);
