import React from "react";
import Accordion from "./Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const moment = require("moment");

const InvoiceHistoric = ({ invoiceState: { invoiceHistorics } }) => {

  const columns = [
    { field: "Date",header: "Date",},
    { field: "action",header: "Action",},
    { field: "status", header: "Status" },
    { field: "recordUser", header: "User" },
    { field: "InvoiceStatus", header: "Invoice Status" },
  ];

  const body = (props)=> {
    const { field } = props.col 
    const  cellData = props?.cellOptions

    if(field == 'Date'){
        return <span>{ cellData.recordDate
            ? moment(cellData.recordDate).format("DD/MM/YYYY HH:mm")
            : ""}</span>
    }else if (field=='action'){
        return <span>{cellData?.invoiceAction?.action}</span>
    }else if (field=='status'){
        return <span>{cellData?.invoiceStatus?.status}</span>
    }else if (field=='recordUser'){
        return <span>{cellData?.recordUser}</span>
    }else if (field=='InvoiceStatus'){
      if (!cellData.cisStatus) return null;
          if (cellData?.cisStatus === "SUCCESS") {
            return <span className="badge badge-success">Success</span>;
          } else if (cellData?.cisError) {
            return (
              <h4 className="mb-0" data-tip={cellData?.cisError}>
                <span className="badge badge-danger d-inline-flex align-items-center">
                  Error
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="ml-2"
                  />
                </span>
              </h4>
            );
          } else {
            return <span className="badge badge-danger">Error</span>;
          }
    }
  }
  

  const dynamiColumns = columns.map((col,i) => {
    const colProp = {
        header:col?.header,
        field:col?.field,
        key:col?.field,
        ...col
    }
    colProp.body = (options) => body({col,cellOptions:options})
    return <Column {...colProp} />
  })

  const sortedHistorics = invoiceHistorics
  ? invoiceHistorics.sort((a, b) => b.recordDate - a.recordDate)
  : [];

  return (
    <div>
      {invoiceHistorics ? (
        <DataTable
                scrollable
                scrollHeight="180px"
                editMode="cell"
                resizableColumns
                columnResizeMode="expand"  
                responsiveLayout="scroll"
                value={sortedHistorics}>
                {dynamiColumns}
        </DataTable>
      ) : null}
    </div>
  );
};

export default InvoiceHistoric;
