import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PopOver from "../../global/PopOver";
import { toast } from "react-toastify";
import Loader from "../../Layout/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import bigDecimal from "js-big-decimal";

const FinalizeInvoice = ({ fullCompany, posState, swal, approveInvoice ,isEditPos=false, isCorrective}) => {
  const { t } = useTranslation("translations");
  let isSimple = posState.paymentMeans == "10" && !isCorrective
  console.log({test2:isSimple});
  
  console.log({isEditPos})
  const [recivedAmount, setRecievedAmount] = useState();
  const [finalState, setFinalState] = useState(posState);
  const [loading, setLoading] = useState(false);

  const addAmount = (value) => {
    setRecievedAmount((amount) => bigDecimal.add(amount, value))
  };

  const allArr = [100, 200, 500, 1000, 2000, 5000];
  const eurUsdArr = [1, 2, 5, 10, 20, 50];
  let cashArray = allArr;
  let currency = "L";
  if (posState.currency == "EUR") {
    currency = "€";
    cashArray = eurUsdArr;
  } else if (posState.currency == "USD") {
    currency = "$";
    cashArray = eurUsdArr;
  }

  const handleChange = (key, val, trim = false) => {
    let value = val
    if (trim) value = value.trim()
    setFinalState((posState) => ({
      ...posState,
      [key]: value,
    }));
  };

  const innerRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      innerRef.current && innerRef.current.select();
    }, 10);
  }, []);

  let change = bigDecimal.subtract(recivedAmount, finalState?.invoiceTotalWithVat);

  const approve = () => {
    if (change.includes("-") && isSimple) {
      toast.error(t(["pos.changeValidation"]), {
        containerId: "all_toast",
      });
      return;
    }
    setLoading(true);
    approveInvoice({ invoiceState: finalState });
  };

  // let inputClass = isCash ? "col-md-4" : "col-md-6";
  return (
    <div className="card border-0 shadow">
      <div className="card-header border-0">
        <div className="d-flex align-items-center">
          <h2 className="mx-auto text-center">{t("pos.finalizeInvoice")}</h2>
          <h1 style={{ cursor: "pointer" }} className="btn btn-danger rounded mr-2" onClick={() => { swal.close() }}>{'\u2715'}</h1>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-3 mb-1">
        <h1 clasName="px-4" style={{ borderBottom: "2px solid #007567", padding: "5px 30px", fontSize: "28px" }}>
          {t("pos.payingAmount")} :{" "}
          <span className="text-danger">{finalState?.invoiceTotalWithVat}{currency}</span>
        </h1>
      </div>
      <div className="card-body">
        <div className="row d-flex justify-content-start align-items-between">
          <div className={`${isSimple ? "col-md-10" : "col-md-12"}`}>
            <div className="row">


              <div className="col-md-4 mt-1 mb-3">
                <label className="form-label">
                  {fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? t("home.internalId")}
                  <PopOver title={fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? t("hints.internalId")} />
                </label>
                <input
                  type="text"
                  name="internalId"
                  className="form-control numkey"
                  value={finalState?.internalId || ""}
                  onChange={(e) => handleChange("internalId", e.target.value, true)}
                />
              </div>
              <div className=" col-md-4 mt-1 mb-3">
                <label>{fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId1")?.value ?? (t("pos.additional") + " 1")}</label>
                <input
                  type="text"
                  name="param1"
                  value={finalState?.param1 || ""}
                  className="form-control numkey"
                  onChange={(e) => handleChange("param1", e.target.value)}
                />
              </div>
              <div className="col-md-4 mt-1 mb-3">
                <label>{fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId2")?.value ?? (t("pos.additional") + " 2")}</label>
                <input
                  type="text"
                  name="param2"
                  value={finalState?.param2 || ""}
                  className="form-control numkey"
                  onChange={(e) => handleChange("param2", e.target.value)}
                />
              </div>
              <div className="col-md-12 mt-1 mb-2">
                <label>{t("pos.paymentNote")}</label>
                <textarea
                  id="payment_note"
                  rows="7"
                  className="form-control numkey"
                  name="payment_note"
                  value={finalState?.paymentNote || ""}
                  onChange={(e) => handleChange("paymentNote", e.target.value)}
                ></textarea>
              </div>
              <div className="d-flex flex-wrap justify-content-end align-items-end">
                {isSimple && (<div className="p-2 mt-1">
                  <h2 className="text-center">{t("pos.recievedAmount")}</h2>
                  <input
                    type="number"
                    ref={innerRef}
                    value={recivedAmount}
                    className="arrow-none border border-dark rounded text-center text-danger my-pos-input py-1"
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.split(".")[1]) {
                        value = value.split(".");
                        value = value[0] + "." + value[1].slice(0, 2);
                      }
                      setRecievedAmount(value);
                    }}
                    // style={{fontSize: 30}}
                    required
                  />
                </div>)}

                {isSimple && (<div className="p-2 mt-1">
                  <h2 className="text-center">{t("pos.change")}</h2>
                  <input
                    type="text"
                    name="change"
                    disabled
                    className="arrow-none ml-2 border border-dark rounded text-danger text-center my-pos-input py-1"
                    onChange={() => { }}
                    style={{ fontSize: 24 }}
                    value={(change.includes("-") ? "0" : change) + currency}
                  />
                </div>)}


                <div className="p-2">
                  <button
                    onClick={approve}
                    id="submit-btn"
                    type="button"
                    disabled={loading}
                    className="btn btn-primary btn-lg"
                  >
                    {loading ? (
                      <Loader size="xs" color="white" />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faCheck} className="mr-1" />{" "}
                        {t("status.approve")}
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>

          </div>
          {isSimple && (
            <div className="col-md-2 qc" data-initial={1}>
              <h4>
                <strong>{t("pos.quickCash")}</strong>
              </h4>
              {cashArray.map((value) => {
                return (
                  <button
                    key={value}
                    onClick={() => addAmount(value)}
                    className="btn btn-block btn-lg btn-primary  mb-2 qc-btn sound-btn"
                    type="button"
                  >
                    {value} {currency}
                  </button>
                );
              })}

              <button
                onClick={() => setRecievedAmount(0)}
                className="btn btn-block btn-danger btn-lg qc-btn sound-btn"
                type="button"
              >
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinalizeInvoice;
