import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import func from "../../services/functions";
import moment from "moment";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from "../../AppContext";
import useOnScreen from "../global/hooks/useOnScreen";
import { DashboardCard } from "../global/CommonComponents";


const ComparisonBu = ({
  title,
  state,
  sessionData,
  setSessionData,
  groupedTotalsByBranch,
  getFilteredResult,
  getTotals,
}) => {
  const { t ,i18n} = useTranslation("translations");
  const {
    selectedCompany: [selectedCompany],
  } = useAppContext();

  const comparisonBuRef = useRef(null)
  const firstUpdate = useRef(true)

  const isVisible= useOnScreen(comparisonBuRef,[selectedCompany])

  const language=i18n.language;
  const [period,setPeriod] = useState({
    name:"previousQuarter",
    fromDate: moment(sessionData?.fromDate).subtract(3, "months")._d,
    toDate: moment(sessionData?.toDate).subtract(3, "months")._d,
  })

  const [comparisonBuState, setComparisonBuState] = useState({
    allTotals: null,
    totalsByBranch: null,
  });

  let periodDiff=moment(sessionData?.toDate).diff(sessionData?.fromDate,"days");

  useEffect(() => {
    if (!(sessionData.fromDate && sessionData.toDate) || !isVisible) return;
    
    const { fromDate, toDate } = period;
    getFilteredResult(fromDate, toDate).then(
      (res) => {
        if (res.data.status) {
        let filtered=res.data.content?.filter((stat=>stat.tcrCode !== stat.businessUnitCode && stat.tcrCode!=="TS001NC001")) ??[]
          let allTotals = getTotals(filtered);
          let totalsByBranch = groupedTotalsByBranch(filtered);
          setComparisonBuState((state) => ({
            ...state,
            allTotals,
            totalsByBranch,
          }));
        }
      }
    );
  }, [period,isVisible]);



  useEffect(()=>{
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if(sessionData?.fromDate?.length>0 && sessionData?.toDate?.length>0 )return 
    setPeriod((state)=>({
      ...state,
      name:"previousCertainPeriod",
      fromDate: moment(sessionData?.fromDate).subtract(3, "months")._d,
      toDate: moment(sessionData?.toDate).subtract(3, "months")._d,
    }))
  },[sessionData.fromDate,sessionData.toDate])
  
  let currentPeriod = useMemo(() => {
    if (sessionData?.businessUnitCode) {
      return state.totalsByBranch.find(
        (branch) => branch.businessUnitCode === sessionData.businessUnitCode
      );
    } else {
      return state.allTotals;
    }
  }, [sessionData.businessUnitCode, state.allTotals]);

  let previousPeriod = useMemo(() => {
    if (sessionData?.businessUnitCode) {
      return comparisonBuState?.totalsByBranch?.find(
        (branch) => branch?.businessUnitCode === sessionData?.businessUnitCode
      );
    } else {
      return comparisonBuState.allTotals;
    }
  }, [sessionData.businessUnitCode, comparisonBuState?.allTotals]);

  const handlePeriodChange = (period) => {
    
    switch (period) {
      case "previousQuarter":
        setPeriod((state)=>({
          ...state,
          name:"previousQuarter",
          fromDate: moment(sessionData?.fromDate).subtract(3, "months")._d,
          toDate: moment(sessionData?.toDate).subtract(3, "months")._d,
        }))
        break;
      case "previousYear":
        setPeriod((state)=>({
          ...state,
          name:"previousYear",
          fromDate: moment(sessionData?.fromDate).subtract(1, "years")._d,
          toDate: moment(sessionData?.toDate).subtract(1, "years")._d,
        }))
        break;
      case "previousCertainPeriod":
        setPeriod((state)=>({
          ...state,
          name:"previousCertainPeriod",
          fromDate: moment(sessionData?.fromDate).subtract(periodDiff, "days")._d,
          toDate: moment(sessionData?.fromDate).subtract(1, "days")._d,
        }))
        break;
  }
}


  let previousCertainPeriod=language=="alb"?`${periodDiff} ditet e meparshme`:`Previous ${periodDiff} days`
  let totalDifference=((currentPeriod?.cashEnd ?? 0) + (currentPeriod?.banknoteCashTot ?? 0) ) - ((previousPeriod?.cashEnd ?? 0) + (previousPeriod?.banknoteCashTot ?? 0))
  return (
    <DashboardCard ref={comparisonBuRef} title={title}>
          <div className="col-lg-12 mt-2">
            <div className="d-flex justify-content-end">
            <div className="dropdown">
            <button style={{backgroundColor:"#fff",border:"2px solid #d1e7dd"}} className="btn btn-dashboard dropdown-toggle d-flex flex-wrap align-items-center justify-content-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <FontAwesomeIcon icon={faCalendarAlt} className="text-primary mr-2"/>
              {period.name!="previousCertainPeriod"?t(`dashboard.${period.name}`):previousCertainPeriod }
              <div className="ml-2 p-1" style={{fontSize:13,backgroundColor:"#edf5f1"}}>{moment(period.fromDate).format("DD/MM/yyyy")}-{moment(period.toDate).format("DD/MM/yyyy") }</div>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{handlePeriodChange("previousCertainPeriod")}}> 
                {previousCertainPeriod}
              </div>
              <div className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{handlePeriodChange("previousYear")}}>{t("dashboard.previousYear")}</div>
              <div className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{handlePeriodChange("previousQuarter")}}>{t("dashboard.previousQuarter")} </div>
            </div>
          </div>
            </div>
            <div className="comparison-section-table table-responsive mt-4">
              <table className="table table-bordered">
                <thead>
                  <tr className="">
                    <th
                      className="text-muted"
                      style={{ backgroundColor:"rgba(209, 231, 221, 0.4)"}}>
                      {t("dashboard.dataToCompare")}{" "}
                    </th>
                    <th
                      className="text-muted text-right"
                      style={{ backgroundColor: "rgba(209, 231, 221, 0.4)" }}
                    >
                      {t("dashboard.currentPeriod")}
                    </th>
                    <th
                      className="text-muted text-right"
                      style={{ backgroundColor: "rgba(209, 231, 221, 0.4)" }}
                    >
                      {t("dashboard.previousPeriod")}
                       {/* <span style={{fontSize:12,fontWeight:"600"}}>({moment(period.fromDate).format("DD/MM/yyyy")}-{moment(period.toDate).format("DD/MM/yyyy") })</span> */}
                    </th>
                    <th
                      className="text-muted text-right"
                      style={{ backgroundColor: "rgba(209, 231, 221, 0.4)" }}
                    >
                      {t("dashboard.difference")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("dashboard.initialBalance")} </td>
                    <td className={`text-right ${currentPeriod?.cashInit<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        currentPeriod?.cashInit ?? 0
                      )}
                    </td>
                    <td className={`text-right ${previousPeriod?.cashInit<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        previousPeriod?.cashInit ?? 0
                      )}
                    </td>
                    <td className={`text-right ${currentPeriod?.cashInit - previousPeriod?.cashInit<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        (currentPeriod?.cashInit ?? 0) -
                          (previousPeriod?.cashInit ?? 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("cashRegister.deposit")}</td>
                    <td className={`text-right ${ currentPeriod?.cashDeposit<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        currentPeriod?.cashDeposit ?? 0
                      )}
                    </td>
                    <td className={`text-right ${previousPeriod?.cashDeposit<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        previousPeriod?.cashDeposit ?? 0
                      )}
                    </td>
                    <td className={`text-right ${currentPeriod?.cashDeposit - previousPeriod?.cashDeposit<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        (currentPeriod?.cashDeposit ?? 0) -
                          (previousPeriod?.cashDeposit ?? 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("dashboard.withdrawDash")}</td>
                    <td className={`text-right ${currentPeriod?.cashWithdraw<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        currentPeriod?.cashWithdraw ?? 0
                      )}
                    </td>
                    <td className={`text-right ${previousPeriod?.cashWithdraw<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        previousPeriod?.cashWithdraw ?? 0
                      )}
                    </td>
                    <td className={`text-right ${currentPeriod?.cashWithdraw - previousPeriod?.cashWithdraw<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        (currentPeriod?.cashWithdraw ?? 0) -
                          (previousPeriod?.cashWithdraw ?? 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("dashboard.salesCash")}</td>
                    <td className={`text-right ${currentPeriod?.banknoteCashTot<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        currentPeriod?.banknoteCashTot ?? 0
                      )}
                    </td>
                    <td className={`text-right ${previousPeriod?.banknoteCashTot<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        previousPeriod?.banknoteCashTot ?? 0
                      )}
                    </td>
                    <td className={`text-right ${currentPeriod?.banknoteCashTot - previousPeriod?.banknoteCashTot <0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        (currentPeriod?.banknoteCashTot ?? 0) -
                          (previousPeriod?.banknoteCashTot ?? 0)
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>{t("invoice.corrective")}</td>
                    <td className={`text-right ${currentPeriod?.cashCorrectiveTot<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        currentPeriod?.cashCorrectiveTot ?? 0
                      )}
                    </td>
                    <td className={`text-right ${previousPeriod?.cashCorrectiveTot<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        previousPeriod?.cashCorrectiveTot ?? 0
                      )}
                    </td>
                    <td className={`text-right ${currentPeriod?.cashCorrectiveTot - previousPeriod?.cashCorrectiveTot<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        (currentPeriod?.cashCorrectiveTot ?? 0) -
                          (previousPeriod?.cashCorrectiveTot ?? 0)
                      )}{" "}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="font-weight-bold">{t("dashboard.totals")}</td>
                    <td className={`text-right font-weight-bold text-success ${(currentPeriod?.cashEnd ?? 0) + (currentPeriod?.banknoteCashTot ?? 0)<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        (currentPeriod?.cashEnd ?? 0) + (currentPeriod?.banknoteCashTot ?? 0) 
                      )}
                    </td>
                    <td className={`text-right font-weight-bold text-success ${(previousPeriod?.cashEnd ?? 0) + (previousPeriod?.banknoteCashTot ?? 0)<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(
                        (previousPeriod?.cashEnd ?? 0) + (previousPeriod?.banknoteCashTot ?? 0)
                      )}
                    </td>
                    <td className={`text-right font-weight-bold text-success ${totalDifference<0?"text-danger":""}`}>
                      {func.getFormattedMoneyValue(totalDifference)}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
    </DashboardCard>
  );
};

export default ComparisonBu;
