import React from 'react'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TooltipOverlay = (props) => {
  const {title, customStyle,show=250,hide=400,placement="auto"}=props;
  const renderTooltip = (props, title) => {

    return (
      <Tooltip  {...props}>
        {title}
      </Tooltip>
    );
  };


  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show, hide }}
      overlay={(props) => renderTooltip(props, title)}
      style={{cursor:"default"}}
    >
    {props.children}
    </OverlayTrigger>
  )
}

export default TooltipOverlay