import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import mainAxios from '../../services/mainAxios';
import { withRouter } from "react-router-dom";
import Select from "react-select";
import selectStyle from '../../design/selectStyle';
import { useAppContext } from '../../AppContext';
import { toast } from "react-toastify";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { css } from 'styled-components';


const PurchaseNotifications = (props) => {
    const { t } = useTranslation("translations");
    const { user: [user], selectedCompany: [selectedCompany]}=useAppContext()
    const companyId = props.match.params.id
    const [state,setState]=useState(null)
    const [purchaseNotifications, setPurchaseNotifications] = useState({
        role: null,
        email: null,
        extractInvoices: false,
        emailSent: false,
        active: false,
        recordUser:null
    },
)



    useEffect(() => {
        mainAxios.post('apiEndpoint/search', {
            object: "GetPurchNotifications",
            company:companyId
        }).then(res => {
            if (res?.data?.status && res.data?.content?.length>0) {
                setPurchaseNotifications(res?.data?.content?.[0] ?? null)
            }
        })
    }, [])


    const handleChange = (e, selectKey, selectValue)=>{
        const key = e ? e?.target?.name : selectKey;
        let value = e ? e?.target?.value : selectValue;
        setPurchaseNotifications((state) => ({
            ...state,
            [key]: value,
        }));
    }

    useEffect(() => {
        mainAxios.post('apiEndpoint/search', {
            object: "GetRoles",
            value: null,
            type: "basic"
        }).then(res => {
            if (res.data.status == true) {
                setState((state) => ({
                    ...state,
                    profiscRoles: res.data.content[0],
                    purchaseRoles: res.data.content[1]
                }))
            }
        })
    }, [])

    let profiscRoles = state?.profiscRoles??[]
    if(user.role.id!=1){
        profiscRoles = profiscRoles?.filter(role => role?.id !== 1)
    }

    let filteredProfiscRoles =[
        {label:t("vat.none"),value:null},
        ...profiscRoles.map(role => ({ label: role.name, value: role.id, ...role }))??[]
    ] 
    //  user?.role?.id == 1 ? state?.profiscRoles : state?.profiscRoles?.filter(role => role?.id !== 1)
    // filteredProfiscRoles = filteredProfiscRoles

    const savePurchaseNotifications=()=>{
        const { role, email, extractInvoices, emailSent, active, recordUser, id }  = purchaseNotifications
        if((!role || role?.length==0) && (!email || email?.length==0)){
            toast.error("Field Role or Email is required", { containerId: "all_toast" });
            return;
        }

      mainAxios
      .post("apiEndpoint/saveOrUpdate", {
          object: "PurchNotifications",
        content: {
            id: id ?? null,
            recordUser: recordUser ?? null,
            role,
            email,
            extractInvoices,
            emailSent,
            active,
            company: companyId
        },
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
        toast.success("Purchase Notification was saved successfully", {
          containerId: "all_toast",
        });
        }
      });
    }
    return (
        <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">{t("company.purchaseNotifications")}</h3>
                </div>
                <div className="col-12 col-md-3 p-0 my-3">
                    <label className="w-100" htmlFor='email'>Email:</label>
                    <ReactMultiEmail
                        placeholder={t("email.enterEmail")}
                        emails={purchaseNotifications?.email?.length > 0 ? purchaseNotifications?.email?.split(",") : []}
                        onChange={(emails) => {
                            // this.setState({ emails: _emails });
                            handleChange(null, "email", emails.join(","))
                        }}
                        validateEmail={email => {
                            return isEmail(email); // return boolean
                        }}
                        getLabel={(
                            email,
                            index,
                            removeEmail,
                        ) => {
                            return (
                                <div data-tag key={index}>
                                    {email}
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                    </span>
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="col-12 col-md-3 p-0 my-3">
                    <label className="w-100" htmlFor='role'>{t('users.role')}</label>
                    <Select
                            styles={selectStyle}
                            className="w-100"
                            options={filteredProfiscRoles}
                            value={filteredProfiscRoles?.find((role) => role?.value == purchaseNotifications?.role)}
                            onChange={(role) => handleChange(null, "role", role.id)}
                            name="role"
                        />
                </div>
                <div className='row mb-3'>
                    <div className='col-12 col-md-7 d-flex align-items-end  '>

                        <div className="custom-control custom-switch mr-3 mb-2 mt-2" >
                            <input
                                type="checkbox"
                                id="extractInvoices"
                                className="custom-control-input"
                                checked={purchaseNotifications.extractInvoices}
                                onChange={(event) => { handleChange(null, "extractInvoices", event.target.checked); }
                                } />
                            <label className="custom-control-label" htmlFor="extractInvoices" >{t("invoice.extractInvoices")} </label>


                        </div >
                        <div className="custom-control custom-switch mb-2" >
                            <input
                                type="checkbox"
                                id="active"
                                className="custom-control-input"
                                checked={purchaseNotifications.active}
                                onChange={(event) => { handleChange(null, "active", event.target.checked); }
                                } />
                            <label className="custom-control-label" htmlFor={"active"} >{t("company.active")}</label>

                        </div >
                    </div>

                </div>
                <div onClick={() => { savePurchaseNotifications() }} className="btn btn-md btn-primary align-self-end ml-3 ml-md-0" style={{ alignSelf: "center", width: 100 }}>
                    {t("common.save")}
                </div>

        </div>
    )
}

export default withRouter(PurchaseNotifications)