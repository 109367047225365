import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import func from "../../../services/functions";
import currencies from "../../../services/currencies.json";
import { useAppContext } from "../../../AppContext";
import { useTranslation } from "react-i18next";
//import invoiceType from '../../../services/InvoiceTypes'
import { withRouter } from "react-router";
//import '../../../design/customToolTip.css'
//import Tooltip from 'react-bootstrap/Tooltip'
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import PopOver from "../../global/PopOver";
import selectStyle from "../../../design/selectStyle";

const { formatNumber, countDecimals } = func;

const InvoiceData = (props) => {
  const { invoiceState, setInvoiceState, bankAccounts, disabled, history, stateDefault,edit=false } =
    props;
  const { t } = useTranslation("translations");
  const vatTypeOptions = [
    { label: t("vat.none"), value: "" },
    { label: t("vat.type_1"), value: "TYPE_1" },
    { label: t("vat.type_2"), value: "TYPE_2" },
    { label: t("vat.tax_free"), value: "TAX_FREE" },
    { label: t("vat.margin_scheme"), value: "MARGIN_SCHEME" },
    { label: t("vat.export_of_goods"), value: "EXPORT_OF_GOODS" },
  ];
  const {
    user: [user],
    selectedCompany: [selectedCompany],
    company: [fullCompany]
  } = useAppContext();
  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  const [minIssueDate, setMinIssueDate] = React.useState("");
  const [checked, setChecked] = React.useState("cash");
  const issueDateRef = React.useRef();


  

  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      invoiceEndDate: "",
    }));
  }, [invoiceState.invoiceStartDate])

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#000000" : styles.color,
      backgroundColor: state.isSelected ? "#0075670d" : styles.color,
      borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
      "&:hover": {
        color: "#000000",
        backgroundColor: "#0075670d",
      },
    }),
    control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 0.rem rgba(68, 68, 68, 0.135)" : 0,
      borderWidth: state.isFocused ? "2px" : "1px",
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      "&:hover": {
        borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      },
    }),
  };

  const typeOfDiscountVatCategoryOptions = [
    { label: "0%", value: "0" },
    { label: "6%", value: "6" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];

  const subseqValues = [
    { value: "", label: "Select" },
    { value: "NOINTERNET", label: "No Internet" },
    { value: "BOUNDBOOK", label: "Boundbook" },
    { value: "SERVICE", label: "Service" },
    { value: "TECHNICALERROR", label: "Technical Error" },
  ];

  const popOver = {
    "NOINTERNET": t("hints.noInternet"),
    "BOUNDBOOK": t("hints.boundBook"),
    "SERVICE": t("hints.service"),
    "TECHNICALERROR": t("hints.TechnicalError"),
  }

  const CustomOption = ({ innerProps, isDisabled, label, data }) =>
    !isDisabled ? (
      <div className="option-custom-style"  {...innerProps}>
        {/* {label} <PopOver title={popoOver.find(popover=>popover.value==data.value).popoOver} /> */}
        {label} <PopOver title={popOver[data.value]} />
        {innerProps.label}
      </div>

    ) : null;
  const handleInvoiceDataChange = (e, label, val, trim = false) => {
    let key = e ? e.target.name : label;
    let value = e ? e.target.value : val;
    if (key == "rate") {
      let count = countDecimals(value);
      if (count > 6) return;
    }
    if(trim===true){
      value=value.trim()
    }
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      [key]: value,
    }));
  };

  const handleIssueDateChange = (date) => {
    const dueDate = date > invoiceState.dueDate ? "" : invoiceState.dueDate;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      issueDate: date,
      dueDate,
    }));
    issueDateRef.current.setOpen(false);

    let today = new Date();
    let technicalErrorMinDate = func.dateBeforeSomeDays(1, today);
    let boundBookMinDate = func.dateBeforeSomeDays(10, today);

    if (date == "" || date == null) return;
    date = date.setHours(0, 0, 0, 0);
    today = today.setHours(0, 0, 0, 0);
    technicalErrorMinDate = technicalErrorMinDate.setHours(0, 0, 0, 0);
    boundBookMinDate = boundBookMinDate.setHours(0, 0, 0, 0)
    if (date >= today) {
      setInvoiceState(invoiceState => ({
        ...invoiceState,
        subSequenceDelivType: ""
      }))
      return;
    }
    if (date >= technicalErrorMinDate && date < today) {
      setInvoiceState(invoiceState => ({
        ...invoiceState,
        subSequenceDelivType: "SERVICE"
      }))
    }
    else if (date >= boundBookMinDate && boundBookMinDate < today) {
      setInvoiceState(invoiceState => ({
        ...invoiceState,
        subSequenceDelivType: "BOUNDBOOK"
      }))
    }
    else {
      setInvoiceState(invoiceState => ({
        ...invoiceState,
        subSequenceDelivType: "NOINTERNET",
      }));
    }
  };

  const handleSelectedBankAccount = (account) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      bankAccount: account,
    }));
  };

  const handleDueDateChange = (date) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      dueDate: date,
    }));
  };

  const handleStartDateChange = (date) => {
    const invoiceEndDate =
      date > invoiceState.invoiceEndDate ? "" : invoiceState.invoiceEndDate;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      invoiceStartDate: date,
      invoiceEndDate,
    }));
  };



  React.useEffect(() => {
    let value = invoiceState.subSequenceDelivType;
    if (value && invoiceState?.issueDate) {
      let date = new Date(invoiceState?.issueDate);
      if (value === "BOUNDBOOK") {
        date = func.dateBeforeSomeDays(11, date);
      } else if (value === "SERVICE" || value === "TECHNICALERROR") {
        date = func.dateBeforeSomeDays(1, date);
      } else {
        date = func.getMinIssueDate();
      }
      setMinIssueDate(date);
    }
  }, []);

  const handleSubseqChange = (subseq) => {
    const { value, label } = subseq;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      subSequenceDelivType: value,
    }));

    var newDate = new Date();
    if (value === "BOUNDBOOK") {
      newDate = func.dateBeforeSomeDays(11, newDate);

    } else if (value === "SERVICE" || value === "TECHNICALERROR") {
      newDate = func.dateBeforeSomeDays(1, newDate);
    } else {
      newDate = func.getMinIssueDate();
    }
    const { issueDate } = invoiceState;

    setMinIssueDate(newDate);

    if ((value === "BOUNDBOOK" || value === "SERVICE" || value === "TECHNICALERROR") && issueDate < newDate) {
      setInvoiceState(invoiceState => ({
        ...invoiceState,
        issueDate: new Date(),
      }));
      issueDateRef.current.setOpen(true);
    }
  };

  const handleEndDateChange = (date) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      invoiceEndDate: date,
    }));
  };

  const handleCurrencyChange = async (currency) => {
    let res=false;
    const items=invoiceState.items
    if (!edit && (items.length > 1 || (items.length==1 && items?.[0] && items?.[0].name!=""))){
      res = await func.swalAlert({ title: t("invoice.currencyChange"),t })
    }
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      currency,
      items: !edit &&  res  ? [{...stateDefault.items[0]}]:[...invoiceState.items],
      bankAccount: null,
      rate:currency!="ALL"?"":"1"
    }));
  };

  const handleDiscountChange = (key, value) => {
    const discountValue = value;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      discount: {
        ...invoiceState.discount,
        [key]: discountValue,
      },
    }));
  };
  const handlePaymentMeansChange = (key, value) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      [key]: value,
    }));
  };

  // const handlePaymentMethodTypeChange = (paymentMethodType) => {
  //     //console.log("test ", paymentMethodType.target.checked)

  //     const value = paymentMethodType.target.checked
  //     if (value == true) {
  //         setInvoiceState(invoiceState => ({

  //             ...invoiceState,
  //                 paymentMethodType: 'cash'
  //         }))
  //         //console.log(paymentMethodType + " from handlePaymentMethodTypeChange")
  //     } else if (value == false) {
  //         setInvoiceState(invoiceState => ({

  //             ...invoiceState,
  //                 paymentMethodType: 'noncash'
  //         }))
  //     }
  //     //console.log({ value })
  // }
  // const handlePaymentMethodTypeChange2 = (paymentMethodType) => {
  //     //console.log("test ", paymentMethodType.target.checked)

  //     const value = paymentMethodType.target.checked
  //     if (value == false) {
  //         setInvoiceState(invoiceState => ({

  //             ...invoiceState,
  //                 paymentMethodType: 'cash'
  //         }))
  //         //console.log(paymentMethodType + " from handlePaymentMethodTypeChange")
  //     } else if (value == true) {
  //         setInvoiceState(invoiceState => ({

  //             ...invoiceState,
  //                 paymentMethodType: 'noncash'
  //         }))
  //     }
  //     //console.log({ value })
  // }


  const addBankAccount = () => {
    history.push(`/companies/${company.id}/bank-accounts/add`);
  };


  const handleInvoiceTcrCodeChange = (tcrCode) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      tcrCode,
    }));
  };

  const bankAccount = invoiceState.bankAccount;
  const now = new Date();

  return (
    <div className="card border-0 shadow mb-4" id="invoice_data">
      <div className="card-header">
        <h3 className="card-title mb-0">{t("invoice.data")}</h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="form-group col-md-6">
            <label className="form-label">
              {fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? t("home.internalId")}
              <PopOver title={t("hints.internalId")} />
            </label>


            <input
              name="internalId"
              type="text"
              title="ID e sistemit te brendshem"
              className="form-control required tip"
              disabled={disabled}
              value={invoiceState.internalId}
              onChange={(e)=>handleInvoiceDataChange(e,null,null,true)}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="form-label">
              {t("invoice.nr")}
              <PopOver title={t("hints.invoiceNumber")} />
              <span className="text-danger">
                {" "}
                {process.env.REACT_APP_ALLOW_INVOICE_NR === "true"
                  ? "*"
                  : t(["invoice.add"])}
              </span>
            </label>
            <input
              name="number"
              type="text"
              className={`form-control ${process.env.REACT_APP_ALLOW_INVOICE_NR === "true"
                  ? "required"
                  : "readonly"
                }`}
              disabled={
                process.env.REACT_APP_ALLOW_INVOICE_NR !== "true" || disabled
              }
              value={invoiceState.number}
              onChange={handleInvoiceDataChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-3">
            <label className="form-label">
              {t("invoice.issueDate")}

              <span className="text-danger"> *</span>
              <PopOver title={t("hints.issueDate")} />
            </label>
            <DatePicker

              ref={issueDateRef}
              selected={invoiceState.issueDate}
              disabled={disabled}
              dateFormat="dd/MM/yyyy"
              name="issueDate"
              autoComplete="off"
              minDate={minIssueDate ? minIssueDate : func.getMinIssueDate()}
              showDisabledMonthNavigation
              onChange={handleIssueDateChange}
              customInput={<input className="form-control" />}
            />
          </div>
          <div className="form-group col-md-3">
            <label className="form-label">
              Subseq
              <PopOver title={t("invoice.subseq")} />
            </label>
            <Select

              components={{ Option: CustomOption }}
              options={subseqValues}
              value={subseqValues.find(
                (subseq) => invoiceState.subSequenceDelivType === subseq.value
              )}
              isDisabled={disabled}
              styles={selectStyle}
              onChange={(subseq) => handleSubseqChange(subseq)}
              name="subseq"
            />
          </div>

          <div className="form-group col-md-6">
            <label className="form-label">
              {t("invoice.paymentDate")}

              <span className="text-danger"> *</span>
              <PopOver title={t("hints.paymentDate")} />
            </label>
            <DatePicker
              selected={invoiceState.dueDate}
              disabled={disabled}
              dateFormat="dd/MM/yyyy"
              name="dueDate"
              autoComplete="off"
              minDate={disabled?null:invoiceState.issueDate}
              showDisabledMonthNavigation
              onChange={handleDueDateChange}
              customInput={<input className="form-control" />}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="form-label">
              {t("invoice.sDate")}
              <PopOver title={t("hints.paymentStartDate")} />
            </label>
            <DatePicker
              selected={invoiceState.invoiceStartDate}
              disabled={disabled}
              // minDate={new Date(now.getFullYear(), now.getMonth(), 1)}
              maxDate={new Date(now.getFullYear(), now.getMonth() + 1, 0)}
              dateFormat="dd/MM/yyyy"
              onChange={handleStartDateChange}
              customInput={<input className="form-control" />}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="form-label">
              {t("invoice.eDate")}
              <PopOver title={t("hints.paymentEndDate")} />
            </label>
            <DatePicker
              selected={invoiceState.invoiceEndDate}
              disabled={disabled}
              minDate={invoiceState.invoiceStartDate?.length != 0 ? invoiceState.invoiceStartDate : new Date(now)}
              maxDate={invoiceState.invoiceStartDate?.length != 0 ? new Date(new Date(invoiceState.invoiceStartDate).getFullYear(), new Date(invoiceState.invoiceStartDate).getMonth() + 1, 0) : new Date(now.getFullYear(), now.getMonth() + 1, 0)}
              dateFormat="dd/MM/yyyy"
              onChange={handleEndDateChange}
              customInput={<input className="form-control" />}
            />
          </div>
        </div>
        <div className="row align-items-start">
          <div className="col-md-6">
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label">
                  {t("invoice.currency")}
                  <PopOver title={t("hints.currencySelect")} />
                </label>

                {disabled ? (
                  <input
                    className="form-control"
                    value={
                      currencies.find(
                        (currency) => currency.value === invoiceState.currency
                      ).label
                    }
                    disabled
                  />
                ) : (
                  <Select
                    styles={customStyles}
                    options={currencies}
                    value={currencies.find(
                      (currency) => currency.value === invoiceState.currency
                    )}
                    isSearchable={true}
                    onChange={(currency) =>
                      handleCurrencyChange(currency.value)
                    }
                    name="currency"
                  />
                )}
              </div>
              <div className="form-group col-md-6">
                {invoiceState.currency !== "ALL" && (
                  <div>
                    <label className="form-label">
                      Exchange Rate
                      <span className="text-danger"> *</span>
                      <PopOver title={t("invoice.exchangeRateInfo")} />

                    </label>
                    <input
                      name="rate"
                      type="number"
                      step="0.001"
                      min="0"
                      className="form-control required"
                      disabled={disabled}
                      value={invoiceState.rate}
                      onChange={handleInvoiceDataChange}
                    />
                  </div>
                )}
              </div>
              <div className="form-group col-md-12">
                <label className="form-label">
                  {t("invoice.contractNo")}
                  <PopOver title={t("hints.contractNo")} />
                </label>
                <input
                  name="contract"
                  type="text"
                  className="form-control required"
                  disabled={disabled}
                  value={invoiceState.contract}
                  onChange={handleInvoiceDataChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group text-right">
                  <label htmlFor="discount">
                    {t("invoice.discount")}

                    <PopOver title={t("hints.invoiceDiscount")} />
                  </label>
                  <div className="input-group">
                    <input
                      type={disabled ? "text" : "number"}
                      step="0.01"
                      min="0.01"
                      id="discount"
                      name="discount"
                      className="form-control text-right"
                      // placeholder="10%/100"
                      disabled={disabled}
                      // disabled={true}
                      value={
                        disabled
                          ? invoiceState.discount
                            ? formatNumber(invoiceState.discount?.value || null)
                            : ""
                          : invoiceState.discount?.value
                      }
                      onChange={(e) =>
                        handleDiscountChange("value", e.target.value)
                      }
                    />
                    <div className="input-group-append dropup">
                      <button
                        className={
                          "btn btn-outline-primary dropdown-toggle" +
                          (disabled ? " disabled" : "")
                        }
                        disabled={disabled}
                        // disabled={true}
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {invoiceState.discount.type}
                      </button>
                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDiscountChange(
                              "type",
                              "Percentage"
                            );
                          }}
                        >
                          {t("invoice.percentage")}
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDiscountChange("type", "value");
                          }}
                        >
                          {t("invoice.value")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="discountVat">
                    {t("invoice.discountVat")}

                    <PopOver title={t("hints.discountVat")} discountVat />
                  </label>

                  <Select
                    styles={customStyles}
                    id="discountVat"
                    name="discountVat"
                    options={typeOfDiscountVatCategoryOptions}
                    value={
                      typeOfDiscountVatCategoryOptions.find(
                        (dVat) => dVat.value === invoiceState.discount.vat
                      ) || null
                    }
                    onChange={(e) => handleDiscountChange("vat", e.value)}
                    isDisabled={disabled}
                  />
                </div>

                {invoiceState.discount.vat === "0" && (
                  <div className="form-group">
                    <label htmlFor="vatLabel">
                      {t("item.vatLabel")}
                      <span className="text-danger"> *</span>
                      <PopOver title={t("hints.vatType")} />
                    </label>

                    <Select
                      styles={customStyles}
                      type="text"
                      id="vatLabel"
                      name="vatLabel"
                      options={vatTypeOptions}
                      value={
                        vatTypeOptions.find(
                          (VatC) =>
                            VatC.value === invoiceState.discount.vatLabel
                        ) || ""
                      }
                      onChange={(e) =>
                        handleDiscountChange("vatLabel", e.value)
                      }
                      isDisabled={disabled}
                    />
                  </div>
                )}
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label
                    htmlFor="bankAccount"
                    className="label-txt d-flex align-items-center"
                  >
                    {t("company.bankAccount")}
                    <PopOver title={t("hints.bankAccount")} />
                    <button
                      className="btn btn-light bg-white shadow-sm btn-sm btn-custom ml-auto"
                      onClick={addBankAccount}
                    >
                      {t("subscription.add")}
                    </button>
                  </label>

                  {disabled ? (
                    <input
                      className="form-control"
                      width="30"
                      value={
                        bankAccount
                          ? `${bankAccount.bankName} | ${bankAccount.iban}`
                          : ""
                      }
                      disabled
                    />
                  ) : (
                    <Select
                      styles={customStyles}
                      options={bankAccounts
                        .filter(
                          (bankAccount) =>
                            bankAccount.currency === invoiceState.currency &&
                            bankAccount.active === true
                        )
                        .map((account) => ({
                          label: `${account.bankName} | ${account.iban}`,
                          value: account.id,
                        }))}
                      value={{
                        label: bankAccount
                          ? `${bankAccount.bankName} | ${bankAccount.iban}`
                          : "",
                        value: bankAccount ? bankAccount.id : "",
                      }}
                      isSearchable={true}
                      onChange={(account) => {
                        handleSelectedBankAccount(
                          bankAccounts.find(
                            (bankAccount) => bankAccount.id === account.value
                          )
                        );
                      }}
                      name="bankAccount"
                      id="bankAccount"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(InvoiceData);
