import React from 'react'

const SelectInput = ({children,disabled,value}) => {
  if(disabled) return <input
  type="text"
  className="form-control"
  value={value ?? ""}
  disabled={true}
  onChange={()=>{}} />
  return (
    children
  )
}

export default SelectInput