import React, { useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../AppContext';
import Loader from '../../Layout/Loader'
import mainAxios from '../../../services/mainAxios';
import { toast } from 'react-toastify';

const EditEmail = (props) => {
    const { t, i18n } = useTranslation('translations');
    const { user: [user, setUser], selectedCompany: [selectedCompany] } = useAppContext()
    const [notifType, setNotifType] = useState("EMAIL")
    const [state, setState] = React.useState({
        newEmail: "",
        confirmEmail: "",
        error: null,
        error_email: null,
    })
    const disableSms = (user.phone == null || user.phone == '') ? true : false
    const [loading, setLoading] = React.useState(false)

    // const handleOptionChange = (e) => {
    //     console.log({ targeti: e.target.value})
    //     setState((state) => ({
    //         ...state,
    //         sms: e.target.value
    //     }))
    // }

    const editEmail = (e) => {
        e.preventDefault()
        let userId = props.match.params.id

        // if (!userId) userId = user.id

        if (state.newEmail === state.confirmEmail) {
            setLoading(true)
            mainAxios.post('general/sendConfirmationNewEmail', {
                // object: object,
                content: {
                    id: userId,
                    newEmail: state.newEmail,
                    notifType: notifType
                    // recipient: state.newPassword
                },
                nuis: user.companiesRights.find(
                    (company) => company.id === parseInt(selectedCompany)
                )?.nuis
            }).then(res => {
                console.log({ res })
                if (res?.data?.status === true) {
                    setUser({
                        ...user,
                        // resetPass: false
                    })
                    props.history.replace('/users/edit/' + userId)
                    let notifSent = notifType == "SMS" ? t(['toast.updateEmailSMSSent']) : t(['toast.updateEmailSent'])
                    toast.success(notifSent, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        containerId: "all_toast"
                    })
                }
                else {
                    setState(state => ({
                        ...state,
                        error: null
                    }))
                }
                setLoading(false)
            })
        }
        else {
            setState(state => ({
                ...state,
                error: t(['toast.emailIdentical'])
            }))
        }

    }

    const handleEmailInputs = (e) => {
        const key = e.target.name
        const value = e.target.value

        setState(state => ({
            ...state,
            [key]: value
        }))
    }

    console.log({sms: notifType})

    return (
        <div className="row mt-4">
            <form className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-12 mx-auto" onSubmit={editEmail}>
                <div className="card border-0 shadow mb-4">
                    <div className="card-header border-0">
                        <h3 className="mb-0">{t('users.editEmail')}</h3>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="new_email">
                                {state.error_email && <span className="d-block text-danger">{state.error_email}</span>}
                                {t('users.newEmail')}
                            </label>
                            <input
                                type="email"
                                className={"form-control" + (state.error_email ? " border-danger" : "")}
                                id="new_email"
                                name="newEmail"
                                placeholder={t('users.newEmail')}
                                value={state.newEmail}
                                onChange={handleEmailInputs}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirm_email">
                                {t('users.confirmEmail')}
                                {state.error && <span className="ml-2 text-danger">{state.error}</span>}
                            </label>
                            <input
                                type="email"
                                className={"form-control" + (state.error ? " border-danger" : "")}
                                id="confirm_email"
                                name="confirmEmail"
                                placeholder={t('users.confirmEmail')}
                                value={state.confirmEmail}
                                onChange={handleEmailInputs}
                                required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirm_email">
                                {t('users.notificationType')}
                            </label>
                            <div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="defaultInline1" name="inlineDefaultRadiosExample" value="EMAIL" onChange={(e) => setNotifType(e.target.value)} defaultChecked />
                                    <label className="custom-control-label" htmlFor="defaultInline1">Email</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id="defaultInline2" name="inlineDefaultRadiosExample" value="SMS" onChange={(e) => setNotifType(e.target.value)} disabled={disableSms} />
                                    <label className="custom-control-label" htmlFor="defaultInline2">SMS</label>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <button
                                className="btn btn-primary mr-3"
                                disabled={loading}
                                style={{ minWidth: "100px" }}
                                type="submit">
                                {loading ? <Loader size="xs" color="white" /> :
                                    (<>
                                        <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
                                    </>)}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditEmail