import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useAppContext } from '../../../AppContext';
import mainAxios from '../../../services/mainAxios';
import { useTranslation } from 'react-i18next';
import Loader from '../../Layout/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCheck, faCheckCircle, faDownload, faEdit, faFileUpload, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Preloader, ThreeDots } from 'react-preloader-icon';
import func from '../../../services/functions';
import { useDropzone } from 'react-dropzone';
import DateRange from '../../global/DateRange';
import moment from 'moment';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import EditCompanyCertificate from '../EditCompanyCertificate';
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'primereact/tooltip';

const Company = ({ company, setCompany, handleCompanyInputs, filePreview, setFilePreview, logoLoading, editCompany,setPageLoading }) => {
  const {
    user: [user],

  } = useAppContext();
  const { t, i18n } = useTranslation("translations");
  const [oldState, setOldState] = useState()
  const [isEditable, setIsEditable] = useState(false)
  const [loading, setLoading] = React.useState(false)
  const isRoleDisabled = user?.role?.id != 1
  const fileContainer = useRef();
  const [visibleCertificate, setVisibleCertificate] = useState(false);


  const FILE_SIZE_LIMIT = 1000 * 1024; // 500 KB

  const history = useHistory();
  const handleEditForm = () => {
    setIsEditable(!isEditable)
    setOldState({ ...company })
  }


  const onPageChange = async (event) => {
    if(company.active){
    await mainAxios.post("/apiEndpoint/search", {
      object: "GetNextOrPreviousCompany",
      value: event,
      company: parseFloat(company.id)
    })
    .then(res => {
      const id=res.data.content?.[0]
      if(res.data.status && id){
        setPageLoading(true)
        const id=res.data.content[0]
        history.push(`/companies/${id}`);
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
  };


  const cancelCompanyEdit = () => {
    setCompany({ ...oldState });
    setIsEditable(false)
  }

  const uploadImage = () => {
    const saveCompanyLogo = () => {
      mainAxios
        .post("apiEndpoint/saveOrUpdate", {
          object: "UploadCompanyLogo",
          nuis: company?.nuis,
          content: {
            file: filePreview.base64,
          },
        })
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(t("company.logoUploaded"), {
              containerId: "all_toast",
            });
            setFilePreview((state) => {
              return { ...state, uploaded: true };
            });
          } else {
            setFilePreview(null);
          }
        });
    };

    return (
      <>
        <div className="upload-wrapper" {...getRootProps()}>
          <input {...getInputProps()} className="w-100" disabled={logoLoading} />
          {logoLoading && (
            <div className="custom-loading">
              <Preloader
                use={ThreeDots}
                size={45}
                strokeWidth={6}
                strokeColor="#007567"
                duration={1000}
              />
            </div>
          )}
          {!filePreview && !logoLoading && (
            <div className="drag-section justify-content-center align-items-center">
              <div className="d-flex justify-content-center mt-2">
                <FontAwesomeIcon
                  icon={faFileUpload}
                  className="upload-image-icon justify-content-center "
                  style={{ color: "#007567" }}
                  size="2x"
                />
              </div>

              <p className="text-center"> {t("company.uploadLogo")}</p>
            </div>
          )}

          {filePreview && !logoLoading && (
            <div ref={fileContainer}>
              <div className="d-flex align-items-center justify-content-center mt-1">
                <img
                  src={func.b64toURL(
                    filePreview.base64,
                    "image/*",
                    filePreview.name
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center w-100">
          {filePreview && !filePreview?.uploaded && (
            <btn
              onClick={closePreview}
              className="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center mr-2"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                className="upload-image-icon mr-1"
              />
              {
                <div className="d-flex justify-content-center align-items-center">
                  {t("common.cancel")}
                </div>
              }
            </btn>
          )}
          {filePreview && !filePreview?.uploaded && (
            <btn
              onClick={saveCompanyLogo}
              className="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon
                onClick={closePreview}
                icon={faCheck}
                className="upload-image-icon mr-1"
              />
              {t("common.save")}
            </btn>
          )}
        </div>
      </>);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".png,.jpg,.jpeg",
    onDrop: async (files) => {
      let filesExceeded = 0;
      for (const file of files) {
        const extension =
          file && file.name.split(".")[file.name.split(".").length - 1];

        if (
          extension &&
          (extension === "png" || extension === "jpg" || extension === "jpeg")
        ) {
          let base64File = await func.toBase64(file);
          if (base64File.length * (3 / 4) < FILE_SIZE_LIMIT) {
            setFilePreview({
              base64: base64File.substr(base64File.indexOf(",") + 1),
              name: file.name,
              extension: extension,
            });
          } else filesExceeded += 1;
        }
      }
      if (filesExceeded) {
        toast.warning(t("company.imageLimit"), { containerId: "all_toast" });
      }
    },
  });


  const closePreview = () => {
    setTimeout(() => {
      setFilePreview(null);
    }, 150);
    fileContainer.current.classList.toggle("open");
  };


  return (
    <div className="card border-0 shadow overflow-x-auto">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          {
            isEditable ?
              <input
                type="text"
                className="form-control form-control-sm w-50"
                id="company_name"
                name="name"
                placeholder="Name"
                disabled={isRoleDisabled}
                value={company.name}
                onChange={handleCompanyInputs}
                required
              />
              :
              <div className='d-flex  align-items-end justify-content-center'>
                <Tooltip target=".custom-target-icon-previous" />
                <button onClick={()=>onPageChange('previous')}  className="custom-target-icon-previous pi pi-angle-left mr-2 pi-arrow-hover p-text-secondary p-overlay-badge border-0 bg-transparent"
                    data-pr-tooltip={company?.active  ? t('company.prevCompany') :  t('company.isCompanyActive')}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    >
                </button>
                  <h3>{company.name}</h3>
                  <Tooltip target=".custom-target-icon-next" />
                <button onClick={()=>onPageChange('next')}   className="custom-target-icon-next pi pi-angle-right ml-2 pi-arrow-hover p-text-secondary p-overlay-badge border-0  bg-transparent"
                    data-pr-tooltip={company?.active  ? t('company.nextCompany') :  t('company.isCompanyActive')}
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    >
                </button>
              </div>
          }
          <div className="company-actions">
            {
              isEditable ?
                <>
                  <button
                    onClick={async () => {
                      setLoading(true)
                      const edited = await editCompany()
                      if (edited) setIsEditable(false)
                      setLoading(false)
                    }}
                    className="btn btn-sm btn-outline-primary mr-3"
                    disabled={loading}
                    style={{ minWidth: "100px" }}
                  >
                    {loading ? <Loader size="xs" color="white" /> :
                      (<>

                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2 mt-1" />
                        {t("common.save")}
                      </>)}
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={cancelCompanyEdit}
                  >
                    <FontAwesomeIcon icon={faCancel} className="mr-2" />
                    {t("common.cancel")}
                  </button>
                </>
                :
                <>
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => handleEditForm()}
                  >
                    <FontAwesomeIcon icon={faEdit} className="mr-2" />
                    {t("company.editCompany")}
                  </button>

                </>
            }
          </div>
        </div>
        <div className="row">{uploadImage()}</div>
        <div className="pt-3">

          {/*  table */}
          <table className="table">
            <tbody>
              <tr>
                <td className="font-weight-bold text-dark">NIPT</td>
                {
                  isEditable ?
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="company_nuis"
                        name="nuis"
                        disabled={isRoleDisabled}
                        placeholder="Company NIPT"
                        value={company.nuis}
                        onChange={handleCompanyInputs}
                        required />
                    </td>
                    :
                    <>
                      <td>{company?.nuis}</td>
                    </>
                }
              </tr>
              <tr>
                <td className="font-weight-bold text-dark">
                  {t("home.date")}
                </td>
                <td>
                  {moment(company.recordDate).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold text-dark">E-mail</td>
                {
                  isEditable ?
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="email"
                        name="email"
                        disabled={isRoleDisabled}
                        placeholder="E-mail"
                        value={company?.email}
                        onChange={handleCompanyInputs}
                        required />
                    </td>
                    :
                    <>
                      <td>{company?.email}</td>
                    </>
                }
              </tr>
              <tr>
                <td className="font-weight-bold text-dark">{t("users.phone")}</td>
                {
                  isEditable ?
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="phone_Number"
                        name="phoneNumber"
                        disabled={isRoleDisabled}
                        placeholder="Phone Number"
                        value={company?.phoneNumber}
                        onChange={handleCompanyInputs}
                        required />
                    </td>
                    :
                    <>
                      <td>{company?.phoneNumber}</td>
                    </>
                }
              </tr>
              <tr>
                <td className="font-weight-bold text-dark">{t("company.websiteUrl")}</td>
                {
                  isEditable ?
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="website_url"
                        name="websiteUrl"
                        disabled={isRoleDisabled}
                        placeholder="Website Url"
                        value={company?.websiteUrl}
                        onChange={handleCompanyInputs}
                        required />
                    </td>
                    :
                    <>
                      <td>{company?.websiteUrl}</td>
                    </>
                }
              </tr>
              <tr>
                <td className="font-weight-bold text-dark">{t("customers.address")}</td>
                {
                  isEditable ?
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="company_Address"
                        name="companyAddress"
                        disabled={isRoleDisabled}
                        placeholder="Adress"
                        value={company?.companyAddress}
                        onChange={handleCompanyInputs}
                        required />
                    </td>
                    :
                    <>
                      <td>{company?.companyAddress}</td>
                    </>
                }
              </tr>
              <tr>
                <td className="font-weight-bold text-dark">
                  {t("subscription.type")}
                </td>
                {
                  isEditable ?
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id="company_type"
                        name="type"
                        placeholder="E.g 'Person Fizik'"
                        disabled={isRoleDisabled}
                        value={company.type}
                        onChange={handleCompanyInputs}
                        required />
                    </td>
                    :
                    <>
                      <td>{company.type}</td>
                    </>
                }
              </tr>
              <tr>
                <td className="font-weight-bold text-dark">
                  {t('home.status')}
                </td>
                {
                  isEditable ?
                    <td>
                      <select
                        type="text"
                        className="form-control form-control-sm"
                        id="company_status"
                        name="active"
                        value={company.active}
                        disabled={isRoleDisabled}
                        onChange={handleCompanyInputs}
                        required>
                        <option value={true}>{t('company.yes')}</option>
                        <option value={false}>{t('company.no')}</option>
                      </select>
                    </td>
                    :
                    <td>
                      {company?.active ? <> {t('company.yes')}</> : <>{t('company.no')}</>}
                    </td>
                }
              </tr>
              <tr>
                <td className="font-weight-bold text-dark">
                  {t("company.certificate")}
                </td>
                <td>{company.hasCertificate == true ?
                  <div className="d-flex align-items-center">
                    <div className="mr-3">{t("common.certificatePresent")} </div>
                    <div
                      onClick={() => setVisibleCertificate(true)}
                      // to={`/companies/${company.id}/certificate`}
                      className="btn btn-primary btn-sm"
                    >
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        {t("subscription.add")}
                      </div>
                    </div>

                  </div>
                  :
                  <div className="d-flex align-items-center">
                    <div className="mr-3">{t("common.certificateNotPresent")}</div>
                    <Link
                      to={`/companies/${company.id}/certificate`}
                      className="nav-link d-inline-block p-0"
                    >
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="mr-2"
                        />
                        {t("common.edit")}
                      </div>
                    </Link>

                  </div>
                }
                </td>
              </tr>
              {company.hasCertificate == true && <tr>
                <td className="font-weight-bold text-dark">
                  {t("common.certificateExpirationDate")}
                </td>
                <td>
                  {moment(company.certificateExpirationDate).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </td>
              </tr>}
              {/* <tr>
                                      <td className="font-weight-bold text-dark">Invoices Left To Send</td>
                                      <td>{state.company.companyLeftInvoices?.leftSentInvoices || 0}</td>
                                  </tr>
                                  <tr>
                                      <td className="font-weight-bold text-dark">Invoices Left To Receive</td>
                                      <td>{state.company.companyLeftInvoices?.leftReceivedInvoices || 0}</td>
                                  </tr> */}
            </tbody>
          </table>
          <EditCompanyCertificate
            company={company}
            setCompany={setCompany}
            visibleCertificate={visibleCertificate}
            setVisibleCertificate={setVisibleCertificate} />
          {/* {////////////////} */}

        </div>
      </div>
    </div>
  )
}

export default Company