import React from 'react'

import mainAxios from '../../services/mainAxios'
import { faCheck, faLocationArrow, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast, ToastContainer } from 'react-toastify'
import Select from "react-select"
import Loader from '../Layout/Loader'
import { useAppContext } from '../../AppContext'
import {useTranslation, withTranslation} from "react-i18next";
import { customStyles } from '../../design/selectStyle'

const Upload = ({ user, selectedCompany  }) => {

const {t,i18n} = useTranslation('translations');

    const [customExtension, setCustomExtension] = React.useState(null)

    const [loading, setLoading] = React.useState(false)

    const company = user.companiesRights.find(
        company => company.id === parseInt(selectedCompany)
    );

    const defaultBranch = company.branches.map((branch) => {
        if(branch.default) {
            return {
                label: branch.sellerName + " - " + branch.sellerAddress,
                value: branch.sellerName,
                ...branch,
            }
        }
    }).filter(branch => branch);

    const [state, setState] = React.useState({
        file: null,
        name: null,
        extension: "",
        fileOption: "CSV-1",
        businUnitCode:defaultBranch[0].businUnitCode
    })


    const branchesOptions =
    company &&
    company.branches.map((data) => ({
      label: data.sellerName + " - " + data.sellerAddress,
      value: data.sellerName,
      ...data,
    }));

    const fieldSeparators = [
        {label: "Coma", value: "Coma", disabled: false},
        {label: "Pipe", value: "Pipe", disabled: true},
        {label: "Tab", value: "Tab", disabled: true},
    ]

    const  fileOptions = [
        {label: "CSV-1", value: "CSV-1", disabled: false},
        {label: "CSV-2", value: "CSV-2", disabled: false},
    ]

    const  stage = [
        {label: "Draft", value: "Draft", disabled: false},
        {label: "Ready To Approve", value: "Ready To Approve", disabled: true},
    ]

      
    const submitInvoice = (e) => {
        e.preventDefault()
        setLoading(true)
        mainAxios.post('apiEndpoint/saveOrUpdate', {
            object: "UploadCustomInvoice",
            content: {
                name: state.name,  
                extension: customExtension ? customExtension : state.extension,
                fileOption: state.fileOption,
                file: state.file
            },
            nuis: user.companiesRights.find(company => company.id === parseInt(selectedCompany))?.nuis
        }).then(res => {
            if(res?.data?.status === true) {
                toast.success(t(['notification.success']), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    containerId: "all_toast"
                })
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const handleFile = (e) => {
        const file = e.target.files[0]
        const extension = file && file.name.split('.')[file.name.split('.').length - 1]
        
        if(extension && (extension === 'csv')) {
            const reader = new FileReader();

            reader.onload = (readerEvent) => {
                let binaryString = readerEvent.target.result
                setState(state => ({
                    ...state,
                    file: btoa(binaryString),
                    name: file.name,
                    extension
                }))
            }
            reader.readAsBinaryString(file)
        }
    }

    return (
        <form className="text-left" onSubmit={submitInvoice}>
            <div>
                <div className="card-body">
                    <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="certificate">{t('invoice.file')}</label>
                            <div className="custom-file">
                                <input 
                                    type="file" 
                                    className="custom-file-input"
                                    id="file"
                                    name="file"
                                    placeholder="Upload File"
                                    accept=".csv"
                                    onClick={(e) => {e.target.value = null}}
                                    onChange={handleFile}/>
                                    
                                <label className="custom-file-label" htmlFor="certificate">{state.name ? state.name : t(['invoice.choose'])}</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <label className="form-label" style={{ marginTop: '0.5rem' }}>
                                    Field Separators
                            </label>
                               <Select
                                    styles={customStyles}
                                    options={fieldSeparators}
                                    value={fieldSeparators.filter(option=> option.disabled === false)}
                                    isSearchable={true}
                                    isDisabled={(option) => option.disabled === true}
                                    // isOptionDisabled={(option) => option.disabled === true}
                                    name="fieldSeparators"
                                />
                        </div>
                        <div className=" col-6">
                            <label className="form-label" style={{ marginTop: '0.5rem' }}>
                                File Options
                            </label>
                            <Select
                                id="fileOptions"
                                options={fileOptions}
                                styles={customStyles}
                                name="fileOptions"
                                value={fileOptions.find(option => option.value == state.fileOption)}
                                defaultValue={fileOptions[0]}
                                onChange={(fileOption) => {
                                    setState({
                                        ...state,
                                        fileOption: fileOption.value
                                    })
                                }}
                                isOptionDisabled={(option) => option.disabled === true}
                            />
                        </div>
                        <div className="form-group col-6">
                            <label className="form-label" style={{ marginTop: '0.5rem' }}>
                                    Stage
                            </label>
                            <Select
                                styles={customStyles}
                                id="stage"
                                options={stage}
                                name="stage"
                                value={stage.filter(option=> option.disabled === false)}
                                // isOptionDisabled={(option) => option.disabled === true}
                                isDisabled={(option) => option.disabled === true}
                            />
                        </div>  
                        <div className="form-group col-12">
                            <label className="form-label" style={{ marginTop: '0.5rem' }}>
                                    Business Unit
                            </label>
                            <Select
                                menuPlacement="top"
                                styles={customStyles}
                                options={branchesOptions}
                                isSearchable={true}
                                onChange={(branch) => {
                                    setState({
                                        ...state,
                                        businUnitCode: branch.businUnitCode
                                    });
                                }}
                                value={branchesOptions.find(branch=>branch.businUnitCode===state.businUnitCode)}
                                name="tcrCode"
                            />
                        </div>  
                        
                        
                    </div>
                    <button 
                        className="btn btn-primary mt-3"
                        disabled={loading || !state.file}
                        style={{minWidth: "80px"}}
                        type="submit">
                        {loading ? <Loader size="xs" color="white"/> : 
                        (<>
                            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />{t('invoice.send')}
                        </>)}
                    </button>
                </div>
            </div>
        </form>
    )

}

export default Upload