import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart  } from 'chart.js';
import "chart.js/auto";
import func from "../../services/functions";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../global/hooks/WindowDimensions";
import { useAppContext } from "../../AppContext";
import { DashboardCard } from "../global/CommonComponents";

const DoughnutPieTable = ({ title,todayTotalsByBranch ,businessUnitCodes}) => {
  const { height, width } = useWindowDimensions();
  const {selectedCompany:[selectedCompany]} = useAppContext()
  const { t } = useTranslation('translations') 
  const doughnutRef =React.useRef()
  let filteredTotalsByBranch=todayTotalsByBranch=todayTotalsByBranch?.filter(stat=>((stat.cashEnd?? 0) +(stat.banknoteCashTot ?? 0))>0)
  let labels=filteredTotalsByBranch?.map(stat=>{ return businessUnitCodes.find((branch=>branch.value==stat.businessUnitCode))?.label?.trim() ?? stat.businessUnitCode}) ?? [];
  let dataSet=filteredTotalsByBranch?.map(stat=>(stat.cashEnd+ 0) +(stat.banknoteCashTot)) ?? []
  let isEmpty=dataSet.every(cashEnd=>cashEnd===0);
  const hoverLabel={
    id:"hoverLabel",
    afterDraw(chart){
      if(!chart.config?.options?.plugins?.hoverLabel) return
      const {ctx,chartArea:{left,top,width,height}} =chart
      ctx.save()
      let active=0
      let dataSetIndex=0
      if(chart._active.length>0){
        active=chart._active?.[0].index
        dataSetIndex=chart._active?.[0].datasetIndex
      }
        const textLabel=chart.config.data.labels[active]
        const numberLabel=chart.config.data.datasets[dataSetIndex].data[active]
        const color= chart.config.data.datasets[dataSetIndex].backgroundColor[active]
        ctx.font = "bolder 18px Arial"
        ctx.fillStyle="#333"
        ctx.textAlign="center"
        if(textLabel && numberLabel){
          let branchName=textLabel.split("| ")?.[0] ?? textLabel
          ctx.font = "bolder 15px Arial"
          ctx.fillText(branchName,left+width/2,top+height/2)
          ctx.font = "bolder 20px Arial"
          ctx.fillStyle="#36c9c6"
          let lineHeight = ctx.measureText('').width+30;
          ctx.fillText(`${func.getFormattedMoneyValue(numberLabel)}L`,width/2,height/2+lineHeight)
        }else{
          ctx.fillStyle="#6c757d"
          ctx.font = "bolder 22px Arial"
          ctx.fillText(t("dashboard.noData"),left+width/2,top+height/2)
        }

      ctx.restore()
    }
  }

  const updateDataset = async (datasetIndex)  => {
    var chart = doughnutRef.current;
    var meta = chart?.legend?.chart?._metasets?.[0]?.data?.[datasetIndex];
    if(!meta) return
    let legendItemHidden=meta?.hidden=== undefined || meta.hidden===null ?true : null;
    meta.hidden = legendItemHidden
    chart.update();
};


  window.updateDataset= updateDataset;

  const customLegend=React.useMemo(()=>{
    return {
      id:"customLegend",
      afterUpdate: function(chart ) {
        // Make sure we're applying the legend to the right chart
        let customLegend=chart.config?.options?.plugins?.customLegend
        if(!customLegend || (customLegend && chart?.legend?.legendItems?.length==0)) return
          let innerHtml="";
          let metas= chart?.legend?.chart?._metasets?.[0]?.data
          chart.legend.legendItems.forEach((item, i) => {
            let meta =metas?.[i]
            let legendItemHidden=meta?.hidden=== undefined || meta.hidden===null ?false : true;
            innerHtml += `
            <li class="w-100 d-flex text-left legend-item ${legendItemHidden?"legend-item-hidden":""}" onclick="updateDataset(${i})"">
              <div class="bullet" style="background-color: ${ item.fillStyle }"> </div>
              <div class="legend-item-name">${ item.text }</div>
            </li>
          `
          });
          const legendInnerHtml=`<ul class="mt-3 custom-scroll-bar text-center w-100">${innerHtml}</ul>`
          const legend=document.querySelector("#custom-legend")
          legend.innerHTML=legendInnerHtml
          return legend
      },
    }
  },[selectedCompany])


  
  Chart.register({
    hoverLabel,
    customLegend
  });

  const colors=[
    "#36c9c6",
    "#fac091",
    "#5075df",
    "#1cbb8c",
    "#eeb902",
    "#508991",
    "#f4f1bb",
    "#f9a825",
    "#9bc1bc",
    "#508991",
    "#f4f1bb",
    "#f9a825",
    "#9bc1bc",
    "#ff9b85",
    "#b8b8ff"
  ]
  const data = {
    labels: isEmpty?[]:labels,
    datasets: [
      {
        label: "My First Dataset",
        data: isEmpty?[1]:dataSet,
        backgroundColor: colors,
        hoverBorderColor :"#ececec",
        hoverBorderWidth:3,
        spacing:4,
      },
    ],
  };

  let cutout=(((width<765?width:width/3) -210 ) /3.528)
  const config = {
    responsive:true,
    cutout: isEmpty?200:cutout, 
    maintainAspectRatio: false,
    plugins:{
    tooltip:{
      usePointStyle: true,
      caretPadding: -100,
      enabled:!isEmpty
    },
    legend: {
      display: false,
    },
    customLegend,
    hoverLabel,
  }
  };


  return (
    <DashboardCard title={title}>
        <div className="dougnut-chart"  style={{width:isEmpty?"100%":"60%",padding:8}}>
        <Doughnut data={data} options={config} ref={doughnutRef}/>
        </div>
        <div id="custom-legend" className="m-auto h-100" style={{width:isEmpty?"0%":"40%"}}></div>
    </DashboardCard>
  );
};

export default React.memo(DoughnutPieTable);
