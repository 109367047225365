import React from 'react'
import CreatableSelect from 'react-select/creatable';
import mainAxios from '../services/mainAxios';
import Loader from './Layout/Loader';


import {useTranslation, withTranslation} from "react-i18next";
import { useAppContext } from '../AppContext';
import { toast } from 'react-toastify';
const _ = require("lodash")

const SendEmail = () => {
    
    const {t,i18n} = useTranslation('translations');
    const [users, setUsers] = React.useState(null)
    const [email, setEmail] = React.useState({
        to: [],
        cc: [],
        subject: "",
        body: "",
        attachments: []
    })
    const {user: [user, setUser]} = useAppContext()
    const fileRef = React.useRef(null)
    const [sentEmailLoading,setSentEmailLoading]=React.useState(false)
    const [pageLoading, setPageloading] = React.useState(true)
    React.useEffect(() => {
        mainAxios.post('apiEndpoint/search', {
            object: 'GetUser'
        }).then(res => {
            if(res?.data?.status === true) {
                const users = res.data.content
                const transformedUsers = users.map(user => {
                    const {companyBranches, ...newUser} = user 
                    let companies = []
                    companyBranches.forEach(data => {
                        let found = companies.find(company => company.id === data.companies.id)
                        if(found) {
                            found['branches'] = [...found['branches'], {...data.branches, defaultBranch: data.defaultBranch}]
                        }
                        else {
                            companies.push({
                                ...data.companies,
                                branches: [{...data.branches, defaultBranch: data.defaultBranch}]
                            })
                        }
                    })
                    return {
                        ...newUser,
                        companies
                    }
                })
                let groupedUsers = []
                transformedUsers.forEach(user => {
                    user.companiesRights.forEach(company => {
                        const index = groupedUsers.findIndex(groupedUser => groupedUser.label === company.name)
                        if(index > -1) {
                            groupedUsers[index].options.push({label: user.email, value: user.email}) 
                        }
                        else {
                            groupedUsers.push({
                                label: company.name,
                                options: [{label: user.email, value: user.email}]
                            })
                        }
                    })
                })
                setUsers(groupedUsers)
                setPageloading(false)
            }
        })
    }, [])

    const handleToChange = (emailOptions) => {
        setEmail(email => ({
            ...email,
            to: emailOptions ? emailOptions : []
        }))
    }

    const handleCcChange = (emailOptions) => {
        setEmail(email => ({
            ...email,
            cc: emailOptions ? emailOptions : []
        }))
    }

    const handleEmailChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setEmail(email => ({
            ...email,
            [key]: value
        }))
    }

    const handleFile = (e) => {
        const files = e.target.files
        let attachments = []
        
        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                let binaryString = readerEvent.target.result
                attachments.push({
                    file: btoa(binaryString),
                    name: file.name
                })
                if(attachments.length === files.length) {
                    setEmail(email => ({
                        ...email,
                        attachments: [...email.attachments, ...attachments]
                    }))
                }
            }
            reader.readAsBinaryString(file)
        })
    }

    const removeAllAttachments = (e) => {
        e.preventDefault()

        setEmail(email => ({
            ...email,
            attachments: []
        }))

        fileRef.current.value = null
    }

    const selectAllTo = (e) => {
        e.preventDefault()
        setEmail(email => ({
            ...email,
            to: users.map(user => user.options).reduce((acc, next) => acc.concat(next), [])
        }))
    }

    const removeAllTo = (e) => {
        e.preventDefault()

        setEmail(email => ({
            ...email,
            to: []
        }))
    }

    const sendEmail = () => {
        setSentEmailLoading(true)
        mainAxios.post('/endpoint/v2/sendMail', {
            ...email,
            from: user.email,
            to: email.to.map(recepient => recepient.value),
            cc: email.cc.map(ccRecepient => ccRecepient.value)
        }).then(res => {
            if(res.data.status===true){
                toast.success(t(['email.successConfirmationEmail']),{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    containerId: "all_toast"})
            }
            setSentEmailLoading(false)

        })
        .catch(err=>{
            setSentEmailLoading(false)
        })
    }
    
    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    return (
        !pageLoading ? 
        <div className="row">
            <div className="col-lg-8 col-md-10 col-12 mx-auto">
                <div className="card border-0 shadow mt-3 mb-4">
                    <div className="card-header border-0">
                        <h3 className="mb-0">{t('email.send')}</h3>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <label htmlFor="to" className="mb-0">{t("email.to")}:</label>
                                <div className="d-flex">
                                    <a 
                                        href="#" 
                                        className="nav-link p-0"
                                        onClick={selectAllTo}>
                                        {t('email.selectAll')}
                                    </a>
                                    <a 
                                        href="#" 
                                        className="nav-link p-0 ml-3"
                                        onClick={removeAllTo}>
                                        {t('email.removeAll')}
                                    </a>
                                </div>
                            </div>
                            <CreatableSelect
                                isMulti
                                id="to"
                                placeholder={t("invoice.select")}
                                value={email.to}
                                onChange={handleToChange}
                                options={users}
                                formatGroupLabel={formatGroupLabel}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="cc">CC:</label>
                            <CreatableSelect
                                isMulti
                                id="cc"
                                placeholder={t("invoice.select")}
                                onChange={handleCcChange}
                                options={users}
                                formatGroupLabel={formatGroupLabel}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject">{t("email.subject")}</label>
                            <input 
                                type="text" 
                                className="form-control"
                                id="subject"
                                name="subject"
                                value={email.subject}
                                onChange={handleEmailChange}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="body">{t('email.message')}</label>
                            <textarea 
                                className="form-control"
                                rows="4"
                                id="body"
                                name="body"
                                value={email.body}
                                onChange={handleEmailChange}/>
                        </div>
                        <div className="form-group">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <label htmlFor="attachments" className="mb-0">{t('email.attachments')}</label>
                                <a 
                                    href="#" 
                                    className="nav-link p-0 ml-3"
                                    onClick={removeAllAttachments}>
                                    {t('email.removeAll')}
                                </a>
                            </div>
                            <div className="custom-file">
                                <input 
                                    type="file" 
                                    id="attachments" 
                                    className="custom-file-input" 
                                    ref={fileRef}
                                    accept=".txt,.pdf,.xlsx,.docx,.pptx"
                                    onChange={handleFile}
                                    multiple/>
                                <label className="custom-file-label" htmlFor="attachments">{email.attachments[0] ? email.attachments.reduce((acc, next, index) => acc + (index === 0 ? next.name : `, ${next.name}`), "") :  t(['invoice.choose'])}</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <button     
                                disabled={sentEmailLoading}
                                className="btn btn-primary"
                                onClick={sendEmail}>
                                {t('invoice.send')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <Loader/>
    )
}

export default SendEmail