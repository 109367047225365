import React from 'react'

const ViewPosTransaction = () => {

    return (
        <h3>View Pos Transaction</h3>
    )
}

export default ViewPosTransaction
