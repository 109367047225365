import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext, usePersistedState } from '../../../AppContext';

import mainAxios from '../../../services/mainAxios';
import PrimeTable, { onColReorder } from "../../PrimeTable/PrimeTable";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faEdit, faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from "primereact/dialog";
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { swalAlert } from '../../../services/functions';
import Loader from '../../Layout/Loader';
import Addons from './Addons';
import AddEditSubscription from './AddEditSubscription';
import { subscriptionStatus as status } from '../../../services/data';
import { useSubscriptionRequests } from '../../../requests/SubscriptionsRequests';

const moment = require("moment");


const Subscriptions = ({ company}) => {
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const [subscriptions, setSubscriptions] = useState([])
  const [subModal, setSubModal] = useState({
    subscription:null,
    open:false
  })
  const [addonModal, setAddonModal] = useState({
    open:false,
    subscription:false
  })
  const { t } = useTranslation("translations");
  const defaultFilters = { params: {} }
  const [filters, setFilters] = React.useState(defaultFilters);
  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [expandedRows, setExpandedRows] = useState(null)
  const [loadingExpandData, setLoadingExpandData] = useState(false);
  const [expandedData, setExpandedData] = useState([]);
  const [loadingGrid,setLoadingGrid]=useState(false)
  const defaultSortCriteria={
    field: "startDate",
    direction: "DESC"
  } 

  const { getCompanySubscriptionAddons }=useSubscriptionRequests({company})

const addOnActive=({sub})=>{
  return [status.active,status.upcoming].includes(sub?.statusId)
} 
  const getGridSubscriptions=({ data,pageSize = 10,page = 1 }={},sortCriteria = [ defaultSortCriteria ])=>{
    setLoadingGrid(true)
    mainAxios.post('apiEndpoint/search', {
      object: "GetCompanySubscriptions",
      company: company.id,
      params:{
        company:company.id.toString()
      },
      pagination:  {
        pageSize,
        pageNumber: page,
      },
      sortCriteria
    }).then(res => {
      setLoadingGrid(false)
      if (res.data.status) {
        setTableRows((tableRows)=>({...tableRows,totalRows:res?.data?.totalSize}))
        // const mappedSubs=mapSubscriptions(res.data.content)
        // const orderSubs=(mappedSubs ?? []).sort(orderSubscriptions); 
        setSubscriptions(res.data.content)
      }
    })
    .catch(err=>{
      setLoadingGrid(false)
    })
  }
  

  const cancelSubscription=(companySubscriptionId)=>{
    setLoadingGrid(true)
    mainAxios.post('subscription/cancelCompanySubscription', {
      company:company.id,
      companySubscriptionId,
    }).then(res => {
      if(res.data.status){
        toast.success(t("common.actionSuccessfully"), { containerId: "all_toast" })
        getGridSubscriptions()
      }
      setLoadingGrid(false)

    })
    .catch(err=>{
      setLoadingGrid(false)
    })
  }
  const cancelAddon=(companySubscriptionAddonId)=>{
    setLoadingGrid(true)
    mainAxios.post('subscription/cancelCompanySubscriptionAddon', {
      company:company.id,
      companySubscriptionAddonId,
    }).then(res => {
      if(res.data.status){
        toast.success(t("common.actionSuccessfully"), { containerId: "all_toast" })
        getGridSubscriptions()
      }
    setLoadingGrid(false)

    })
    .catch(err=>{
      setLoadingGrid(false)
    })
  }


  useEffect(()=>{
    getGridSubscriptions()
  },[company.id])

  const rowClass = (data) => {
    return {
        'disable-row': ![status.upcoming,status.active].includes(data.status)
    };
};



  const columns = [

    {
      header: t(["home.actions"]),
      field: "actions",
      style: { minWidth: "280px", maxWidth: "220px" },
      body: (sub) => (<>
        {
          (sub.status==status.active || sub.status==status.upcoming ) && 
          <div className='d-flex align-items-center'>
          <Link onClick={(e) => { e.preventDefault(); setSubModal({open:true,subscription:sub})}}  className="nav-link p-0 mr-2">
            <FontAwesomeIcon icon={faEdit} className="mr-1" />
            {t("common.edit")}
          </Link>
          <Link onClick={(e) => { e.preventDefault(); setAddonModal({open:true,subscription:sub})}}  className="nav-link text-info p-0 mr-2">
            <FontAwesomeIcon icon={faPlus} className="mr-1" />
            Addon
          </Link>
          {/* <Link onClick={(e) => { e.preventDefault(); handleCancel(sub); }}  className="nav-link p-0 mr-2 text-danger">
            <FontAwesomeIcon icon={faCancel} className="mr-1" />
            {t("common.cancel")}
          </Link> */}
        </div>
        }
      </>
      ),
      persisted:true,
      hidden:user?.role?.id!==1
    },
    {
      header: "Status",
      field: "status",
      style: { minWidth: "90px", maxWidth: "90px" },
      body: (sub) => (
        <>
          <div className='d-flex align-items-center'>
            {sub.status == status.active && 
              <div className="badge badge-success">{t("company.active")}</div>
            }
            {sub.status == status.canceled && 
              <div className="badge badge-danger">{t("common.cancelled")}</div>
            }
            {sub.status == status.upcoming && 
              <div className="badge badge-info">Upcoming</div>
            }
            {sub.status !=status.canceled && moment(sub.endDate).isBefore(new Date())&&
              <div className="badge badge-warning">Expired</div>
            }
          </div>
        </>
      ),
      persisted:true,
    },
        {
      header: t("purchases.warnings"),
      field: "expiresThisMonth",
      style: { minWidth: "220px", maxWidth: "220px" },
        body: (row) => (
        row?.expiresThisMonth
          ? <div style={{ backgroundColor: '#ffc107', color: '#664d03', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            {t(["company.expiresWarning"])}
          </div>
          : null
      ),
    },
    {
      header: t(["subscription.packName"]),
      field: "name",
      body: (sub) => sub?.subscriptionName,
      style: { minWidth: "160px", maxWidth: "180px" },
      persisted:true,
    },
    {
      header: t(["invoice.sDate"]),
      field: "startDate",
      style: { minWidth: "220px", maxWidth: "220px" },
      body: (subs) =>
      subs.startDate
          ? moment(subs.startDate).format("DD/MM/YYYY HH:mm")
          : "",
      persisted:true,
      
    },
    {
      header: t(["invoice.endDate"]),
      field: "endDate",
      style: { minWidth: "220px", maxWidth: "220px" },
      body: (subs) =>
      subs.endDate
          ? moment(subs.endDate).format("DD/MM/YYYY HH:mm")
          : "",
      persisted:true,
      
    },
    {
      header: t("subscription.subPeriod"),
      field: "periodSub",
      style: { minWidth: "180px", maxWidth: "200px" },
      body: (sub) => sub?.period + " " + "months",
      default:true,
      persisted:true,
    },
  
    {
      header:t("subscription.totalInvoices"),
      field: "sentInvoices",
      body: (sub) =>{
        const sentInvoices=sub?.sentInvoices
        return <>                       
      {(sentInvoices!=null ?sentInvoices:"-" )+" /"}  {sub?.subscription?.id===5? <span>{'\u221E'}</span>:sub?.availableInvoices}
      </>
      },
      style: { minWidth: "150px", maxWidth: "150px" },
      default:true
    },
    
    {
      header:t("subscription.totalUsers"),
      field: "totalNoUsers",
      body: (sub) =><>{sub?.noUsersTot }</>,
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header:t("subscription.totalTcrs"),
      field: "totalTcrs",
      body: (sub) =><>{sub?.noTcrsTot }</>,
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    // {
    //   header: t(["subscription.packageUsers"]),
    //   field: "totalUsers",
    //   body: (sub) => sub?.totalUsers,
    //   style: { minWidth: "170px", maxWidth: "170px" },
    // },
    // {
    //   header: t("subscription.packageTcrs"),
    //   field: "noTcrs",
    //   body: (sub) => sub?.subscription?.noTcrs,
    //   style: { minWidth: "150px", maxWidth: "150px" },
    // },
    // {
    //   header: t("subscription.cancelationDate"),
    //   field: "cancellationDate",
    //   style: { minWidth: "150px", maxWidth: "150px" },
    //   body: (row) =>row?.cancellationDate?moment(row?.cancellationDate).format("DD/MM/YYYY"):"",
    //   default:true
    // },
    {
      header:t("item.notes"),
      field: "notes",
      body: (sub) =>sub?.notes ,
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header:t("table.recordUser"),
      field: "recordUser",
      body: (sub) => sub?.recordUser,
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header: t("table.recordDate"),
      field: "recordDate",
      body: (sub) =>sub?.recordDate?moment(sub?.recordDate).format("DD/MM/YYYY"):"",
      style: { minWidth: "150px", maxWidth: "150px" },
      default:true
    },
    {
      header: t("table.cancellationUser"),
      field: "cancellationUser",
      style: { minWidth: "150px", maxWidth: "150px" },
      default:true
    },
  ];
  
  const [selectedColumns, setSelectedColumns] = usePersistedState(
    columns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})})),
    "subscriptions2"
);



  const addSubscription = (
    <div className="page-actions mr-3">
      <Link onClick={(e) => { e.preventDefault(); setSubModal({open:true,subscription:null})}}  className="btn btn-sm btn-outline-primary">
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        {t("subscription.addSubscription")}
      </Link>
    </div>
  )


  const handleCancel = (sub) => {
    swalAlert({ title: t("notification.cancelSubscription"), t })
      .then((res) => {
        if (res) {
          cancelSubscription(sub?.id);
        } else {
   
          console.log('Cancellation canceled.');
        }
      });
  };
  const handleCancelAddon = (addOn) => {
    swalAlert({ title: t("notification.cancelAddon"), t })
      .then((res) => {
        if (res) {
          cancelAddon(addOn?.id);
        } else {
          console.log('Cancellation canceled.');
        }
      });
  };



  const expansionColumns=[
    {
      header:"",
      field:"",
      style: { minWidth: "100px", maxWidth: "100px" },
    },
    {
      header: t(["home.actions"]),
      field: "actions",
      style: { minWidth: "100px", maxWidth: "100px" },
      body: ({sub,...addOn}) => {
        const active = addOnActive({sub,addOn})
        if(active){
          return <>
            <div className='d-flex align-items-center'>
            <Link onClick={(e) => { e.preventDefault(); handleCancelAddon(addOn)}}  
            className="nav-link p-0 mr-2 text-danger">
              <FontAwesomeIcon icon={faCancel} className="mr-1" />
              {t("common.cancel")}
            </Link>
          </div>
        </>
        }
        return <></>
      },
      // hidden:user?.role?.id!==1
    },
    {
      header:"Status",
      field: "statusId",
      style: { minWidth: "90px", maxWidth: "90px" },
      body: ({sub,...addOn}) => {
        const active = addOnActive({sub,addOn})
        if(active){
          return (
            <div className="badge badge-success">{t("company.active")}</div>
          )
        }
        return <></>
      },    },
    {
      header:t("subscription.addonName"),
      field: "name",
      style: { minWidth: "220px", maxWidth: "220px" },
      body: (row) =>row?.addonsSubscriptionName ?? "",
    },
    {
      header: t("item.quantity"),
      field: "unit",
      style: { minWidth: "100px", maxWidth: "100px" },
      body: (row) =>row?.unit
    },
    // {
    //   header: t("table.recordUser"),
    //   field: "companySubscriptionAddonsRecordUser",
    //   style: { minWidth: "150px", maxWidth: "150px" },
    //   body: (row) =>row?.companySubscriptionAddonsRecordUser
    // },
    // {
    //   header: t("table.recordDate"),
    //   field: "companySubscriptionAddonsRecordDate",
    //   style: { minWidth: "150px", maxWidth: "150px" },
    //   body: (row) =>row?.companySubscriptionAddonsRecordDate?moment(row?.companySubscriptionAddonsRecordDate).format("DD/MM/YYYY"):""
    // },
    {
      header: t("table.cancellationUser"),
      field: "cancellationUser",
      style: { minWidth: "150px", maxWidth: "150px" },
      default:true
    },
    {
      header: t("subscription.cancelationDate"),
      field: "cancellationDate",
      style: { minWidth: "150px", maxWidth: "150px" },
      body: (row) =>row?.cancellationDate?moment(row?.cancellationDate).format("DD/MM/YYYY"):""
    },
    // {
    //   header: t("purchases.warnings"),
    //   field: "expiresThisMonth",
    //   style: { minWidth: "220px", maxWidth: "220px" },
    //     body: (row) => (
    //     row?.expiresThisMonth
    //       ? <div style={{ backgroundColor: '#ffc107', color: '#664d03', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center' }}>
    //         <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
    //         {t(["company.expiresWarning"])}
    //       </div>
    //       : null
    //   ),
    // },
    {
      header: t("item.notes"),
      field: "notes",
      style: { minWidth: "220px", maxWidth: "220px" },
    },
  ]


  const expansionRowClass=(sub)=>{
    console.log({expand:sub})
    return {
      'disable-row':  [status.active,status.upcoming].includes(sub.status) 
  }
}

  const columnsFiltered=useMemo(()=>{
    return columns.filter(col=>col.hidden!==true)
  },[user?.role])

  const expansionColumnsFiltered=useMemo(()=>{
    return expansionColumns.filter(col=>col.hidden!==true)
  },[user?.role])

  const rowExpansionTemplate = (data) => {
    if (loadingExpandData) return (
      <div className="d-flex justify-content-start" style={{width:"100vw"}}>
        <Loader color="white"></Loader>;
      </div>
    )
    return (
      <div className="prime-header-sticky" style={{ width: "80vw", }}>
        <h3 className='my-2' style={{marginLeft:110,textDecoration:"underline"}}>Add-Ons</h3>
        <PrimeTable
            stripedRows={false}
            reorderableColumns={true}
            columns={expansionColumnsFiltered}
            // filterDisplay={"row"}
            responsiveLayout="scroll"
            showHeader={false}
            dataKey="id"
            rowClassName={(addOn)=>expansionRowClass(addOn)}
            simpleTable={true}
            sortField={"active"}
            sortOrder={-1}
            className="white-header"
            // clearFilters={false}
            // showSelectColumns={false}     
            value={expandedData ?? []}
            columnResizeMode="expand"
            scrollable={true}
            scrollHeight="350px"

        />
      </div>
    )
  }

  const onRowExpand =async (event) => {
    setLoadingExpandData(true);
    setExpandedRows([event.data]);
    console.log({test:event.data})
    const content=await getCompanySubscriptionAddons(event.data.id.toString())
    setLoadingExpandData(false);
    setExpandedData(content ?? []);
  };

  const onRowCollapse = (event) => {
    setExpandedRows([]);
  };

  return (
    <div className='overflow-y-scroll'>
    <Dialog
        header={subModal.subscription?t("subscription.edit"):t("subscription.addSubscription")}
        visible={subModal.open}
        onHide={() => setSubModal(false)}
        style={{ width: "25vw",minHeight:"55%", overflow: "auto" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}

      >
        {subModal?.open && <AddEditSubscription
          subscription={subModal.subscription}
          company={company}
          onSubscriptionChange={() => {  setSubModal(false);getGridSubscriptions()}}
        />}
      </Dialog>

    <Dialog
        header={t("subscription.addons")}
        visible={addonModal?.open}
        onHide={() => setAddonModal({open:false,subscription:null})}
        style={{ width: "25vw",minHeight:"55%", overflow: "auto" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}

      >
        {addonModal?.open && <Addons
          subscriptionsHistory={subscriptions}
          subscription={addonModal?.subscription}
          company={company}
          onSubscriptionChange={() => {  setAddonModal({open:false,subscription:null});getGridSubscriptions()}}
        />}
      </Dialog>

      <div className="border-0">
        <div className="card-body">
        <PrimeTable
          // globalFilterValue={[searchQuery, setSearchQuery]}
          stripedRows={false}
          rowClassName={rowClass}
          rowExpansionTemplate={rowExpansionTemplate}
          tableRows={[tableRows, setTableRows]}
          value={subscriptions ?? []}
          columns={columnsFiltered}
          showFilters={false}
          loading={loadingGrid}
          filterDisplay={"row"}
          responsiveLayout="scroll"
          scrollHeight="580px"
          // filters={[filters, setFilters]}
          defaultFilters={defaultFilters}
          showHeader={true}
          simpleTable={true}
          sortField={"active"}
          sortOrder={-1}
          // showGlobalSearch={false}
          dataKey="id"
          headerButtonsRight= {addSubscription}
          // showSelectColumns= {false}
          clearFilters= {false}
          className= "white-header"
          selectedColumns={[selectedColumns, setSelectedColumns]}
          expandedRows={expandedRows}
          onRowExpand={onRowExpand}
          onRowCollapse={onRowCollapse}
          onColReorder={(reorder) => onColReorder({ reorder, setSelectedColumns, childrenCount: 1 })}
          onRemoteDataChange={(remoteData) => {
            const updatedFilters = remoteData.filters ?? filters
            const updatedTableData = remoteData.tableRows ?? tableRows
            let { currentPage, rowsPerPage, sortCriteria } = updatedTableData
            sortCriteria=sortCriteria?[sortCriteria]:[]
            getGridSubscriptions({ data: updatedFilters, pageSize: rowsPerPage, page: currentPage, sortCriteria })
          }}
      >
                <Column 
            expander={() => true}
            style={{ width: "0.5em" }}
            header=""
            field="expansion"
            frozen={true}
          />
        </PrimeTable>
        </div>
      </div>
    </div>
  );





};

export default Subscriptions;

