import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../AppContext';

const AlertSubscription = () => {
const { t ,i18n} = useTranslation("translations");
const language=i18n.language;

const {
  user:[user],
  selectedCompany: [selectedCompany],
  company:[fullCompany]
} = useAppContext();

if(!fullCompany?.expirationDate) return <></>
const today=moment()
const daysDifference=  moment(fullCompany.expirationDate).diff(today, "days")

  return (
    (daysDifference <= 10) && <div className="alert alert-danger mb-0 px-3 py-2 ml-3" role="alert">
    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
      {daysDifference<0 ? t("common.subscriptionExpired") : t("common.subscriptionExpires")} {moment(fullCompany.expirationDate).format("DD/MM/YYYY")}
    <button type="button" className="close ml-2 text-center" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  )
}

export default AlertSubscription