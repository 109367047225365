import React from 'react'
import { useTranslation } from 'react-i18next';
import PurchaseAttachment from '../Invoice/columns/PurchaseAttachment';
import { useAppContext } from "../../AppContext";

const PurchaseComments = ({invoice,handleChange,purchaseAttachments, setPurchaseAttachments}) => {
  const { t, i18n } = useTranslation("translations");
  const {
    user: [user],
    selectedCompany
  } = useAppContext();
  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  const branch =
  company.branches.find((branch) => branch.default) ||
  company.branches[0];
//console.log({branch})
const businessUnitCode =
  branch?.tcrTypes.filter((tcrType) => !!tcrType.tcrCode)[0]?.businUnitCode ||
  "";
  return (
    <div className="row mt-4">
      <div className="col-md-12 d-flex">
        <div className="border-0 mb-4 p-0 w-100">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 d-flex">
                <div className=" mb-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="supplierInfo">{t("logs.user")}</label>
                        <input
                          type="text"
                          name="user"
                          id="user"
                          className="form-control"
                          value={user?.username ?? ""}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="businessUnit">
                          {t("invoice.businessUnit")}
                        </label>
                        <input
                          type="text"
                          name="businessUnit"
                          id="businessUnit"
                          className="form-control"
                          value={ businessUnitCode ?? ""}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="card-body w-100">
                      <div className=" font-weight-bold d-flex align-items-center input-length-validation">
                        <textarea
                          style={{paddingRight:"75px"}}
                          placeholder={t('invoice.comment')}
                          className="form-control"
                          value={invoice?.comment ?? ""}
                          id="comment"
                          rows="3"
                          onChange={(e)=>handleChange("","comment",e.target.value,300)}
                          disabled={invoice?.conditions?.allowComment == false }
                        />
                         <div style={{marginRight:15}} 
                         className={`length-text ${invoice?.comment?.length == 300 ? "text-danger" : ""}`}>
                           {invoice?.comment?.length ?? "0" }/300</div>
                      </div>
                    </div>
                    <PurchaseAttachment purchaseAttachments={purchaseAttachments} setPurchaseAttachments={setPurchaseAttachments} disabled={invoice?.conditions?.allowAttachment==false}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseComments
