import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ExportCsvPrime from "../global/ExportCsvPrime";
import func from "../../services/functions";
import { useAppContext, usePersistedState } from "../../AppContext";
import Select from "react-select";
import selectStyle from "../../design/selectStyle";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";
import PrimeTable, { onColReorder } from "../PrimeTable/PrimeTable";
import {
  faArrowUpRightFromSquare,
  faQrcode,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import mainAxios from "../../services/mainAxios";
import { Column } from "primereact/column";
import CellInput from "../PrimeTable/CellInput";
import MyTooltip from "../global/hooks/MyTooltip";
import Loader from "../Layout/Loader";
import swal from "@sweetalert/with-react";
import { Dropdown } from "primereact/dropdown";
import {printStatuses} from "../../services/printInvoiceOnError";
import TaxCategorizationPurchase from "./TaxCategorizationPurchase";
import { Checkbox } from 'primereact/checkbox';
import { paymentOptions } from "../../services/data";
import CisMessage from "../global/CisMessage";



const PurchasesBook = () => {
  const { t, i18n } = useTranslation("translations");
  const periodRef = useRef();
  const [loading, setLoading] = useState(false);
  const [loadingExpandData, setLoadingExpandData] = useState(false);
  const [data, setData] = useState([]);
  const [csvData,setCsvData]=useState([])
  const [expandedData, setExpandedData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [stats, setStats] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [statistics, setStatistics] = useState([]);
  const [period,setPeriod]=usePersistedState(new Date().setHours(2, 0, 0, 0),"purchaseBookPeriod")
  const [pdfToDisplay, setPdfToDisplay] = React.useState(null);
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const pdfContainer = React.useRef();
  const [currentInvoiceIndex, setCurrentInvoiceIndex] = React.useState(null);
  const firstUpdate = React.useRef(true);
  const [taxCategorization, setTaxCategorization] = useState(null);
  const [checked, setChecked] = useState(false)
  const {unitsOfMeasure}=useAppContext()

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    printThermalInvoice();
  }, [currentInvoiceIndex]);

  const tempFilters = [
    {
      key: "none",
      label: t("books.showAll"),
      value: null,
      showCount: false,
    },
    {
      key: "isExemptedFromVat",
      label: t("books.zero%exempted"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.exemptedInvoicesNo,
      count2: statistics?.exemptedInvoicesWithoutVat,
      count3: statistics?.exemptedInvoicesVatAmount,
    },
    {
      key: "isReverseCharge",
      label: t("books.isReverseChargePurchases"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.reverseChargeInvoicesNo,
      count2: statistics?.reverseChargePurchaseWithoutVat,
      count3: statistics?.reverseChargePurchaseVatAmount,
    },
    // {
    //   key: "isZeroVatRated",
    //   label: t("books.isZeroVatRated"),
    //   value: "true",
    //   // count: statistics?.einvoicesNo,
    //   count1: statistics?.zeroVatInvoicesNo,
    //   count2: statistics?.zeroVatPurchaseWithoutVat,
    //   count3: statistics?.zeroVatPurchaseVatAmount,
    // },
    {
      key: "isStandardPurchase",
      label: t("books.isStandartPurchase"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.standardPurchaseInvoicesNo,
      count2: statistics?.standardPurchaseWithoutVat,
      count3: statistics?.standardPurchaseVatAmount,
    },
    {
      key: "isStandardImport",
      label: t("books.isStandardImport"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.standardImportNo,
      count2: statistics?.standardImportWithoutVat,
      count3: statistics?.standardImportVatAmount,
      count4: statistics?.standardImportWithVat,
    },
    {
      key: "isBadDebt",
      label: t("books.badDebt"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.badDebtInvoicesNo,
      count2: statistics?.badDebtPurchaseWithoutVat,
      count3: statistics?.badDebtPurchaseVatAmount,
    },
    {
      key: "isImportExempted",
      label: t("books.importExempted"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.exemptedImportNo,
      count2: statistics?.exemptedImportWithoutVat,
      count3: statistics?.exemptedImportVatAmount,
      count4: statistics?.exemptedImportWithVat,
    },
  ];


  const {
    user: [user],
    company: [fullCompany],
    selectedCompany:[selectedCompany]
  } = useAppContext();

  const defaultSortCriteria={
    field: "issueDate",
    direction: "DESC"
  }
  
  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
    sortCriteria:defaultSortCriteria
  });
  const branches = fullCompany?.branches ?? [];
  const businessUnitCodes = React.useMemo(() => {
    return [
      {
        label: t("invoice.businessUnitCode"),
        value: null,
      },
    ].concat(
        fullCompany?.branches?.map((branch) => {
          return {
            ...branch,
            label: branch.businUnitCode + " | " + branch.sellerName,
            value: branch.businUnitCode,
          };
        }) ?? []
    );
  }, [branches]);

  const [persistedData, setPersistedData] = usePersistedState(
      null,
      "purchaseBookData"
  );

  const filterParams = {
    // isEinvoice: null,
    // correctiveInvoiceType: null,
    // ublInvoiceTypeCode: null,
    // isForeign: null,
    // fiscStatus: [],
    // fiscInvoiceType: null,
    // ublProfileId: [],
    // isOldInvoice: null,
    // isDifferentPeriod: null,
    // // period: new Date().setHours(2, 0, 0, 0),
    isType1Exempted: null,
    // isType2Exempted: null,
    // isExportOfGoodsExempted: null,
    // // isMarginSchemeExempted: null,
    // // isTaxFreeExempted: null,
    isReverseCharge: null,
    isZeroVatRated: null,
    // // isTwentyPercentVatRated: null,
    // // isTenPercentVatRated: null,
    // // isSixPercentVatRated: null,
    isBadDebt: null,
  };

  const defaultFilters = {
    params: {
      ...filterParams,
    },
  };
  const [filters, setFilters] = useState(defaultFilters);

  const businessUnitSelected = businessUnitCodes.some(
      (branch) => branch?.value === persistedData?.businessUnitCode
  )
      ? persistedData?.businessUnitCode
      : null;

  const [sessionData, setSessionData] = useState({
    businessUnitCode: businessUnitSelected,
  });


  const handleStatistics = () => {
    const params = {
      period: moment(period).format("M/yyyy") ,
    };

    if (sessionData?.businessUnitCode) {
      params.businessUnitCode = sessionData?.businessUnitCode;
    }
    mainAxios
        .post("endpoint/v2/purchase/statistics", {
          object: "GetPurchaseBookStatistics",
          params,
        })
        .then((res) => {
          if (res?.data?.status && res?.data?.content?.[0]) {
            setStatistics(res?.data?.content[0]);
          }else {
            setStatistics(null)
          }
        })
  };

  useEffect(() => {
    handleStatistics();
  }, [sessionData.businessUnitCode,period, fullCompany]);

  useEffect(() => {
    getFilteredResults({
      data:{ ...defaultFilters, params: { ...defaultFilters.params } },
      page:1,
      pageSize:10
    });
  }, [period, refreshData, sessionData.businessUnitCode,taxCategorization,fullCompany])

  const vatOptions = [
    {
      key: "isDomesticpurchaseWithTwentyPercentVat",
      label: t("books.vat") + "  20%",
      value: "true",
      count: statistics?.domesticPurchaseTwentyPercentVatInvoicesNo,
      //   count: 27,
    },
    {
      key: "isDomesticpurchaseWithTenPercentVat",
      label: t("books.vat") + "  10%",
      value: "true",
      count: statistics?.domesticPurchaseTenPercentVatInvoicesNo,
      //   count: 100,
    },
    {
      key: "isDomesticpurchaseWithSixPercentVat",
      label: t("books.vat") + "  6%",
      value: "true",
      count: statistics?.domesticPurchaseSixPercentVatInvoicesNo,
      //   count: 100,
    },
  ];

  const typeOptions=[
    {
        key: "isOnlyFisc",
        count: statistics?.onlyFisc,
        label: t("parameters.onlyFiscalization"),
        value: "true",
    },
    {
        key: "isCustom",
        count: statistics?.customsNo,
        label: "Deklarate Doganore",
        value: "true",
    },
    {
        key: "isEinvoice",
        count: statistics?.einvoiceNo,
        label: "E-Invoice",
        value: "true",
    },
]

  const sourceOptions = [
    {
      key: "cisStatus",
      count: statistics?.deliveredInvoicesNo,
      // multiSelect: true,
      label: func.capitalizeFirstLetter(t("status.DELIVERED")),
      value: "DELIVERED",
    },
    {
      key: "cisStatus",
      count: statistics?.acceptedInvoicesNo,
      // multiSelect: true,
      label: t("queue.accepted"),
      value: "ACCEPTED",
    },
    {
      key: "cisStatus",
      // multiSelect: true,
      count: statistics?.refusedInvoicesNo,
      label: t("purchases.rejected"),
      value: "REFUSED",
    },

    {
      key: "cisStatus",
      // multiSelect: true,
      count: statistics?.partiallyPaidInvoicesNo,
      label: func.capitalizeFirstLetter(t("status.PARTIALLY_PAID")),
      value: "PARTIALLY_PAID",
    },
    {
      key: "cisStatus",
      count: statistics?.paidInvoicesNo,
      // multiSelect: true,
      label: func.capitalizeFirstLetter(t("status.PAID")),
      value: "PAID",
    },
  ];

  const currencyOptions = [
    {
      key: "currency",
      count: statistics?.allCurrencyInvoicesNo,
      // multiSelect: true,
      label: "Lek",
      value: "ALL",
    },
    {
      key: "currency",
      // multiSelect: true,
      count: statistics?.usdCurrencyInvoicesNo,
      label: "USD",
      value: "USD",
    },
    {
      key: "currency",
      count: statistics?.eurCurrencyInvoicesNo,
      // multiSelect: true,
      label: "Euro",
      value: "EUR",
    },
  ];

  const allOtherOptions = [
    {
      key: "isPurchaseFromFarmers",
      // multiSelect: false,
      count: statistics?.purchaseFromFarmersInvoicesNo,
      //   count: 2,
      label: t("books.purchaseFromFarmers"),
      value: "true",
    },
    {
      key: "isReverseCharge",
      // multiSelect: false,
      // count:
      count: statistics?.reverseChargeInvoicesNo,
      label: t("books.isReverseCharge"),
      value: "true",
    },
    {
      key: "isBadDebt",
      count: statistics?.badDebtInvoicesNo,
      label: t("books.badDebt"),
      value: "true",
      // multiSelect: false,

    },
    {
      key: "isExemptedFromVat",
      label: t("books.exludePurchases"),
      value: "true",
      count: statistics?.exemptedInvoicesNo,
      // multiSelect: false,

    },
    {
      key: "invoiceType",
      label: t("invoice.selfInvoice"),
      value: "Autofature",
      count: statistics?.selfInvoicesNo,
      // multiSelect: false,
    },
    // {
    //   key: "filterByPeriodDate",
    //   label: t("invoice.filterByPeriodDate"),
    //   value: "true",
    //   className:"extend-cell",
    //   showCount:false,
    //   excludeIfNull:true,
    //   userRoles:[1]
    // }
  ];

  const handleParamsChange = (e, label, val) => {
    const key = e ? e.target.name : label;
    const value = e ? e.target.value : val;
    // const uiOption = uIOptions?.find((op) => op.value===key)
    // if (uiOption) {
    //     setUIType({
    //         ...uiOption,
    //         uidValue: value,
    //         value: key,
    //     });
    // } else {
    setFilters((filters) => ({
      ...filters,
      params: {
        ...filters.params,
        [key]: value,
      },
    }));
    // }
  };

  const showInvoicePdf = (invoice) => {
    setLoadingPdf(true);
    if (invoice.invoiceType === "Autofature") {
      mainAxios
          .post("apiEndpoint/search", {
            object: "GetFiscPDF",
            params: {
              iic: invoice.iic,
            },
          })
          .then((res) => {
            const blob = func.blobToFile(
                func.b64toBlob(res.data.content[0], "application/pdf"),
                "Custom Invoice"
            );
            setPdfToDisplay(URL.createObjectURL(blob));
            setTimeout(() => {
              pdfContainer.current.classList.toggle("open");
              setLoadingPdf(false);
            }, 0);
          });
    } else {
      mainAxios
          .post("endpoint/v2/getAtachementByte", {value:invoice.attachmentId})
          .then((res) => {
            const blob = func.blobToFile(
                func.b64toBlob(res.data.content[0], "application/pdf"),
                "Custom Invoice"
            );
            setPdfToDisplay(URL.createObjectURL(blob));
            setTimeout(() => {
              pdfContainer.current.classList.toggle("open");
              setLoadingPdf(false);
            }, 0);
          });
    }
  };

  const printThermalInvoice = () => {
    const content = document.getElementById("thermalPrinterInvoice");
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(
        `<html><style>@page{margin:0mm auto;margin-bottom:10mm;font-family: 'EB Garamond', serif;}</style>${content.innerHTML}</html>`
    );
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const handleThermalPrint = (id) => {
    let index = data.findIndex((invoice) => invoice.saleInvoiceId === id);
    if (index === currentInvoiceIndex) {
      printThermalInvoice();
    } else {
      setCurrentInvoiceIndex(
          data.findIndex((invoice) => invoice.saleInvoiceId === id)
      );
    }
  };


  const invoiceTypeOptions=[
    {
      value:"Normale",
      label:"Normale",
    },
    {
      value:"Autofature",
      label:"Autofature",
    },
    {
      value:"Korrigjuese",
      label:"Korrigjuese",
    },
  ]




  const columns = useMemo(() => {
    // const restCols =
    //   Object.keys(data?.[0] ?? {})?.filter(
    //     (key) => !defaultCols.includes(key)
    //   ) ?? [];

    return [
      {
        field: "fiscInvoiceNumber",
        header: t("books.fiscInvNo"),
        sortable: true,
        style: { minWidth: "220px", maxWidth: "250px" },
        format: (invoice)=>func?.preventExcelFormatFn(invoice?.fiscInvoiceNumber || ""),
        filter:true,
        like:true,
        persisted: true,
        preventExcelFormat:true,
      },
      {
        field: "fic",
        header: t("invoice.FIC"),
        // body: (row) => func.getFormattedMoneyValue(row?.cashInit ?? 0),
        // style: { textAlign: "right" }
        style: { minWidth: "200px", maxWidth: "200px" },
        format:(row)=>row.fic || "",
        body: (row) => <CellInput value={row?.fic ?? ""} width={150} />,
        filter: true,
        like: true,
        persisted: true
      },
      {
        field: "qrUrl",
        header: t("home.actions"),
        // style:{width:"15rem"},
        //   body: (row) => PrintActions({ row, type: "tcr" }),
        persisted: true,
        body: (url) => {
          return (
              <a
                  href={`${url.qrUrl}`}
                  target="_blank"
                  className="nav-link p-0 d-flex align-items-center"
              >
                <FontAwesomeIcon size="lg" className="mr-1" icon={faQrcode} />
                {"QR Url"}
              </a>
          );
        },
        style: { minWidth: "110px", maxWidth: "110px" },
      },
      {
        field: "print",
        header: t(["invoice.print"]),
        persisted: true,
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (invoice) => {
          const src=!invoice.einvoice ?"https://profisc.al/wp-content/uploads/2021/10/Asset-11.svg":"https://profisc.al/wp-content/uploads/2021/10/Asset-12.svg"
            return <div className="row">
              <>

                    <a
                        href="#"
                        className={
                            "nav-link p-0 d-flex-align-items-center" +
                            (loadingPdf ? " disabled" : "")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          showInvoicePdf(invoice);
                        }}
                    >
                      <img
                          src={src}
                          width="38"
                          height="32"
                          className="mr-1"
                      />
                    </a>
              </>
            </div>
        }
      },
      {
        field: "iic",
        header: t("invoice.IIC"),
        style: { minWidth: "200px", maxWidth: "200px" },
        format:(row)=>row.iic || "",
        body: (row) => <CellInput value={row?.iic ?? ""} width={150} />,
        filter: true,
        like: true,
        persisted: true
      },
      {
        field: "eic",
        header: "EIC",
        // style: { textAlign: "right" }
        style: { minWidth: "200px", maxWidth: "200px" },
        sortable: true,
        format:(row)=>row.eic || "",
        body: (row) => <CellInput value={row?.eic ?? ""} width={150} />,
        filter: true,
        like: true
      },
      {
        field: "issueDate",
        header: t("invoice.issueDate"),
        formattedExportValue:true,
        body: (row) => moment(row?.issueDate).format("DD/MM/YYYY"),
        // style: { textAlign: "right" }
        style: { minWidth: "250px", maxWidth: "250px" },
        sortable: true,
        persisted: true,
        preventExcelFormat: true,
      },
      {
        field: "invoiceType",
        header: t("customers.type"),
        style: { minWidth: "160px", maxWidth: "160px" },
        filter: true,
        filterType: "DROPDOWN",
        filterConfig: {
          options: invoiceTypeOptions,
          optionValue: "value",
          optionLabel: "label",
        },
        persisted: true
      },
      {
        field: "sellerName",
        header: t("company.sellerName"),
        style: { minWidth: "250px", maxWidth: "250px" },
        filter: true,
        like: true,
        persisted: true
      },
      {
        field: "sellerNuis",
        header: t("books.sellerNuis"),
        style: { minWidth: "250px", maxWidth: "250px" },
        filter: true,
        like: true,
        persisted: true
      },
      {
        field: "sellerCountry",
        header: t("company.sellerCountry"),
        style: { minWidth: "160px", maxWidth: "160px" },
        persisted: true
      },
      {
        field: "totalWithVat",
        header: t("books.totalPurchases"),
        style: { minWidth: "130px", maxWidth: "130px" },
        body: (row) => func.getFormattedMoneyValue(row?.totalWithVat ?? 0),
        align:"right",
        persisted: true
      },
      {
        field: "cisStatus",
        header: "Cis Status",
        style: { minWidth: "150px", maxWidth: "150px" },
        like: true,
        align:"center"
      },
      {
        field: "currency",
        header: t("invoice.currency"),
        style: { minWidth: "150px", maxWidth: "150px" },
        like: true,
        align:"center"
      },
      {
        field: "zeroVatAndExemptedPurchaseAmount",
        header: t("books.zeroVatAndExemptedPurchaseAmount"),
        style: { minWidth: "220px", maxWidth: "220px" },
        body: (row) => func.getFormattedMoneyValue(row?.zeroVatAndExemptedPurchaseAmount ?? 0),
        align:"right"
      },
      {
        field: "domesticPurchaseOfInvestmentWithoutVat",
        header: t("books.domesticPurchaseOfInvestmentWithoutVat"),
        style: { minWidth: "220px", maxWidth: "220px" },
        body: (row) => func.getFormattedMoneyValue(row?.domesticPurchaseOfInvestmentWithoutVat ?? 0),
        align:"right"
      },
      {
        field: "purchaseFromDomesticSellersTwentyPercentTaxableAmount",
        header: t("books.purchaseFromDomesticSellersTwentyPercentTaxableAmount") + " "+ "20%",
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.purchaseFromDomesticSellersTwentyPercentTaxableAmount ?? 0),
        align:"right"
      },
      {
        field: "purchaseFromDomesticSellersTwentyPercentTaxAmount",
        header:  t("books.vat") + " "+ "20%",
        style: { minWidth: "100px", maxWidth: "100px" },
        format:(row)=>row?.purchaseFromDomesticSellersTwentyPercentTaxAmount ?? "",
        body: (row) =>
            func.getFormattedMoneyValue(row?.purchaseFromDomesticSellersTwentyPercentTaxAmount ?? 0),
        align:"right"
      },
      {
        field: "purchaseFromDomesticSellersTenPercentTaxableAmount",
        header: t("books.purchaseFromDomesticSellersTwentyPercentTaxableAmount") + " "+ "10%",
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.purchaseFromDomesticSellersTenPercentTaxableAmount ?? 0),
        align:"right"
      },
      {
        field: "purchaseFromDomesticSellersTenPercentTaxAmount",
        header: t("books.vat") + " "+ "10%",
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.purchaseFromDomesticSellersTenPercentTaxAmount ?? 0),
        align:"right"
      },
      {
        field: "purchaseFromDomesticSellersSixPercentTaxableAmount",
        header: t("books.purchaseFromDomesticSellersTwentyPercentTaxableAmount") + " "+ "6%",
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.purchaseFromDomesticSellersSixPercentTaxableAmount ?? 0),
        align:"right"
      },
      {
        field: "purchaseFromDomesticSellersSixPercentTaxAmount",
        header: t("books.vat") + " "+ "6%",
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.purchaseFromDomesticSellersSixPercentTaxAmount ?? 0),
        align:"right"
      },
      {
        field: "purchaseFromDomesticFarmersSixPercentTaxableAmount",
        header: t("books.purchaseFromDomesticFarmersSixPercentTaxableAmount") + " "+ "6%",
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.purchaseFromDomesticFarmersSixPercentTaxableAmount ?? 0),
        align:"right"
      },
      {
        field: "purchaseFromDomesticFarmersSixPercentTaxAmount",
        header: t("books.vat") + " "+ "6%",
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.purchaseFromDomesticFarmersSixPercentTaxAmount ?? 0),
        align:"right"
      },
      {
        field: "domesticPurchaseOfInvestmentTwentyPercentTaxableAmount",
        header: t("books.domesticPurchaseOfInvestmentTwentyPercentTaxableAmount") + " "+ "20%",
        style: { minWidth: "260px", maxWidth: "260px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.domesticPurchaseOfInvestmentTwentyPercentTaxableAmount ?? 0),
        align:"right"
      },
      {
        field: "domesticPurchaseOfInvestmentTwentyPercentTaxAmount",
        header:  t("books.vat") + " "+ "20%",
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.domesticPurchaseOfInvestmentTwentyPercentTaxAmount ?? 0),
        align:"right"
      },
      {
        field: "reverseChargeTaxableAmount",
        header: t("books.autoLoadTax"),
        style: { minWidth: "240px", maxWidth: "240px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.reverseChargeTaxableAmount ?? 0),
        align:"right"
      },
      {
        field: "reverseChargeTaxAmount",
        header: t("books.autoLoad") + " " + t("books.vat"),
        style: { minWidth: "170px", maxWidth: "170px" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.reverseChargeTaxAmount ?? 0),
        align:"right"
      },
      {
        field: "badDebtTaxableAmount",
        header: t("books.badDebtTax"),
        style: { minWidth: "200px", maxWidth: "200px",textAlign:"right" },
        body: (row) =>
            func.getFormattedMoneyValue(row?.bedDebtTaxableAmount ?? 0),
        align:"right"
      },
      {
        field: "badDebtTaxAmount",
        header: t("books.badDebt") + " " + t("books.taxAmount"),
        style: { minWidth: "180px", maxWidth: "180px",textAlign:"right" },
        body: (row) => func.getFormattedMoneyValue(row?.bedDebtTaxAmount ?? 0),
        align:"right"
      },
      {
        field:"paymentType",
        header:"Cash/NonCash",
        style: { minWidth: "150px", maxWidth: "150px" },
        filter:true,
        filterType:"DROPDOWN",
        filterConfig:{
          options: paymentOptions,

        }

      },
      {
        field:"impCustDecNum",
        header:t("books.impCustDecNum"),
        style: { minWidth: "200px", maxWidth: "200px" },
        filter:true
      },
      {
        field:"einvoice",
        header:"E-Invoice",
        style: { minWidth: "120px", maxWidth: "120px" },
        dataType:"boolean",
        filter:true,
        filterType:"TRISTATECHECKBOX",
        filterConfig:{
          field:"isEinvoice"
        }
      },
      {
        field: "exemptedImportGoodsAmount",
        header: t("books.exemptedImportGoodsAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.exemptedImportGoodsAmount ?? 0),
        align:"right",
        filter:true
      },
      {
        field: "twentyPercentImportGoodsTaxableAmount",
        header: t("books.twentyPercentImportGoodsTaxableAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.twentyPercentImportGoodsTaxableAmount ?? 0),
        align:"right",
        filter:true
      },
      {
        field: "twentyPercentImportGoodsTaxAmount",
        header: t("books.twentyPercentImportGoodsTaxAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.twentyPercentImportGoodsTaxAmount ?? 0),
        align:"right",
        filter:true
      },
      {
        field: "tenPercentImportGoodsTaxableAmount",
        header: t("books.tenPercentImportGoodsTaxableAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.tenPercentImportGoodsTaxableAmount ?? 0),
        align:"right",
        filter:true
      },
      {
        field: "tenPercentImportGoodsTaxAmount",
        header: t("books.tenPercentImportGoodsTaxAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.tenPercentImportGoodsTaxAmount ?? 0),
        align:"right",
        filter:true
      },
      {
        field: "sixPercentImportGoodsTaxableAmount",
        header: t("books.sixPercentImportGoodsTaxableAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.sixPercentImportGoodsTaxableAmount ?? 0),
        align:"right",
        filter:true
      },
      {
        field: "sixPercentImportGoodsTaxAmount",
        header: t("books.sixPercentImportGoodsTaxAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.sixPercentImportGoodsTaxAmount ?? 0),
        align:"right",
        filter:true
      },
      {
        field: "investmentExemptedImportGoodsAmount",
        header: t("books.investmentExemptedImportGoodsAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.investmentExemptedImportGoodsAmount ?? 0),
        align:"right",
        filter:true
      },
      {
        field: "investmentTwentyPercentImportGoodsTaxableAmount",
        header: t("books.investmentTwentyPercentImportGoodsTaxableAmount"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => func.getFormattedMoneyValue(row?.investmentTwentyPercentImportGoodsTaxableAmount ?? 0),
        align:"right",
        filter:true
      },
      //   ...restCols.map((key) => ({
      //     header: func.camelToFlat(key),
      //     field: key,
      //   })),
    ];
  });

  const [selectedColumns, setSelectedColumns] = usePersistedState(
    columns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})})),
    "purchaseBooksColumns4"
  );
  

  const getFilteredResults = ({
    data,
    pageSize=10,
    page=1,
    exportCsv=false,
    sortCriteria = [ defaultSortCriteria ]
  }) => {
      // data: updatedFilters, pageSize: rowsPerPage, page: currentPage, sortCriteria
    const queueData = data ?? filters
    let correctiveInvoiceType = null;
    let ublInvoiceTypeCode = null;

    if (queueData?.params?.correctiveInvoiceType) {
      if (queueData.params.correctiveInvoiceType == 380) {
        correctiveInvoiceType = null;
        ublInvoiceTypeCode = queueData.params.correctiveInvoiceType;
      } else {
        correctiveInvoiceType = queueData.params.correctiveInvoiceType;
        ublInvoiceTypeCode = null;
      }
    }
    queueData.params.correctiveInvoiceType = correctiveInvoiceType;
    queueData.params.ublInvoiceTypeCode = ublInvoiceTypeCode;
    // if (uIType?.uidValue && !clearFilters) {
    //
    //   if(uIType?.like==true){
    //     let val = "<L>" + uIType.uidValue + "<L>";
    //     queueData.params[uIType.value] = val;
    //   }else{
    //     if (uIType.type === "date") {
    //       queueData.params[uIType.value] = uIType?.uidValue ?moment(uIType.uidValue).format("M/yyyy"):""
    //     } else {
    //       queueData.params[uIType.value] = uIType.uidValue
    //     }
    //   }
    // }
    // if (csvData.length > 0) setCsvData([]);
    // let fiscStatus = queueData.params.fiscStatus;

    // let invStatCorrectedAndSuccessful = [null, "-100"].every((status) => {
    //   return fiscStatus.includes(status);
    // });

    // if (invStatCorrectedAndSuccessful) {
    //   fiscStatus = "-100";
    // } else {
    //   fiscStatus = fiscStatus.join(",") || null;
    // }

    let requestBody = {
      object: "GetPurchaseBook",
      ...queueData,
      params: {
        ...queueData.params,
        // ublProfileId: queueData.params?.ublProfileId.join(",") || null,
        period: moment(period).format("M/yyyy"),
        // fiscStatus,
        ...(taxCategorization?.key && taxCategorization?.value ? { [taxCategorization?.key]: taxCategorization?.value }:{}),
        // buCodes: (sessionData?.businessUnitCode !== "" && sessionData?.businessUnitCode !== null) ? sessionData?.businessUnitCode : businessUnitCodes.filter(x=>x.value != null).map(x => x.value).join(",")
        // businessUnitCode: sessionData?.businessUnitCode
      },
      pagination: exportCsv?null: {
        pageSize: pageSize,
        pageNumber: page,
      },
      sortCriteria
    };

    return mainAxios
        .post(`apiEndpoint/search`, requestBody)
        .then((res) => {
          setLoading(false);
          if (res?.data?.status === true) {
            let content = res.data.content;
            if(exportCsv){
              setCsvData(content)
            }else{
              setData(content);
              setTableRows((state) => ({
                ...state,
                totalRows: res?.data?.totalSize,
              }));
            }
            return true;
          } else {
            setData([]);
          }
        })
        .catch((err) => {
          setLoading(false);
          return false;
        });
  };

  const filteredColumns = useMemo(() => {
    let _filteredColumns = [];
    selectedColumns.forEach((persistedCol) => {
        const column = columns.find((col) => col.field === persistedCol.field);
        if (column && (persistedCol.show || column.persisted)) _filteredColumns.push(column);
    });
    return _filteredColumns;
  }, [selectedColumns, data, i18n.language, csvData]);

  const onRowExpand = (event) => {
    setLoadingExpandData(true);
    setExpandedRows([event.data]);
    mainAxios
        .post("apiEndpoint/search", {
          object: "GetPurchaseInvoiceItems",
          params: {
            invoiceId: event?.data?.invoiceId?.toString(),
          },
        })
        .then((res) => {
          if (res) {
            setExpandedData(res?.data?.content);
            setLoadingExpandData(false);
          }
        });
  };

  const onRowCollapse = (event) => {
    setExpandedRows([]);
  };

  const handleRefreshData = () => {
    const dateForRefresh = Date.now();

    setLoading(true);

    mainAxios
        .post("apiEndpoint/call", {
          object: "RefreshSaleBookMv",
          fromDate: dateForRefresh,
          toDate: dateForRefresh,
        })
        .then((res) => {
          if (res.data.status === true) {
            setRefreshData(!refreshData);
            getFilteredResults({});
          }
        });
  };

  const unitofMeasureBody = useCallback((row)=>{const uom = unitsOfMeasure.find(uom=>uom.englishName===row.unitOfMeasure) ; if(uom){ return i18n.language == "alb" ?   uom?.albanianName: uom?.englishName} return row?.unitOfMeasure ?? ""},[unitsOfMeasure,i18n])

    const expandedColumns = [
      {
        field: "",
        header: "",
        style: { minWidth: 40,maxWidth:40 },
      },
      {
        field: "itemCode",
        header: t("books.itemCode"),
        style: { minWidth: 170,maxWidth:170 },
        sortable: true,
      },
      {
        field: "itemName",
        header: t("books.itemName"),
        style: { minWidth: 170,maxWidth:170 },
        sortable: true,
      },
      {
        field: "unitOfMeasure",
        header: t("books.unitOfMeasure"),
        style: { minWidth: 120,maxWidth:120 },
        body:unitofMeasureBody
      },
      {
        field: "itemQuantity",
        header: t("books.itemQuantity"),
        style: { minWidth: 90,maxWidth:90 },
      },
      {
        field: "unitPriceBefore",
        header: t("books.unitPrice"),
        style: { minWidth: 150,maxWidth:150 },
        body: (row) =>
            func.getFormattedMoneyValue(row?.unitPriceBefore ?? 0),
        align:"right"
      },
      {
        field: "vatRate",
        header: t("books.vatRate"),
        style: { minWidth: 85,maxWidth: 85 },
        body: (row) => (row?.vatRate ?? "0") + "%",
        align:"right"
      },
      {
        field: "allowanceValue",
        header: t("books.allowanceValue"),
        style: { minWidth: 140,maxWidth:140 },
        align:"right"
      },
      {
        field: "itemAllowancePercent",
        header: t("books.itemAllowancePercent"),
        style: { minWidth: 120,maxWidth:120 },
        align:"right"
      },
      {
        field: "linePriceBefore",
        header: t("books.linePriceBefore"),
        style: { minWidth: 150,maxWidth:150 },
        body: (row) =>
            func.getFormattedMoneyValue(row?.linePriceBefore ?? 0),
        align:"right"
      },
      {
        field: "vatAmount",
        header: t("books.vatAmount"),
        style: { minWidth: 150,maxWidth:150 },
        body: (row) =>
            func.getFormattedMoneyValue(row?.vatAmount ?? 0),
        align:"right"
      },
      {
        field: "",
        header: "",
        style: { width: 2500 },
      },
    ];


  const rowExpansionTemplate = () => {
    if (loadingExpandData) return <div style={{width:"100vw"}}><Loader /> </div>
    return (
      <div style={{width:"95vw"}}>
        <PrimeTable
            value={expandedData}
            columns={expandedColumns}
            showFilters={false}
            paginator={false}
            filterDisplay={false}
            responsiveLayout="scroll"
        />
      </div>
    );
  };
  
  const filterStatisticCard = (
      typeOptions,
      {
        title,
        height = "75px",
        cardClasses = "grid-4",
        type = "toggle",
        showCount = true,
        userRoles,
        singleSelect=false
      } = {}
  ) => {
    let params = { ...filters.params };

    return (
        <>
          <div className={`card card-fill filter-card`}>
          {title && <div className="filters-header">
            <span className="header-underline">{title}</span>
            </div>}
            <div
                className={`card-body filters-list-group ${cardClasses}`}
                style={{ height }}
            >
              {typeOptions.map((opt) => {
                if (opt?.userRoles && !opt?.userRoles.includes(user.role.id))
                  return <></>;

                let found = opt?.multiSelect
                ? params[opt.key]?.some((optValue) => optValue == opt.value)
                : opt.value == params[opt.key];

                const onChangeHandle = () => {
                  if (opt.disabled) return;
                  let value=null
                  if (found) {
                    value = opt?.multiSelect
                        ? [...params[opt.key]].filter((val) => val !== opt.value)
                        : null;
                    if(!singleSelect) {
                      handleParamsChange(null, opt.key, value);
                      getFilteredResults({
                        data: {
                          ...filters,
                          params: { ...params, [opt.key]: value },
                        }
                      });
                    }
                  } else {
                    value = opt?.multiSelect
                        ? [...(params[opt.key] ? params[opt.key] : []), opt.value]
                        : opt.value;
                    if(!singleSelect){
                      handleParamsChange(null, opt.key, value);
                      getFilteredResults({
                        data: {
                          ...filters,
                          params: { ...params, [opt.key]: value },
                        }
                      });
                    }
                  }
                  if(singleSelect){
                    let tempParams={
                      ...filters.params,
                    [opt.key]:value}

                    typeOptions.forEach(nestedOpt=>{
                      if(nestedOpt.key!=opt.key){
                        tempParams[nestedOpt.key]=null
                      }
                    })
                    setFilters((filters) => ({
                      ...filters,
                      params: {
                        ...tempParams,
                      },
                    }));
                    getFilteredResults({
                      data: {
                        ...filters,
                        params: { ...tempParams },
                      }
                    });
                  }
                };

                return (
                    <li
                        key={opt.value + opt?.key}
                        className={`filters-list-group-item ${
                            opt?.className ?? ""
                        }  ${found ? "active" : ""}`}
                        onClick={onChangeHandle}
                    >
                      {type == "checkbox" && (
                          <div className="custom-control pl-4 custom-checkbox mb-2">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                name="showDeleted"
                                checked={found}
                                onChange={() => {}}
                            />
                            <label
                                style={{
                                  paddingTop: "3px",
                                  paddingLeft: "6px",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                }}
                                className="custom-control-label"
                            >
                              {opt.label}
                            </label>
                          </div>
                      )}
                      {type == "toggle" && (
                          <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={found}
                                onChange={() => {}}
                            />
                            <label
                                className="custom-control-label"
                                style={{ fontSize: "14px" }}
                            >
                              {opt.label}
                            </label>
                          </div>
                      )}
                      {showCount && opt?.showCount == undefined && (
                          <span className="badge rounded-pill rounded-pill-bg">
                      {opt?.count ?? 0}
                    </span>
                      )}
                    </li>
                );
              })}
            </div>
          </div>
        </>
    );
  };

  const exportColumns = useCallback(
      ({ simpleExport }) => {
        if (simpleExport) {
          return (
              filteredColumns?.map((col) => ({
                ...col,
                field:col.field,
                header: col.header ?? func.camelToFlat(col.field),
              })) ?? []
          );
        }
        return (
            columns?.map((col) => ({
              ...col,
              field:col.field,
              header: col.header ?? func.camelToFlat(col.field),
            })) ?? []
        );
      },
      [data, columns, filteredColumns, i18n.language,csvData]
  );

  const openDropDownModal=()=>{
    swal(
      <TaxCategorizationPurchase
        tempFilters={tempFilters}
        totalCount={tableRows.totalRows}
      />,
      {
        className: "books-modal",
        closeOnClickOutside: true,
        closeOnEsc: true,
        buttons: false,
        title: `${t("books.taxCategorization")}`,
      }
    );
  }

  const headerButtonsLeft = (
      <>
       <div className={`p-2 d-flex align-items-center justify-content-start`} style={{width:350,gap:"10px"}}>
        <Dropdown
          value={taxCategorization?.label}
          options={tempFilters}
          optionValue= "label"
          optionLabel= "label"
          placeholder={t("books.taxCategorization")}
          scrollHeight="600px"
          display="chip"
          panelStyle={{
            width:400
          }}
          onChange={(option)=>{
            const filter=tempFilters.find(filter=>filter.label==option.value)
            if (filter){
              setTaxCategorization(filter)
            }
          }}
          style={{ height: "35px",minWidth:250, fontSize: "14px" }}
          itemTemplate={(opt)=>filterItemTemplate(opt)}
        />
         <div className="btn btn-primary open-modal-icon" onClick={openDropDownModal}>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare}  />
          </div>
        </div>
      </>

  );



  const filterItemTemplate = (option) => {
    return (
        <div className="d-flex justify-content-between align-items-center w-100">
          <div
              // style={{width: "300px"}}
          >
            {option.label}
          </div>
          <div>
            {option?.showCount == undefined && (
                <span className="badge bg-primary rounded-pill mr-1 ml-3 text-white">
                {option?.count1 ?? 0}
              </span>
            )}
          </div>
        </div>
    );
  };


  return (
      <>
        <div className="d-flex flex-wrap justify-content-between align-items-center my-2">
          <div className="left-header d-flex align-items-center ">
            <h3 className="mb-0 d-flex align-items-center">
              {t("navbar.purchasesBook")}
            </h3>
            <small className="ml-3">
              <ExportCsvPrime
                  data={csvData}
                  onClick={() => getFilteredResults({ exportCsv: true })}
                  columns={exportColumns({ simpleExport: true })}
                  excludedColumns={["space", "actions", "print"]}
                  tooltip={t("books.onlyGridColumns")}
              />
            </small>
            <small className="ml-3">
              <ExportCsvPrime
                  label="Export All"
                  data={csvData}
                  onClick={() => getFilteredResults({exportCsv:true})}
                  columns={exportColumns({ simpleExport: false })}
                  excludedColumns={["space","actions","print"]}
                  tooltip={t("books.allColumns")}
              />
            </small>
          </div>
          <CisMessage objectType={"GET_P_INVOICES_FROM_CIS"} />
          <div className="ml-md-auto mb-md-0 d-flex align-items-center">
            <label className="align-items-center mr-2 mb-0">
              {t("queue.period")}:
            </label>
            <DatePicker
                id="periodDate"
                ref={periodRef}
                selected={period}
                popperPlacement="bottom"
                popperModifiers={{ flip: { behavior: ["bottom"] }, preventOverflow: { enabled: true }, hide: { enabled: false } }}
                placeholderText={t("queue.selectDate")}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                onChange={(date) =>
                    setPeriod(new Date(date).setHours(2, 0, 0, 0))
                }
                customInput={
                  <div className="form-control d-flex justify-content-center align-items-center" style={{cursor:"pointer"}}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <div className="ml-1">
                      {period
                          ? moment(period).format("MM/yyyy")
                          : moment().format("MM/yyyy")}
                    </div>
                  </div>
                }
            />
          </div>
          {fullCompany?.workflow?.id == 2 ?
            <>
          <div className="col-6 col-md-2 pr-0 d-flex align-items-center">
              <label className="align-items-center mr-2 mb-0">BU</label>
              <Select
                  styles={selectStyle}
                  className="w-100"
                  style={{ width: "14em" }}
                  options={businessUnitCodes}
                  value={
                    businessUnitCodes.find(
                      (businessUnitCode) =>
                      businessUnitCode.value === sessionData.businessUnitCode
                      ) || {
                        label: t("invoice.businessUnitCode"),
                        value: "",
                      }
                    }
                    isSearchable={true}
                    onChange={(businessUnitCode) => {
                      setPersistedData({
                        ...persistedData,
                        businessUnitCode: businessUnitCode.value,
                    });
                    setSessionData({
                      ...sessionData,
                      businessUnitCode: businessUnitCode.value,
                    });
                  }}
                  name="businessUnitCode"
                  />
                  </div>
                  </>
                  : ""}
        </div>
        <div className="row px-1">
          <ul className="col-12 col-sm-6 col-md-2 p-2 mb-1">
            {filterStatisticCard(typeOptions, {
              title: t("invoice.type"),
              cardClasses: "grid-2",
              // showCount:false
            })}
          </ul>
          <ul className="col-12 col-sm-6 col-md-4 p-2 mb-1">
            {filterStatisticCard(sourceOptions, {
              title: t("purchases.cisStatus"),
              cardClasses: "grid-4",
            })}
          </ul>
          <ul className="col-12 col-sm-6 col-md-2 p-2 mb-1">
            {filterStatisticCard(currencyOptions, {
              title: t("invoice.currency"),
              cardClasses: "grid-2",
            })}
          </ul>
          <ul className={`col-12 col-sm-6 col-md-4 p-2 mb-1`}>
            {filterStatisticCard(allOtherOptions, {
              title: t("invoice.other"),
              cardClasses: "grid-4",
              singleSelect:true
              // type: "checkbox",
            })}
          </ul>
          {/*<ul className="col-12 col-sm-6 col-md-2 p-2"></ul>*/}
        </div>
        <div className="pdf-container" ref={pdfContainer}>
          {pdfToDisplay && (
              <div className="pdf-viewer">
                <div
                    className="pdf-overlay"
                    onClick={() => {
                      setTimeout(() => {
                        setPdfToDisplay(null);
                      }, 150);
                      pdfContainer.current.classList.toggle("open");
                    }}
                />
                <div className="pdf-content">
                  <embed src={pdfToDisplay} />
                </div>
              </div>
          )}
        </div>
        <div>
          <PrimeTable
              rowClassName="tvsh-categorization"
              showFilters={true}
              reorderableColumns={true}
              filters={[filters, setFilters]}
              onFilter={(data) => {
                setFilters(data.filters);
              }}
              defaultFilters={defaultFilters}
              headerButtonsLeft={headerButtonsLeft}
              tableRows={[tableRows, setTableRows]}
              value={data}
              selectedColumns={[selectedColumns, setSelectedColumns]}
              searchableKeys={["sellerName", "sellerCountry", "sellerNuis"]}
              columns={columns}
              expandedRows={expandedRows}
              onRowExpand={onRowExpand}
              onRowCollapse={onRowCollapse}
              onRemoteDataChange={(remoteData) => {
                const updatedFilters = remoteData.filters ?? filters
                const updatedTableData = remoteData.tableRows ?? tableRows
                let { currentPage, rowsPerPage, sortCriteria } = updatedTableData
                sortCriteria=sortCriteria?[sortCriteria]:[]
                getFilteredResults({ data: updatedFilters, pageSize: rowsPerPage, page: currentPage, sortCriteria })
              }}
              responsiveLayout="scroll"
              rowExpansionTemplate={rowExpansionTemplate}
              onColReorder={(reorder) => onColReorder({ reorder, setSelectedColumns, childrenCount: 1 })}
              defaultSortCriteria={defaultSortCriteria}
          >
            <Column
                expander={() => true}
                style={{ width: "0.5em" }}
                header=""
                field="expansion"
                frozen={true}
            />
          </PrimeTable>
        </div>
      </>
  );
};

export default PurchasesBook;
