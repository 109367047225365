import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import selectStyle from "../../design/selectStyle";
import DateRange from "../global/DateRange";
import { useTranslation } from "react-i18next";
import mainAxios from "../../services/mainAxios";
import { useAppContext, usePersistedState } from "../../AppContext";
import { Link } from "react-router-dom";
import moment from "moment";
import DoughnutPieTable from "./DoughnutPieTable";
import func from "../../services/functions";
import CashBalance from "./CashBalance";
import TcrCashBalance from "./TcrCashBalance";
import StatisticsInfo from "./StatisticsInfo";
import ComparisonBu from "./ComparisonBu";
import CashInsightforPeriod from "./CashInsightforPeriod";
import AlertCertificate from "../global/AlertCertificate";
import PurchasesDashoard from "./PurchasesDashboard";
import AlertSubscription from "../global/AlertSubscription";
import SalesAndPurchases from "./SalesAndPurchases";
import TopTenSuppliers from "./TopTenSuppliers";
import TopTenCustomers from "./TopTenCustomers";

const Dashboard = () => {
  const { t } = useTranslation("translations");
  const [state, setState] = useState({
    totalsByBranch: null,
    allTotals: null,
    allStats: null,
    todayTotalsByBranch: null,
    todayTotals: null,
    todayStats: null,
    purchaseStatsTotals: null,
  });
  const [loading, setLoading] = useState({
    tcrCashBalance: true,
    purchaseStats: true,
  });

  const {
    user: [user],
    selectedCompany: [selectedCompany],
    dates: [dates, setDates],
  } = useAppContext();
  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const [hideCash, setHideCash] = usePersistedState(false);

  const [sessionData, setSessionData] = useState({
    fromDate: dates.fromDate,
    toDate: dates.toDate ,
    businessUnitCode: null,
  });
  const userCanCreate = [1, 2, 4].includes(user.role.id);
  const businessUnitCodes = React.useMemo(() => {
    return [
      {
        label: t("invoice.businessUnitCode"),
        value: null,
      },
    ].concat(
      company.branches.map((branch) => {
        return {
          label: branch.businUnitCode + " | " + branch.sellerName,
          value: branch.businUnitCode,
        };
      })
    );
  }, [selectedCompany]);

  let buCodes = company.branches?.map((branch) => branch.businUnitCode) ?? [];

  const getFilteredResult = (fromDate, toDate) => {
    return mainAxios.post("/endpoint/v2/reports/queue/branch/aggregate", {
      object: "GetQueueCashDepositStatisticsAnalytics",
      params: { businessUnitCode: buCodes.join(",") },
      fromDate,
      toDate,
    });
  };

  useEffect(() => {
    if (!(sessionData?.fromDate && sessionData?.toDate)) {
      return;
    }
    let fromDate = moment(sessionData?.fromDate).format("YYYY-MM-DD");
    let toDate = moment(sessionData?.toDate).format("YYYY-MM-DD");

    getFilteredResult(fromDate, toDate)
      .then((res) => {
        setLoading((prev) => ({ ...prev, tcrCashBalance: false }));
        if (res.data.status) {
          let filtered = res.data?.content?.filter(
            (stat) => stat.tcrCode !== stat.businessUnitCode
          );

          let allTotals = getTotals(filtered);
          let totalsByBranch = groupedTotalsByBranch(filtered);
          setState((state) => ({
            ...state,
            allTotals,
            allStats: filtered,
            totalsByBranch,
          }));
        }
      })
      .catch(() => {
        setLoading((prev) => ({ ...prev, tcrCashBalance: false }));
      });
  }, [sessionData?.fromDate, sessionData?.toDate, selectedCompany]);

  useEffect(() => {
    if (hideCash) return;
    let today = moment().format("YYYY-MM-DD");

    getFilteredResult(today, today).then((res) => {
      if (res?.data?.status && res.data?.content?.length>0) {
        let filtered = res?.data?.content.filter(
          (stat) =>
            stat.tcrCode !== stat.businessUnitCode &&
            stat.tcrCode !== "TS001NC001"
        );
        let todayTotals = getTotals(res.data?.content);
        let todayTotalsByBranch = groupedTotalsByBranch(filtered);
        setState((state) => ({
          ...state,
          todayTotals,
          todayTotalsByBranch,
          todayStats: filtered,
        }));
      }
    });
  }, [selectedCompany, hideCash]);

  const getFilteredPurchaseResults = (fromDate, toDate) => {
    let fromDateFormatted = moment(fromDate).format("YYYY-MM-DD");
    let toDateFormatted = moment(toDate).format("YYYY-MM-DD");
    let endpoint = sessionData?.businessUnitCode
      ? "/endpoint/v2/reports/mypurchase/branch/aggregate"
      : "/endpoint/v2/reports/mypurchase/company/aggregate";
    return mainAxios.post(endpoint, {
      object: "GetMyPurchaseCashDepositStatisticsAnalytics",
      fromDate: fromDateFormatted,
      toDate: toDateFormatted,
      ...(sessionData?.businessUnitCode
        ? { params: { businessUnitCode: sessionData?.businessUnitCode } }
        : {}),
    });
  };

  useEffect(() => {
    if (!(sessionData?.fromDate && sessionData?.toDate)) {
      return;
    }
    getFilteredPurchaseResults(sessionData?.fromDate, sessionData?.toDate).then(
      (res) => {
        setLoading(false);
        if (res.data.status && res.data?.content) {
          let filtered = res.data.content.filter(
            (stat) => stat.tcrCode !== stat.businessUnitCode
          );

          let allTotals = getTotals(filtered);
          setState((state) => ({
            ...state,
            purchaseStatsTotals: allTotals,
          }));
        }
      }
    );
  }, [sessionData, selectedCompany]);

  const getTotals = (data) => {
    if (data?.length > 0) {
      const {
        id,
        tcrCode,
        businessUnitCode,
        actionFrom,
        company,
        purchaseUnit,
        ...rest
      } = data[0];
      let stats = {};
      Object.keys(rest).forEach((key) => {
        stats[key] = data.reduce((sum, tcrStat) => sum + tcrStat[key], 0);
      });
      return stats;
    }
    return [];
  };

  const groupedTotalsByBranch = (allStats) => {
    var result = [];
    allStats.reduce((res, curr) => {
      const { id, actionFrom, company, recordDate, ...rest } = curr;

      if (!res[curr.businessUnitCode]) {
        res[curr.businessUnitCode] = rest;
        result.push(res[curr.businessUnitCode]);
      } else {
        Object.keys(rest).forEach((key) => {
          if (typeof curr[key] === "number") {
            res[curr.businessUnitCode][key] += curr[key];
          } else {
            res[curr.businessUnitCode][key] = curr[key];
          }
        });
      }
      return res;
    }, {});
    return result;
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row justify-content-between align-items-center">
          <h3 className="mb-0 col-auto">{t("navbar.dashboard")}</h3>
          <div className="custom-control custom-checkbox mr-sm-2">
            <input
              type="checkbox"
              className="custom-control-input"
              id="hideCash"
              name="hideCash"
              checked={hideCash}
              cursor="pointer"
              onChange={(e) => setHideCash(e.target.checked)}
            />
            <label
              style={{
                paddingTop: "3px",
                paddingLeft: "3px",
                cursor: "pointer",
              }}
              htmlFor="hideCash"
              className="custom-control-label font-weight-bold text-capitalize text-muted"
            >
              {t("dashboard.hideCash")}
            </label>
          </div>
          <div className="actions mr-3">
            {userCanCreate && (
              <Link to={"/create-invoice"} className="btn btn-sm btn-primary">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                {t("navbar.create")}
              </Link>
            )}
          </div>
          <AlertCertificate />
          <AlertSubscription />
          <div className="ml-md-auto mb-md-0">
            <DateRange
              fromDate={sessionData.fromDate}
              toDate={sessionData.toDate}
              onChange={(dates) => {
                let fromDate = dates[0];
                let toDate = dates[1];
                setSessionData({ ...sessionData, fromDate, toDate });
                setDates({ fromDate, toDate });
              }}
            />
          </div>

          <div className="col-6 col-md-2 d-flex align-items-center">
            <label className="align-items-center mr-1 mb-0">BU</label>

            <Select
              styles={selectStyle}
              className="w-100"
              options={businessUnitCodes}
              value={
                businessUnitCodes.find(
                  (businessUnitCode) =>
                    businessUnitCode.value === sessionData?.businessUnitCode
                ) || {
                  label: t("invoice.businessUnitCode"),
                  value: "",
                }
              }
              isSearchable={true}
              onChange={(businessUnitCode) => {
                setSessionData({
                  ...sessionData,
                  businessUnitCode: businessUnitCode?.value,
                });
              }}
              name="businessUnitCode"
            />
          </div>
        </div>
        <div className="w-100 clearfix pb-0">
          <StatisticsInfo allTotals={state.allTotals} />
        </div>
        <div className="row">
          {!hideCash && (
            <>
              <div
                className="col-md-4 col-12 mb-4 d-flex"
                style={{ height: "520px" }}
              >
                <DoughnutPieTable
                  todayTotalsByBranch={state.todayTotalsByBranch}
                  sessionData={sessionData}
                  title={t("dashboard.buCompare")}
                  businessUnitCodes={businessUnitCodes}
                />
              </div>

              <div
                className="col-md-4 col-12 mb-4 d-flex"
                style={{ height: "520px" }}
              >
                <TcrCashBalance
                  stats={state.todayStats}
                  sessionData={sessionData}
                  loading={loading.tcrCashBalance}
                  title={t("dashboard.tcrCashBalance")}
                />
              </div>

              <div
                className="col-md-4 col-12 mb-4 d-flex"
                style={{ height: "520px" }}
              >
                <CashBalance
                  title={t("dashboard.cashBalanceToday")}
                  todayTotalsByBranch={state.todayTotalsByBranch}
                  todayTotals={state.todayTotals}
                  sessionData={sessionData}
                />
              </div>
              <div
                className="col-md-6 col-12  mb-4 mb-0 d-flex"
                style={{ height: "520px" }}
              >
                <ComparisonBu
                  title={t("dashboard.buComparision")}
                  state={state}
                  sessionData={sessionData}
                  groupedTotalsByBranch={groupedTotalsByBranch}
                  getFilteredResult={getFilteredResult}
                  getTotals={getTotals}
                />
              </div>

              <div
                className="col-md-6 col-12  mb-4 mb-0 d-flex"
                style={{ height: "520px" }}
              >
                <CashInsightforPeriod
                  title={t("dashboard.cashInsightforPeriod")}
                  sessionData={sessionData}
                  groupedTotalsByBranch={groupedTotalsByBranch}
                  businessUnitCodes={businessUnitCodes}
                />
              </div>
            </>
          )}

          <div className="col-md-5 col-12 d-flex" style={{ height: "520px" }}>
            <PurchasesDashoard
              title={t("dashboard.purchaseDashboard")}
              purchaseStatsTotals={state.purchaseStatsTotals}
              loading={loading.purchaseStatsTotals}
            />
          </div>

          <div className="col-md-7 col-12  d-flex" style={{ height: "520px" }}>
            <SalesAndPurchases
              getFilteredPurchaseResults={getFilteredPurchaseResults}
              getFilteredResult={getFilteredResult}
              state={state}
              sessionData={sessionData}
              currentPurchaseStats={state.purchaseStatsTotals}
              getTotals={getTotals}
              groupedTotalsByBranch={groupedTotalsByBranch}
              title={t("dashboard.salesAndPurchase")}
            />
          </div>
          <div className="col-md-6 col-12 d-flex mt-4 mb-4">
            <TopTenSuppliers
              fromDate={sessionData.fromDate}
              toDate={sessionData.toDate}
              title={t("topten.titleSupplier")}
            />
          </div>
          <div className="col-md-6 col-12 d-flex mt-4 mb-4">
            <TopTenCustomers
              fromDate={sessionData.fromDate}
              toDate={sessionData.toDate}
              title={t("topten.titleCustomer")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
